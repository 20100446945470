import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardHeader,
  IonChip,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonLabel,
  IonModal,
  IonPage,
  IonRow,
  IonSearchbar,
  IonTitle,
  IonToolbar,
  useIonAlert,
  useIonLoading,
  useIonToast,
} from "@ionic/react";
import "./CSS/AllImages.css";
import { url_app_inventario } from "../Urls";
import {
  add,
  alertCircleOutline,
  arrowBack,
  chevronUp,
  close,
  closeCircle,
  cloudDoneOutline,
  cloudUpload,
  trashOutline,
} from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import { deleteObject } from "firebase/storage";
import { UploadImagesFirebase } from "../Logic/ImageUpload";
import { defaultFolders } from "../Logic/DataDownload";
import { UploadDataCartellaAllImmagini } from "../Logic/DataUpload";
import UploadMultipleImagesPortfolio from "../components/UploadMultipleImagesPortfolio";

interface PageProps {
  allImmagini: any;
  getImmagini: any;
  cartelle: any;
  updateCartelle: () => void;
}

const AllImages: React.FC<PageProps> = ({
  allImmagini,
  getImmagini,
  cartelle,
  updateCartelle,
}) => {
  //VARIABILI ------------------------------
  const scrollRef = useRef<HTMLIonContentElement | null>(null);
  const [searchTextModale, setSearchTextModale] = useState("");
  const [filtroImmagini, setFiltroImmagini] = useState<string>();
  const [presentToast] = useIonToast();
  const [presentAlert] = useIonAlert();
  const [presentAlertNomeCartella] = useIonAlert();
  const [present, dimiss] = useIonLoading();

  const [immagini, setImmagini] = useState<any>([]);

  const [doUpdate, setDoUpdate] = useState(false);

  const [filtroVisualizzazioneImmagini, setFiltroVisualizzazioneImmagini] =
    useState("column-portfolio-2");

  const [modaleAggiungiImmagini, setModaleAggiungiImmagini] = useState(false);

  const [nomeCartella, setNomeCartella] = useState<string | undefined>(
    undefined
  );
  const [inputImmagini, setInputImmagini] = useState<any>([]);
  const [linkImmagini, setLinkImmagini] = useState<any>([]);
  const [allImagesUploaded, setAllImagesUploaded] = useState<boolean>(false);

  const [localCartelle, setLocalCartelle] = useState<any>(defaultFolders);
  //CONDIZIONI -----------------------------
  useEffect(() => {

    console.log("Tutte le immagini useEffect: ", allImmagini)
    setImmagini(allImmagini);

  }, [allImmagini]);


  const scrollToTop = () => {
    scrollRef.current && scrollRef.current.scrollToTop(500);
  };

  const handleSearch = (testoCercato: string) => {
    setFiltroImmagini(testoCercato);
    let arr = Array.from(
      window.document.getElementsByClassName("immaginiGalleria")
    );
    const query = testoCercato.toLowerCase();
    requestAnimationFrame(() => {
      arr.forEach((item: any) => {
        const shouldShow = item.textContent.toLowerCase().indexOf(query) > -1;
        item.style.display = shouldShow ? "block" : "none";
      });
    });
  };

  const handleEliminaImmagine = (dataImmagine: any, index: number) => {
    presentAlert({
      header: "Attenzione!",
      message:
        "L'eliminazione è definitiva! E l'immagine potrebbe essere utilizzata anche per altri prodotti ecc.",
      buttons: [
        {
          text: "Annulla",
          role: "cancel",
        },
        {
          text: "Conferma",
          role: "confirm",
          handler: () => {
            handleEliminaDalDB(dataImmagine, index);
          },
        },
      ],
    });
  };

  const handleAggiungiImmagini = async () => {
    if (nomeCartella !== undefined && inputImmagini.length > 0) {
      setModaleAggiungiImmagini(false);
      present({
        message: "Sto caricando le immagini...",
      });
      let auxId = Date.now();

      await UploadImagesFirebase(
        inputImmagini!,
        nomeCartella + auxId + new Date(),
        setLinkImmagini,
        nomeCartella,
        setAllImagesUploaded
      );
    } else {
      presentToastType(
        "Attenzione selezionare e completare tutti i campi.",
        "warning",
        () => { }
      );
    }
  };

  useEffect(() => {
    if (allImagesUploaded === true) {
      setSearchTextModale("");
      getImmagini();
      dimiss();
    }
  }, [allImagesUploaded]);

  const handleAggiungiNuovaCartella = () => {
    presentAlertNomeCartella({
      header: "Creazione nuova cartella",
      inputs: [
        {
          type: "text",
          placeholder: "Nome",
          name: "nomeCartella",
        },
      ],
      buttons: [
        {
          text: "Annulla",
          role: "cancel",
          handler: () => { },
        },
        {
          text: "Crea",
          role: "confirm",
          handler: (alertData) => {
            let x = [...cartelle.allCartelleImmagini];
            x.push(alertData.nomeCartella);

            UploadDataCartellaAllImmagini(x).then(() => {
              console.log("Cartella caricata con successo.");
              updateCartelle();
            });
          },
        },
      ],
    });
  };

  const handleDeleteCartella = (inde: number) => {
    presentAlertNomeCartella({
      header:
        "Attenzione! L'eliminazione della cartella eliminirà anche le immagini all'interno.",
      buttons: [
        {
          text: "Annulla",
          role: "cancel",
          handler: () => { },
        },
        {
          text: "Elimina cartella",
          role: "confirm",
          handler: () => {
            let x = [...cartelle.allCartelleImmagini];

            let y = x.splice(inde, 1);

            UploadDataCartellaAllImmagini(x).then(() => {
              console.log("Cartella caricata con successo.");
              updateCartelle();
              handleEliminaAllFilesFrom(y[0]);
            });
          },
        },
      ],
    });
  };

  const handleEliminaAllFilesFrom = (folder: string) => {
    console.log("handleEliminaAllFilesFrom : ", folder);
    let auxArrayImmaginiDaEliminare: any = [];
    immagini?.map((immagineObj: any, index: number) => {
      if (immagineObj.cartella === folder) {
        let obj = {
          immagineObj: immagineObj,
          index: index,
        };
        auxArrayImmaginiDaEliminare.push(obj);
      }
    });

    auxArrayImmaginiDaEliminare.forEach((element: any, index: number) => {
      deleteObject(element.immagineObj.riferimentoImmagine)
        .then(() => {
          // File deleted successfully
          console.log("Eliminazione immagine DONE!");
          presentToastType(
            "Immagine eliminata con successo!",
            "success",
            () => { }
          );
          // handleEliminaLocalmente(element.index);
        })
        .catch((error) => {
          // Uh-oh, an error occurred!
          console.log("Errore eliminazione immagine: ", error);
          presentToastType(
            "Ops! Qualcosa è andato storto. Riavvia l'app.",
            "danger",
            () => { }
          );
        });
    });
    setSearchTextModale("");
    getImmagini();
  };

  const handleEliminaDalDB = (dataImmagine: any, index: number) => {
    deleteObject(dataImmagine.riferimentoImmagine)
      .then(() => {
        // File deleted successfully
        console.log("Eliminazione immagine DONE!");
        presentToastType(
          "Immagine eliminata con successo!",
          "success",
          () => { }
        );
        setSearchTextModale("");
        getImmagini();
      })
      .catch((error) => {
        // Uh-oh, an error occurred!
        console.log("Errore eliminazione immagine: ", error);
        presentToastType(
          "Ops! Qualcosa è andato storto. Riavvia l'app.",
          "danger",
          () => { }
        );
      });
  };

  // const handleEliminaLocalmente = (index: number) => {
  //   setImmagini((immagini: any) =>
  //     immagini.filter((_: any, i: any) => i !== index)
  //   );
  //   setDoUpdate(true);
  // };

  const presentToastType = (
    message: string,
    type: "danger" | "success" | "warning",
    buttonEv: () => void
  ) => {
    const iconType = {
      danger: close,
      success: cloudDoneOutline,
      warning: alertCircleOutline,
    };

    presentToast({
      message: message,
      position: "top",
      duration: type === "danger" ? undefined : 3000,
      icon: iconType[type],
      color: type,
      buttons: [
        {
          text: "Chiudi",
          role: "cancel",
          handler: () => {
            buttonEv();
          },
        },
      ],
    });
  };

  //RETURN ---------------------------------
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton
              onClick={() => {
                getImmagini();
              }}
              routerLink={url_app_inventario}
              routerDirection="back"
            >
              <IonIcon icon={arrowBack} />
              Inventario
            </IonButton>
          </IonButtons>
          <IonTitle>Tutte le immagini</IonTitle>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar
            value={searchTextModale}
            placeholder={"Inserisci il nome della cartella"}
            onIonChange={(e) => {
              setSearchTextModale(e.detail.value!);
              handleSearch(e.detail.value!);
            }}
          ></IonSearchbar>
          <div>
            <IonButton
              expand="block"
              onClick={() => setModaleAggiungiImmagini(true)}
            >
              <IonIcon icon={cloudUpload} className="ion-margin-end" />
              Carica immagini
            </IonButton>
            <div className="display-flex">
              <IonLabel>
                <p className="ion-padding">
                  Filtro visualizzazione:
                  <IonChip
                    color={
                      filtroVisualizzazioneImmagini === "column-portfolio-1"
                        ? "primary"
                        : undefined
                    }
                    onClick={() =>
                      setFiltroVisualizzazioneImmagini("column-portfolio-1")
                    }
                  >
                    1/1
                  </IonChip>
                  <IonChip
                    color={
                      filtroVisualizzazioneImmagini === "column-portfolio-2"
                        ? "primary"
                        : undefined
                    }
                    onClick={() =>
                      setFiltroVisualizzazioneImmagini("column-portfolio-2")
                    }
                  >
                    1/2
                  </IonChip>
                  <IonChip
                    color={
                      filtroVisualizzazioneImmagini === "column-portfolio-3"
                        ? "primary"
                        : undefined
                    }
                    onClick={() =>
                      setFiltroVisualizzazioneImmagini("column-portfolio-3")
                    }
                  >
                    1/3
                  </IonChip>
                  <IonChip
                    color={
                      filtroVisualizzazioneImmagini === "column-portfolio-4"
                        ? "primary"
                        : undefined
                    }
                    onClick={() =>
                      setFiltroVisualizzazioneImmagini("column-portfolio-4")
                    }
                  >
                    1/4
                  </IonChip>
                </p>
              </IonLabel>
              <IonLabel>
                <p className="ion-padding-start ion-padding-end">
                  Cartelle:{" "}
                  <IonChip
                    color="success"
                    onClick={() => handleAggiungiNuovaCartella()}
                  >
                    Aggiungi
                    <IonIcon icon={add}></IonIcon>
                  </IonChip>
                  {localCartelle?.map((cartella: string, index: number) => {
                    return (
                      <IonChip
                        key={"cartelle" + index}
                        color={
                          searchTextModale === cartella ? "primary" : undefined
                        }
                        onClick={() => {
                          setSearchTextModale(cartella);
                          handleSearch(cartella);
                        }}
                      >
                        {cartella}
                      </IonChip>
                    );
                  })}
                  {cartelle?.allCartelleImmagini?.map(
                    (cartella: string, index: number) => {
                      return (
                        <IonChip
                          key={"cartelleDB" + index}
                          color={
                            searchTextModale === cartella
                              ? "primary"
                              : undefined
                          }
                          onClick={() => {
                            setSearchTextModale(cartella);
                            handleSearch(cartella);
                          }}
                        >
                          {cartella}
                          <IonIcon
                            onClick={() => {
                              handleDeleteCartella(index);
                            }}
                            className="ion-margin-start"
                            icon={closeCircle}
                          />
                        </IonChip>
                      );
                    }
                  )}
                </p>
              </IonLabel>
            </div>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen ref={scrollRef}>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Tutte le immagini</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonFab slot="fixed" vertical="bottom" horizontal="start">
          <IonFabButton onClick={() => scrollToTop()}>
            <IonIcon icon={chevronUp}></IonIcon>
          </IonFabButton>
        </IonFab>

        {/* CONTENUTO */}

        <div className="row-portfolio">
          {immagini.length === 0 ?
            <h1>
              Seleziona una cartella
            </h1>
            :
            null
          }
          {immagini?.map((immagineObj: any, index: number) => {
            return (
              <IonCard
                key={"immagine-" + index}
                className={"immaginiGalleria " + filtroVisualizzazioneImmagini}
              >
                <IonCardHeader>
                  <IonGrid>
                    <IonRow className="ion-align-items-center">
                      <IonCol>
                        <IonLabel>
                          <p>Cartella:</p>
                          <IonChip
                            onClick={() => {
                              setSearchTextModale(immagineObj.cartella);
                              handleSearch(immagineObj.cartella);
                            }}
                          >
                            {immagineObj.cartella}
                          </IonChip>
                        </IonLabel>
                      </IonCol>
                      <IonCol className="ion-text-end">
                        <IonButton
                          size="small"
                          color={"danger"}
                          onClick={() =>
                            handleEliminaImmagine(immagineObj, index)
                          }
                        >
                          <IonIcon icon={trashOutline} />
                        </IonButton>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonCardHeader>
                <IonImg src={immagineObj.urlImmagine} />
              </IonCard>
            );
          })}
        </div>

        {/* MODALI */}

        <IonModal
          isOpen={modaleAggiungiImmagini}
          onDidDismiss={() => setModaleAggiungiImmagini(false)}
        >
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton
                  color={"medium"}
                  onClick={() => setModaleAggiungiImmagini(false)}
                >
                  Chiudi
                </IonButton>
              </IonButtons>
              <IonTitle>Carica Immagini</IonTitle>
              <IonButtons slot="end">
                <IonButton
                  color={"success"}
                  onClick={() => handleAggiungiImmagini()}
                >
                  Carica
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>

          <IonContent fullscreen>
            <IonLabel>
              <h1 className="ion-padding">
                Seleziona cartella dove caricare: {nomeCartella}
              </h1>
              <p className="ion-padding-start ion-padding-end">
                <IonChip
                  color="success"
                  onClick={() => handleAggiungiNuovaCartella()}
                >
                  Aggiungi
                  <IonIcon icon={add}></IonIcon>
                </IonChip>
                {localCartelle?.map((cartella: string, index: number) => {
                  return (
                    <IonChip
                      key={"cartelle" + index}
                      color={nomeCartella === cartella ? "primary" : undefined}
                      onClick={() => {
                        setNomeCartella(cartella);
                      }}
                    >
                      {cartella}
                    </IonChip>
                  );
                })}
                {cartelle?.allCartelleImmagini?.map(
                  (cartella: string, index: number) => {
                    return (
                      <IonChip
                        key={"cartelleDB" + index}
                        color={
                          nomeCartella === cartella ? "primary" : undefined
                        }
                        onClick={() => {
                          setNomeCartella(cartella);
                        }}
                      >
                        {cartella}
                        <IonIcon
                          onClick={() => {
                            handleDeleteCartella(index);
                          }}
                          className="ion-margin-start"
                          icon={closeCircle}
                        />
                      </IonChip>
                    );
                  }
                )}
              </p>
            </IonLabel>

            <UploadMultipleImagesPortfolio setImage={setInputImmagini} />
          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default AllImages;
