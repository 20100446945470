import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonReorder,
  IonReorderGroup,
  IonTitle,
  IonToolbar,
  ItemReorderEventDetail,
  useIonLoading,
  useIonToast
} from '@ionic/react';
import './ModaleCategoria.css';
import {
  useEffect,
  useState
} from 'react';
import {
  add,
  alertCircleOutline,
  checkmark,
  close,
  closeOutline,
  cloudDoneOutline,
} from 'ionicons/icons';
import { UploadNewSezione, UploadNuovaCategoria } from '../../Logic/DataUpload';
import { UploadSingleImageFirebase } from '../../Logic/ImageUpload';
import { Prodotto } from '../../data/dataSheet';
import ModalInputImmagini from '../Modal_Input_Immagini/ModalInputImmagineSingola';
import ModaleModificaProdotti from '../Modale_Prodotti/ModaleModificaProdotti';
import CardSelezionaProdotti from '../CardSelezionaProdotti';

interface ContainerProps {
  oggettoDaModificare: any,
  indModifica: any,
  isOpen: boolean,
  setIsOpen: any,
  cartelle: any,
  allImmagini: any,
  allProdotti: any,

  allCategorie: any,

  AllSezioni: any,

  UpdateAll: any,

}

const ModaleModificaCategoriaInventario: React.FC<ContainerProps> = ({
  oggettoDaModificare,
  indModifica,
  isOpen,
  setIsOpen,
  cartelle,
  allImmagini,
  allProdotti,

  allCategorie,

  AllSezioni,

  UpdateAll,
}) => {
  //VARIABILI --------------------------------------------
  const folder = "/categorie";

  const [isModalModificheProdottiOpen, setIsModalModificheProdottiOpen] =
    useState(false);


  const [indiceModifica, setIndiceModifica] = useState<number>();
  const [idModifica, setIdModifica] = useState<number>();
  const [inputNomeModifiche, setInputNomeModifiche] = useState();
  const [inputDescrizioneModifiche, setInputDescrizioneModifiche] = useState();
  const [inputImmagineModifiche, setInputImmagineModifiche] = useState();
  const [inputProdottiModifiche, setInputProdottiModifiche] = useState<any>([]);

  const [linkImmagineModifiche, setLinkImmagineModifiche] = useState();

  const [immagineSelezionata, setImmagineSelezionata] =
    useState<any>(undefined);


  const [presentToast] = useIonToast();
  const [present, dimiss] = useIonLoading();
  //CONDIZIONI -------------------------------------------

  const resetImageInput = () => {
    setLinkImmagineModifiche(undefined);
    setImmagineSelezionata(undefined);
  }

  useEffect(() => {
    console.log("handleModifica()");
    setIndiceModifica(indModifica);
    setIdModifica(oggettoDaModificare?.id);
    setInputImmagineModifiche(oggettoDaModificare?.immagineUrl!);
    setInputNomeModifiche(oggettoDaModificare?.nome);
    setInputDescrizioneModifiche(oggettoDaModificare?.descrizione);
    setInputProdottiModifiche(oggettoDaModificare?.prodotti);
  }, [oggettoDaModificare])

  const handleAggiornaAllSezioni = (oggettoCategoria: any, idCategoria: any) => {
    AllSezioni.map((sezione: any, index: number) => {
      console.log(sezione);
      sezione.categorie.map((cate: any, inde: number) => {
        if (cate.id === idCategoria) {
          console.log("TROVATO LA CATEGORIA DA AGGIORNARE : ", sezione.nome, " nome cat:", cate.nome);
          sezione.categorie[inde] = oggettoCategoria;
          console.log(sezione);
          UploadNewSezione(sezione, sezione.id).then(() => {
            presentToastType("Sezione " + sezione.nome + " aggiornata.", "success", () => { });
          });
        }
      })
    })
  }

  const resetAllInputs = () => {
    setIndiceModifica(undefined);
    setIdModifica(undefined);
    setInputNomeModifiche(undefined);
    setInputDescrizioneModifiche(undefined);
    setInputImmagineModifiche(undefined);
    setInputProdottiModifiche([]);
    setLinkImmagineModifiche(undefined);
  };

  const handleSalvaModifiche = async () => {
    if (immagineSelezionata !== undefined) {
      present({
        message: "Sto salvando la categoria...",
      });
      const oggettoModificato = {
        id: idModifica,
        nome: inputNomeModifiche,
        descrizione: inputDescrizioneModifiche,
        immagineUrl: immagineSelezionata,
        prodotti: inputProdottiModifiche,
      };

      UploadNuovaCategoria(oggettoModificato, idModifica).then(() => {
        handleAggiornaAllSezioni(oggettoModificato, idModifica);
        UpdateAll();
        dimiss();
        resetAllInputs();
        setIsOpen(false);
      });
    } else {
      present({
        message: "Sto salvando la categoria...",
      });

      let oggettoModificato: any;
      if (inputImmagineModifiche === allCategorie[indiceModifica!].immagineUrl) {
        oggettoModificato = {
          id: idModifica,
          nome: inputNomeModifiche,
          descrizione: inputDescrizioneModifiche,
          immagineUrl: inputImmagineModifiche,
          prodotti: inputProdottiModifiche,
        };
        console.log(
          "handleSalvaModifiche - oggettoModificato : ",
          oggettoModificato
        );
        UploadNuovaCategoria(oggettoModificato, idModifica).then(() => {
          handleAggiornaAllSezioni(oggettoModificato, idModifica);
          UpdateAll();
          dimiss();
          resetAllInputs();
          setIsOpen(false);
        });
      } else {
        await UploadSingleImageFirebase(
          inputImmagineModifiche!,
          idModifica!,
          setLinkImmagineModifiche,
          folder
        );
      }
    }
  };

  const handleRimuoviProdottoModifiche = (ind: number) => {
    setInputProdottiModifiche((inputProdottiModifiche: any) =>
      inputProdottiModifiche.filter((_: any, i: number) => i !== ind)
    );
  };

  useEffect(() => {
    if (linkImmagineModifiche !== undefined) {
      const oggettoCategoria = {
        id: idModifica,
        nome: inputNomeModifiche,
        descrizione: inputDescrizioneModifiche,
        immagineUrl: linkImmagineModifiche,
        prodotti: inputProdottiModifiche,
      };
      console.log(oggettoCategoria);
      UploadNuovaCategoria(oggettoCategoria, idModifica).then(() => {

        UpdateAll();
        dimiss();
        resetAllInputs();
      });
    }
  }, [linkImmagineModifiche]);


  const presentToastType = (
    message: string,
    type: "danger" | "success" | "warning",
    buttonEv: () => void,
    durationS?: number
  ) => {
    const iconType = {
      danger: close,
      success: cloudDoneOutline,
      warning: alertCircleOutline,
    };
    return presentToast({
      message: message,
      position: "top",
      duration: durationS ? durationS : 3000,
      icon: iconType[type],
      color: type,
      buttons: [
        {
          text: "Chiudi",
          role: "cancel",
          handler: () => {
            buttonEv();
          },
        },
      ],
    });
  };




  //RETURN -----------------------------------------------
  return (
    <>
      <IonModal
        isOpen={isOpen}
        onDidDismiss={() => {
          setIsOpen(false);
        }}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton
                color={"medium"}
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                Annulla
              </IonButton>
            </IonButtons>
            <IonTitle>Modifica categoria</IonTitle>
            <IonButtons slot="end">
              <IonButton
                color={"success"}
                fill="solid"
                onClick={() => handleSalvaModifiche()}
              >
                Salva
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>

          <ModalInputImmagini
            resetImageInput={resetImageInput}
            inputImmagine={inputImmagineModifiche}
            setInputImmagine={setInputImmagineModifiche}
            immagineSelezionata={immagineSelezionata}
            setImmagineSelezionata={setImmagineSelezionata}
            cartelle={cartelle}
            allImmagini={allImmagini}
          />

          <div className="ion-padding windowDubleHeight">
            <IonList>
              <IonItem fill="outline">
                <IonLabel position="stacked">Inserisci nome</IonLabel>
                <IonInput
                  value={inputNomeModifiche}
                  onIonInput={(ev: any) => {
                    setInputNomeModifiche(ev.target.value);
                  }}
                  clearInput
                  placeholder="Inserisci nome"
                ></IonInput>
              </IonItem>

              <IonItem fill="outline">
                <IonLabel position="stacked">
                  Inserisci breve descrizione
                </IonLabel>
                <IonInput
                  value={inputDescrizioneModifiche}
                  onIonInput={(ev: any) => {
                    setInputDescrizioneModifiche(ev.target.value);
                  }}
                  clearInput
                  placeholder="Inserisci breve descrizione"
                ></IonInput>
              </IonItem>
            </IonList>


            <CardSelezionaProdotti
              setModalOpen={setIsModalModificheProdottiOpen}
              inputItems={inputProdottiModifiche}
              setInputItems={setInputProdottiModifiche}
              handleRemoveItem={handleRimuoviProdottoModifiche}
            />

          </div>
        </IonContent>
      </IonModal>

      {/* MODALE SELEZIONA PRODOTTI */}
      <ModaleModificaProdotti
        isOpen={isModalModificheProdottiOpen}
        setIsOpen={setIsModalModificheProdottiOpen}
        inputProdotti={inputProdottiModifiche}
        setInputProdotti={setInputProdottiModifiche}
        allProdotti={allProdotti}
      />

    </>
  );
};

export default ModaleModificaCategoriaInventario;
