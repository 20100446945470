import { IonAvatar, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonHeader, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonList, IonModal, IonTitle, IonToolbar, useIonLoading } from '@ionic/react';
import './ModaleSezioni.css';
import { add, closeOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { UploadSingleImageFirebase } from '../../Logic/ImageUpload';
import ModalInputImmagini from '../Modal_Input_Immagini/ModalInputImmagineSingola';
import ModaleImportaCategorieDaInventario from '../Modale_ImportaCategorieDaInventario/ModaleImportaCategorieDaInventario';
import { Categoria } from '../../data/dataSheet';

interface ContainerProps {
  oggettoDaModificare: any,
  indModifica: any
  isModalModificheOpen: any,
  setIsModalModificheOpen: any,
  allSezioni: any,
  handleUploadModifiche: any,
  cartelle: any,
  allImmagini: any,
  categorieAttivita: any

}

const ModalModificaSezioneAttivita: React.FC<ContainerProps> = ({
  oggettoDaModificare,
  indModifica,
  isModalModificheOpen,
  setIsModalModificheOpen,
  allSezioni,
  handleUploadModifiche,
  cartelle,
  allImmagini,
  categorieAttivita
}) => {
  //VARIABILI --------------------------------------------
  const folder = "/sezioni";

  const [indiceModifica, setIndiceModifica] = useState<number>()
  const [idModifica, setIdModifica] = useState();
  const [inputNomeModifiche, setInputNomeModifiche] = useState();
  const [inputDescrizioneModifiche, setInputDescrizioneModifiche] = useState();
  const [inputImmagineModifiche, setInputImmagineModifiche] = useState();
  const [inputCategorieModifiche, setInputCategorieModifiche] = useState<any>([]);

  const [linkImmagineModifiche, setLinkImmagineModifiche] = useState();
  const [immagineSelezionata, setImmagineSelezionata] =
    useState<any>(undefined);

  const [isModalAddCategorieModificheOpen, setIsModalAddCategorieModificheOpen] = useState(false);

  //CONDIZIONI -------------------------------------------
  useEffect(() => {
    console.log("handleModifica() sezione:", oggettoDaModificare, "INDICEMODIFICA : ", indModifica);
    setIndiceModifica(indModifica)
    setIdModifica(oggettoDaModificare?.id)
    setInputNomeModifiche(oggettoDaModificare?.nome)
    setInputDescrizioneModifiche(oggettoDaModificare?.descrizione)
    setInputImmagineModifiche(oggettoDaModificare?.immagineUrl)
    setInputCategorieModifiche(oggettoDaModificare?.categorie)

  }, [oggettoDaModificare]);

  const handleSalvaModifiche = async () => {
    if (immagineSelezionata !== undefined) {

      const oggettoModificato = {
        id: idModifica,
        nome: inputNomeModifiche,
        descrizione: inputDescrizioneModifiche,
        immagineUrl: immagineSelezionata,
        categorie: inputCategorieModifiche,
      };
      console.log("handleSalvaModifiche - oggettoModificato : ", oggettoModificato);
      handleUploadModifiche(oggettoModificato);
    } else {
      setIsModalModificheOpen(false);
      let oggettoModificato;
      if (inputImmagineModifiche === allSezioni[indiceModifica!].immagineUrl) {
        oggettoModificato = {
          id: idModifica,
          nome: inputNomeModifiche,
          descrizione: inputDescrizioneModifiche,
          immagineUrl: inputImmagineModifiche,
          categorie: inputCategorieModifiche,
        }
        console.log("handleSalvaModifiche - oggettoModificato : ", oggettoModificato);
        handleUploadModifiche(oggettoModificato);
      } else {
        await UploadSingleImageFirebase(inputImmagineModifiche!, idModifica!, setLinkImmagineModifiche, folder);
      }
    }
  }

  useEffect(() => {
    if (linkImmagineModifiche !== undefined) {
      const oggettoModificato = {
        id: idModifica,
        nome: inputNomeModifiche,
        descrizione: inputDescrizioneModifiche,
        immagineUrl: linkImmagineModifiche,
        categorie: inputCategorieModifiche,
      };
      console.log(oggettoModificato);
      handleUploadModifiche(oggettoModificato);
    }
  }, [linkImmagineModifiche]);

  const resetImageInput = () => {
    setLinkImmagineModifiche(undefined);
    setImmagineSelezionata(undefined);
  }

  const handleRimuoviCategoriaModifiche = (index: number) => {
    setInputCategorieModifiche((inputCategorieModifiche: any) => inputCategorieModifiche.filter((_: any, i: number) => i !== index))
  }

  //RETURN -----------------------------------------------
  return (

    <>

      <IonModal isOpen={isModalModificheOpen} onDidDismiss={() => { setIsModalModificheOpen(false) }} >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton color={"medium"} onClick={() => setIsModalModificheOpen(false)}>Annulla</IonButton>
            </IonButtons>
            <IonTitle>Modifica sezione</IonTitle>
            <IonButtons slot="end">
              <IonButton color={"success"} fill="solid" onClick={() => handleSalvaModifiche()}>Salva</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>

          <ModalInputImmagini
            resetImageInput={resetImageInput}
            inputImmagine={inputImmagineModifiche}
            setInputImmagine={setInputImmagineModifiche}
            immagineSelezionata={immagineSelezionata}
            setImmagineSelezionata={setImmagineSelezionata}
            cartelle={cartelle}
            allImmagini={allImmagini}
          />

          <div className='ion-padding windowDubleHeight'>
            <IonList>
              <IonItem fill="outline">
                <IonLabel position="stacked">Inserisci nome</IonLabel>
                <IonInput value={inputNomeModifiche} onIonInput={(ev: any) => { setInputNomeModifiche(ev.target.value) }} clearInput placeholder="Obbligatorio"></IonInput>
              </IonItem>

              <IonItem fill="outline">
                <IonLabel position="stacked">Inserisci breve descrizione</IonLabel>
                <IonInput value={inputDescrizioneModifiche} onIonInput={(ev: any) => { setInputDescrizioneModifiche(ev.target.value) }} clearInput placeholder="Obbligatorio"></IonInput>
              </IonItem>

            </IonList>

            <IonCard>
              <IonCardHeader>
                <IonCardTitle>
                  Seleziona categorie
                </IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                <IonButton expand='block' onClick={() => setIsModalAddCategorieModificheOpen(true)}>
                  <IonIcon icon={add} />
                  Importa categorie
                </IonButton>

                <IonLabel>
                  <p>Categorie selezionate : {inputCategorieModifiche?.length}</p>
                </IonLabel>
                <IonList>
                  {inputCategorieModifiche?.map((categoria: Categoria, index: number) => {
                    return (
                      <IonItem key={"categoriaSelezionata" + index}>
                        <IonAvatar slot="start">
                          <IonImg src={categoria.immagineUrl} alt="Immagine" />
                        </IonAvatar>
                        <IonLabel>
                          {categoria.nome}
                          <p> Prodotti : {categoria.prodotti.length}</p>
                        </IonLabel>

                        <IonButton color={"danger"} slot="end"
                          onClick={() => handleRimuoviCategoriaModifiche(index)}
                        >
                          <IonIcon icon={closeOutline} />
                        </IonButton>
                      </IonItem>
                    )
                  })}
                </IonList>
              </IonCardContent>
            </IonCard>

            <ModaleImportaCategorieDaInventario
              isOpen={isModalAddCategorieModificheOpen}
              setIsOpen={setIsModalAddCategorieModificheOpen}
              inputCategorie={inputCategorieModifiche}
              setInputCategorie={setInputCategorieModifiche}
              allCategorie={categorieAttivita}
            />
          </div>

        </IonContent>
      </IonModal>
    </>

  );
};

export default ModalModificaSezioneAttivita;
