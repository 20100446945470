import {
  IonBadge,
  IonButton,
  IonButtons,
  IonChip,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenuButton,
  IonModal,
  IonPage,
  IonSearchbar,
  IonTitle,
  IonToolbar,
  useIonAlert,
  useIonToast,
} from "@ionic/react";
import {
  addCircle,
  calendarClearOutline,
  close,
  listOutline,
  qrCodeOutline,
  squareOutline,
  timeOutline,
  warningOutline,
} from "ionicons/icons";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import TableComponent from "../components/TableComponent";
import { DataDownloadStoricoOrdini } from "../Logic/DataDownload";
import { AddQrCodeTable, AddRealTable } from "../Logic/DataUpload";
import "./TableScreen.css";

interface PageProps {
  AllRealTables: any;
  RealTablesData: any;
  setAuxIdRealTables: any;
}

const TableScreen: React.FC<PageProps> = ({
  AllRealTables,
  RealTablesData,
  setAuxIdRealTables,
}) => {
  //VARIABILI ------------------------------
  const { idAttivita } = useParams<{ idAttivita: string }>();
  const [isModalAddOpen, setIsModalAddOpen] = useState<boolean>(false);
  const [presentAlertTavoloReale] = useIonAlert();
  const [presentConfermaCreazione] = useIonToast();
  const [nomeAttivita, setNomeAttivita] = useState("");

  const [tableData, setTableData] = useState<any>();
  const [realTablesName, setRealTablesName] = useState();

  //CONDIZIONI -----------------------------

  useEffect(() => {
    const index = RealTablesData.findIndex(
      (elem: any) => elem.idAttivita == idAttivita
    );
    setRealTablesName(RealTablesData[index].dbId);
    setAuxIdRealTables(RealTablesData[index].dbId);
    setNomeAttivita(RealTablesData[index].nome);
  }, [idAttivita]);

  useEffect(() => {
    if (AllRealTables?.id === "RealTables-" + idAttivita) {
      setTableData(AllRealTables);
    } else {
      console.log(
        "Non è la stessa attività : ",
        "RealTables-" + AllRealTables?.id
      );
    }
  }, [AllRealTables]);

  //RETURN ---------------------------------
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Gestione tavoli</IonTitle>
          <IonButtons slot="end">
            <IonButton
              color={"success"}
              fill="solid"
              onClick={() => setIsModalAddOpen(true)}
            >
              <IonIcon icon={addCircle} className="ion-margin-end"></IonIcon>
              Crea tavolo
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Gestione tavoli</IonTitle>
          </IonToolbar>
        </IonHeader>
        {/* ------------- CONTENT ---------------- */}

        <IonLabel>
          <p className="ion-padding">{nomeAttivita}</p>
        </IonLabel>
        <div className="ion-padding">
          <IonList>
            {tableData?.data?.map((tableRawData: any, index: number) => {
              return (
                <div key={index + "TableScreentableRawData"}>
                  <TableComponent
                    tableData={tableRawData}
                    index={index}
                    idAttivita={idAttivita}
                  />
                </div>
              );
            })}
          </IonList>
        </div>

        {/* ------------------ MODAL CREA TAVOLO -------------------- */}
        <IonModal
          isOpen={isModalAddOpen}
          onDidDismiss={() => {
            setIsModalAddOpen(false);
          }}
        >
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton
                  color={"medium"}
                  onClick={() => setIsModalAddOpen(false)}
                >
                  Annulla
                </IonButton>
              </IonButtons>
              <IonTitle>Crea tavolo</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <IonLabel>
              <p className="ion-text-center ion-padding-bottom">
                Il tavolo reale è un tavolo fisicamente presente, ed il servizio
                è accessibile tramite il Qr Code presente sul tavolo.
              </p>
            </IonLabel>
            <IonButton
              expand="block"
              onClick={() =>
                presentAlertTavoloReale({
                  header: "Creazione tavolo reale",
                  message:
                    "Inserire il codice del tavolo. Attenzione! Evitare i caratteri speciali come il trattino (-) ed utilizzare nomi(o codici) UNIVOCI: utilizzare lo stesso nome(o codice) per due tavoli farà una sovrascrizione di essi. Il nome (o codice) deve essere di almeno 2 caratteri.",
                  inputs: [
                    {
                      type: "text",
                      placeholder: "Es: 01, est14, int22, a19",
                      name: "codice",
                    },
                  ],
                  buttons: [
                    {
                      text: "Annulla",
                      role: "cancel",
                      handler: () => {},
                    },
                    {
                      text: "Crea",
                      role: "confirm",
                      handler: async (alertData) => {
                        if (alertData.codice.length === 1) {
                          presentConfermaCreazione({
                            message:
                              "Attenzione, il nome deve avere almeno due caratteri. Nel caso di un numero aggiungere lo zero davanti.",
                            duration: 3000,
                            position: "top",
                            color: "warning",
                            icon: warningOutline,
                          });
                        } else {
                          await AddRealTable(
                            realTablesName!,
                            alertData.codice
                          ).then(() => {
                            setIsModalAddOpen(false);
                            presentConfermaCreazione({
                              message:
                                "Tavolo " +
                                alertData.codice +
                                " creato con successo",
                              duration: 1500,
                              position: "top",
                              color: "success",
                              icon: squareOutline,
                            });
                          });
                        }
                      },
                    },
                  ],
                })
              }
            >
              <IonIcon icon={squareOutline} className="ion-margin-end" />
              Tavolo reale
            </IonButton>

            <IonLabel>
              <p className="ion-text-center ion-padding">
                Il tavolo Qr Code è un tavolo "Usa e getta", cioè una sessione
                esterna ai tavoli fisici, dove l'unico modo per accedere è
                tramite il cameriere o titolare, che ha a disposizione il Qr
                Code di accesso da far inquadrare ai clienti che desidera.
              </p>
            </IonLabel>

            <IonButton
              expand="block"
              className="ion-margin-top"
              onClick={() =>
                presentAlertTavoloReale({
                  header: "Creazione tavolo Qr Code",
                  message:
                    "A questo tavolo verrà assegnato un codice automatico, e sarà accessibile tramite un QrCode appena viene confermata la creazione. Per facilitare l'individuazione da parte tua puoi assegnare un nome (non influisce sul codice).",
                  inputs: [
                    {
                      type: "text",
                      placeholder: 'Es: "La company", "Daniel", "Sara"',
                      name: "codice",
                    },
                  ],
                  buttons: [
                    {
                      text: "Annulla",
                      role: "cancel",
                      handler: () => {},
                    },
                    {
                      text: "Crea",
                      role: "confirm",
                      handler: async (alertData) => {
                        if (alertData.codice.length === 1) {
                          presentConfermaCreazione({
                            message:
                              "Attenzione, il nome deve avere almeno due caratteri. Nel caso di un numero aggiungere lo zero davanti.",
                            duration: 3000,
                            position: "top",
                            color: "warning",
                            icon: warningOutline,
                          });
                        } else {
                          await AddQrCodeTable(
                            realTablesName!,
                            alertData.codice
                          ).then(() => {
                            setIsModalAddOpen(false);
                            presentConfermaCreazione({
                              message:
                                "Tavolo " +
                                alertData.codice +
                                " creato con successo",
                              duration: 1500,
                              position: "top",
                              color: "success",
                              icon: squareOutline,
                            });
                          });
                        }
                      },
                    },
                  ],
                })
              }
            >
              <IonIcon icon={qrCodeOutline} className="ion-margin-end" />
              Tavolo Qr Code
            </IonButton>
          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default TableScreen;
