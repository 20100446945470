import {
  IonBadge,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonToolbar,
} from "@ionic/react";
import { chevronForward, speedometerSharp } from "ionicons/icons";
import { useEffect, useState } from "react";
import { url_app_inventario_prodotti } from "../Urls";
import "./ProdottiPerformance.css";

interface ContainerProps {
  ProdottiDb: any;
  onlyInfo?: boolean;
}

const ProdottiPerformance: React.FC<ContainerProps> = ({
  ProdottiDb,
  onlyInfo = false,
}) => {
  //VARIABILI --------------------------------------------
  const [prodotti, setProdotti] = useState<any>(ProdottiDb);

  const [prodottiSenzaImmagine, setProdottiSenzaImmagine] = useState(0);
  const [prodottiSenzaIngredienti, setProdottiSenzaIngredienti] = useState(0);
  const [prodottiSenzaDescrizione, setProdottiSenzaDescrizione] = useState(0);
  const [prodottiSenzaTag, setProdottiSenzaTag] = useState(0);

  const [attivitaPerformanceSTR, setAttivitaPerformanceSTR] = useState("");

  const [prodottiPerformance, setProdottiPerformance] = useState(0);

  const [isModalOpen, setIsModalOpen] = useState(false);

  //CONDIZIONI -------------------------------------------
  useEffect(() => {
    let auxProdottiSenzaImmagine = 0;
    let auxProdottiSenzaIngredienti = 0;
    let auxProdottiSenzaDescrizione = 0;
    let auxProdottiSenzaTag = 0;

    ProdottiDb?.map((prodotto: any, index: number) => {
      if (
        prodotto.immaginiUrl[0] ===
        "https://firebasestorage.googleapis.com/v0/b/menushare-a4100.appspot.com/o/logos%2FmenuShareLogo.jpg?alt=media&token=6f02aee1-26d7-4eca-9714-c3ba4515abfd"
      )
        auxProdottiSenzaImmagine++;
      if (prodotto.ingredienti.length == 0) auxProdottiSenzaIngredienti++;
      if (prodotto.descrizione === "Non specificato.")
        auxProdottiSenzaDescrizione++;
      if (prodotto.tags.length == 0) auxProdottiSenzaTag++;
    });

    let perf =
      (100 / (ProdottiDb.length * 4)) *
      (auxProdottiSenzaImmagine +
        auxProdottiSenzaIngredienti +
        auxProdottiSenzaDescrizione +
        auxProdottiSenzaTag);

    perf = 100 - perf;
    setProdottiPerformance(perf);

    let str = perf.toString().split(".");
    let strr;
    if (str[0]?.length === 1) {
      strr = "0" + str[0]
    } else {
      strr = str[0]
    }
    if (str[1]?.length > 1) {
      strr = strr + "." + str[1].slice(0, 2);
    }
    setAttivitaPerformanceSTR(strr);

    setProdottiSenzaImmagine(auxProdottiSenzaImmagine);
    setProdottiSenzaIngredienti(auxProdottiSenzaIngredienti);
    setProdottiSenzaDescrizione(auxProdottiSenzaDescrizione);
    setProdottiSenzaTag(auxProdottiSenzaTag);

    setProdotti(ProdottiDb);
  }, [ProdottiDb]);

  //RETURN -----------------------------------------------
  return (
    <>
      <div className="InventarioComp-box">
        <div className="InventarioComp-box-title">
          <h2>PRODOTTI</h2>
        </div>
        <div className="InventarioComp-box-content">
          <p className="InventarioComp-box-content-number">{prodotti.length}</p>

          <div className="InventarioComp-box-content-alerts">
            <p className="InventarioComp-box-content-subtitle"> Performance</p>
            <IonButton
              color={prodottiPerformance > 80 ? "success" : "warning"}
              onClick={() => setIsModalOpen(true)}
            >
              <IonIcon icon={speedometerSharp} className="ion-margin-end" />
              {attivitaPerformanceSTR} %
            </IonButton>
          </div>
        </div>
      </div>

      {/* Modale performance */}
      <IonModal
        isOpen={isModalOpen}
        onDidDismiss={() => {
          setIsModalOpen(false);
        }}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton color={"medium"} onClick={() => setIsModalOpen(false)}>
                Chiudi
              </IonButton>
            </IonButtons>
            <IonButtons slot="end">
              {!onlyInfo ? (
                <IonButton
                  color={"success"}
                  routerLink={url_app_inventario_prodotti}
                  onClick={() => setIsModalOpen(false)}
                >
                  Pagina Prodotti
                  <IonIcon icon={chevronForward} />
                </IonButton>
              ) : null}
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonButton
            expand="block"
            color={prodottiPerformance > 80 ? "success" : "warning"}
            className="ion-margin-bottom"
          >
            <IonIcon icon={speedometerSharp} className="ion-margin-end" />
            {attivitaPerformanceSTR} %
          </IonButton>

          <IonList>
            {/* Totale */}
            <IonItem>
              <IonBadge slot="start">{prodotti.length}</IonBadge>
              <IonLabel>
                <h3>Numero totale prodotti</h3>
              </IonLabel>
            </IonItem>
            <br />

            {/* SENZA IMMAGINI */}
            <IonItem>
              <IonBadge
                color={
                  (100 / prodotti.length) * prodottiSenzaImmagine > 50
                    ? "danger"
                    : "warning"
                }
                slot="start"
              >
                {prodottiSenzaImmagine < 10
                  ? "0" + prodottiSenzaImmagine
                  : prodottiSenzaImmagine}
              </IonBadge>
              <IonLabel>
                <h3>Prodotti senza immagini</h3>
                <p>
                  Il{" "}
                  {((100 / prodotti.length) * prodottiSenzaImmagine)
                    .toString()
                    .substring(0, 4)}
                  % dei prodotti non ha un'immagine{" "}
                </p>
              </IonLabel>
            </IonItem>

            {/* SENZA INGREDIENTI */}
            <IonItem>
              <IonBadge
                color={
                  (100 / prodotti.length) * prodottiSenzaIngredienti > 50
                    ? "danger"
                    : "warning"
                }
                slot="start"
              >
                {prodottiSenzaIngredienti < 10
                  ? "0" + prodottiSenzaIngredienti
                  : prodottiSenzaIngredienti}
              </IonBadge>
              <IonLabel>
                <h3>Prodotti senza ingredienti</h3>
                <p>
                  Il{" "}
                  {((100 / prodotti.length) * prodottiSenzaIngredienti)
                    .toString()
                    .substring(0, 4)}
                  % dei prodotti non ha gli ingredienti selezionati{" "}
                </p>
              </IonLabel>
            </IonItem>

            {/* SENZA DESCRIZIONE */}
            <IonItem>
              <IonBadge
                color={
                  (100 / prodotti.length) * prodottiSenzaDescrizione > 50
                    ? "danger"
                    : "warning"
                }
                slot="start"
              >
                {prodottiSenzaDescrizione < 10
                  ? "0" + prodottiSenzaDescrizione
                  : prodottiSenzaDescrizione}
              </IonBadge>
              <IonLabel>
                <h3>Prodotti senza descrizione</h3>
                <p>
                  Il{" "}
                  {((100 / prodotti.length) * prodottiSenzaDescrizione)
                    .toString()
                    .substring(0, 4)}
                  % dei prodotti non ha una descrizione appropriata{" "}
                </p>
              </IonLabel>
            </IonItem>

            {/* SENZA TAG */}
            <IonItem>
              <IonBadge
                color={
                  (100 / prodotti.length) * prodottiSenzaTag > 50
                    ? "danger"
                    : "warning"
                }
                slot="start"
              >
                {prodottiSenzaTag < 10
                  ? "0" + prodottiSenzaTag
                  : prodottiSenzaTag}
              </IonBadge>
              <IonLabel>
                <h3>Prodotti senza tag</h3>
                <p>
                  Il{" "}
                  {((100 / prodotti.length) * prodottiSenzaTag)
                    .toString()
                    .substring(0, 4)}
                  % dei prodotti non ha un tag collegato{" "}
                </p>
              </IonLabel>
            </IonItem>
          </IonList>
        </IonContent>
      </IonModal>
    </>
  );
};

export default ProdottiPerformance;
