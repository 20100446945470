import {
  IonBadge,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { chevronForward, speedometerSharp } from "ionicons/icons";
import { useEffect, useState } from "react";
import { url_app_inventario_ingredienti } from "../Urls";
import "./IngredientiPerformance.css";

interface ContainerProps {
  IngredientiDb: any;
  onlyInfo?: boolean;
}

const IngredientiPerformance: React.FC<ContainerProps> = ({
  IngredientiDb,
  onlyInfo = false,
}) => {
  //VARIABILI --------------------------------------------
  const [ingredienti, setIngredienti] = useState(IngredientiDb);
  const [attivitaPerformanceSTR, setAttivitaPerformanceSTR] = useState("");
  const [elementiSenzaImmagine, setElementiSenzaImmagine] = useState(0);
  const [elementiSenzaMarca, setElementiSenzaMarca] = useState(0);
  const [ingredientiPerformance, setIngredientiPerformance] = useState(0);

  const [isModalOpen, setIsModalOpen] = useState(false);
  //CONDIZIONI -------------------------------------------

  useEffect(() => {
    let auxContatoreImmagini = 0;
    let auxContatoreMarca = 0;
    IngredientiDb?.map((ingredient: any, index: number) => {
      if (
        ingredient.immagineUrl ===
        "https://firebasestorage.googleapis.com/v0/b/menushare-a4100.appspot.com/o/logos%2FmenuShareLogo.jpg?alt=media&token=6f02aee1-26d7-4eca-9714-c3ba4515abfd"
      )
        auxContatoreImmagini++;
      if (ingredient.marca === "Non specificato.") auxContatoreMarca++;
    });

    let perf =
      (100 / (IngredientiDb.length * 2)) *
      (auxContatoreImmagini + auxContatoreMarca);

    perf = 100 - perf
    setIngredientiPerformance(perf);

    let str = perf.toString().split(".");
    let strr;
    if(str[0]?.length === 1){
      strr = "0"+str[0]
    }else{
      strr = str[0]
    }
    if(str[1]?.length > 1){
      strr = strr +"."+str[1].slice(0,2);
    }
    setAttivitaPerformanceSTR(strr);

    setElementiSenzaImmagine(auxContatoreImmagini);
    setElementiSenzaMarca(auxContatoreMarca);
    setIngredienti(IngredientiDb);
  }, [IngredientiDb]);

  //RETURN -----------------------------------------------
  return (
    <>
      <div className="InventarioComp-box">
        <div className="InventarioComp-box-title">
          <h2>Ingredienti</h2>
        </div>
        <div className="InventarioComp-box-content">
          <p className="InventarioComp-box-content-number">
            {ingredienti.length}
          </p>

          <div className="InventarioComp-box-content-alerts">
            <p className="InventarioComp-box-content-subtitle"> Performance</p>
            <IonButton
              color={ingredientiPerformance > 80 ? "success" : "warning"}
              onClick={() => setIsModalOpen(true)}
            >
              <IonIcon icon={speedometerSharp} className="ion-margin-end" />
              {attivitaPerformanceSTR} %
            </IonButton>
          </div>
        </div>
      </div>

      {/* Modale performance */}
      <IonModal
        isOpen={isModalOpen}
        onDidDismiss={() => {
          setIsModalOpen(false);
        }}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton color={"medium"} onClick={() => setIsModalOpen(false)}>
                Chiudi
              </IonButton>
            </IonButtons>
            <IonButtons slot="end">
            {!onlyInfo ? 
            <IonButton color={"success"} routerLink={url_app_inventario_ingredienti} onClick={() => setIsModalOpen(false)} >
              Pagina Ingredienti
              <IonIcon icon={chevronForward} />
            </IonButton> 
            : null}
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonButton
            expand="block"
            color={ingredientiPerformance > 80 ? "success" : "warning"}
            className="ion-margin-bottom"
          >
            <IonIcon icon={speedometerSharp} className="ion-margin-end" />
            {attivitaPerformanceSTR} %
          </IonButton>

          <IonList>
            {/* Totale */}
            <IonItem>
              <IonBadge slot="start">{ingredienti.length}</IonBadge>
              <IonLabel className="ion-text-wrap">
                <h3>Numero totale ingredienti</h3>
              </IonLabel>
            </IonItem>
            <br />

            {/* SENZA IMMAGINI */}
            <IonItem>
              <IonBadge
                color={
                  (100 / ingredienti.length) * elementiSenzaImmagine > 50
                    ? "danger"
                    : "warning"
                }
                slot="start"
              >
                {elementiSenzaImmagine < 10 ? "0"+ elementiSenzaImmagine : elementiSenzaImmagine}
              </IonBadge>
              <IonLabel className="ion-text-wrap">
                <h3>Ingredienti senza immagini</h3>
                <p>
                  Il{" "}
                  {((100 / ingredienti.length) * elementiSenzaImmagine)
                    .toString()
                    .substring(0, 4)}
                  % dei prodotti non ha un'immagine{" "}
                </p>
              </IonLabel>
            </IonItem>

            {/* SENZA MARCA */}
            <IonItem>
              <IonBadge
                color={
                  (100 / ingredienti.length) * elementiSenzaMarca > 50
                    ? "danger"
                    : "warning"
                }
                slot="start"
              >
                {elementiSenzaMarca  < 10 ? "0"+ elementiSenzaMarca : elementiSenzaMarca}
              </IonBadge>
              <IonLabel className="ion-text-wrap">
                <h3>Ingredienti senza marca</h3>
                <p>
                  Il{" "}
                  {((100 / ingredienti.length) * elementiSenzaMarca)
                    .toString()
                    .substring(0, 4)}
                  % dei prodotti non ha la marca specificata{" "}
                </p>
              </IonLabel>
            </IonItem>
          </IonList>
        </IonContent>
      </IonModal>
    </>
  );
};

export default IngredientiPerformance;
