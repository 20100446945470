import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
} from "@ionic/react";
import "./StoricoComp.css";
import StoricoOrdiniComponente from "./StoricoOrdiniComponente";

interface ContainerProps {
  AllAttivita:any
}

const StoricoComp: React.FC<ContainerProps> = ({
 AllAttivita,
}) => {
  //VARIABILI --------------------------------------------
  //CONDIZIONI -------------------------------------------
  //RETURN -----------------------------------------------
  return (
    <IonCard>
      <IonCardHeader>
        <IonCardSubtitle>Storico Ordini</IonCardSubtitle>
      </IonCardHeader>
      <IonCardContent>
        <div className="StoricoComp-row">
         {
           AllAttivita?.map((attivita:any, index:number) => {
             return(
               <StoricoOrdiniComponente
                key={"storicoOrdiniComponente"+index}
                attivitaData={attivita}
               />
             )
           })
         }
        </div>
      </IonCardContent>
    </IonCard>
  );
};

export default StoricoComp;
