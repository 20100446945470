import { IonButton, IonButtons, IonCard, IonCardHeader, IonCardSubtitle, IonChip, IonContent, IonHeader, IonImg, IonLabel, IonModal, IonTitle, IonToolbar } from '@ionic/react';
import './ModaleImmagini.css';
import { useState } from 'react';
import { defaultFolders } from '../../Logic/DataDownload';

interface ContainerProps {
  isModalOpen: any,
  setIsModalOpen: any,
  immagineSelezionata: any,
  setImmagineSelezionata: any,
  cartelle: any,
  allImmagini: any,
}

const ModaleImmagini: React.FC<ContainerProps> = ({
  isModalOpen,
  setIsModalOpen,
  immagineSelezionata,
  setImmagineSelezionata,
  cartelle,
  allImmagini,
}) => {
  //VARIABILI --------------------------------------------
  const [filtroCartelle, setFiltroCartelle] = useState<any>("");
  const [filtroVisualizzazioneImmagini, setFiltroVisualizzazioneImmagini] =
    useState<any>("column-portfolio-4");
  //CONDIZIONI -------------------------------------------

  const handleFiltraImmagini = (testoCercato: string) => {
    let arr = Array.from(
      window.document.getElementsByClassName("immaginiGalleria")
    );
    const query = testoCercato.toLowerCase();
    requestAnimationFrame(() => {
      arr.forEach((item: any) => {
        const shouldShow = item.textContent.toLowerCase().indexOf(query) > -1;
        item.style.display = shouldShow ? "block" : "none";
      });
    });
  };


  //RETURN -----------------------------------------------
  return (
    <IonModal
      isOpen={isModalOpen}
      onDidDismiss={() => {
        setIsModalOpen(false);
      }}
    >
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton
              color={"medium"}
              onClick={() => {
                setImmagineSelezionata(undefined);
                setIsModalOpen(false);
              }}
            >
              Annulla
            </IonButton>
          </IonButtons>
          <IonTitle>Immagini</IonTitle>
          <IonButtons slot="end">
            <IonButton
              fill="solid"
              color={"success"}
              onClick={() => setIsModalOpen(false)}
            >
              Conferma
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div>
          <IonLabel>
            <p className="ion-padding">
              Filtro visualizzazione:
              <IonChip
                color={
                  filtroVisualizzazioneImmagini === "column-portfolio-1"
                    ? "primary"
                    : undefined
                }
                onClick={() =>
                  setFiltroVisualizzazioneImmagini("column-portfolio-1")
                }
              >
                1/1
              </IonChip>
              <IonChip
                color={
                  filtroVisualizzazioneImmagini === "column-portfolio-2"
                    ? "primary"
                    : undefined
                }
                onClick={() =>
                  setFiltroVisualizzazioneImmagini("column-portfolio-2")
                }
              >
                1/2
              </IonChip>
              <IonChip
                color={
                  filtroVisualizzazioneImmagini === "column-portfolio-3"
                    ? "primary"
                    : undefined
                }
                onClick={() =>
                  setFiltroVisualizzazioneImmagini("column-portfolio-3")
                }
              >
                1/3
              </IonChip>
              <IonChip
                color={
                  filtroVisualizzazioneImmagini === "column-portfolio-4"
                    ? "primary"
                    : undefined
                }
                onClick={() =>
                  setFiltroVisualizzazioneImmagini("column-portfolio-4")
                }
              >
                1/4
              </IonChip>
            </p>
          </IonLabel>
          <IonLabel>
            <p className="ion-padding-start ion-padding-end">
              Cartelle:{" "}
              {defaultFolders?.map((cartella: string, index: number) => {
                return (
                  <IonChip
                    key={"cartelleIngredientidefaultFolders" + index}
                    color={
                      filtroCartelle === cartella ? "primary" : undefined
                    }
                    onClick={() => {
                      setFiltroCartelle(cartella);
                      handleFiltraImmagini(cartella);
                    }}
                  >
                    {cartella}
                  </IonChip>
                );
              })}
              {cartelle?.allCartelleImmagini?.map(
                (cartella: string, index: number) => {
                  return (
                    <IonChip
                      key={"cartelleIngredienti" + index}
                      color={
                        filtroCartelle === cartella ? "primary" : undefined
                      }
                      onClick={() => {
                        setFiltroCartelle(cartella);
                        handleFiltraImmagini(cartella);
                      }}
                    >
                      {cartella}
                    </IonChip>
                  );
                }
              )}
            </p>
          </IonLabel>
        </div>

        <div className="row-portfolio">
          {allImmagini?.map((immagineObj: any, index: number) => {
            return (
              <IonCard
                color={
                  immagineSelezionata === immagineObj.urlImmagine
                    ? "primary"
                    : undefined
                }
                key={"immagine-" + index}
                className={
                  "immaginiGalleria " + filtroVisualizzazioneImmagini
                }
                onClick={() =>
                  setImmagineSelezionata(immagineObj.urlImmagine)
                }
              >
                <IonCardHeader>
                  <IonCardSubtitle>{immagineObj.cartella}</IonCardSubtitle>
                </IonCardHeader>
                <IonImg src={immagineObj.urlImmagine} />
              </IonCard>
            );
          })}
        </div>
      </IonContent>
    </IonModal>
  );
};

export default ModaleImmagini;
