import React, { useContext, useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./fb";


interface Auth {
  loggedIn: boolean,
  userId?: string
}

interface AuthInit {
  loading: boolean,
  auth?: Auth
}

export const AuthContext = React.createContext<Auth>({ loggedIn: false });

export function useAuth(): Auth {
  return useContext(AuthContext);
}

export function useAuthInit(): AuthInit {
  const [authInit, setAuthInit] = useState<AuthInit>({ loading: true });

  useEffect(() => {
    // console.log("EFFETTUA ACCESSO:");
    return onAuthStateChanged(auth, (user) => {
      const auth = user ?
        { loggedIn: true, userId: user.uid } :
        { loggedIn: false };
      setAuthInit({ loading: false, auth });
    });
  }, []);

  return authInit;
}