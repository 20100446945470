import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
} from "@ionic/react";
import "./AttivitaComp.css";
import AttivitaPerformance from "./AttivitaPerformance";

interface ContainerProps {
  AllAttivita: any;
}

const AttivitaComp: React.FC<ContainerProps> = ({ AllAttivita }) => {
  //VARIABILI --------------------------------------------
  //CONDIZIONI -------------------------------------------
  //RETURN -----------------------------------------------
  return (
    <IonCard>
      <IonCardHeader>
        <IonCardSubtitle>Attività</IonCardSubtitle>
      </IonCardHeader>
      <IonCardContent>
        <div className="AttivitaComp-row">
          {AllAttivita?.map((attivita: any, index: number) => {
            return (
              <AttivitaPerformance
                key={"attivitàPerformance" + index}
                attivitaData={attivita}
                indexAttivita={index}
              />
            );
          })}
        </div>
      </IonCardContent>
    </IonCard>
  );
};

export default AttivitaComp;
