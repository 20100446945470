// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import {
  getAuth,
  indexedDBLocalPersistence,
  initializeAuth,
} from "firebase/auth";
import { Capacitor } from "@capacitor/core";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC7psonJnen7YDOylt8B1V0_PnZaDpXUx8",
  authDomain: "menushare-a4100.firebaseapp.com",
  projectId: "menushare-a4100",
  storageBucket: "menushare-a4100.appspot.com",
  messagingSenderId: "617191314253",
  appId: "1:617191314253:web:dcf2d16a0c1e2f51909d04",
  measurementId: "G-9619T2TYWL",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const storage = getStorage(app);
export const db = getFirestore(app);
export const auth = fixAuth();

//AUTH CAPACITOR FIX
function fixAuth() {
  let auth;
  if (Capacitor.isNativePlatform()) {
    auth = initializeAuth(app, {
      persistence: indexedDBLocalPersistence,
    });
  } else {
    auth = getAuth();
  }
  return auth;
}
