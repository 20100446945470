export type Ingrediente = {
  id: string;
  nome: string;
  marca?: string;
  descrizione?: string;
  immagineUrl?: string;
};

//-----------------
export type Giornata = {
  inizio: any;
  fine: any;
  chiuso?: boolean;
};

export type Orario = {
  giorno: string;
  orario: Giornata[];
  chiuso?: boolean;
};

export type ContattoTelefonico = {
  nome: string;
  numero: string;
};

export type AboutUs = {
  immaginiUrl: string[];
  descrizione: string;
  instagram?: string;
  facebook?: string;
  linkedin?: string;
  sitoWeb?: string;
  telefono: ContattoTelefonico[];
};
export type Persona = {
  id: string;
  nome: string;
  mansione: string;
  titoloDiStudio?: string;
  descrizione?: string;
  immagineUrl?: string;
  instagram?: string;
  facebook?: string;
  linkedin?: string;
  sitoWeb?: string;
};
export type Prodotto = {
  id: string;
  nome: string;
  prezzo: string;
  descrizione: string;
  ingredienti?: any[];
  tags?: string[];
  immaginiUrl: string[];
};
export type Categoria = {
  id: string;
  nome: string;
  descrizione?: string;
  immagineUrl: string;
  tags?: string[];
  prodotti: Prodotto[];
};
export type Sezione = {
  id: string;
  nome: string;
  descrizione?: string;
  immagineUrl: string;
  categorie: Categoria[];
};
export type Comunicazioni = {
  id?: string;
  nome: string;
  sottotitolo: string;
  descrizione: string;
  paragrafi: string;
  dataPubblicazione: string;
  immagineUrl?: string;
};

export type MenuData = {
  id?: string;
  nome?: string;
  logoUrl?: string;
  dataCreazione?: string;
  comunicazioni?: Comunicazioni[]; //HeaderHome
  sezioni?: Sezione[];
  categorie?: Categoria[];
  personale?: Persona[];
  prodottiConsigliati?: any;
  about?: AboutUs;
  orari?: Orario[];
};

export type Attivita = {
  attivita: MenuData[];
};

export const logoMenuShare =
  "https://firebasestorage.googleapis.com/v0/b/menushare-a4100.appspot.com/o/logos%2FmenuShareLogo.jpg?alt=media&token=6f02aee1-26d7-4eca-9714-c3ba4515abfd";

export const dataSheet: Attivita = {
  attivita: [
    {
      nome: "Caffè commercio",
      dataCreazione: "06/02/2022",
      comunicazioni: [
        {
          nome: "Benvenuto",
          immagineUrl:
            "https://img.freepik.com/free-photo/welcome-wooden-sign-mockup_53876-88487.jpg?w=996&t=st=1675627024~exp=1675627624~hmac=fe4a87d7bd2cae4ebd88626009f5fe084d2f5d2d0dca598e15368deead1129f7",
          sottotitolo: "Novità",
          descrizione:
            "Here's a small text description for the card content. Nothing more, nothing less.",
          paragrafi:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut id ex euismod, suscipit lorem eu, dictum est. Phasellus quis dui non felis tempor laoreet nec eget sem.",
          dataPubblicazione: "2023-02-04T14:28:33.01Z",
        },
        {
          nome: "Offerta di benvenuto",
          sottotitolo: "Offerta",
          descrizione:
            "Here's a small text description for the card content. Nothing more, nothing less.",
          paragrafi:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut id ex euismod, suscipit lorem eu, dictum est. Phasellus quis dui non felis tempor laoreet nec eget sem.",
          dataPubblicazione: "2023-02-04T14:28:33.01Z",
        },
      ],
      sezioni: [],
      personale: [],
      orari: [],
    },
    {
      nome: "string",

      dataCreazione: "string",
      comunicazioni: [],
      sezioni: [],
      personale: [],
      orari: [],
    },
    {
      nome: "string",
      dataCreazione: "string",
      comunicazioni: [],
      sezioni: [],
      personale: [],
      orari: [],
    },
  ],
};
