import {
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useState } from "react";
import "../pages/CSS/Inventario.css";
import InventarioColumn_Sezioni from "../components/InventarioColumn_Sezioni";

import { Categoria, Ingrediente, Prodotto, Sezione } from "../data/dataSheet";
import InventarioColumn_Categorie from "../components/InventarioColumn_Categorie";
import InventarioColumn_Prodotti from "../components/InventarioColumn_Prodotti";
import InventarioColumn_Ingredienti from "../components/InventarioColumn_Ingredienti";

interface PageProps {
  DataDb: any,
  IngredientiDb: any,
  ProdottiDb: any,
  CategorieDb: any,
  SezioniDb: any,
  cartelle: any,
  ImmaginiDb: any,
  TagsDb: any,
  UpdateAll: any,
  handleAggiornaTutteLeCategorie: (data: any) => void;
  handleAggiornaTuttiIProdotti: (data: any) => void;
}

const Inventario: React.FC<PageProps> = ({
  DataDb,
  IngredientiDb,
  ProdottiDb,
  CategorieDb,
  SezioniDb,
  cartelle,
  ImmaginiDb,
  TagsDb,
  UpdateAll,
  handleAggiornaTutteLeCategorie,
  handleAggiornaTuttiIProdotti,
}) => {
  //VARIABILI ---------------------
  const [allIngredienti, setAllIngredienti] = useState<Ingrediente[]>();
  const [allProdotti, setAllProdotti] = useState<Prodotto[]>();
  const [allCategorie, setAllCategorie] = useState<Categoria[]>();
  const [allSezioni, setAllSezioni] = useState<Sezione[]>();
  const [allImmagini, setAllImmagini] = useState<any>();
  //CONDIZIONI --------------------

  //RETURN ------------------------
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Inventario</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Inventario</IonTitle>
          </IonToolbar>
        </IonHeader>

        {/* Contenuto */}
        <IonGrid>
          <IonRow class="ion-align-items-start">
            <IonCol size="3">
              <InventarioColumn_Sezioni
                AllData={DataDb}
                AllSezioni={SezioniDb}
                AllCategorie={CategorieDb}
                AllImmagini={ImmaginiDb}
                cartelle={cartelle}
                UpdateAll={UpdateAll}
              />
            </IonCol>

            <IonCol size="3">
              <InventarioColumn_Categorie
                AllSezioni={SezioniDb}
                allCategorie={CategorieDb}
                UpdateAll={UpdateAll}
                allProdotti={ProdottiDb}
                allImmagini={ImmaginiDb}
                cartelle={cartelle}
              />
            </IonCol>

            <IonCol size="3">
              <InventarioColumn_Prodotti
                ProdottiDb={ProdottiDb}
                IngredientiDb={IngredientiDb}
                TagsDb={TagsDb}
                UpdateAll={UpdateAll}
                allImmagini={ImmaginiDb}
                cartelle={cartelle}
                handleAggiornaTutteLeCategorie={(data) =>
                  handleAggiornaTutteLeCategorie(data)
                } />
            </IonCol>
            <IonCol size="3">
              <InventarioColumn_Ingredienti
                UpdateAll={UpdateAll}
                IngredientiDb={IngredientiDb}
                handleAggiornaTuttiIProdotti={(data) =>
                  handleAggiornaTuttiIProdotti(data)
                }
                allImmagini={ImmaginiDb}
                cartelle={cartelle}
              />
            </IonCol>
          </IonRow>
        </IonGrid>



      </IonContent>
    </IonPage>
  );
};

export default Inventario;
