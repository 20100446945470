import { Redirect, Route } from "react-router-dom";
import {
  IonApp,
  IonRouterOutlet,
  IonSplitPane,
  setupIonicReact,
  useIonLoading,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import AttivitaScreen from "./pages/AttivitaScreen";
import ComunicazioniScreen from "./pages/ComunicazioniScreen";
import SezioniScreen from "./pages/SezioniScreen";
import PersonaleScreen from "./pages/PersonaleScreen";
import OrariScreen from "./pages/OrariScreen";
import ChisiamoScreen from "./pages/ChisiamoScreen";
import React from "react";
import Inventario from "./pages/Inventario";
import IngredientiScreen from "./pages/IngredientiScreen";
import ProdottiScreen from "./pages/ProdottiScreen";
import CategorieScreen from "./pages/CategorieScreen";
import {
  DataDownloadAllData,
  DataDownLoadCategorie,
  DataDownLoadIngredienti,
  DataDownLoadProdotti,
  DataDownLoadSezioni,
  DataDownLoadTags,
  DataGetAllImmages,
  DataGetCartelle,
} from "./Logic/DataDownload";

import { collection, DocumentData, onSnapshot } from "firebase/firestore";

import Menu from "./components/Menu";
import SugestedProducts from "./pages/SugestedProducts";
import Dashboard from "./pages/Dashboard";
import TutteLeAttivitaScreen from "./pages/TutteLeAttivitaScreen";
import TableScreen from "./pages/TableScreen";
import { auth, db } from "./fb";
import OrdersTableScreen from "./pages/OrdersTableScreen";
import { useAuth } from "./auth";
import { defineCustomElements } from "@ionic/pwa-elements/loader";

import {
  url_app,
  url_app_allAttivita,
  url_app_allImmagini,
  url_app_attivita,
  url_app_attivita_categorie,
  url_app_attivita_chisiamo,
  url_app_attivita_comunicazioni,
  url_app_attivita_orari,
  url_app_attivita_personale,
  url_app_attivita_portfolio,
  url_app_attivita_prodottiConsigliati,
  url_app_attivita_sezionimenu,
  url_app_dashboard,
  url_app_inventario,
  url_app_inventario_categorie,
  url_app_inventario_ingredienti,
  url_app_inventario_prodotti,
  url_app_inventario_sezioni,
  url_app_tavoli,
  url_app_tavolo_ordini,
  url_loginScreen,
} from "./Urls";

import {
  ActionPerformed,
  PushNotifications,
  PushNotificationSchema,
  Token,
} from "@capacitor/push-notifications";

import {
  RegisterToken,
  UploadAggiornamentoCategorie,
  UploadDataProdotto,
  UploadNuovaCategoria,
} from "./Logic/DataUpload";

import AllCategorieScreen from "./pages/AllCategorieScreen";
import PortfolioScreen from "./pages/PortfolioScreen";
import { Categoria, Prodotto } from "./data/dataSheet";
import { getDownloadURL, listAll, ref } from "firebase/storage";
import AllImages from "./pages/AllImages";
import AllSezioniScreen from "./pages/AllSezioniScreen";
import { signOut } from "firebase/auth";
import { GETUID } from "./Logic/LocalStorage";

defineCustomElements(window);

setupIonicReact({
  swipeBackEnabled: false,
});

const App: React.FC = () => {
  //VARIABILI --------------------------------
  const { loggedIn, userId } = useAuth();
  const [data, setData] = React.useState<DocumentData>([]);
  const [prodotti, setProdotti] = React.useState<any>([]);
  const [ingredienti, setIngredienti] = React.useState<any>([]);
  const [tags, setTags] = React.useState<DocumentData>([]);
  const [categorie, setCategorie] = React.useState<any>([]);
  const [sezioni, setSezioni] = React.useState<any>([]);
  const [immagini, setImmagini] = React.useState<any>([]);
  const [cartelleImmagini, setCartelleImmagini] = React.useState<any>([]);

  const [present, dimiss] = useIonLoading();

  const [AllRealTables, setAllRealTables] = React.useState<any>([]);

  const [notificationToken, setNotificationToken] = React.useState<string>();

  const [realTablesData, setRealTablesData] = React.useState<any>();

  const [auxIdRealTables, setAuxIdRealTables] = React.useState<string>("Real");

  const [allAttivita, setAllAttivita] = React.useState<any>();

  //CONDIZIONI -------------------------------

  React.useEffect(() => {
    // console.log("************** auxIdRealTables : ", auxIdRealTables)
    const unsub = onSnapshot(collection(db, auxIdRealTables), (snapshot) => {
      const obj = {
        data: snapshot.docs,
        id: auxIdRealTables,
      };
      setAllRealTables(obj);
    });
  }, [auxIdRealTables]);

  // TOKEN -----
  React.useEffect(() => {
    // console.log('Initializing HomePage');

    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermissions().then((result) => {
      console.log("******** RICHIESTA PERMESSO");
      if (result.receive === "granted") {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
        console.log("******** RICHIESTA REGISTRATA");
      } else {
        // Show some error
        console.log("******** RICHIESTA ERRATA");
      }
    });

    // On success, we should be able to receive notifications
    PushNotifications.addListener("registration", (token: Token) => {
      console.log("********Push registration success, token: " + token.value);
      setNotificationToken(token.value);
      RegisterToken(token.value, "fcmTokens", userId!);
    });

    // Some issue with our setup and push will not work
    PushNotifications.addListener("registrationError", (error: any) => {
      console.log(
        "********Push Error on registration: " + JSON.stringify(error)
      );
    });

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener(
      "pushNotificationReceived",
      (notification: PushNotificationSchema) => {
        console.log(
          " ********Push Push received: " + JSON.stringify(notification)
        );
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener(
      "pushNotificationActionPerformed",
      (notification: ActionPerformed) => {
        console.log(
          " ********Push Push action performed: " +
            (notification.notification)
        );
        const data = notification.notification.data;
        if (data.datailsId) {
          console.log("ROUTE TO : ", data.datailsId);
        }
      }
    );
  }, []);

  const getRealTablesId = (data: any) => {
    // console.log("getRealTablesId ....");
    let arr: any = [];
    data.map((attivitaFunc: any, index: number) => {
      let id = attivitaFunc?.realTables?.id.split("-")[1];
      const objRealTables = {
        nome: attivitaFunc.nome,
        dbId: attivitaFunc?.realTables?.id,
        idAttivita: id,
      };
      // console.log("ATTIVITA :", attivitaFunc.nome, " OBJ : ", objRealTables);
      if (attivitaFunc?.realTables?.attivata) {
        arr.push(objRealTables);
      }
    });
    setRealTablesData(arr);
  };

  //DATA ATTIVITA ----
  const getData = async () => {
    let a = await DataDownloadAllData();
    // console.log("App - getData - a: ", Array.isArray(a));
    let b = [...a];
    return b;
  };
  const getProdotti = async () => {
    let a = await DataDownLoadProdotti();
    // console.log("App - getProdotti - a: ", (a));
    let b = [...a];
    return b;
  };
  const getIngredienti = async () => {
    let a = await DataDownLoadIngredienti();
    // console.log("App - getIngredienti - a: ", (a));
    let b = [...a];
    return b;
  };
  const getTags = async () => {
    let a = await DataDownLoadTags();
    // console.log("App - getTags - a: ", (a));
    return a;
  };
  const getCategorie = async () => {
    let a = await DataDownLoadCategorie();
    // console.log("App - getCategorie - a: ", (a));
    let b = [...a];
    return b;
  };
  const getSezioni = async () => {
    let a = await DataDownLoadSezioni();
    // console.log("App - getSezioni - a: ", (a));
    let b = [...a];
    return b;
  };

  const UpdateCategorie = async () => {
    // console.log("************* APP: UpdateCategorie ()");
    setCategorie(await getCategorie());
  };

  const UpdateSezioni = async () => {
    console.log("************* APP: UpdateSezioni ()");
    setSezioni(await getSezioni());
  };

  const UpdateIngAndProducts = async () => {
    // console.log("************* APP: UpdateIngAndProducts");
    setProdotti(await getProdotti());
    setIngredienti(await getIngredienti());
    setCategorie(await getCategorie());
    setSezioni(await getSezioni());
    setTags(await getTags());
  };

  const UpdateDataAttivitaAndSezioni = async () => {
    console.log("************* APP: UpdateDataAttivitaAndSezioni");
    setData(await getData());
    UpdateSezioni();
  };

  // DATA INITIZLIZZATION -------------------
  const UpdateAll = async () => {
    // console.log("************* APP: UPDATE ALL");
    setData(await getData());
    UpdateIngAndProducts();
  };

  const getImmagini = async () => {
    console.log("getImmagini TRIGGERATO.");
    await getCartelle();
    await DataGetCartelle().then(async (x) => {
      console.log("getImmagini : cartelle trovate :", x);
      setCartelleImmagini(x);
      await DataGetAllImmages(x).then((immagini) => {
        console.log("getImmagini : ", immagini);
        setImmagini(immagini);
      });
    });
  };

  const getCartelle = async () => {
    await DataGetCartelle().then(async (x) => {
      setCartelleImmagini(x);
    });
  };

  React.useState(async () => {
    let uid = await GETUID();

    if (uid !== userId) signOut(auth);

    if (loggedIn) {
      console.log("UTENTE: ", userId);
      present({
        message: "Sto aggiornando i dati...",
      });
      await UpdateAll();
      await getImmagini();
      await getCartelle();
    }
  });

  React.useEffect(() => {
    // console.log("App - React.useEffect: data", data);
    getRealTablesId(data);
    setAllAttivita(data);
    dimiss();
  }, [data]);

  //---------- Aggiornamento automatico
  //Inventario - tutti i prodotti
  /**
   * Quando un ingrediente viene modificato aggirna tutti i prodotti del db Prodotti
   */
  const handleAggiornaTuttiIProdotti = async (ingredienteModificato: any) => {
    let auxProdotti = [...prodotti];
    console.log(
      "handleAggiornaTuttiIProdotti - ingrediente: ",
      ingredienteModificato
    );
    present({
      message: "Sto aggiornando i prodotti con gli ingredienti aggiornati...",
    });

    auxProdotti?.map((prodotto: Prodotto, indexProdotto: number) => {
      prodotto.ingredienti?.map(
        async (ingrediente: any, indexIngrediente: number) => {
          if (ingrediente.id === ingredienteModificato.id) {
            //console.log("********* Ingrediente modificato trovato in:","Inidice ingrediente : ",indexIngrediente ," ID INGREDIENTE: ", ingrediente.id, "ingredienteModificato.id : ",ingredienteModificato.id , " ----- ", prodotto);
            auxProdotti[indexProdotto].ingredienti[indexIngrediente] =
              ingredienteModificato;
            // console.log("PRODOTTO PRIMA: ", auxProdotti[indexProdotto]);
            await handleAggiornaTutteLeCategorie(prodotto);
            await UploadDataProdotto(auxProdotti[indexProdotto]).then(() => {
              console.log("Ingrediente aggiornato con successo!");
            });
          }
        }
      );
    });
    dimiss();
  };

  const handleAggiornaTutteLeCategorie = async (prodottoDaAggiornare: any) => {
    let auxCategorie = [...categorie];
    // console.log("handleAggiornaTutteLeCategorie - prodotto: ", prodottoDaAggiornare);
    // present({
    //   message: "Sto aggiornando i prodotti con gli ingredienti aggiornati..."
    // });

    auxCategorie?.map(async (categoria: Categoria, indexCategoria: number) => {
      categoria.prodotti?.map(
        async (prodotto: Prodotto, indexProdotto: number) => {
          if (prodotto.id === prodottoDaAggiornare.id) {
            auxCategorie[indexCategoria].prodotti[indexProdotto] =
              prodottoDaAggiornare;
            await UploadNuovaCategoria(
              auxCategorie[indexCategoria],
              categoria.id
            ).then(async () => {
              // console.log("PRODOTTO TROVATO NELL CATEGORIA : ", categoria.nome, "ID Categoria: ", categoria.id, " Aggiornato con successo! : ", auxCategorie[indexCategoria]);
              await UpdateCategorie().then(() => {
                console.log("Tutte le categorie sono state aggiornate.");
              });
            });
          }
        }
      );
    });
    await handleAggiornaPerOgniAttivitàIlProdotto(prodottoDaAggiornare);
  };

  const handleAggiornaPerOgniAttivitàIlProdotto = async (
    prodottoDaAggiornare: any
  ) => {
    let auxAllAttività = [...allAttivita];
    // console.log("+++++++++++++++++ handleAggiornaPerOgniAttivitàIlProdotto: ", prodottoDaAggiornare);
    auxAllAttività?.map(async (attivita: any, indexAttivita: number) => {
      // console.log("+++++",indexAttivita,"ATTIVITA : ", attivita);

      attivita?.categorie.map(
        async (categoria: any, indexCategoria: number) => {
          categoria?.prodotti.map(
            async (prodotto: any, indexProdotto: number) => {
              if (prodotto.id === prodottoDaAggiornare.id) {
                // console.log("******* il prodotto : ", prodottoDaAggiornare.nome, " è stato trovato in : ", attivita.nome, " nella categoria: ", categoria.nome);
                auxAllAttività[indexAttivita].categorie[
                  indexCategoria
                ].prodotti[indexProdotto] = prodottoDaAggiornare;

                // *** Aggiornamento categoria in Sezioni dell'attività
                auxAllAttività[indexAttivita].sezioni?.map(
                  (categoriaInSezioni: any, indexSezione: number) => {
                    // console.log("La sezione :", categoriaInSezioni.nome, " ha queste categorie:")
                    categoriaInSezioni?.categorie.map(
                      (cat: any, indexCat: number) => {
                        console.log(indexCat, cat.nome);
                        if (categoria.id === cat.id) {
                          // console.log("******************* Questa categoria è da aggiornare");
                          // console.log("Vecchia versione:",cat);
                          // console.log("Nuova versione: ", auxAllAttività[indexAttivita].categorie[indexCategoria]);
                          auxAllAttività[indexAttivita].sezioni[
                            indexSezione
                          ].categorie[indexCat] =
                            auxAllAttività[indexAttivita].categorie[
                              indexCategoria
                            ];
                        }
                      }
                    );
                  }
                );
              }
            }
          );
        }
      );
      //Aggiornamento dati prodotto in Prodotti Consigliati
      attivita?.prodottiConsigliati.map(
        (prodottoConsigliato: any, indexProdottoConsigliato: number) => {
          if (prodottoConsigliato.id === prodottoDaAggiornare.id) {
            auxAllAttività[indexAttivita].prodottiConsigliati[
              indexProdottoConsigliato
            ] = prodottoDaAggiornare;
          }
        }
      );
      await UploadAggiornamentoCategorie(
        auxAllAttività[indexAttivita],
        auxAllAttività[indexAttivita].id
      );
    });
  };

  if (!loggedIn) {
    console.log("LOGGED IN : ", loggedIn);
    return <Redirect to={url_loginScreen} />;
  }

  //RETURN -----------------------------------
  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId="main">
          <Menu allActivity={data} realTables={realTablesData} />
          <IonRouterOutlet id="main">
            <Redirect exact={true} path={url_app} to={url_app_dashboard} />

            {/* TABS PAGES */}
            <Route path={url_app_dashboard} exact={true}>
              <Dashboard
                AllData={data}
                setAllData={setData}
                AllRealTables={AllRealTables}
                IngredientiDb={ingredienti}
                ProdottiDbDashboard={prodotti}
              />
            </Route>

            <Route exact={true} path={url_app_inventario}>
              <Inventario
                IngredientiDb={ingredienti}
                ProdottiDb={prodotti}
                SezioniDb={sezioni}
                DataDb={data}
                CategorieDb={categorie}
                cartelle={cartelleImmagini}
                ImmaginiDb={immagini}
                UpdateAll={UpdateAll}
                TagsDb={tags}
                handleAggiornaTutteLeCategorie={(data) =>
                  handleAggiornaTutteLeCategorie(data)
                }
                handleAggiornaTuttiIProdotti={(data) =>
                  handleAggiornaTuttiIProdotti(data)
                }
              />
            </Route>

            <Route exact={true} path={url_app_tavoli}>
              <TableScreen
                AllRealTables={AllRealTables}
                RealTablesData={realTablesData}
                setAuxIdRealTables={setAuxIdRealTables}
              />
            </Route>

            <Route exact={true} path={url_app_tavolo_ordini}>
              <OrdersTableScreen AllRealTables={AllRealTables} AllData={data} />
            </Route>

            <Route exact={true} path={url_app_allAttivita}>
              <TutteLeAttivitaScreen AllData={data} setAllData={setData} />
            </Route>

            {/* PAGINE INVENTARIO */}
            <Route exact={true} path={url_app_inventario_ingredienti}>
              <IngredientiScreen
                allImmagini={immagini}
                cartelle={cartelleImmagini}
                handleAggiornaTuttiIProdotti={(data) =>
                  handleAggiornaTuttiIProdotti(data)
                }
                IngredientiDb={ingredienti}
                UpdateAll={UpdateAll}
              />
            </Route>

            <Route path={url_app_allImmagini} exact={true}>
              <AllImages
                updateCartelle={() => getCartelle()}
                allImmagini={immagini}
                getImmagini={() => getImmagini()}
                cartelle={cartelleImmagini}
              />
            </Route>

            <Route exact={true} path={url_app_inventario_prodotti}>
              <ProdottiScreen
                allImmagini={immagini}
                cartelle={cartelleImmagini}
                ProdottiDb={prodotti}
                IngredientiDb={ingredienti}
                TagsDb={tags}
                UpdateAll={UpdateAll}
                handleAggiornaTutteLeCategorie={(data) =>
                  handleAggiornaTutteLeCategorie(data)
                }
              />
            </Route>

            <Route exact={true} path={url_app_inventario_categorie}>
              <AllCategorieScreen
                AllSezioni={sezioni}
                allImmagini={immagini}
                cartelle={cartelleImmagini}
                allCategorie={categorie}
                UpdateAll={UpdateCategorie}
                allProdotti={prodotti}
              />
            </Route>

            <Route exact={true} path={url_app_inventario_sezioni}>
              <AllSezioniScreen
                AllData={data}
                AllSezioni={sezioni}
                AllCategorie={categorie}
                AllImmagini={immagini}
                cartelle={cartelleImmagini}
                UpdateAll={() => UpdateDataAttivitaAndSezioni()}
              />
            </Route>

            {/* PAGINE HOME SCREEN */}
            <Route path={url_app_attivita} exact={true}>
              <AttivitaScreen AllData={data} setAllData={setData} />
            </Route>
            <Route path={url_app_attivita_prodottiConsigliati} exact={true}>
              <SugestedProducts
                AllData={data}
                setAllData={setData}
                AllProdotti={prodotti}
              />
            </Route>
            <Route path={url_app_attivita_comunicazioni} exact={true}>
              <ComunicazioniScreen
                AllData={data}
                setAllData={setData}
                allImmagini={immagini}
                cartelle={cartelleImmagini}
              />
            </Route>

            <Route path={url_app_attivita_sezionimenu} exact={true}>
              <SezioniScreen
                AllData={data}
                setAllData={setData}
                allImmagini={immagini}
                cartelle={cartelleImmagini}
                sezioniInventario={sezioni}
              />
            </Route>

            <Route path={url_app_attivita_categorie} exact={true}>
              <CategorieScreen
                allImmagini={immagini}
                cartelle={cartelleImmagini}
                AllData={data}
                setAllData={setData}
                prodotti={prodotti}
                categorieGenerali={categorie}
              />
            </Route>

            <Route path={url_app_attivita_personale} exact={true}>
              <PersonaleScreen
                AllData={data}
                setAllData={setData}
                allImmagini={immagini}
                cartelle={cartelleImmagini}
              />
            </Route>

            <Route path={url_app_attivita_orari} exact={true}>
              <OrariScreen AllData={data} setAllData={setData} />
            </Route>
            <Route path={url_app_attivita_chisiamo} exact={true}>
              <ChisiamoScreen
                AllData={data}
                setAllData={setData}
                allImmagini={immagini}
                cartelle={cartelleImmagini}
              />
            </Route>

            <Route exact={true} path={url_app_attivita_portfolio}>
              <PortfolioScreen AllData={data} setAllData={setData} />
            </Route>
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
