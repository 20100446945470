import React from "react";
import "./css/UploadMultipleImagesPortfolio.css";
import imageCompression from "browser-image-compression";

type UploadMultipleImagesProps = {
  setImage: any;
  immagini?: string[];
};

const UploadMultipleImagesPortfolio: React.FC<UploadMultipleImagesProps> = ({
  setImage,
  immagini,
}) => {
  //VARIABILI --------------------------------------------
  const [images, setImages] = React.useState([] as any);
  const [imageURLS, setImageURLs] = React.useState(immagini ? immagini : []);

  //CONDIZIONI -------------------------------------------
  React.useEffect(() => {
    console.log(images);
    if (images.length < 1) return;
    const newImageUrls: any = [];
    images.forEach((image: any) =>
      newImageUrls.push(URL.createObjectURL(image))
    );
    setImageURLs(newImageUrls);
    setImage(images);
  }, [images]);

  function onImageChange(e: any) {
    setImages([...e.target.files]);
    compressImage([...e.target.files]);
  }

  const compressImage = async (e: any) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    let auxArrCompress: File[] = [];
    e.forEach(async (element: any) => {
      try {
        const compressFile = await imageCompression(element, options);
        auxArrCompress.push(compressFile);
        setImage(auxArrCompress);
      } catch (error) {
        console.log("Compression error:", error);
      }
    });
  };

  //RETURN -----------------------------------------------
  return (
    <>
      <input
        type="file"
        multiple
        accept="application/octet-stream, image/*"
        onChange={onImageChange}
        className="ion-margin-bottom"
      />
      {immagini === undefined ? (
        <>
          {imageURLS?.map((imageSrc: string, index: number) => {
            return (
              <div
                key={index + "div-img-prodotto-immagini-portfolio"}
                className="div-img-prod"
              >
                <img
                  src={imageSrc}
                  alt="not fount"
                  width={"250px"}
                  className="img-prod"
                />
              </div>
            );
          })}
        </>
      ) : null}
    </>
  );
};

export default UploadMultipleImagesPortfolio;
