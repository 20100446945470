import { IonSlide, IonSlides } from "@ionic/react";
import React from "react";
import "./UploadMultipleImages.css";
import imageCompression from "browser-image-compression";

type UploadSingleImageProps = {
  setImage: any;
  immagine?: string;
};

const UploadSingleImage: React.FC<UploadSingleImageProps> = ({
  setImage,
  immagine,
}) => {
  //VARIABILI --------------------------------------------
  const slideOpts = {
    initialSlide: 0,
    speed: 400,
    spaceBetween: 5,
  };
  const [images, setImages] = React.useState([] as any);
  const [imageURLS, setImageURLs] = React.useState(immagine);
  //CONDIZIONI -------------------------------------------
  React.useEffect(() => {
    if (images.length < 1) return;
    const newImageUrls: any = [];
    images.forEach((image: any) =>
      newImageUrls.push(URL.createObjectURL(image))
    );
    setImageURLs(newImageUrls);
    // setImage(newImageUrls);
  }, [images]);

  function onImageChange(e: any) {
    setImages([...e.target.files]);
    compressImage(e);
  }

  const compressImage = async (e: any) => {
    const imageFile = e.target.files[0];

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    try {
      const compressFile = await imageCompression(imageFile, options);
      setImage(compressFile);
    } catch (error) {
      console.log("Compression error:", error);
    }
  };

  //RETURN -----------------------------------------------
  return (
    <>
      <input
        type="file"
        accept="application/octet-stream, image/*"
        onChange={onImageChange}
        className="ion-margin-bottom"
      />
      <IonSlides pager options={slideOpts}>
        {imageURLS ? (
          <IonSlide>
            <div className="div-img-prod">
              <img
                src={imageURLS}
                alt="not fount"
                width={"250px"}
                className="img-prod"
              />
            </div>
          </IonSlide>
        ) : null}
      </IonSlides>
    </>
  );
};

export default UploadSingleImage;
