import { useEffect, useRef, useState } from 'react';
import './InventarioColumn.css';
import { IonAvatar, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonChip, IonContent, IonHeader, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonList, IonModal, IonSearchbar, IonSegment, IonSegmentButton, IonTitle, IonToolbar, useIonAlert, useIonLoading, useIonToast } from '@ionic/react';
import { add, alertCircleOutline, close, cloudDoneOutline, imagesOutline, pencil, trash } from 'ionicons/icons';
import { Ingrediente, logoMenuShare } from '../data/dataSheet';
import { DeleteSingleImage, UploadSingleImageFirebase } from '../Logic/ImageUpload';
import { LocalSaveIngredienti } from '../Logic/LocalStorage';
import { DeleteDataIngrediente, UploadDataIngredienti } from '../Logic/DataUpload';
import { DataDownLoadIngredienti, defaultFolders } from '../Logic/DataDownload';
import UploadSingleImage from './UploadSingleImage';

interface ContainerProps {
  UpdateAll: any;
  IngredientiDb: any;
  handleAggiornaTuttiIProdotti: (data: any) => void;
  allImmagini: any;
  cartelle: any;
}

const InventarioColumn_Ingredienti: React.FC<ContainerProps> = ({
  UpdateAll,
  IngredientiDb,
  handleAggiornaTuttiIProdotti,
  allImmagini,
  cartelle,
}) => {
  //VARIABILI --------------------------------------------

  const folder = "ingredienti";
  const [allIngredienti, setAllIngredienti] = useState(IngredientiDb);
  const [auxAllIngredienti, setAuxAllIngredienti] = useState<any>([]);

  const [presentAlert] = useIonAlert();
  const [searchTextModale, setSearchTextModale] = useState("");
  //aggiungi
  const [isModalAddOpen, setIsModalAddOpen] = useState<boolean>(false);

  const [id, setId] = useState<number>();
  const [inputNome, setInputNome] = useState("Non specificato.");
  const [inputMarca, setInputMarca] = useState("Non specificato.");
  const [inputDescrizione, setInputDescrizione] =
    useState("Non specificato.");
  const [immagine, setImmagine] = useState();

  //modifica
  const [indiceModifica, setIndiceModifica] = useState<number>();
  const [isModalModificheAddOpen, setIsModalModificheAddOpen] =
    useState<boolean>(false);

  const [idModifiche, setIdModifiche] = useState();
  const [inputNomeModifiche, setInputNomeModifiche] = useState();
  const [inputMarcaModifiche, setInputMarcaModifiche] = useState();
  const [inputDescrizioneModifiche, setInputDescrizioneModifiche] =
    useState();
  const [immagineModifiche, setImmagineModifiche] = useState();

  const [linkImmagine, setLinkImmagine] = useState(undefined);
  const [linkImmagineModifica, setLinkImmagineModifica] =
    useState(undefined);

  const [present, dimiss] = useIonLoading();
  const [presentToast] = useIonToast();

  const scrollRef = useRef<HTMLIonContentElement | null>(null);

  const [segmentValue, setSegmentValue] = useState("Seleziona");
  const [immagineSelezionata, setImmagineSelezionata] = useState();

  const [isModaleImmaginiOpen, setIsModaleImmaginiOpen] = useState(false);
  const [filtroCartelle, setFiltroCartelle] = useState<any>();
  const [filtroVisualizzazioneImmagini, setFiltroVisualizzazioneImmagini] =
    useState<any>("column-portfolio-4");

  //CONDIZIONI --------------------

  const generateItems = () => {
    console.log("generateItems");
    const newItems = [];
    for (let index = auxAllIngredienti.length; index < (auxAllIngredienti.length + 30); index++) {
      if (allIngredienti.length > index) {
        newItems.push(allIngredienti[index]);
      } else {
        console.log("tutti i prodotti caricati")
      }
    }
    setAuxAllIngredienti([...auxAllIngredienti, ...newItems]);
  }

  const resetGenerateItems = () => {
    console.log("generateItems");
    const newItems = [];
    for (let index = 0; index < (30); index++) {
      if (allIngredienti.length > index) {
        newItems.push(allIngredienti[index]);
      } else {
        console.log("tutti i prodotti caricati")
      }
    }
    setAuxAllIngredienti([...newItems]);
  }

  useEffect(() => {
    generateItems()
  }, [])

  const handleSelectSegment = (segmentValue: string) => {
    setSegmentValue(segmentValue);
    //reset degli input di entrambi quando si cambia
    setImmagine(undefined);
    setImmagineModifiche(undefined);
    setImmagineSelezionata(undefined);
  };

  const handleFiltraImmagini = (testoCercato: string) => {
    let arr = Array.from(
      window.document.getElementsByClassName("immaginiGalleria")
    );
    const query = testoCercato.toLowerCase();
    requestAnimationFrame(() => {
      arr.forEach((item: any) => {
        const shouldShow = item.textContent.toLowerCase().indexOf(query) > -1;
        item.style.display = shouldShow ? "block" : "none";
      });
    });
  };

  const presentToastType = (
    message: string,
    type: "danger" | "success" | "warning",
    buttonEv: () => void
  ) => {
    const iconType = {
      danger: close,
      success: cloudDoneOutline,
      warning: alertCircleOutline,
    };

    presentToast({
      message: message,
      position: "top",
      duration: 3000,
      icon: iconType[type],
      color: type,
      buttons: [
        {
          text: "Chiudi",
          role: "cancel",
          handler: () => {
            buttonEv();
          },
        },
      ],
    });
  };

  const salvaNuovoIngrediente = async () => {
    if (inputNome !== "Non specificato.") {
      present({
        message: "Sto salvando l'ingrediente...",
      });
      setIsModalAddOpen(false);
      let auxId = Date.now();
      setId(auxId);
      //Carica immagine e poi si salva tramite il useEffect

      if (immagineSelezionata !== undefined) {
        const oggettoIngrediente = {
          id: auxId,
          nome: inputNome,
          marca: inputMarca,
          descrizione: inputDescrizione,
          immagineUrl: immagineSelezionata,
        };
        salvaAllData(oggettoIngrediente);
      } else {
        if (immagine === undefined) {
          const oggettoIngrediente = {
            id: auxId,
            nome: inputNome,
            marca: inputMarca,
            descrizione: inputDescrizione,
            immagineUrl: logoMenuShare,
          };
          salvaAllData(oggettoIngrediente);
        } else {
          await UploadSingleImageFirebase(
            immagine!,
            auxId,
            setLinkImmagine,
            folder
          );
        }
      }
    } else {
      presentToastType("ATTENZIONE! Nome non inserito", "warning", () => { });
    }
  };

  useEffect(() => {
    if (linkImmagine !== undefined) {
      const oggettoIngrediente = {
        id: id,
        nome: inputNome,
        marca: inputMarca,
        descrizione: inputDescrizione,
        immagineUrl: linkImmagine,
      };
      salvaAllData(oggettoIngrediente);
    }
  }, [linkImmagine]);

  const salvaAllData = async (oggettoIngrediente: any) => {
    let a = [...allIngredienti];
    a.push(oggettoIngrediente);
    console.log("salvaAllData - a : ", a);
    setAllIngredienti(a);
    LocalSaveIngredienti(a);

    await UploadDataIngredienti(oggettoIngrediente);
    //Reset All data input
    UpdateAll();
    resetAllDataInput();
    dimiss();
    presentToastType("Ingrediente salvato", "success", () => { });
  };

  const handleModifica = (item: any) => {
    // console.log("modifica", index, "Ingrediente : ", allIngredienti[index]);
    let auxIngrediente: any;
    let auxIndex: any;
    allIngredienti.forEach((ingrediente: Ingrediente, index: number) => {
      if (ingrediente.id === item.id) {
        auxIngrediente = ingrediente;
        auxIndex = index;
      }
    });
    setIndiceModifica(auxIndex);
    setIsModalModificheAddOpen(true);
    setIdModifiche(auxIngrediente.id);
    setInputNomeModifiche(auxIngrediente.nome);
    setInputMarcaModifiche(auxIngrediente.marca);
    setInputDescrizioneModifiche(auxIngrediente.descrizione);
    setImmagineModifiche(auxIngrediente.immagineUrl);
  };

  const handleSalvaModifiche = async () => {
    present({
      message: "Sto aggiornando l'ingrediente...",
    });
    setIsModalModificheAddOpen(false);

    if (immagineSelezionata !== undefined) {
      let oggettoModificato = {
        id: idModifiche,
        nome: inputNomeModifiche,
        marca: inputMarcaModifiche,
        descrizione: inputDescrizioneModifiche,
        immagineUrl: immagineSelezionata,
      };
      handleSalvaAllModifiche(oggettoModificato);
    } else {
      let oggettoModificato;
      if (immagineModifiche === allIngredienti[indiceModifica!].immagineUrl) {
        console.log("immagineModifiche è la stessa immagine");
        oggettoModificato = {
          id: idModifiche,
          nome: inputNomeModifiche,
          marca: inputMarcaModifiche,
          descrizione: inputDescrizioneModifiche,
          immagineUrl: immagineModifiche,
        };
        handleSalvaAllModifiche(oggettoModificato);
      } else {
        console.log("UPLOAD IMMAGINE");
        await UploadSingleImageFirebase(
          immagineModifiche!,
          idModifiche!,
          setLinkImmagineModifica,
          folder
        );
      }
    }
  };

  useEffect(() => {
    if (linkImmagineModifica !== undefined) {
      console.log("linkImmagineModifica : ", linkImmagineModifica);
      const oggettoModificato = {
        id: idModifiche,
        nome: inputNomeModifiche,
        marca: inputMarcaModifiche,
        descrizione: inputDescrizioneModifiche,
        immagineUrl: linkImmagineModifica,
      };
      handleSalvaAllModifiche(oggettoModificato);
    }
  }, [linkImmagineModifica]);

  const handleSalvaAllModifiche = async (oggettoModificato: any) => {
    let a = [...allIngredienti];
    a[indiceModifica!] = oggettoModificato;
    setAllIngredienti(a);
    LocalSaveIngredienti(a);
    await UploadDataIngredienti(oggettoModificato);
    dimiss();
    presentToastType("Ingrediente aggiornato.", "success", () => { });
    UpdateAll();
    resetAllDataInput();

    console.log("AGGIORNAMENTO TUTTI I PRODOTTI ...");
    handleAggiornaTuttiIProdotti(oggettoModificato);
  };

  const handleCancella = async (item: any) => {
    // console.log("cancella", index);
    let auxIngrediente: any;
    let auxIndex: any;
    allIngredienti.forEach((ingrediente: Ingrediente, index: number) => {
      if (ingrediente.id === item.id) {
        auxIngrediente = ingrediente;
        auxIndex = index;
      }
    });

    let a = [...allIngredienti];

    let idIngrediente = a[auxIndex].id;
    console.log(idIngrediente);
    a.splice(auxIndex, 1);
    setAllIngredienti(a);
    LocalSaveIngredienti(a);
    await DeleteDataIngrediente(idIngrediente);
    setSearchTextModale("");
    // await DeleteSingleImage(idIngrediente, folder);
    UpdateAll();
  };

  const handleSearch = (testoCercato: string) => {
    let arr: any = [];
    const query = testoCercato.toLowerCase();
    if (testoCercato.length === 0) {
      resetGenerateItems()
    } else {
      requestAnimationFrame(() => {
        allIngredienti.forEach((item: Ingrediente) => {
          const shouldShow = item.nome.toLowerCase().indexOf(query) > -1;
          if (shouldShow) arr.push(item);
        });
        setAuxAllIngredienti([...arr]);
      });
    }
  };

  const resetAllDataInput = () => {
    console.log("Reset All data input");
    setId(undefined);
    setInputNome("Non specificato.");
    setInputMarca("Non specificato.");
    setInputDescrizione("Non specificato.");
    setImmagine(undefined);
    setIndiceModifica(undefined);
    setIdModifiche(undefined);
    setInputNomeModifiche(undefined);
    setInputMarcaModifiche(undefined);
    setInputDescrizioneModifiche(undefined);
    setImmagineModifiche(undefined);
    setLinkImmagineModifica(undefined);
    setLinkImmagine(undefined);

    setImmagineSelezionata(undefined);
  };

  const getIngredienti = async () => {
    let a = await DataDownLoadIngredienti();
    // console.log("App - getIngredienti - a: ", (a));
    let b = [...a];
    return b;
  };

  const scrollToTop = () => {
    scrollRef.current && scrollRef.current.scrollToTop(500);
  };

  useState(async () => {
    // console.log(LocalGetIngredienti());
    setAllIngredienti(await getIngredienti());
    resetAllDataInput();
  });

  //RETURN -----------------------------------------------
  return (
    <div className="inventario-column-container">
      <IonToolbar>
        <IonTitle>
          Ingredienti: {allIngredienti.length}
        </IonTitle>
        <IonButtons slot='end'>
          <IonButton fill='solid' color={"primary"} onClick={() => setIsModalAddOpen(true)}>
            <IonIcon icon={add} />
            Aggiungi
          </IonButton>
        </IonButtons>
      </IonToolbar>
      <IonToolbar>
        <IonSearchbar
          value={searchTextModale}
          placeholder={"Cerca per nome"}
          onIonChange={(e) => {
            setSearchTextModale(e.detail.value!);
            handleSearch(e.detail.value!);
          }}
        ></IonSearchbar>
      </IonToolbar>
      <div className="inventario-content">
        <div className="inventario-column-items">
          <IonList>
            {auxAllIngredienti?.map((item: Ingrediente, index: number) => {
              let clas = "ingredienteInventario";
              if (item.marca === "Non specificato.") clas += " noMarca";
              if (
                item.immagineUrl ===
                "https://firebasestorage.googleapis.com/v0/b/menushare-a4100.appspot.com/o/logos%2FmenuShareLogo.jpg?alt=media&token=6f02aee1-26d7-4eca-9714-c3ba4515abfd"
              )
                clas += " noImmagine";

              return (
                <div key={index + "ingredienteInventario"} className={clas}>
                  <IonItem>
                    <IonAvatar slot="start">
                      <img src={item.immagineUrl} alt="Immagine" />
                    </IonAvatar>
                    <IonLabel>
                      {item.nome}
                      <p>Marca : {item.marca}</p>
                      <IonToolbar>
                        <IonButtons className='ion-justify-content-around'>
                          <IonButton
                            color={"primary"}
                            slot="end"
                            fill='solid'
                            onClick={() => handleModifica(item)}
                          >
                            <IonIcon icon={pencil} />
                          </IonButton>
                          <IonButton
                            color={"danger"}
                            slot="end"
                            fill='solid'
                            onClick={() =>
                              presentAlert({
                                header: "Attenzione!",
                                message:
                                  "L'eliminazione è definitiva! E rimarrà nei prodotti che lo contengono. Per un'eliminazione completa rimuoverlo anche dai prodotti.",
                                buttons: [
                                  {
                                    text: "Annulla",
                                    role: "cancel",
                                  },
                                  {
                                    text: "Conferma",
                                    role: "confirm",
                                    handler: () => {
                                      handleCancella(item);
                                    },
                                  },
                                ],
                              })
                            }
                          >
                            <IonIcon icon={trash} />
                          </IonButton>
                        </IonButtons>
                      </IonToolbar>
                    </IonLabel>

                  </IonItem>
                </div>
              );
            })}

          </IonList>
        </div>
        {
          auxAllIngredienti.length === allIngredienti.length ?
            <IonButton expand='block' color={"success"}>
              Tutti gli ingredienti caricati
            </IonButton>
            :
            <IonButton expand='block' onClick={() => generateItems()}>
              Carica altri ingredienti
            </IonButton>
        }
      </div>
      {/*---------------------------------- AGGIUNGI MODALI --------------------------------------------------*/}
      <IonModal
        isOpen={isModalAddOpen}
        onDidDismiss={() => {
          setIsModalAddOpen(false);
        }}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton
                color={"medium"}
                onClick={() => {
                  setImmagineSelezionata(undefined);
                  setIsModalAddOpen(false);
                }}
              >
                Annulla
              </IonButton>
            </IonButtons>
            <IonTitle>Nuovo Ingrediente</IonTitle>
            <IonButtons slot="end">
              <IonButton
                fill="solid"
                color={"success"}
                onClick={() => {
                  salvaNuovoIngrediente();
                }}
              >
                Salva
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <IonSegment value={segmentValue}>
            <IonSegmentButton
              value="Carica"
              onClick={() => handleSelectSegment("Carica")}
            >
              <IonLabel>Carica Immagine</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton
              value="Seleziona"
              onClick={() => handleSelectSegment("Seleziona")}
            >
              <IonLabel>Seleziona Immagine</IonLabel>
            </IonSegmentButton>
          </IonSegment>

          {segmentValue === "Carica" ? (
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>Carica immagine</IonCardTitle>
                <IonCardSubtitle>
                  Se non carichi nessuna immagine sarà utilizzato il logo di
                  MenuShare
                </IonCardSubtitle>
              </IonCardHeader>
              <IonCardContent>
                <UploadSingleImage setImage={setImmagine} />
              </IonCardContent>
            </IonCard>
          ) : (
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>Seleziona immagine</IonCardTitle>
                <IonCardSubtitle>
                  Se non selezioni nessuna immagine sarà utilizzato il logo di
                  MenuShare
                </IonCardSubtitle>
              </IonCardHeader>
              <IonCardContent>
                <IonButton
                  color={
                    immagineSelezionata !== undefined ? "success" : "primary"
                  }
                  expand="block"
                  onClick={() => setIsModaleImmaginiOpen(true)}
                >
                  <IonIcon icon={imagesOutline} className="ion-margin-end" />
                  Seleziona
                </IonButton>
                {immagineSelezionata !== undefined ? (
                  <IonImg src={immagineSelezionata}></IonImg>
                ) : null}
              </IonCardContent>
            </IonCard>
          )}

          <div className="ion-padding windowDubleHeight">
            <IonList>
              <IonItem fill="outline">
                <IonLabel position="stacked">
                  Inserisci nome ingrediente
                </IonLabel>
                <IonInput
                  onIonInput={(ev: any) => {
                    setInputNome(ev.target.value);
                  }}
                  clearInput
                  placeholder="Obbligatorio"
                ></IonInput>
              </IonItem>

              <IonItem fill="outline">
                <IonLabel position="stacked">Inserisci nome marca</IonLabel>
                <IonInput
                  onIonInput={(ev: any) => {
                    setInputMarca(ev.target.value);
                  }}
                  clearInput
                  placeholder="Facoltativo"
                ></IonInput>
              </IonItem>

              <IonItem fill="outline">
                <IonLabel position="stacked">Inserisci descrizione</IonLabel>
                <IonInput
                  onIonInput={(ev: any) => {
                    setInputDescrizione(ev.target.value);
                  }}
                  clearInput
                  placeholder="Facoltativo"
                ></IonInput>
              </IonItem>
            </IonList>
          </div>
        </IonContent>
      </IonModal>

      {/*---------------------------------- MODIFICA MODALI --------------------------------------------------*/}
      <IonModal
        isOpen={isModalModificheAddOpen}
        onDidDismiss={() => {
          setIsModalModificheAddOpen(false);
        }}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton
                color={"medium"}
                onClick={() => {
                  setImmagineSelezionata(undefined);
                  setIsModalModificheAddOpen(false);
                }}
              >
                Annulla
              </IonButton>
            </IonButtons>
            <IonTitle>Modifica</IonTitle>
            <IonButtons slot="end">
              <IonButton
                fill="solid"
                color={"success"}
                onClick={() => handleSalvaModifiche()}
              >
                Salva
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonSegment value={segmentValue}>
            <IonSegmentButton
              value="Carica"
              onClick={() => handleSelectSegment("Carica")}
            >
              <IonLabel>Carica Immagine</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton
              value="Seleziona"
              onClick={() => handleSelectSegment("Seleziona")}
            >
              <IonLabel>Seleziona Immagine</IonLabel>
            </IonSegmentButton>
          </IonSegment>

          {segmentValue === "Carica" ? (
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>Carica immagine</IonCardTitle>
                <IonCardSubtitle>
                  Se non carichi nessuna immagine sarà utilizzato il logo di
                  MenuShare
                </IonCardSubtitle>
              </IonCardHeader>
              <IonCardContent>
                <UploadSingleImage
                  setImage={setImmagineModifiche}
                  immagine={immagineModifiche}
                />
              </IonCardContent>
            </IonCard>
          ) : (
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>Seleziona immagine</IonCardTitle>
                <IonCardSubtitle>
                  Se non selezioni nessuna immagine sarà utilizzato il logo di
                  MenuShare
                </IonCardSubtitle>
              </IonCardHeader>
              <IonCardContent>
                <IonButton
                  color={
                    immagineSelezionata !== undefined ? "success" : "primary"
                  }
                  expand="block"
                  onClick={() => setIsModaleImmaginiOpen(true)}
                >
                  <IonIcon icon={imagesOutline} className="ion-margin-end" />
                  Seleziona
                </IonButton>
                {immagineSelezionata !== undefined ? (
                  <IonImg src={immagineSelezionata}></IonImg>
                ) : null}
              </IonCardContent>
            </IonCard>
          )}

          <div className="ion-padding windowDubleHeight">
            <IonList>
              <IonItem fill="outline">
                <IonLabel position="stacked">Modifica nome</IonLabel>
                <IonInput
                  value={inputNomeModifiche}
                  onIonInput={(ev: any) => {
                    setInputNomeModifiche(ev.target.value);
                  }}
                  clearInput
                  placeholder="Obbligatorio"
                ></IonInput>
              </IonItem>

              <IonItem fill="outline">
                <IonLabel position="stacked">Modifica marca</IonLabel>
                <IonInput
                  value={inputMarcaModifiche}
                  onIonInput={(ev: any) => {
                    setInputMarcaModifiche(ev.target.value);
                  }}
                  clearInput
                  placeholder="Facoltativo"
                ></IonInput>
              </IonItem>

              <IonItem fill="outline">
                <IonLabel position="stacked">Modifica descrizione</IonLabel>
                <IonInput
                  value={inputDescrizioneModifiche}
                  onIonInput={(ev: any) => {
                    setInputDescrizioneModifiche(ev.target.value);
                  }}
                  clearInput
                  placeholder="Facoltativo"
                ></IonInput>
              </IonItem>
            </IonList>
          </div>
        </IonContent>
      </IonModal>

      {/*---------------------------------- MODALE IMMAGINI --------------------------------------------------*/}
      <IonModal
        isOpen={isModaleImmaginiOpen}
        onDidDismiss={() => {
          setIsModaleImmaginiOpen(false);
        }}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton
                color={"medium"}
                onClick={() => {
                  setImmagineSelezionata(undefined);
                  setIsModaleImmaginiOpen(false);
                }}
              >
                Annulla
              </IonButton>
            </IonButtons>
            <IonTitle>Immagini </IonTitle>
            <IonButtons slot="end">
              <IonButton
                fill="solid"
                color={"success"}
                onClick={() => setIsModaleImmaginiOpen(false)}
              >
                Conferma
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div>
            <IonLabel>
              <p className="ion-padding">
                Filtro visualizzazione:
                <IonChip
                  color={
                    filtroVisualizzazioneImmagini === "column-portfolio-1"
                      ? "primary"
                      : undefined
                  }
                  onClick={() =>
                    setFiltroVisualizzazioneImmagini("column-portfolio-1")
                  }
                >
                  1/1
                </IonChip>
                <IonChip
                  color={
                    filtroVisualizzazioneImmagini === "column-portfolio-2"
                      ? "primary"
                      : undefined
                  }
                  onClick={() =>
                    setFiltroVisualizzazioneImmagini("column-portfolio-2")
                  }
                >
                  1/2
                </IonChip>
                <IonChip
                  color={
                    filtroVisualizzazioneImmagini === "column-portfolio-3"
                      ? "primary"
                      : undefined
                  }
                  onClick={() =>
                    setFiltroVisualizzazioneImmagini("column-portfolio-3")
                  }
                >
                  1/3
                </IonChip>
                <IonChip
                  color={
                    filtroVisualizzazioneImmagini === "column-portfolio-4"
                      ? "primary"
                      : undefined
                  }
                  onClick={() =>
                    setFiltroVisualizzazioneImmagini("column-portfolio-4")
                  }
                >
                  1/4
                </IonChip>
              </p>
            </IonLabel>
            <IonLabel>
              <p className="ion-padding-start ion-padding-end">
                Cartelle:{" "}
                {defaultFolders?.map((cartella: string, index: number) => {
                  return (
                    <IonChip
                      key={"cartelleIngredientidefaultFolders" + index}
                      color={
                        filtroCartelle === cartella ? "primary" : undefined
                      }
                      onClick={() => {
                        setFiltroCartelle(cartella);
                        handleFiltraImmagini(cartella);
                      }}
                    >
                      {cartella}
                    </IonChip>
                  );
                })}
                {cartelle?.allCartelleImmagini?.map(
                  (cartella: string, index: number) => {
                    return (
                      <IonChip
                        key={"cartelleIngredienti" + index}
                        color={
                          filtroCartelle === cartella ? "primary" : undefined
                        }
                        onClick={() => {
                          setFiltroCartelle(cartella);
                          handleFiltraImmagini(cartella);
                        }}
                      >
                        {cartella}
                      </IonChip>
                    );
                  }
                )}
              </p>
            </IonLabel>
          </div>

          <div className="row-portfolio">
            {allImmagini?.map((immagineObj: any, index: number) => {
              return (
                <IonCard
                  color={
                    immagineSelezionata === immagineObj.urlImmagine
                      ? "primary"
                      : undefined
                  }
                  key={"immagine-" + index}
                  className={
                    "immaginiGalleria " + filtroVisualizzazioneImmagini
                  }
                  onClick={() =>
                    setImmagineSelezionata(immagineObj.urlImmagine)
                  }
                >
                  <IonCardHeader>
                    <IonCardSubtitle>{immagineObj.cartella}</IonCardSubtitle>
                  </IonCardHeader>
                  <IonImg src={immagineObj.urlImmagine} />
                </IonCard>
              );
            })}
          </div>
        </IonContent>
      </IonModal>
    </div>
  );
};

export default InventarioColumn_Ingredienti;
