import { IonButton, IonContent, IonHeader, IonInput, IonItem, IonLabel, IonList, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { useState } from 'react';
import { Redirect } from 'react-router';
import { useAuth } from '../auth';
import './LoginScreen.css';
import { SaveOnLocalUID } from '../Logic/LocalStorage';

const LoginScreen: React.FC = () => {
  //VARIABILI ------------------------------
  const auth = getAuth();

  const { loggedIn, userId } = useAuth();

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const [errore, setErrore] = useState(false);

  //CONDIZIONI -----------------------------

  const handleLogin = () => {
    signInWithEmailAndPassword(auth, email, password).then((userCredential) => {
      const user = userCredential.user;
      console.log("User Loggato : ", user);
      SaveOnLocalUID(user.uid);
    })
      .catch((error) => {
        console.log("ERRORE DI CONNESSIONE: ", error);
        setErrore(true);
      })
  }

  if (loggedIn) {
    return <Redirect to={"/app"} />
  }
  //RETURN ---------------------------------
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Login</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Login</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className='ion-padding'>
          <IonList>
            <IonItem>
              <IonLabel>Email</IonLabel>
              <IonInput type='email' value={email} placeholder="Inserisci mail" onIonChange={(e) => setEmail(e.detail.value!)} clearInput />
            </IonItem>
            <IonItem>
              <IonLabel>Password</IonLabel>
              <IonInput type='password' value={password} placeholder="Inserisci password" onIonChange={(e) => setPassword(e.detail.value!)} clearInput />
            </IonItem>
          </IonList>
          <IonLabel>
            {errore ?
              <p style={{ color: "red" }}>Email o password errata!</p>
              :
              null
            }
          </IonLabel>

          <IonButton expand='block' onClick={handleLogin}>
            Login
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default LoginScreen;
