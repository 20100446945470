import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import React from 'react';
import AttivitaComp from '../components/AttivitaComp';
import InventarioComp from '../components/InventarioComp';
import StoricoComp from '../components/StoricoComp';
import '../pages/CSS/Dashboard.css'


type HomeScreenProps = {
  AllData: any,
  setAllData: any,
  AllRealTables: any,
  IngredientiDb: any,
  ProdottiDbDashboard: any,
}


const Dashboard: React.FC<HomeScreenProps> = ({ AllData, setAllData, AllRealTables, IngredientiDb, ProdottiDbDashboard }) => {
  //VARIABILI --------------------------------------------

  //CONDIZIONI -------------------------------------------

  //RETURN -----------------------------------------------
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Dashboard</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Dashboard</IonTitle>
          </IonToolbar>
        </IonHeader>
        {/* Contenuto */}


        <InventarioComp
          IngredientiDb={IngredientiDb}
          ProdottiDb={ProdottiDbDashboard}
        />

        <AttivitaComp
          AllAttivita={AllData}
        />

        <StoricoComp
          AllAttivita={AllData}
        />

      </IonContent>
    </IonPage>
  );
};

export default Dashboard;
