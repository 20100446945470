import { IonAvatar, IonBadge, IonButton, IonButtons, IonChip, IonContent, IonHeader, IonIcon, IonImg, IonItem, IonLabel, IonList, IonModal, IonSearchbar, IonTitle, IonToolbar } from '@ionic/react';
import './ModaleProdotti.css';
import { useState } from 'react';
import { Prodotto } from '../../data/dataSheet';
import { add, closeCircle } from 'ionicons/icons';

interface ContainerProps {
  isOpen: boolean,
  setIsOpen: any,
  inputProdotti: any,
  setInputProdotti: any,

  allProdotti: any
}

const ModaleSelezionaProdotti: React.FC<ContainerProps> = ({
  isOpen,
  setIsOpen,
  inputProdotti,
  setInputProdotti,

  allProdotti,
}) => {
  //VARIABILI --------------------------------------------
  const [searchTextProdottoModale, setSearchTextProdottoModale] =
    useState<any>([]);
  //CONDIZIONI -------------------------------------------
  const handleSearchProdotto = (testoCercato: string) => {
    let arr = Array.from(
      window.document.getElementsByClassName("prodottoDaAggiungere")
    );
    const query = testoCercato.toLowerCase();
    requestAnimationFrame(() => {
      arr.forEach((item: any) => {
        const shouldShow = item.textContent.toLowerCase().indexOf(query) > -1;
        item.style.display = shouldShow ? "block" : "none";
      });
    });
  };

  const handleRimuoviProdotto = (index: number) => {
    setInputProdotti((inputProdotti: any) =>
      inputProdotti.filter((_: any, i: number) => i !== index)
    );
  };

  const handleAggiungiProdotto = (prodottoDaAggiungere: any) => {
    setInputProdotti(inputProdotti.concat(prodottoDaAggiungere));
  };

  //RETURN -----------------------------------------------
  return (
    <IonModal
      isOpen={isOpen}
    >
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={() => setIsOpen(false)}>
              Chiudi
            </IonButton>
          </IonButtons>
          <IonTitle>Seleziona prodotti</IonTitle>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar
            value={searchTextProdottoModale}
            placeholder={"Cerca per nome, per prezzo o per tag"}
            onIonChange={(e) => {
              setSearchTextProdottoModale(e.detail.value!);
              handleSearchProdotto(e.detail.value!);
            }}
          ></IonSearchbar>
          <IonLabel>
            <p className='ion-padding'>Prodotti aggiunti: {inputProdotti?.length}</p>
          </IonLabel>
          <div className='div-y-scrollable ion-padding-start ion-padding-end'>
            {inputProdotti?.map((ingrediente: Prodotto, index: number) => {
              return (
                <IonChip
                  key={"ingredienteSelezionato" + index}
                  color={"primary"}
                >
                  {ingrediente.nome}
                  <IonIcon
                    color="medium"
                    icon={closeCircle}
                    onClick={() => {
                      handleRimuoviProdotto(index);
                    }}
                  />
                </IonChip>
              );
            })}

          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent>

        <IonList>
          {allProdotti?.map((item: Prodotto, index: number) => {
            let y = inputProdotti.find((ite: Prodotto) => ite === item);
            if (y == undefined)
              return (
                <div
                  key={index + "prodottoDaAggiungere"}
                  className="prodottoDaAggiungere"
                >
                  <IonItem>
                    <IonAvatar slot="start">
                      <IonImg src={item.immaginiUrl[0]} alt="Immagine" />
                    </IonAvatar>
                    <IonLabel>
                      {item.nome}
                      <p>€ {item.prezzo}</p>

                      {item?.tags?.map((tag: string, ind: number) => {
                        return (
                          <IonBadge color={"tertiary"} key={"productTag" + ind + index} style={{ margin: "4px" }}>
                            {tag}
                          </IonBadge>
                        )
                      })}

                    </IonLabel>
                    <IonButton
                      color={"primary"}
                      slot="end"
                      onClick={() => handleAggiungiProdotto(item)}
                    >
                      <IonIcon icon={add} />
                    </IonButton>
                  </IonItem>
                </div>
              );
          })}
        </IonList>
      </IonContent>
    </IonModal>
  );
};

export default ModaleSelezionaProdotti;
