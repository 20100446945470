import { IonAvatar, IonButton, IonButtons, IonIcon, IonImg, IonItem, IonLabel, IonList, IonTitle, IonToolbar, useIonAlert, useIonLoading, useIonToast } from '@ionic/react';
import './InventarioColumn.css';
import { add, alertCircleOutline, close, cloudDoneOutline, pencil, trash } from 'ionicons/icons';
import { useState } from 'react';
import { UploadNewSezione } from '../Logic/DataUpload';
import { deleteDoc, doc } from 'firebase/firestore';
import { db } from '../fb';
import { Categoria } from '../data/dataSheet';
import ModaleCreaCategoriaInventario from './Modale_Categorie/ModaleCreaCategoriaInventario';
import ModaleModificaCategoriaInventario from './Modale_Categorie/ModaleModificaCategoriaInventario';

interface ContainerProps {
  AllSezioni: any,
  allCategorie: any;
  UpdateAll: any;
  allProdotti: any;
  allImmagini: any;
  cartelle: any;
}

const InventarioColumn_Categorie: React.FC<ContainerProps> = ({
  AllSezioni,
  allCategorie,
  UpdateAll,
  allProdotti,
  allImmagini,
  cartelle,
}) => {
  //VARIABILI --------------------------------------------
  const [presentAlert] = useIonAlert();


  const [isModalAddOpen, setIsModalAddOpen] = useState(false);
  const [isModalModificheOpen, setIsModalModificheOpen] = useState(false);
  const [searchTextModale, setSearchTextModale] = useState("");

  const [oggettoDaModificare, setOggettoDaModificare] = useState<any>();
  const [indiceModifica, setIndiceModifica] = useState<number>();

  const [present, dimiss] = useIonLoading();
  const [presentToast] = useIonToast();


  //CONDIZIONI -------------------------------------------
  const handleAddToAltreSezioni = (arrSezioni: any, oggettoCategoria: any) => {
    arrSezioni.map((sez: any, ind: number) => {
      AllSezioni.map((sezione: any, index: number) => {
        if (sezione.id === sez) {
          let oggettoSezione = sezione.categorie.concat(oggettoCategoria);
          sezione.categorie = oggettoSezione;
          console.log(sezione);
          UploadNewSezione(sezione, sezione.id).then(() => {
            presentToastType("Sezione " + sezione.nome + " aggiornata.", "success", () => { });
          });
        }
      })
    })
  }

  // const handleSelectFilter = () => {
  //   presentAlert({
  //     header: "Seleziona filtro",
  //     inputs: [
  //       {
  //         label: "A-Z",
  //         type: "radio",
  //         value: "A-Z",
  //         name: "A-Z",
  //       },
  //       {
  //         label: "Z-A",
  //         type: "radio",
  //         value: "Z-A",
  //         name: "Z-A",
  //       },
  //       {
  //         label: "Ultimo aggiunto",
  //         type: "radio",
  //         value: "Ultimo-aggiunto",
  //         name: "Ultimo-aggiunto",
  //       },
  //     ],
  //     buttons: [
  //       {
  //         text: "Resetta",
  //         role: "cancel",
  //         handler: () => {
  //           setFiltroContenuto(undefined);
  //         },
  //       },
  //       {
  //         text: "Conferma",
  //         role: "confirm",
  //         handler: (alertData) => {
  //           console.log(alertData);
  //           setFiltroContenuto(alertData);
  //         },
  //       },
  //     ],
  //   });
  // };

  // useEffect(() => {
  //   categorieDb.sort((a: any, b: any) => a - b);
  // }, [filtroContenuto]);

  // ----- prodotto

  //------- categoria

  const rimuoviCategoria = async (ind: number, item: any) => {
    console.log("rimuoviCategoria()");
    present({ message: "Sto eliminando la categoria..." });
    await deleteDoc(doc(db, "categorie", item.id.toString())).then(() => {
      console.log("Categoria ", item.id, " eliminata con successo.");
      presentToastType("Categoria " + item.nome + " eliminata con successo.", "success", () => { });
      UpdateAll();
      dimiss();
    });
  };

  const presentToastType = (
    message: string,
    type: "danger" | "success" | "warning",
    buttonEv: () => void,
    durationS?: number
  ) => {
    const iconType = {
      danger: close,
      success: cloudDoneOutline,
      warning: alertCircleOutline,
    };
    return presentToast({
      message: message,
      position: "top",
      duration: durationS ? durationS : 3000,
      icon: iconType[type],
      color: type,
      buttons: [
        {
          text: "Chiudi",
          role: "cancel",
          handler: () => {
            buttonEv();
          },
        },
      ],
    });
  };

  const handleSearch = (testoCercato: string) => {
    let arr = Array.from(
      window.document.getElementsByClassName("AllCategoria")
    );
    const query = testoCercato.toLowerCase();
    requestAnimationFrame(() => {
      arr.forEach((item: any) => {
        const shouldShow = item.textContent.toLowerCase().indexOf(query) > -1;
        item.style.display = shouldShow ? "block" : "none";
      });
    });
  };

  //RETURN -----------------------------------------------
  return (
    <div className="inventario-column-container">
      <IonToolbar>
        <IonTitle>
          Categorie
        </IonTitle>
        <IonButtons slot='end'>
          <IonButton fill='solid' color={"primary"} onClick={() => setIsModalAddOpen(true)}>
            <IonIcon icon={add} />
            Aggiungi
          </IonButton>
        </IonButtons>
      </IonToolbar>
      <div className="inventario-content">
        <div className="inventario-column-items">
          <IonList>
            {allCategorie?.map((item: Categoria, index: number) => {
              return (
                <IonItem key={index + "categorie"} className="AllCategoria">

                  <IonAvatar className="ion-margin-end">
                    <IonImg src={item.immagineUrl}></IonImg>
                  </IonAvatar>
                  <IonLabel>
                    <h2>
                      {item.nome}
                    </h2>
                    <p>
                      {item.descrizione}
                    </p>
                    <p>
                      Prodotti: {item.prodotti.length}
                    </p>

                    <IonToolbar>
                      <IonButtons className='ion-justify-content-around'>
                        <IonButton
                          slot="end"
                          color={"primary"}
                          fill="solid"
                          onClick={() => {
                            setOggettoDaModificare(item);
                            setIndiceModifica(index);
                            setIsModalModificheOpen(true);
                          }}
                        >
                          <IonIcon icon={pencil} />
                        </IonButton>
                        <IonButton
                          color={"danger"}
                          slot="end"
                          fill="solid"
                          onClick={() =>
                            presentAlert({
                              header: "Attenzione!",
                              message:
                                "L'eliminazione è definitiva! La categoria non sarà rimossa dalle sezioni in cui è presente, in quanto ogni attività potrebbe avere una sua versione ma modificata, e per ragioni di sicurezza la categoria non viene eliminata direttamente dalle attività in cui è presente.",
                              buttons: [
                                {
                                  text: "Annulla",
                                  role: "cancel",
                                },
                                {
                                  text: "Conferma",
                                  role: "confirm",
                                  handler: () => {
                                    rimuoviCategoria(index, item);
                                  },
                                },
                              ],
                            })
                          }
                        >
                          <IonIcon icon={trash} />
                        </IonButton>
                      </IonButtons>
                    </IonToolbar>
                  </IonLabel>
                </IonItem>
              );
            })}
          </IonList>
        </div>
      </div>
      {/* ------------------ MODAL CREA CATEGORIA -------------------- */}
      <ModaleCreaCategoriaInventario
        isOpen={isModalAddOpen}
        setIsOpen={setIsModalAddOpen}
        allProdotti={allProdotti}
        cartelle={cartelle}
        allImmagini={allImmagini}
        AllSezioni={AllSezioni}
        handleAddToAltreSezioni={handleAddToAltreSezioni}
        UpdateAll={UpdateAll}
      />

      {/* ------------------ MODAL MODIFICA -------------------- */}
      <ModaleModificaCategoriaInventario
        isOpen={isModalModificheOpen}
        setIsOpen={setIsModalModificheOpen}
        cartelle={cartelle}
        allImmagini={allImmagini}
        allProdotti={allProdotti}
        allCategorie={allCategorie}
        AllSezioni={AllSezioni}
        UpdateAll={UpdateAll}
        oggettoDaModificare={oggettoDaModificare}
        indModifica={indiceModifica}
      />

    </div>
  );
};

export default InventarioColumn_Categorie;
