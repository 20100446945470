import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonSearchbar,
  IonTitle,
  IonToolbar,
  useIonAlert,
  useIonLoading,
  useIonToast,
} from "@ionic/react";
import { deleteDoc, doc } from "firebase/firestore";
import {
  addCircle,
  alertCircleOutline,
  arrowBack,
  close,
  cloudDoneOutline,
  pencil,
  trash,
} from "ionicons/icons";
import { useState } from "react";
import { Categoria } from "../data/dataSheet";
import { db } from "../fb";
import { UploadNewSezione } from "../Logic/DataUpload";
import { url_app_inventario } from "../Urls";
import "./CSS/AllCategorieScreen.css";
import ModaleCreaCategoriaInventario from "../components/Modale_Categorie/ModaleCreaCategoriaInventario";
import ModaleModificaCategoriaInventario from "../components/Modale_Categorie/ModaleModificaCategoriaInventario";


interface PageProps {
  AllSezioni: any,
  allCategorie: any;
  UpdateAll: any;
  allProdotti: any;
  allImmagini: any;
  cartelle: any;
}

const AllCategorieScreen: React.FC<PageProps> = ({
  AllSezioni,
  allCategorie,
  UpdateAll,
  allProdotti,
  allImmagini,
  cartelle,
}) => {
  //VARIABILI ------------------------------
  const [presentAlert] = useIonAlert();


  const [isModalAddOpen, setIsModalAddOpen] = useState(false);
  const [isModalModificheOpen, setIsModalModificheOpen] = useState(false);
  const [searchTextModale, setSearchTextModale] = useState("");

  const [oggettoDaModificare, setOggettoDaModificare] = useState<any>();
  const [indiceModifica, setIndiceModifica] = useState<number>();

  const [present, dimiss] = useIonLoading();
  const [presentToast] = useIonToast();


  //CONDIZIONI -----------------------------
  const handleAddToAltreSezioni = (arrSezioni: any, oggettoCategoria: any) => {
    arrSezioni.map((sez: any, ind: number) => {
      AllSezioni.map((sezione: any, index: number) => {
        if (sezione.id === sez) {
          let oggettoSezione = sezione.categorie.concat(oggettoCategoria);
          sezione.categorie = oggettoSezione;
          console.log(sezione);
          UploadNewSezione(sezione, sezione.id).then(() => {
            presentToastType("Sezione " + sezione.nome + " aggiornata.", "success", () => { });
          });
        }
      })
    })
  }

  // const handleSelectFilter = () => {
  //   presentAlert({
  //     header: "Seleziona filtro",
  //     inputs: [
  //       {
  //         label: "A-Z",
  //         type: "radio",
  //         value: "A-Z",
  //         name: "A-Z",
  //       },
  //       {
  //         label: "Z-A",
  //         type: "radio",
  //         value: "Z-A",
  //         name: "Z-A",
  //       },
  //       {
  //         label: "Ultimo aggiunto",
  //         type: "radio",
  //         value: "Ultimo-aggiunto",
  //         name: "Ultimo-aggiunto",
  //       },
  //     ],
  //     buttons: [
  //       {
  //         text: "Resetta",
  //         role: "cancel",
  //         handler: () => {
  //           setFiltroContenuto(undefined);
  //         },
  //       },
  //       {
  //         text: "Conferma",
  //         role: "confirm",
  //         handler: (alertData) => {
  //           console.log(alertData);
  //           setFiltroContenuto(alertData);
  //         },
  //       },
  //     ],
  //   });
  // };

  // useEffect(() => {
  //   categorieDb.sort((a: any, b: any) => a - b);
  // }, [filtroContenuto]);

  // ----- prodotto

  //------- categoria

  const rimuoviCategoria = async (ind: number, item: any) => {
    console.log("rimuoviCategoria()");
    present({ message: "Sto eliminando la categoria..." });
    await deleteDoc(doc(db, "categorie", item.id.toString())).then(() => {
      console.log("Categoria ", item.id, " eliminata con successo.");
      presentToastType("Categoria " + item.nome + " eliminata con successo.", "success", () => { });
      UpdateAll();
      dimiss();
    });
  };

  const presentToastType = (
    message: string,
    type: "danger" | "success" | "warning",
    buttonEv: () => void,
    durationS?: number
  ) => {
    const iconType = {
      danger: close,
      success: cloudDoneOutline,
      warning: alertCircleOutline,
    };
    return presentToast({
      message: message,
      position: "top",
      duration: durationS ? durationS : 3000,
      icon: iconType[type],
      color: type,
      buttons: [
        {
          text: "Chiudi",
          role: "cancel",
          handler: () => {
            buttonEv();
          },
        },
      ],
    });
  };

  const handleSearch = (testoCercato: string) => {
    let arr = Array.from(
      window.document.getElementsByClassName("AllCategoria")
    );
    const query = testoCercato.toLowerCase();
    requestAnimationFrame(() => {
      arr.forEach((item: any) => {
        const shouldShow = item.textContent.toLowerCase().indexOf(query) > -1;
        item.style.display = shouldShow ? "block" : "none";
      });
    });
  };

  //RETURN ---------------------------------
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton routerLink={url_app_inventario} routerDirection="back">
              <IonIcon icon={arrowBack} />
              Inventario
            </IonButton>
          </IonButtons>
          <IonTitle>Tutte le categorie</IonTitle>
          {/* <IonButtons slot="end">
            <IonButton onClick={() => handleSelectFilter()}>
              <IonIcon icon={filterCircle} className="ion-margin-end"></IonIcon>
              Filtro {filtroContenuto}
            </IonButton>
          </IonButtons> */}
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar
            value={searchTextModale}
            placeholder={"Cerca per nome"}
            onIonChange={(e) => {
              setSearchTextModale(e.detail.value!);
              handleSearch(e.detail.value!);
            }}
          ></IonSearchbar>

          <IonButton expand="block" onClick={() => setIsModalAddOpen(true)}>
            <IonIcon icon={addCircle} className="ion-padding-end" />
            Crea categoria
          </IonButton>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>

        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Tutte le categorie</IonTitle>
          </IonToolbar>
        </IonHeader>

        {/* CONTENUTO */}
        <div className="ion-padding">
          <IonLabel>
            <p className="ion-padding-bottom">
              Queste categorie possono essere utilizzare come base per tutte le
              attività. Nella sezione Attività/Categoria potrai aggiungere una
              categoria creata qui, e modificare i singoli prodotti adattandola
              all'attività.
            </p>
            <p className="ion-padding-bottom">
              Attenzione! La creazione di una categoria tramite l'attività non
              la aggiungerà a questa collezione in comune. Quindi se è una
              collezione specifica per una attività potete farla direttamente
              tramite le funzionalità dell'attività, ma è comunque consigliabile
              crearla da qui.
            </p>
          </IonLabel>
        </div>

        <IonList>
          {allCategorie?.map((item: Categoria, index: number) => {
            return (
              <IonItem key={index + "categorie"} className="AllCategoria">
                <IonButton
                  slot="end"
                  fill="solid"
                  onClick={() => {
                    setOggettoDaModificare(item);
                    setIndiceModifica(index);
                    setIsModalModificheOpen(true);
                  }}
                >
                  <IonIcon icon={pencil} />
                </IonButton>
                <IonButton
                  color={"danger"}
                  slot="end"
                  fill="solid"
                  onClick={() =>
                    presentAlert({
                      header: "Attenzione!",
                      message:
                        "L'eliminazione è definitiva! La categoria non sarà rimossa dalle sezioni in cui è presente, in quanto ogni attività potrebbe avere una sua versione ma modificata, e per ragioni di sicurezza la categoria non viene eliminata direttamente dalle attività in cui è presente.",
                      buttons: [
                        {
                          text: "Annulla",
                          role: "cancel",
                        },
                        {
                          text: "Conferma",
                          role: "confirm",
                          handler: () => {
                            rimuoviCategoria(index, item);
                          },
                        },
                      ],
                    })
                  }
                >
                  <IonIcon icon={trash} />
                </IonButton>
                <IonAvatar className="ion-margin-end">
                  <IonImg src={item.immagineUrl}></IonImg>
                </IonAvatar>
                <IonLabel>
                  <h2>
                    {item.nome}
                  </h2>
                  <p>
                    {item.descrizione}
                  </p>
                  <p>
                    Prodotti: {item.prodotti.length}
                  </p>
                </IonLabel>
              </IonItem>
            );
          })}
        </IonList>
        {/* MODALI */}

        {/* ------------------ MODAL CREA CATEGORIA -------------------- */}
        <ModaleCreaCategoriaInventario
          isOpen={isModalAddOpen}
          setIsOpen={setIsModalAddOpen}
          allProdotti={allProdotti}
          cartelle={cartelle}
          allImmagini={allImmagini}
          AllSezioni={AllSezioni}
          handleAddToAltreSezioni={handleAddToAltreSezioni}
          UpdateAll={UpdateAll}
        />

        {/* ------------------ MODAL MODIFICA -------------------- */}
        <ModaleModificaCategoriaInventario
          isOpen={isModalModificheOpen}
          setIsOpen={setIsModalModificheOpen}
          cartelle={cartelle}
          allImmagini={allImmagini}
          allProdotti={allProdotti}
          allCategorie={allCategorie}
          AllSezioni={AllSezioni}
          UpdateAll={UpdateAll}
          oggettoDaModificare={oggettoDaModificare}
          indModifica={indiceModifica}
        />



      </IonContent>
    </IonPage>
  );
};

export default AllCategorieScreen;
