export const url_loginScreen = "/login";
export const url_loginScreenurl_app = "/app";

export const url_app = "/app";

export const url_app_tutorial = "/app/tutorial";
export const url_app_dashboard = "/app/dashboard";
export const url_app_inventario = "/app/inventario";
export const url_app_inventario_ingredienti = "/app/inventario/ingredienti";
export const url_app_inventario_prodotti = "/app/inventario/prodotti";
export const url_app_inventario_categorie = "/app/inventario/categorie";
export const url_app_inventario_sezioni = "/app/inventario/sezioni";
export const url_app_tavoli_init = "/app/tavoli/";
export const url_app_tavoli = "/app/tavoli/:idAttivita";
export const url_app_tavolo_ordini_init = "/app/tavolo/ordini/";
export const url_app_tavolo_ordini =
  "/app/tavolo/ordini/:indexTable/:idAttivita";

export const url_app_allImmagini = "/app/immagini";

export const url_app_allPersonale = "/app/allPersonale";

export const url_app_allAttivita = "/app/attivita";
export const url_app_attivita_init = "/app/attivita/";
export const url_app_attivita = "/app/attivita/:indiceAttivita";
export const url_app_attivita_prodottiConsigliati_init =
  "/app/attivita/prodottiConsigliati/-";
export const url_app_attivita_prodottiConsigliati =
  "/app/attivita/prodottiConsigliati/:indiceAttivita";
export const url_app_attivita_comunicazioni_init =
  "/app/attivita/comunicazioni/-";
export const url_app_attivita_comunicazioni =
  "/app/attivita/comunicazioni/:indiceAttivita";
export const url_app_attivita_categorie_init = "/app/attivita/categorie/-";
export const url_app_attivita_categorie =
  "/app/attivita/categorie/:indiceAttivita";

export const url_app_attivita_portfolio_init = "/app/attivita/portfolio/-";
export const url_app_attivita_portfolio =
  "/app/attivita/portfolio/:indiceAttivita";

export const url_app_attivita_sezionimenu_init = "/app/attivita/sezionimenu/-";
export const url_app_attivita_sezionimenu =
  "/app/attivita/sezionimenu/:indiceAttivita";
export const url_app_attivita_personale_init = "/app/attivita/personale/-";
export const url_app_attivita_personale =
  "/app/attivita/personale/:indiceAttivita";
export const url_app_attivita_orari_init = "/app/attivita/orari/-";
export const url_app_attivita_orari = "/app/attivita/orari/:indiceAttivita";
export const url_app_attivita_chisiamo_init = "/app/attivita/chisiamo/-";
export const url_app_attivita_chisiamo =
  "/app/attivita/chisiamo/:indiceAttivita";
