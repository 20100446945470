import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { deleteObject, ref } from "firebase/storage";
import { db, storage } from "../fb";

// TAGS -------------------------------------------------
export async function UploadDataTags(arrayTags: any) {
  const json = JSON.stringify(arrayTags);
  console.log("UploadDataIngredienti : ", json);
  let obj = {
    tags: arrayTags,
  };
  await setDoc(doc(db, "tags", "tags"), obj).then(() => {
    console.log("L'array arrayTags:", json, " caricato correttamente");
  });
}
// il delete tag è all'interno di ProdottiScreen

// INGREDIENTI ------------------------------------------
export async function UploadDataIngredienti(oggettoIngrediente: any) {
  console.log("UploadDataIngredienti : ", oggettoIngrediente);
  let id = oggettoIngrediente.id.toString();
  await setDoc(doc(db, "ingredienti", id), oggettoIngrediente).then(() => {
    console.log("Oggetto", id, " caricato correttamente");
  });
}

export async function DeleteDataIngrediente(idIngrediente: string) {
  let id = idIngrediente.toString();
  await deleteDoc(doc(db, "ingredienti", id)).then(() => {
    console.log("Oggetto", id, " elimninato correttamente");
  });
}

// PRODOTTI ------------------------------------------
export async function UploadDataProdotto(oggettoProdotto: any) {
  console.log("UploadDataProdotto : ", oggettoProdotto);
  let id = oggettoProdotto.id.toString();
  await setDoc(doc(db, "prodotti", id), oggettoProdotto).then(() => {
    console.log("Oggetto", id, " caricato correttamente");
  });
}

export async function DeleteDataProdotto(idProdotto: string) {
  let id = idProdotto.toString();
  await deleteDoc(doc(db, "prodotti", id)).then(() => {
    console.log("Oggetto", id, " elimninato correttamente");
  });
}

// ATTIVITA ------------------------------------------
export async function UploadDataAttivita(oggettoAttivita: any) {
  console.log("UploadDataAttivita : ", oggettoAttivita);
  let id = oggettoAttivita.id.toString();

  await setDoc(doc(db, "attivita", id), oggettoAttivita).then(async () => {
    console.log("Oggetto", id, " caricato correttamente");
    console.log(
      "oggettoAttivita.realTables.attivata :",
      oggettoAttivita.realTables.attivata
    );
    if (oggettoAttivita.realTables.attivata === true) {
      await setDoc(doc(db, oggettoAttivita.realTables.id, "int01"), {
        pin: "",
      }).then(() => {
        console.log(
          "Tavolo ",
          oggettoAttivita.realTables.id,
          " caricato correttamente"
        );
      });
    }
  });
}
export async function DeleteDataAttivita(idProdotto: string) {
  let id = idProdotto.toString();
  await deleteDoc(doc(db, "attivita", id)).then(() => {
    console.log("Oggetto", id, " elimninato correttamente");
  });
}

// COMUNCAZIONE ------------------------------------------
export async function UploadDataComunicazione(
  oggettoAttivita: any,
  idAttivita: any
) {
  console.log("UploadDataProdotto : ", oggettoAttivita);
  await setDoc(
    doc(db, "attivita", idAttivita.toString()),
    oggettoAttivita
  ).then(() => {
    console.log("Oggetto", idAttivita, " caricato correttamente");
  });
}

// CARTELLA ALL IMMAGINI ------------------------------------------
export async function UploadDataCartellaAllImmagini(arrayCartelle: any) {
  console.log("UploadDataCartellaAllImmagini : ", arrayCartelle);
  let obj = {
    allCartelleImmagini: arrayCartelle,
  };
  await setDoc(doc(db, "tags", "allCartelleImmagini"), obj).then(() => {
    console.log("Oggetto", arrayCartelle, " caricato correttamente");
  });
}

export async function DeleteDataCartellaAllImmagini(
  nomeCartella: string,
  AllImmaginiCartella: any
) {
  console.log("UploadDataCartellaAllImmagini : ", nomeCartella);
  const allCartelleRef = doc(db, "tags", "allCartelleImmagini");
  await updateDoc(allCartelleRef, {
    allCartelleImmagini: arrayRemove(nomeCartella),
  }).then(() => {
    DeleteAllImmaginiFromFolderAndFolder(AllImmaginiCartella);
  });
}

async function DeleteAllImmaginiFromFolderAndFolder(AllImmaginiCartella: any) {
  AllImmaginiCartella.forEach((immagine: any, index: number) => {
    const path = immagine.meta.fullPath;
    const docRef = ref(storage, path);
    deleteObject(docRef).then(() => {
      console.log(index, "/", AllImmaginiCartella.length, " - Eliminate");
    });
  });
}

// CATEGORIE ------------------------------------------
export async function UploadDataCategorie(
  oggettoCategoria: any,
  idAttivita: any
) {
  console.log("UploadDataCategorie : ", oggettoCategoria);
  await setDoc(
    doc(db, "attivita", idAttivita.toString()),
    oggettoCategoria
  ).then(() => {
    console.log("Oggetto", idAttivita, " caricato correttamente");
  });
}

export async function UploadNuovaCategoria(oggettoCategoria: any, id: any) {
  await setDoc(doc(db, "categorie", id.toString()), oggettoCategoria).then(
    () => {
      console.log("Oggetto ", id, " caricato correttamente");
    }
  );
}

export async function UploadAggiornamentoCategorie(
  oggettoCategoria: any,
  idAttivita: any
) {
  console.log("UploadDataCategorie : ", oggettoCategoria);
  await setDoc(
    doc(db, "attivita", idAttivita.toString()),
    oggettoCategoria
  ).then(() => {
    console.log("Oggetto", idAttivita, " caricato correttamente");
  });
}

// SEZIONI ------------
export async function UploadDataSezioni(oggettoSezione: any, idAttivita: any) {
  console.log("UploadDataSezioni : ", oggettoSezione);
  await setDoc(doc(db, "attivita", idAttivita.toString()), oggettoSezione).then(
    () => {
      console.log("Oggetto", idAttivita, " caricato correttamente");
    }
  );
}

export async function UploadNewSezione(oggettoSezione: any, id: any) {
  await setDoc(doc(db, "sezioni", id.toString()), oggettoSezione).then(() => {
    console.log("Oggetto ", id, " caricato correttamente");
  });
}

// PERSONALE------------
export async function UploadDataPersonale(
  oggettoPersona: any,
  idAttivita: any
) {
  console.log("UploadDataPersonale : ", oggettoPersona);
  await setDoc(doc(db, "attivita", idAttivita.toString()), oggettoPersona).then(
    () => {
      console.log("Oggetto", idAttivita, " caricato correttamente");
    }
  );
}

// ORARI------------
export async function UploadDataOrari(oggettoOrari: any, idAttivita: any) {
  console.log(
    "UploadDataOrari : idAttivita : ",
    idAttivita,
    "-------",
    oggettoOrari
  );
  await setDoc(doc(db, "attivita", idAttivita.toString()), oggettoOrari).then(
    () => {
      console.log("Oggetto", idAttivita, " caricato correttamente");
    }
  );
}

// ABOUT------------
export async function UploadDataAbout(oggettoAbout: any, idAttivita: any) {
  console.log("UploadDataAbout : ", oggettoAbout);
  await setDoc(doc(db, "attivita", idAttivita.toString()), oggettoAbout).then(
    () => {
      console.log("Oggetto", idAttivita, " caricato correttamente");
    }
  );
}

//PORTFOLIO IMMAGINI --------------
export async function UploadDataPortfolio(portfolio: any, idAttivita: any) {
  console.log("UploadDataAbout : ", portfolio);
  await setDoc(doc(db, "attivita", idAttivita.toString()), portfolio).then(
    () => {
      console.log("Oggetto", idAttivita, " caricato correttamente");
    }
  );
}

export async function UploadDataCartelle(
  cartelleGallery: any,
  idAttivita: any
) {
  console.log("UploadDataAbout : ", cartelleGallery);
  await setDoc(
    doc(db, "attivita", idAttivita.toString()),
    cartelleGallery
  ).then(() => {
    console.log("Oggetto", idAttivita, " caricato correttamente");
  });
}

// SUGGESTED PRODUCTS------------
export async function UploadDataSugestedProducts(
  SugestedProducts: any,
  idAttivita: any
) {
  console.log("UploadDataSugestedProducts : ", SugestedProducts);
  await setDoc(
    doc(db, "attivita", idAttivita.toString()),
    SugestedProducts
  ).then(() => {
    console.log("Oggetto", idAttivita, " caricato correttamente");
  });
}

// AGGIUNGI TAVOLO -----------------
export async function AddRealTable(dbTablesName: string, tableNumber: string) {
  await setDoc(doc(db, dbTablesName, tableNumber), {
    pin: "",
  })
    .then(() => {
      console.log("Tavolo creato");
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export async function AddQrCodeTable(dbTablesName: string, name: string) {
  await addDoc(collection(db, dbTablesName), {
    pin: "",
    company: name,
  })
    .then(() => {
      console.log("Tavolo creato");
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

//UPDATE ORDINE --------------
export async function UpdateOrder(
  dbTablesName: string,
  tableNumber: string,
  order: any
) {
  const ordersRef = doc(db, dbTablesName, tableNumber);
  await setDoc(ordersRef, { orders: order }, { merge: true }).then(() => {
    console.log("Aggiornamento effettuato....");
  });
}

//TOKEN UPLOAD -------------
export async function RegisterToken(
  token: string,
  realTablesName: string,
  uid: string
) {
  console.log(
    "Registrazione token : ",
    token,
    " SU :",
    realTablesName,
    " UTENTE: ",
    uid
  );
  //AGGIUNTA TOKEN SU TUTTI I TOKEN
  await setDoc(doc(db, realTablesName, token), {
    UID: uid,
  });

  console.log("DataDownload - DataDownloadPersonale ()");
  const docRef = doc(db, "personale", uid);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    let data = docSnap.data();
    //AGGIUNTA DEL TOKEN SUL PROFILO PERSONALE
    await setDoc(
      doc(db, "personale", uid),
      {
        token: token,
      },
      { merge: true }
    );
    //AGGIUNTA TOKEN SULLA TABELLA SPECIFICA
    let table = "fcmTokens-" + data?.attivita;
    await setDoc(doc(db, table, token), {
      UID: uid,
    });
  } else {
    console.log("No such document!");
    return undefined;
  }
}

// CONTO -----------
export async function ChiudiContoParziale(
  dbTablesName: string,
  tableNumber: string,
  partialCheck: any
) {
  const ordersRef = doc(db, dbTablesName, tableNumber);
  await setDoc(
    ordersRef,
    { requestedChecks: partialCheck },
    { merge: true }
  ).then(() => {
    console.log("Aggiornamento effettuato....");
  });
}

export async function ChiudiContoTotale(
  dbTablesName: string,
  tableNumber: string,
  totalCheck: any
) {
  const ordersRef = doc(db, dbTablesName, tableNumber);
  await setDoc(ordersRef, { totalCheck: totalCheck }, { merge: true }).then(
    () => {
      console.log("Aggiornamento effettuato....");
    }
  );
}

export async function LiberaTavolo(
  dbTablesName: string,
  tableNumber: string,
  idAttivita: any,
  tableData: any
) {
  await addDoc(collection(db, "StoricoOrdini-" + idAttivita), {
    tableData,
    dataChiusura: new Date().toLocaleString(),
    tavolo: tableNumber,
  }).then(async () => {
    const ordersRef = doc(db, dbTablesName, tableNumber);
    await setDoc(ordersRef, { pin: "" }).then(() => {
      console.log("Aggiornamento effettuato....");
    });
  });
}
