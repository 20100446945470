import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "firebase/storage";
import { storage } from "../fb";

export async function UploadImagesFirebase(
  immagini: any,
  nome: string | number,
  setLinkImmagini: any,
  folder: string,
  setAllImagesUploaded: any
) {
  console.log("Immagini : ", immagini);
  let arrLinks: string[] = [];
  // let compressImage = await handleCompressImage(immagini);
  immagini.forEach(async (immagine: any, index: number) => {
    let path = "/" + folder + "/" + nome + "-" + index;
    let imageRef = ref(storage, path);

    await uploadBytes(imageRef, immagine).then(async (snapshot) => {
      console.log("Uploaded a blob or file!");
      let url = await getDownloadURL(imageRef);
      arrLinks.push(url);
      console.log(
        index + 1,
        " su ",
        immagini.length,
        "arrLinks.length: ",
        arrLinks.length,
        " url Immagine : ",
        url
      );
      if (arrLinks.length === immagini.length) setAllImagesUploaded(true);
    });

    //
    setLinkImmagini(arrLinks);
  });
}

export async function UploadSingleImageFirebase(
  immagine: File,
  nome: string | number,
  setLinkImmagine: any,
  folder: string
) {
  // console.log("Immagini : ", immagine);
  let path = "/" + folder + "/" + nome;

  let imageRef = ref(storage, path);
  console.log("Immagine : ", immagine);
  await uploadBytes(imageRef, immagine).then((snapshot) => {
    console.log("Uploaded a blob or file!");
  });
  let url = await getDownloadURL(imageRef);
  console.log(url);
  setLinkImmagine(url);
}

export async function DeleteSingleImage(id: string, folder: string) {
  const desertRef = ref(storage, folder + "/" + id);
  deleteObject(desertRef)
    .then(() => {
      console.log("Oggetto", id, " elimininato correttamente");
    })
    .catch((error) => {
      console.log("ERRORE ! Oggetto", id, " NON ELIMINATO : ", error);
    });
}

export async function DeleteImages(id: string, folder: string, number: number) {
  for (let index = 0; index < number; index++) {
    const desertRef = ref(storage, folder + "/" + id + "-" + index);
    deleteObject(desertRef)
      .then(() => {
        console.log("Oggetto", id + "-" + index, " elimininato correttamente");
      })
      .catch((error) => {
        console.log("ERRORE ! Oggetto", id, " NON ELIMINATO : ", error);
      });
  }
}


export async function DeleteImagePortfolio(id:any, nomeImmagine:any) {
    const desertRef = ref(storage,"portfolio-" + id+"/"+nomeImmagine);
    deleteObject(desertRef)
      .then(() => {
        console.log("Oggetto ", "portfolio-" + id+"/"+nomeImmagine, " Eliminato con successo");
      })
      .catch((error) => {
        console.log("ERRORE ! Oggetto", id, " NON ELIMINATO : ", error);
      });
}
