import { IonAvatar, IonButton, IonButtons, IonCard, IonContent, IonHeader, IonIcon, IonImg, IonItem, IonLabel, IonList, IonModal, IonPage, IonReorder, IonReorderGroup, IonSearchbar, IonThumbnail, IonTitle, IonToolbar, ItemReorderEventDetail, useIonLoading, useIonToast } from '@ionic/react';
import { add, addCircle, alertCircleOutline, arrowBack, close, cloudDoneOutline, menuOutline } from 'ionicons/icons';
import { useState } from 'react';
import { useParams } from 'react-router';
import { Prodotto } from '../data/dataSheet';
import { UploadDataSugestedProducts } from '../Logic/DataUpload';
import { LocalSaveAttivita } from '../Logic/LocalStorage';
import { url_app_attivita_init } from '../Urls';
import './CSS/SugestedProducts.css';

interface PageProps {
  AllData: any,
  setAllData: any,
  AllProdotti: any
}

const SugestedProducts: React.FC<PageProps> = ({ AllData, setAllData, AllProdotti }) => {
  //VARIABLES-----------------
  const { indiceAttivita } = useParams<{ indiceAttivita: string }>();
  const index = indiceAttivita.split('-')[1];
  const [dataAttivita, setDataAttivita] = useState<any>();
  const [prodottiConsigliati, setProdottiConsigliati] = useState<any[]>([]);

  const [prodottiUnici, setProdottiUnici] = useState<any>();

  const [presentToast] = useIonToast();
  const [present, dimiss] = useIonLoading();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [searchTextIngredienteModale, setSearchTextIngredienteModale] = useState('');


  //CONDITIONS----------------

  useState(() => {
    // console.log("AllProdotti[parseInt(index)].prodottiConsigliati : ", AllData[parseInt(index)]?.prodottiConsigliati);
    setDataAttivita(AllData[parseInt(index)]);
    // console.log("All categorie : ", AllData[parseInt(index)]?.categorie);
    setProdottiConsigliati(AllData[parseInt(index)]?.prodottiConsigliati);
    let uniqueProducts: any[] = [];

    AllData[parseInt(index)].categorie?.map((categoria: any) => {

      categoria?.prodotti.map((prodotto: any) => {
        if ((uniqueProducts.find((prod: any) => prod.id === prodotto.id) === undefined)) {
          uniqueProducts.push(prodotto);
          // console.log("Prodotto unico: ", prodotto);
        }
      })
    })
    setProdottiUnici(uniqueProducts);
  });


  const handleSearchProdotto = (testoCercato: string) => {
    let arr = Array.from(window.document.getElementsByClassName("prodotto"));
    const query = testoCercato.toLowerCase();
    requestAnimationFrame(() => {
      arr.forEach((item: any) => {
        const shouldShow = item.textContent.toLowerCase().indexOf(query) > -1;
        item.style.display = shouldShow ? 'block' : 'none';
      });
    });
  }

  const handleAggiungiProdotto = async (prodotto: any) => {
    if (prodottiConsigliati.length === 5) {
      presentToastType("Limite di prodotti raggiunto! Massimo 5 prodotti.", "danger", () => { });
    } else {
      presentToastType("Prodotto " + prodotto.nome + " aggiunto con successo.", "success", () => { });
      console.log("handleAggiungiProdotto: ", prodotto);
      let a = [...prodottiConsigliati];
      a.push(prodotto);
      setProdottiConsigliati(a);
    }
  }

  const handleCancella = async (ind: number) => {
    let a = [...prodottiConsigliati];
    a = a.filter((_, i) => i !== ind);
    setProdottiConsigliati(a);
    handleUpdateData(a);
  }

  const handleUpdateData = async (newArray?: any) => {
    setIsModalOpen(false);
    present({
      message: "Sto salvando i prodotti consigliati..."
    });
    let a = [...AllData];
    a[parseInt(index)].prodottiConsigliati = newArray;
    setAllData(a);

    await UploadDataSugestedProducts(a[parseInt(index)], a[parseInt(index)].id);
    LocalSaveAttivita(a);
    dimiss();
    presentToast({
      message: 'Dati salvati con successo',
      duration: 1000,
      position: "top",
      color: "success",
    });
  }

  const handleOrdinamento = (ev: CustomEvent<ItemReorderEventDetail>) => {
    // console.log("handleOrdinamento()")
    setProdottiConsigliati(ev.detail.complete(prodottiConsigliati));
    handleUpdateData(ev.detail.complete(prodottiConsigliati));
    ev.detail.complete();
  }


  const presentToastType = (message: string, type: "danger" | "success" | "warning", buttonEv: () => void, durationS?: number) => {

    const iconType = {
      "danger": close,
      "success": cloudDoneOutline,
      "warning": alertCircleOutline,
    }

    presentToast({
      message: message,
      position: "top",
      duration: durationS ? durationS : 3000,
      icon: iconType[type],
      color: type,
      buttons: [
        {
          text: 'Chiudi',
          role: 'cancel',
          handler: () => { buttonEv() }
        }
      ]
    })
  }

  //RETURN--------------------
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonButton routerLink={url_app_attivita_init + indiceAttivita} routerDirection="back">
              <IonIcon icon={arrowBack} />
              {dataAttivita?.nome}
            </IonButton>
          </IonButtons>
          <IonTitle>Prodotti consigliati</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Prodotti consigliati</IonTitle>
          </IonToolbar>
        </IonHeader>

        {/* CONTENUTO */}
        <div>
          <IonLabel>
            <p className='ion-text-center ion-padding'>
              I prodotti consigliati sono importanti per spingere la vendita di alcuni prodotti specifici. È altrettanto importante l'ordine (dal 1° al 5°), per modificarlo clicca su <IonIcon icon={menuOutline} /> e trascina il prodotto nella posizione desiderata.
            </p>
          </IonLabel>

          <div className='ion-padding'>
            <IonButton expand='block' fill='solid' onClick={() => setIsModalOpen(true)}>
              <IonIcon icon={addCircle} className="ion-margin-end" />
              Aggiungi prodotti consigliati
            </IonButton>
          </div>

          <IonList>
            <IonReorderGroup disabled={false} onIonItemReorder={handleOrdinamento}>
              {prodottiConsigliati?.map((item: Prodotto, index: number) => {
                return (
                  <div key={index + "prodottoProdottiConsigliati"} className="prodottoConsigliatoAggiunto">
                    <IonItem>
                      <IonAvatar slot="start">
                        <IonImg src={item.immaginiUrl[0]} alt="Immagine" />
                      </IonAvatar>
                      <IonLabel>
                        <h2 className='ion-text-wrap'>{item.nome}</h2>
                        <p>€ {item.prezzo}</p>
                      </IonLabel>

                      <IonButton color={"danger"} slot="end" onClick={() => handleCancella(index)}>
                        <IonIcon icon={close} />
                      </IonButton>
                      <IonReorder slot="end"></IonReorder>
                    </IonItem>
                  </div>
                )
              })}
            </IonReorderGroup>
          </IonList>
        </div>



        {/* MODALE */}
        <IonModal isOpen={isModalOpen} onDidDismiss={() => { setIsModalOpen(false) }} >
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton onClick={() => setIsModalOpen(false)}>Chiudi</IonButton>
              </IonButtons>
              <IonTitle>Prodotti</IonTitle>
              <IonButtons slot="end">
                <IonButton color={"success"} fill='solid' onClick={() => handleUpdateData(prodottiConsigliati)}>Salva</IonButton>
              </IonButtons>
            </IonToolbar>
            <IonToolbar>
              <IonSearchbar value={searchTextIngredienteModale} placeholder={"Cerca per nome o per marca"} onIonChange={e => { setSearchTextIngredienteModale(e.detail.value!); handleSearchProdotto(e.detail.value!) }}></IonSearchbar>
            </IonToolbar>
          </IonHeader>
          <IonContent>

            <IonLabel>
              <p className='ion-padding'>Tutti i prodotti delle categorie aggiunte:</p>
            </IonLabel>
            <IonList>
              {prodottiUnici?.map((item: Prodotto, index: number) => {
                return (
                  <div key={index + "prodottoDaSelezionare"} className="prodotto">
                    <IonItem>
                      <IonAvatar slot="start">
                        <IonImg src={item.immaginiUrl[0]} alt="Immagine" />
                      </IonAvatar>
                      <IonLabel>
                        <h2 className='ion-text-wrap'>{item.nome}</h2>
                        <p>€ {item.prezzo}</p>
                      </IonLabel>

                      <IonButton color={"primary"} slot="end" onClick={() => handleAggiungiProdotto(item)}>
                        <IonIcon icon={add} />
                      </IonButton>
                    </IonItem>
                  </div>
                )
              })}
            </IonList>

          </IonContent>
        </IonModal>

      </IonContent>
    </IonPage>
  );
};

export default SugestedProducts;
