import { IonAvatar, IonBadge, IonButton, IonButtons, IonChip, IonContent, IonHeader, IonIcon, IonImg, IonItem, IonLabel, IonList, IonModal, IonSearchbar, IonTitle, IonToolbar } from '@ionic/react';
import { useState } from 'react';
import { Categoria } from '../../data/dataSheet';
import { add, closeCircle } from 'ionicons/icons';

interface ContainerProps {
  isOpen: boolean,
  setIsOpen: any,
  inputCategorie: any,
  setInputCategorie: any,

  allCategorie: any
}

const ModaleImportaCategorieDaInventario: React.FC<ContainerProps> = ({
  isOpen,
  setIsOpen,
  inputCategorie,
  setInputCategorie,

  allCategorie,
}) => {
  //VARIABILI --------------------------------------------
  const [searchTextCategoriaModale, setSearchTextCategoriaModale] =
    useState<any>([]);
  //CONDIZIONI -------------------------------------------
  const handleSearchCategoria = (testoCercato: string) => {
    let arr = Array.from(
      window.document.getElementsByClassName("CategoriaDaAggiungere")
    );
    const query = testoCercato.toLowerCase();
    requestAnimationFrame(() => {
      arr.forEach((item: any) => {
        const shouldShow = item.textContent.toLowerCase().indexOf(query) > -1;
        item.style.display = shouldShow ? "block" : "none";
      });
    });
  };

  const handleRimuoviCategoria = (index: number) => {
    setInputCategorie((inputCategorie: any) =>
      inputCategorie.filter((_: any, i: number) => i !== index)
    );
  };

  const handleAggiungiCategoria = (CategoriaDaAggiungere: any) => {
    setInputCategorie(inputCategorie.concat(CategoriaDaAggiungere));
  };

  //RETURN -----------------------------------------------
  return (
    <IonModal
      isOpen={isOpen}
    >
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={() => setIsOpen(false)}>
              Chiudi
            </IonButton>
          </IonButtons>
          <IonTitle>Seleziona categorie</IonTitle>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar
            value={searchTextCategoriaModale}
            placeholder={"Cerca per nome, per prezzo o per tag"}
            onIonChange={(e) => {
              setSearchTextCategoriaModale(e.detail.value!);
              handleSearchCategoria(e.detail.value!);
            }}
          ></IonSearchbar>
          <IonLabel>
            <p className='ion-padding'>Categorie aggiunte: {inputCategorie?.length}</p>
          </IonLabel>
          <div className='div-y-scrollable ion-padding-start ion-padding-end'>
            {inputCategorie?.map((ingrediente: Categoria, index: number) => {
              return (
                <IonChip
                  key={"ingredienteSelezionato" + index}
                  color={"primary"}
                >
                  {ingrediente.nome}
                  <IonIcon
                    color="medium"
                    icon={closeCircle}
                    onClick={() => {
                      handleRimuoviCategoria(index);
                    }}
                  />
                </IonChip>
              );
            })}

          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent>

        <IonList>
          {allCategorie?.map((item: Categoria, index: number) => {
            let y = inputCategorie.find((ite: Categoria) => ite.id === item.id);
            if (y == undefined)
              return (
                <div
                  key={index + "CategoriaDaAggiungere"}
                  className="CategoriaDaAggiungere"
                >
                  <IonItem>
                    <IonAvatar slot="start">
                      <IonImg src={item.immagineUrl} alt="Immagine" />
                    </IonAvatar>
                    <IonLabel>
                      {item.nome}

                      {item?.tags?.map((tag: string, ind: number) => {
                        return (
                          <IonBadge color={"tertiary"} key={"productTag" + ind + index} style={{ margin: "4px" }}>
                            {tag}
                          </IonBadge>
                        )
                      })}

                    </IonLabel>
                    <IonButton
                      color={"primary"}
                      slot="end"
                      onClick={() => handleAggiungiCategoria(item)}
                    >
                      <IonIcon icon={add} />
                    </IonButton>
                  </IonItem>
                </div>
              );
          })}
        </IonList>
      </IonContent>
    </IonModal>
  );
};

export default ModaleImportaCategorieDaInventario;
