import { Preferences } from "@capacitor/preferences";

// TUTTI I DATI ----------------------------------------
export const SaveOnLocalUID = async (UID: any) => {
  await Preferences.set({
    key: "UID",
    value: JSON.stringify(UID),
  });
};
export const GETUID = async () => {
  const { value } = await Preferences.get({ key: "UID" });
  if (value !== null) {
    return JSON.parse(value);
  } else {
    return null;
  }
};

export const SaveOnLocal = async (AllData: any) => {
  await Preferences.set({
    key: "LocalAllData",
    value: JSON.stringify(AllData),
  });
};

export const GetLocal = async () => {
  const { value } = await Preferences.get({ key: "LocalAllData" });
  if (value !== null) {
    return JSON.parse(value);
  } else {
    return null;
  }
};

//ATTIVITA -----------------------------------------
export const LocalSaveAttivita = async (data: any) => {
  await Preferences.set({
    key: "attivita",
    value: JSON.stringify(data),
  });
};

export const LocalGetAttivita = async () => {
  const { value } = await Preferences.get({ key: "attivita" });

  if (value !== null) {
    return JSON.parse(value);
  } else {
    return [];
  }
};

//INVENTARIO -----------------------------------------
export const SaveInventario = async (data: any) => {
  await Preferences.set({
    key: "Inv",
    value: JSON.stringify(data),
  });
};

export const GetInventario = async () => {
  const { value } = await Preferences.get({ key: "Inv" });
  if (value !== null) {
    return JSON.parse(value);
  } else {
    return [];
  }
};

//PRODOTTI -----------------------------------------------------
export const LocalSaveProdotti = async (data: any) => {
  await Preferences.set({
    key: "Prodotti",
    value: JSON.stringify(data),
  });
};

export const LocalGetProdotti = async () => {
  const { value } = await Preferences.get({ key: "Prodotti" });
  if (value !== null) {
    return JSON.parse(value);
  } else {
    return [];
  }
};

// INGREDIENTI -------------------------------------------------
export const LocalSaveIngredienti = async (data: any) => {
  await Preferences.set({
    key: "Ingredienti",
    value: JSON.stringify(data),
  });
};

export const LocalGetIngredienti = async () => {
  const { value } = await Preferences.get({ key: "Ingredienti" });
  if (value !== null) {
    return JSON.parse(value);
  } else {
    return [];
  }
};

// TAGS --------------------------------------------------------
export const LocalSaveTags = async (data: any) => {
  await Preferences.set({
    key: "Tags",
    value: JSON.stringify(data),
  });
};

export const LocalGetTags = async () => {
  const { value } = await Preferences.get({ key: "Tags" });
  if (value !== null) {
    return JSON.parse(value);
  } else {
    return [];
  }
};
