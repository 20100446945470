import { IonAvatar, IonBadge, IonButton, IonButtons, IonChip, IonContent, IonHeader, IonIcon, IonImg, IonItem, IonLabel, IonList, IonModal, IonSearchbar, IonTitle, IonToolbar } from '@ionic/react';
import './ModaleIngredienti.css';
import { useState } from 'react';
import { Ingrediente } from '../../data/dataSheet';
import { add, closeCircle } from 'ionicons/icons';

interface ContainerProps {
  isOpen: boolean,
  setIsOpen: any,
  inputIngredienti: any,
  setInputIngredienti: any,

  allIngredienti: any
}

const ModaleModificaIngredienti: React.FC<ContainerProps> = ({
  isOpen,
  setIsOpen,
  inputIngredienti,
  setInputIngredienti,

  allIngredienti,
}) => {
  //VARIABILI --------------------------------------------
  const [searchTextIngredienteModale, setSearchTextIngredienteModale] =
    useState<any>([]);
  //CONDIZIONI -------------------------------------------
  const handleSearchIngrediente = (testoCercato: string) => {
    let arr = Array.from(
      window.document.getElementsByClassName("IngredienteDaAggiungere")
    );
    const query = testoCercato.toLowerCase();
    requestAnimationFrame(() => {
      arr.forEach((item: any) => {
        const shouldShow = item.textContent.toLowerCase().indexOf(query) > -1;
        item.style.display = shouldShow ? "block" : "none";
      });
    });
  };

  const handleRimuoviIngrediente = (index: number) => {
    setInputIngredienti((inputIngredienti: any) =>
      inputIngredienti.filter((_: any, i: number) => i !== index)
    );
  };

  const handleAggiungiIngrediente = (IngredienteDaAggiungere: any) => {
    setInputIngredienti(inputIngredienti.concat(IngredienteDaAggiungere));
  };

  //RETURN -----------------------------------------------
  return (
    <IonModal
      isOpen={isOpen}
    >
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={() => setIsOpen(false)}>
              Chiudi
            </IonButton>
          </IonButtons>
          <IonTitle>Seleziona Ingredienti</IonTitle>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar
            value={searchTextIngredienteModale}
            placeholder={"Cerca per nome, per prezzo o per tag"}
            onIonChange={(e) => {
              setSearchTextIngredienteModale(e.detail.value!);
              handleSearchIngrediente(e.detail.value!);
            }}
          ></IonSearchbar>
          <IonLabel>
            <p className='ion-padding'>Ingredienti aggiunti: {inputIngredienti?.length}</p>
          </IonLabel>
          <div className='div-y-scrollable ion-padding-start ion-padding-end'>
            {inputIngredienti?.map((ingrediente: Ingrediente, index: number) => {
              return (
                <IonChip
                  key={"ingredienteSelezionato" + index}
                  color={"primary"}
                >
                  {ingrediente.nome}
                  <IonIcon
                    color="medium"
                    icon={closeCircle}
                    onClick={() => {
                      handleRimuoviIngrediente(index);
                    }}
                  />
                </IonChip>
              );
            })}

          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent>

        <IonList>
          {allIngredienti?.map((item: Ingrediente, index: number) => {
            let y = inputIngredienti?.find((ite: Ingrediente) => ite.id === item.id);
            if (y == undefined)
              return (
                <div
                  key={index + "IngredienteDaAggiungere"}
                  className="IngredienteDaAggiungere"
                >
                  <IonItem>
                    <IonAvatar slot="start">
                      <IonImg src={item.immagineUrl} alt="Immagine" />
                    </IonAvatar>
                    <IonLabel>
                      {item.nome}
                      <p>Marca: {item.marca}</p>
                    </IonLabel>
                    <IonButton
                      color={"primary"}
                      slot="end"
                      onClick={() => handleAggiungiIngrediente(item)}
                    >
                      <IonIcon icon={add} />
                    </IonButton>
                  </IonItem>
                </div>
              );
          })}
        </IonList>
      </IonContent>
    </IonModal>
  );
};

export default ModaleModificaIngredienti;
