import { IonSlide, IonSlides } from "@ionic/react";
import React from "react";
import "./UploadMultipleImages.css";
import imageCompression from "browser-image-compression";

type UploadMultipleImagesProps = {
  setImage: any;
  immagini?: string[];
};

const UploadMultipleImages: React.FC<UploadMultipleImagesProps> = ({
  setImage,
  immagini,
}) => {
  //VARIABILI --------------------------------------------
  const slideOpts = {
    initialSlide: 0,
    speed: 400,
    spaceBetween: 5,
  };
  const [images, setImages] = React.useState([] as any);
  const [imageURLS, setImageURLs] = React.useState(immagini ? immagini : []);
  const slidesRef = React.useRef<HTMLIonSlidesElement>(null);

  //CONDIZIONI -------------------------------------------
  React.useEffect(() => {
    console.log(images);
    if (images.length < 1) return;
    const newImageUrls: any = [];
    images.forEach((image: any) =>
      newImageUrls.push(URL.createObjectURL(image))
    );
    setImageURLs(newImageUrls);
    setImage(images);
  }, [images]);

  function onImageChange(e: any) {
    setImages([...e.target.files]);
    compressImage([...e.target.files]);
  }

  const compressImage = async (e: any) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    let auxArrCompress: File[] = [];
    e.forEach(async (element: any) => {
      try {
        const compressFile = await imageCompression(element, options);
        auxArrCompress.push(compressFile);
        setImage(auxArrCompress);
      } catch (error) {
        console.log("Compression error:", error);
      }
    });
  };

  //RETURN -----------------------------------------------
  return (
    <>
      <input
        type="file"
        multiple
        accept="application/octet-stream, image/*"
        onChange={onImageChange}
        className="ion-margin-bottom"
      />
      {immagini === undefined ? (
        <IonSlides pager options={slideOpts} ref={slidesRef}>
          {imageURLS?.map((imageSrc: string, index: number) => {
            return (
              <IonSlide key={index + "immagine-selezionata"}>
                <div key={index + "div-img-prodotto"} className="div-img-prod">
                  <img
                    src={imageSrc}
                    alt="not fount"
                    width={"250px"}
                    className="img-prod"
                  />
                </div>
              </IonSlide>
            );
          })}
        </IonSlides>
      ) : null}
    </>
  );
};

export default UploadMultipleImages;
