import { IonButton, IonButtons, IonContent, IonHeader, IonInput, IonItem, IonLabel, IonList, IonModal, IonTitle, IonToolbar, useIonLoading, useIonToast } from '@ionic/react';
import './ModaleCategoria.css';
import { alertCircleOutline, close, cloudDoneOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { UploadSingleImageFirebase } from '../../Logic/ImageUpload';
import CardSelezionaProdotti from '../CardSelezionaProdotti';
import ModalInputImmagini from '../Modal_Input_Immagini/ModalInputImmagineSingola';
import ModaleModificaProdotti from '../Modale_Prodotti/ModaleModificaProdotti';

interface ContainerProps {
  oggettoDaModificare: any,
  indModifica: any,
  isOpen: any,
  setIsOpen: any,
  cartelle: any,
  allImmagini: any,
  allProdotti: any,

  allCategorie: any,
  handleUploadModifiche: any,

  AllSezioni: any,
  handleAggiornaAltreSezioni: any,

  present: any,
}

const ModalModificaCategoriaAttività: React.FC<ContainerProps> = ({
  oggettoDaModificare,
  indModifica,
  isOpen,
  setIsOpen,
  cartelle,
  allImmagini,
  allProdotti,

  allCategorie,

  handleUploadModifiche,

  AllSezioni,
  handleAggiornaAltreSezioni,

  present,
}) => {
  //VARIABILI --------------------------------------------
  const folder = "/categorie";

  const [isModalModificheProdottiOpen, setIsModalModificheProdottiOpen] =
    useState(false);



  const [indiceModifica, setIndiceModifica] = useState<number>();
  const [idModifica, setIdModifica] = useState<number>();
  const [inputNomeModifiche, setInputNomeModifiche] = useState();
  const [inputDescrizioneModifiche, setInputDescrizioneModifiche] = useState();
  const [inputImmagineModifiche, setInputImmagineModifiche] = useState();
  const [inputProdottiModifiche, setInputProdottiModifiche] = useState<any>([]);

  const [linkImmagineModifiche, setLinkImmagineModifiche] = useState();

  const [immagineSelezionata, setImmagineSelezionata] =
    useState<any>(undefined);


  const [presentToast] = useIonToast();

  //CONDIZIONI -------------------------------------------

  const resetImageInput = () => {
    setLinkImmagineModifiche(undefined);
    setImmagineSelezionata(undefined);
  }

  useEffect(() => {
    console.log("handleModifica()", oggettoDaModificare, " INDICE:", indModifica);
    setIndiceModifica(indModifica);
    setIdModifica(oggettoDaModificare?.id);
    setInputImmagineModifiche(oggettoDaModificare?.immagineUrl!);
    setInputNomeModifiche(oggettoDaModificare?.nome);
    setInputDescrizioneModifiche(oggettoDaModificare?.descrizione);
    setInputProdottiModifiche(oggettoDaModificare?.prodotti);
  }, [oggettoDaModificare])


  const resetAllInputs = () => {
    setIndiceModifica(undefined);
    setIdModifica(undefined);
    setInputNomeModifiche(undefined);
    setInputDescrizioneModifiche(undefined);
    setInputImmagineModifiche(undefined);
    setInputProdottiModifiche([]);
    setLinkImmagineModifiche(undefined);
  };

  const handleSalvaModifiche = async () => {
    present({
      message: "Sto salvando la categoria...",
    });
    setIsOpen(false);
    let oggettoModificato;
    if (inputImmagineModifiche === allCategorie[indiceModifica!].immagineUrl) {
      oggettoModificato = {
        id: idModifica,
        nome: inputNomeModifiche,
        descrizione: inputDescrizioneModifiche,
        immagineUrl: inputImmagineModifiche,
        prodotti: inputProdottiModifiche,
      };
      console.log(
        "handleSalvaModifiche - oggettoModificato : ",
        oggettoModificato
      );
      handleUploadModifiche(oggettoModificato);
    } else {
      await UploadSingleImageFirebase(
        inputImmagineModifiche!,
        idModifica!,
        setLinkImmagineModifiche,
        folder
      );
    }
  };


  const handleRimuoviProdottoModifiche = (ind: number) => {
    setInputProdottiModifiche((inputProdottiModifiche: any) =>
      inputProdottiModifiche.filter((_: any, i: number) => i !== ind)
    );
  };


  useEffect(() => {
    if (linkImmagineModifiche !== undefined) {
      const oggettoCategoria = {
        id: idModifica,
        nome: inputNomeModifiche,
        descrizione: inputDescrizioneModifiche,
        immagineUrl: linkImmagineModifiche,
        prodotti: inputProdottiModifiche,
      };
      console.log(oggettoCategoria);
      handleUploadModifiche(oggettoCategoria);
    }
  }, [linkImmagineModifiche]);



  const presentToastType = (
    message: string,
    type: "danger" | "success" | "warning",
    buttonEv: () => void,
    durationS?: number
  ) => {
    const iconType = {
      danger: close,
      success: cloudDoneOutline,
      warning: alertCircleOutline,
    };
    return presentToast({
      message: message,
      position: "top",
      duration: durationS ? durationS : 3000,
      icon: iconType[type],
      color: type,
      buttons: [
        {
          text: "Chiudi",
          role: "cancel",
          handler: () => {
            buttonEv();
          },
        },
      ],
    });
  };




  //RETURN -----------------------------------------------
  return (
    <>
      <IonModal
        isOpen={isOpen}
        onDidDismiss={() => {
          setIsOpen(false);
        }}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton
                color={"medium"}
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                Annulla
              </IonButton>
            </IonButtons>
            <IonTitle>Modifica categoria</IonTitle>
            <IonButtons slot="end">
              <IonButton
                color={"success"}
                fill="solid"
                onClick={() => handleSalvaModifiche()}
              >
                Salva
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>

          <ModalInputImmagini
            resetImageInput={resetImageInput}
            inputImmagine={inputImmagineModifiche}
            setInputImmagine={setInputImmagineModifiche}
            immagineSelezionata={immagineSelezionata}
            setImmagineSelezionata={setImmagineSelezionata}
            cartelle={cartelle}
            allImmagini={allImmagini}
          />

          <div className="ion-padding windowDubleHeight">
            <IonList>
              <IonItem fill="outline">
                <IonLabel position="stacked">Inserisci nome</IonLabel>
                <IonInput
                  value={inputNomeModifiche}
                  onIonInput={(ev: any) => {
                    setInputNomeModifiche(ev.target.value);
                  }}
                  clearInput
                  placeholder="Inserisci nome"
                ></IonInput>
              </IonItem>

              <IonItem fill="outline">
                <IonLabel position="stacked">
                  Inserisci breve descrizione
                </IonLabel>
                <IonInput
                  value={inputDescrizioneModifiche}
                  onIonInput={(ev: any) => {
                    setInputDescrizioneModifiche(ev.target.value);
                  }}
                  clearInput
                  placeholder="Inserisci breve descrizione"
                ></IonInput>
              </IonItem>
            </IonList>


            <CardSelezionaProdotti
              setModalOpen={setIsModalModificheProdottiOpen}
              inputItems={inputProdottiModifiche}
              setInputItems={setInputProdottiModifiche}
              handleRemoveItem={handleRimuoviProdottoModifiche}
            />

          </div>
        </IonContent>
      </IonModal>

      {/* MODALE SELEZIONA PRODOTTI */}
      <ModaleModificaProdotti
        isOpen={isModalModificheProdottiOpen}
        setIsOpen={setIsModalModificheProdottiOpen}
        inputProdotti={inputProdottiModifiche}
        setInputProdotti={setInputProdottiModifiche}
        allProdotti={allProdotti}
      />

    </>
  );
};

export default ModalModificaCategoriaAttività;
