import {
  collection,
  doc,
  DocumentData,
  getDoc,
  getDocs,
  orderBy,
  query,
} from "firebase/firestore";
import { db, storage } from "../fb";
import { getDownloadURL, getMetadata, listAll, ref } from "firebase/storage";

export const DataDownloadAllData = async () => {
  // console.log("DataDownload - DataDownloadAllData ()");
  const q = query(collection(db, "attivita"));
  let data: DocumentData[] = [];
  try {
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      // console.log(doc.id, " => ", doc.data());
      data.push(doc.data());
    });
    // console.log(data);
    return data;
  } catch (error) {
    console.log("DataDownload - DataDownloadAllData () - ERROR: ", error);
    return [];
  }
};

export const DataDownLoadProdotti = async () => {
  // console.log("DataDownload - DataDownloadAllData ()");
  const q = query(collection(db, "prodotti"));
  let data: DocumentData[] = [];
  try {
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      // console.log(doc.id, " => ", doc.data());
      data.push(doc.data());
    });
    // console.log(data);
    return data;
  } catch (error) {
    console.log("DataDownload - DataDownloadAllData () - ERROR: ", error);
    return [];
  }
};
export const DataDownLoadIngredienti = async () => {
  // console.log("DataDownload - DataDownloadAllData ()");
  const q = query(collection(db, "ingredienti"));
  let data: DocumentData[] = [];
  try {
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      // console.log(doc.id, " => ", doc.data());
      data.push(doc.data());
    });
    // console.log(data);
    return data;
  } catch (error) {
    console.log("DataDownload - DataDownloadAllData () - ERROR: ", error);
    return [];
  }
};

export const DataDownLoadTags = async () => {
  // console.log("DataDownload - DataDownloadAllData ()");
  const docRef = doc(db, "tags", "tags");
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    // console.log("Document data:", docSnap.data());
    return docSnap.data();
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
    return [];
  }
};

export const DataDownLoadCategorie = async () => {
  // console.log("DataDownload - DataDownLoadCategorie ()");
  const q = query(collection(db, "categorie"));
  let data: DocumentData[] = [];
  try {
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      // console.log(doc.id, " => ", doc.data());
      data.push(doc.data());
    });
    // console.log(data);
    return data;
  } catch (error) {
    console.log("DataDownload - DataDownLoadCategorie () - ERROR: ", error);
    return [];
  }
};

export const DataDownLoadSezioni = async () => {
  // console.log("DataDownload - DataDownLoadSezioni ()");
  const q = query(collection(db, "sezioni"));
  let data: DocumentData[] = [];
  try {
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      // console.log(doc.id, " => ", doc.data());
      data.push(doc.data());
    });
    // console.log(data);
    return data;
  } catch (error) {
    console.log("DataDownload - DataDownLoadSezioni () - ERROR: ", error);
    return [];
  }
};

export const DataDownloadPersonale = async (uid: string) => {
  // console.log("DataDownload - DataDownloadPersonale ()");
  const docRef = doc(db, "personale", uid);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    // console.log("Document data:", docSnap.data());
    return docSnap.data();
  } else {
    // doc.data() will be undefined in this case
    // console.log("No such document!");
    return undefined;
  }
};

export const DataDownloadStoricoOrdini = async (tableId: string) => {
  const q = query(
    collection(db, "StoricoOrdini-" + tableId),
    orderBy("dataChiusura")
  );
  let data: DocumentData[] = [];
  try {
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      // console.log(doc.id, " => ", doc.data());
      data.push(doc.data());
    });
    // console.log(data);
    return data;
  } catch (error) {
    console.log("DataDownload - DataDownloadStoricoOrdini () - ERROR: ", error);
    return [];
  }
};

export const defaultFolders = [
  "ingredienti",
  "prodotti",
  "about",
  "categorie",
  "comunicazioni",
  "logos",
  "personale",
  "sezioni",
];

export const DataGetAllImmages = async (x?: any) => {
  let AuxArray: any[] = [];
  let folders = defaultFolders.concat(x.allCartelleImmagini);
  console.log("DataGetAllImmages - folders : ", folders);
  folders.map(async (folder: string, index: number) => {
    const listRef = ref(storage, folder);

    await listAll(listRef)
      .then((res) => {
        res.items.forEach(async (itemRef, index: number) => {
          let urlImmagine = await getDownloadURL(itemRef);
          getMetadata(itemRef)
            .then((metadata) => {
              let oggettoImag = {
                cartella: folder,
                riferimentoImmagine: itemRef,
                urlImmagine: urlImmagine,
                meta: metadata,
              };
              AuxArray.push(oggettoImag);
            })
            .catch((error) => {
              console.error(error);
            });
        });
      })
      .catch((error) => {
        console.error(error);
      });
  });

  return AuxArray;
};

export const DataGetCartelle = async () => {
  const docRef = doc(db, "tags", "allCartelleImmagini");
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    // console.log("Document data:", docSnap.data());
    return docSnap.data();
  } else {
    // doc.data() will be undefined in this case
    // console.log("No such document!");
    return [];
  }
};
