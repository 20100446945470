import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonNote,
  IonPage,
  IonToolbar,
  useIonAlert,
} from "@ionic/react";
import {
  albumsOutline,
  appsOutline,
  arrowBack,
  earthOutline,
  folderOutline,
  globeOutline,
  helpOutline,
  imagesOutline,
  newspaperOutline,
  peopleOutline,
  timeOutline,
} from "ionicons/icons";
import React from "react";
import { useParams } from "react-router";
import AttivitaPerformance from "../components/AttivitaPerformance";
import { DeleteDataAttivita } from "../Logic/DataUpload";
import { LocalSaveAttivita } from "../Logic/LocalStorage";
import "../pages/CSS/AttivitaScreen.css";
import {
  url_app_allAttivita,
  url_app_attivita_categorie_init,
  url_app_attivita_chisiamo_init,
  url_app_attivita_comunicazioni_init,
  url_app_attivita_orari_init,
  url_app_attivita_personale_init,
  url_app_attivita_portfolio_init,
  url_app_attivita_prodottiConsigliati_init,
  url_app_attivita_sezionimenu_init,
} from "../Urls";

type ScreenProps = {
  AllData: any;
  setAllData: any;
};

const AttivitaScreen: React.FC<ScreenProps> = ({ AllData, setAllData }) => {
  //VARIABILI --------------------------------------------
  const [dataAttivita, setDataAttivita] = React.useState<any>([]);
  const { indiceAttivita } = useParams<{ indiceAttivita: string }>();
  const index = indiceAttivita.split("-")[1];
  const [presentAlert] = useIonAlert();
  //CONDIZIONI -------------------------------------------

  const handleEliminaAttivita = async () => {
    await DeleteDataAttivita(dataAttivita?.id);
    let auxDataAttivita = [...AllData];
    auxDataAttivita.splice(parseInt(indiceAttivita), 1);
    console.log(
      "AttivitaScreen - handleEliminaAttivita() - auxDataAttivita",
      auxDataAttivita
    );
    setAllData(auxDataAttivita);
    LocalSaveAttivita(auxDataAttivita);
  };

  React.useEffect(() => {
    const data = AllData;
    console.log("AttivitaScreen : AllData : ", AllData);
    setDataAttivita(data[parseInt(index)]);
  }, [AllData, indiceAttivita]);

  //RETURN -----------------------------------------------
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton routerLink={url_app_allAttivita} routerDirection="back">
              <IonIcon icon={arrowBack} />
              Tutte le attività
            </IonButton>
          </IonButtons>
          <IonButtons slot="end">
            {dataAttivita.linkMenu !== undefined ? (
              <IonButton
                color={"primary"}
                fill="solid"
                onClick={() => {
                  window.open(dataAttivita.linkMenu, "_blank");
                }}
              >
                <IonIcon
                  className="ion-margin-end"
                  icon={globeOutline}
                ></IonIcon>
                Menù
              </IonButton>
            ) : null}

            {dataAttivita.linkMenuTavoli !== undefined ? (
              <IonButton
                fill="solid"
                color={"primary"}
                onClick={() => {
                  window.open(dataAttivita.linkMenuTavoli, "_blank");
                }}
              >
                <IonIcon
                  className="ion-margin-end"
                  icon={earthOutline}
                ></IonIcon>
                Menù Tavoli
              </IonButton>
            ) : null}
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        {/* Contenuto */}
        <div>
          <div className="ion-padding">
            <AttivitaPerformance attivitaData={dataAttivita} onlyInfo={true} />
          </div>

          <div className="ion-padding-horizontal ion-padding-top ion-text-center">
            <IonNote>Gestione catalogo</IonNote>
          </div>

          <IonCard
            routerLink={url_app_attivita_prodottiConsigliati_init + index}
          >
            <IonCardHeader>
              <IonCardSubtitle>
                <IonIcon
                  icon={appsOutline}
                  className="ion-margin-end"
                  size="small"
                />
                Prodotti consigliati
              </IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
              <IonLabel>
                <p>
                  Sono i primi 5 prodotti visibili sulla Home del menu
                  digiatale.
                </p>
              </IonLabel>
            </IonCardContent>
          </IonCard>
          <IonCard routerLink={url_app_attivita_categorie_init + index}>
            <IonCardHeader>
              <IonCardSubtitle>
                <IonIcon
                  icon={folderOutline}
                  className="ion-margin-end"
                  size="small"
                />
                Categorie
              </IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
              <IonLabel>
                <p>
                  Le categorie ti permettono di racchiudere più prodotti sono
                  un'unica "cartella".
                </p>
              </IonLabel>
            </IonCardContent>
          </IonCard>
          <IonCard routerLink={url_app_attivita_sezionimenu_init + index}>
            <IonCardHeader>
              <IonCardSubtitle>
                <IonIcon
                  icon={albumsOutline}
                  className="ion-margin-end"
                  size="small"
                />
                Sezioni Menu
              </IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
              <IonLabel>
                <p>
                  Le sezioni Menu sono visibili sulla Home del menu digitale e
                  ti permettono di racchiudere più categorie in un'unica
                  "cartella".
                </p>
              </IonLabel>
            </IonCardContent>
          </IonCard>

          <div className="ion-padding">
            <hr
              style={{
                color: "#c1c1c1",
                backgroundColor: "#c1c1c1",
                height: 1,
              }}
            />
          </div>

          <div className="ion-padding-horizontal ion-padding-top ion-text-center">
            <IonNote>Gestione informazioni attività</IonNote>
          </div>

          <IonCard routerLink={url_app_attivita_portfolio_init + index}>
            <IonCardHeader>
              <IonCardSubtitle>
                <IonIcon
                  icon={imagesOutline}
                  className="ion-margin-end"
                  size="small"
                />
                Portfolio immagini
              </IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
              <IonLabel>
                <p>
                  Questa funzionalità permette di aggiungere immagini di
                  presentazione, che sia dell'attività, degli eventi, dei
                  servizi o prodotti. In poche parole è la galleria
                  dell'attività.
                </p>
              </IonLabel>
            </IonCardContent>
          </IonCard>

          <IonCard routerLink={url_app_attivita_comunicazioni_init + index}>
            <IonCardHeader>
              <IonCardSubtitle>
                <IonIcon
                  icon={newspaperOutline}
                  className="ion-margin-end"
                  size="small"
                />
                Comunicazioni
              </IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
              <IonLabel>
                <p>
                  Le comunicazioni sono la prima cosa visibile ad un utente
                  sulla pagina Home. Sono molto importanti per dare un primo
                  impatto al utente. Che sia una Promozione o un Evento.
                </p>
              </IonLabel>
            </IonCardContent>
          </IonCard>

          <IonCard routerLink={url_app_attivita_personale_init + index}>
            <IonCardHeader>
              <IonCardSubtitle>
                <IonIcon
                  icon={peopleOutline}
                  className="ion-margin-end"
                  size="small"
                />
                Personale
              </IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
              <IonLabel>
                <p>
                  Permetti ai tuoi clienti di conoscere il tuo personale, le
                  loro qualifiche, e/o le loro passioni, creando un legame
                  cliente/personale.
                </p>
              </IonLabel>
            </IonCardContent>
          </IonCard>

          <IonCard routerLink={url_app_attivita_orari_init + index}>
            <IonCardHeader>
              <IonCardSubtitle>
                <IonIcon
                  icon={timeOutline}
                  className="ion-margin-end"
                  size="small"
                />
                Orari
              </IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
              <IonLabel>
                <p>
                  Dai una panoramica sugli orari del tuo bar/ristorante ai tuoi
                  clienti permettendo loro di prenotare un tavolo in base alle
                  disponibilità.
                </p>
              </IonLabel>
            </IonCardContent>
          </IonCard>

          <IonCard routerLink={url_app_attivita_chisiamo_init + index}>
            <IonCardHeader>
              <IonCardSubtitle>
                <IonIcon
                  icon={helpOutline}
                  className="ion-margin-end"
                  size="small"
                />
                Chi siamo
              </IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
              <IonLabel>
                <p>
                  Fai conoscere la storia, e la vision del tuo locale/ristorante
                  ai tuoi clienti.
                </p>
              </IonLabel>
            </IonCardContent>
          </IonCard>

          <IonButton
            color="danger"
            expand="block"
            fill="clear"
            onClick={() => {
              presentAlert({
                header: "ATTENZIONE !",
                message:
                  "L'eliminazione è definitiva. Tutti i dati dell'attività verranno cancellati. I prodotti e gli ingredienti invece non saranno toccati.",
                buttons: [
                  {
                    text: "Annulla",
                    role: "cancel",
                  },
                  {
                    text: "Procedi",
                    role: "confirm",
                    handler: () => {
                      handleEliminaAttivita();
                    },
                  },
                ],
              });
            }}
          >
            Elimina attività
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default AttivitaScreen;
