import { IonBadge, IonButton, IonButtons, IonChip, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonModal, IonSearchbar, IonTitle, IonToolbar } from '@ionic/react';
import { calendarClearOutline, squareOutline, timeOutline } from 'ionicons/icons';
import { useState } from 'react';
import { DataDownloadStoricoOrdini } from '../Logic/DataDownload';
import './StoricoOrdiniComponente.css';

interface ContainerProps {
  attivitaData:any
}

const StoricoOrdiniComponente: React.FC<ContainerProps> = ({
  attivitaData,
 }) => {
  //VARIABILI --------------------------------------------
  const [storicoOrdini, setStoricoOrdini] = useState<any>();
  const [isModalOpen,setIsModalOpen] = useState(false);
  const [filtro, setFiltro] = useState("Nessun filtro");
  //CONDIZIONI -------------------------------------------
  useState(async()=>{
    await DataDownloadStoricoOrdini(attivitaData?.id).then((data) => {
      setStoricoOrdini(data);
    });
  })
  
  const handelFiltraOrdini = (filtro: string) => {
    setFiltro(filtro);
    let arr = Array.from(
      window.document.getElementsByClassName("item-storico-ordine")
    );
    const query = filtro.toLowerCase();
    requestAnimationFrame(() => {
      arr.forEach((item: any) => {
        const shouldShow = item.textContent.toLowerCase().indexOf(query) > -1;
        item.style.display = shouldShow ? "block" : "none";
      });
    });
    if (filtro === "Nessun filtro") {
      requestAnimationFrame(() => {
        arr.forEach((item: any) => {
          item.style.display = "block";
        });
      });
    }
  };

  //RETURN -----------------------------------------------
  return (
    <>
     <div className={"AttivitaPerformance-box"}>
        <div className="AttivitaPerformance-box-title">
          <h2>{attivitaData?.nome}</h2>
        </div>
        <div className='ion-text-center'>
          <p>Totale: </p>
        </div>
        <div className="AttivitaPerformance-box-content">
          <div className="AttivitaPerformance-box-content-alerts">
            <IonButton
              onClick={() => setIsModalOpen(true)}
            >
              <IonIcon icon={timeOutline} className="ion-margin-end" />
              Ordini: {storicoOrdini?.length}
            </IonButton>
          </div>
        </div>
      </div>

       {/* ------------------ MODAL ORDINI -------------------- */}
       <IonModal
          isOpen={isModalOpen}
          onDidDismiss={() => {
            setIsModalOpen(false);
          }}
        >
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton
                  color={"medium"}
                  onClick={() => setIsModalOpen(false)}
                >
                  Chiudi
                </IonButton>
              </IonButtons>
              <IonTitle>Storico ordini</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <IonSearchbar value={filtro} placeholder={"Cerca per nome tavolo o data"} onIonChange={e => { handelFiltraOrdini(e.detail.value!) }}></IonSearchbar>

            {storicoOrdini == undefined ? (
              <IonLabel>
                <p>Caricamento dati in corso</p>
              </IonLabel>
            ) : (
              <IonList>
                {storicoOrdini?.map((story: any, index: number) => {
                  let data = story?.dataChiusura;

                  return (
                    <IonItem
                      key={"StoricoOrdini" + attivitaData.id + index}
                      className="item-storico-ordine"
                    >
                      <IonLabel className="ion-text-wrap">
                        <p>
                          <IonIcon icon={squareOutline} className="ion-padding-end" />
                          Tavolo:
                          <IonChip onClick={()=>handelFiltraOrdini(story?.tavolo)}>
                            {story?.tavolo
                              ? story?.tavolo
                              : "Nome non disponibile"}
                          </IonChip>
                        </p>
                        <p>
                          <IonIcon icon={calendarClearOutline} className="ion-padding-end"  />
                          Data:
                          <IonChip
                          onClick={()=>handelFiltraOrdini(data.split(",")[0])}
                          >{data}</IonChip>
                        </p>
                        <p>
                          <IonBadge>
                          Ordini effettuati:
                          <span className="ion-padding">
                          {story.tableData?.orders
                              ? story.tableData?.orders.length
                              : "Nessun ordine"}
                          </span>
                           
                          </IonBadge>
                        </p>
                      </IonLabel>
                    </IonItem>
                  );
                })}
              </IonList>
            )}
          </IonContent>
        </IonModal>
    </>
  );
};

export default StoricoOrdiniComponente;
