import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
} from "@ionic/react";
import IngredientiPerformance from "./IngredientiPerformance";
import "./InventarioComp.css";
import ProdottiPerformance from "./ProdottiPerformance";

interface ContainerProps {
  IngredientiDb: any;
  ProdottiDb: any;
}

const InventarioComp: React.FC<ContainerProps> = ({
  IngredientiDb,
  ProdottiDb,
}) => {
  //VARIABILI --------------------------------------------
  //CONDIZIONI -------------------------------------------
  //RETURN -----------------------------------------------
  return (
    <IonCard>
      <IonCardHeader>
        <IonCardSubtitle>Inventario</IonCardSubtitle>
      </IonCardHeader>
      <IonCardContent>
        <div className="InventarioComp-row">
          <IngredientiPerformance IngredientiDb={IngredientiDb} />
          <ProdottiPerformance ProdottiDb={ProdottiDb} />
        </div>
      </IonCardContent>
    </IonCard>
  );
};

export default InventarioComp;
