import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonChip, IonIcon, IonLabel } from "@ionic/react";
import { checkmark } from "ionicons/icons";

interface ContainerProps {
  AllSezioni: any,
  arrAutomaticAdd: any,
  setArrAutomaticAdd: any,
}

const CardAddAutomaticToSezione: React.FC<ContainerProps> = ({
  AllSezioni,
  arrAutomaticAdd,
  setArrAutomaticAdd
}) => {
  //VARIABILI --------------------------------------------
  //CONDIZIONI -------------------------------------------
  const handleAutomaticAdd = (idAttivita: any,) => {
    setArrAutomaticAdd(arrAutomaticAdd.concat(idAttivita));
  }

  const handleRemoveFromAutomatic = (idAttivita: any,) => {
    setArrAutomaticAdd((arrAutomaticAdd: any) => arrAutomaticAdd.filter((_: any, i: number) => _ !== idAttivita));
  }

  //RETURN -----------------------------------------------
  return (

    <IonCard>
      <IonCardHeader>
        <IonCardTitle>Clicca per selezionare le sezioni a cui aggiungere automaticamente</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <IonLabel>
          <h2>Sezioni:</h2>
        </IonLabel>
        {AllSezioni.length === 0 ?
          <IonLabel>
            <p>Nessuna sezione</p>
          </IonLabel>
          : null
        }
        {
          AllSezioni.map((sez: any, index: number) => {
            let verify = arrAutomaticAdd.includes(sez.id);
            return (
              !verify ?
                <IonChip color={verify ? "success" : "primary"} key={"attivitàchip" + index} onClick={() => { handleAutomaticAdd(sez.id) }}>
                  {sez.nome}
                  {verify ?
                    <IonIcon icon={checkmark} className='ion-margin-start' />
                    : null
                  }
                </IonChip> :
                <IonChip color={verify ? "success" : "primary"} key={"attivitàchip" + index} onClick={() => { handleRemoveFromAutomatic(sez.id) }}>
                  {sez.nome}
                  {verify ?
                    <IonIcon icon={checkmark} className='ion-margin-start' />
                    : null
                  }
                </IonChip>
            )
          })
        }


      </IonCardContent>
    </IonCard>

  );
};

export default CardAddAutomaticToSezione;
