import { IonAvatar, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonChip, IonContent, IonHeader, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonList, IonModal, IonSearchbar, IonSegment, IonSegmentButton, IonThumbnail, IonTitle, IonToolbar, useIonAlert, useIonLoading, useIonToast } from '@ionic/react';
import './InventarioColumn.css';
import { add, alertCircleOutline, checkmark, close, closeCircle, closeOutline, cloudDoneOutline, imagesOutline, pencil, trash } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { Categoria, Sezione } from '../data/dataSheet';
import { UploadDataSezioni, UploadNewSezione } from '../Logic/DataUpload';
import { UploadSingleImageFirebase } from '../Logic/ImageUpload';
import { deleteDoc, doc } from 'firebase/firestore';
import { db } from '../fb';
import UploadSingleImage from './UploadSingleImage';
import { defaultFolders } from '../Logic/DataDownload';

interface ContainerProps {
  AllData: any,
  AllSezioni: any,
  AllCategorie: any,
  AllImmagini: any,
  cartelle: any,
  UpdateAll: any,
}

const InventarioColumn_Sezioni: React.FC<ContainerProps> = ({
  AllData,
  AllSezioni,
  AllCategorie,
  AllImmagini,
  cartelle,
  UpdateAll,
}) => {
  //VARIABILI --------------------------------------------
  const folder = "/sezioni";

  const [attivita, setAttivita] = useState<any>([])

  const [sezioni, setSezioni] = useState<any>([]);
  const [categorie, setCategorie] = useState<any>([]);

  const [presentAlert] = useIonAlert();
  const [present, dimiss] = useIonLoading();

  //Aggiungi
  const [id, setId] = useState<number>();
  const [inputNome, setInputNome] = useState();
  const [inputDescrizione, setInputDescrizione] = useState();
  const [inputImmagine, setInputImmagine] = useState();
  const [inputCategorie, setInputCategorie] = useState<any>([]);

  const [linkImmagine, setLinkImmagine] = useState();


  const [arrAutomaticAdd, setArrAutomaticAdd] = useState<any>([]);
  const [insertToAtt, setInsertToAtt] = useState(false);
  const [oggettoDaAggiungere, setOggettoDaAggiungere] = useState<any>(undefined);

  //Modificia
  const [indiceModifica, setIndiceModifica] = useState<number>()
  const [idModifica, setIdModifica] = useState();
  const [inputNomeModifiche, setInputNomeModifiche] = useState();
  const [inputDescrizioneModifiche, setInputDescrizioneModifiche] = useState();
  const [inputImmagineModifiche, setInputImmagineModifiche] = useState();
  const [inputCategorieModifiche, setInputCategorieModifiche] = useState<any>([]);

  const [linkImmagineModifiche, setLinkImmagineModifiche] = useState();

  //Modali
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalModificheOpen, setIsModalModificheOpen] = useState(false);

  const [isModalAddCategorieOpen, setIsModalAddCategorieOpen] = useState(false);
  const [isModalAddCategorieModificheOpen, setIsModalAddCategorieModificheOpen] = useState(false);

  //Search 
  const [searchTextCategorieModale, setSearchTextCategorieModale] = useState("");

  const [searchTextModale, setSearchTextModale] = useState("");

  const [presentToast] = useIonToast();

  const [segmentValue, setSegmentValue] = useState("Seleziona");
  const [immagineSelezionata, setImmagineSelezionata] =
    useState<any>(undefined);

  const [isModaleImmaginiOpen, setIsModaleImmaginiOpen] = useState(false);
  const [filtroCartelle, setFiltroCartelle] = useState<any>("");
  const [filtroVisualizzazioneImmagini, setFiltroVisualizzazioneImmagini] =
    useState<any>("column-portfolio-4");

  //CONDIZIONI -------------------------------------------
  useEffect(() => {
    console.log("AllData", AllData)
    setAttivita(AllData);
    setSezioni(AllSezioni);
    setCategorie(AllCategorie);

  }, [AllData, AllSezioni, AllCategorie]);


  const handleAutomaticAdd = (idAttivita: any, verify: boolean, indAtt: number) => {
    if (verify) {
      //eliminare
      setArrAutomaticAdd((arrAutomaticAdd: any) => arrAutomaticAdd.filter((_: any, i: number) => i !== indAtt));
    } else {
      //aggiungere
      setArrAutomaticAdd(arrAutomaticAdd.concat(idAttivita));
    }
  }

  const handleAddToAltreAttivita = (arrAtt: any, oggettoSezione: any) => {
    arrAtt.map((att: any) => {
      attivita.map((attiv: any, index: any) => {
        console.log(attiv.id == att)
        if (attiv.id === att) {
          console.log("Attivita : indice attività:", index, attiv.id === att, " - ", attiv);
          let a = attiv;

          a.sezioni?.push(oggettoSezione);

          oggettoSezione?.categorie.map((categoriaImportata: any, indexImportata: any) => {
            let find = a.categorie.find((catEsistente: any) => catEsistente.id === categoriaImportata.id);
            if (find !== undefined) {
              console.log("categoriaImportata è presente nelle categorie esistenti: NON IMPORTARE ", categoriaImportata);
            } else {
              console.log("categoriaImportata Non è presente nelle categorie esistenti: IMPORTA ", categoriaImportata);
              a.categorie.push(categoriaImportata);
            }
          })

          console.log(a);
          UploadDataSezioni(a, a.id).catch((err) => {
            presentToastType("Errore aggiornamento attività: " + err, "danger", () => { });
          }).then(() => {
            presentToastType("Attivita : " + a.nome + " aggiornata con successo. ", "success", () => { });
            UpdateAll();
          });
        }
      })
    })
  }


  const handleSelectSegment = (segmentValue: string) => {
    setSegmentValue(segmentValue);
    //reset degli input di entrambi quando si cambia
  };

  const handleFiltraImmagini = (testoCercato: string) => {
    let arr = Array.from(
      window.document.getElementsByClassName("immaginiGalleria")
    );
    const query = testoCercato.toLowerCase();
    requestAnimationFrame(() => {
      arr.forEach((item: any) => {
        const shouldShow = item.textContent.toLowerCase().indexOf(query) > -1;
        item.style.display = shouldShow ? "block" : "none";
      });
    });
  };

  const presentToastType = (message: string, type: "danger" | "success" | "warning", buttonEv: () => void, durationS?: number) => {

    const iconType = {
      "danger": close,
      "success": cloudDoneOutline,
      "warning": alertCircleOutline,
    }

    presentToast({
      message: message,
      position: "top",
      duration: durationS ? durationS : 3000,
      icon: iconType[type],
      color: type,
      buttons: [
        {
          text: 'Chiudi',
          role: 'cancel',
          handler: () => { buttonEv() }
        }
      ]
    })
  }



  const handleSalvaSezione = async () => {
    if (immagineSelezionata !== undefined && inputNome !== undefined && inputDescrizione !== undefined) {
      setIsModalOpen(false);
      present({
        message: "Sto salvando la sezione..."
      });
      let auxIdd = Date.now();
      setId(auxIdd);

      const oggettoSezione = {
        id: auxIdd,
        nome: inputNome,
        descrizione: inputDescrizione,
        immagineUrl: immagineSelezionata,
        categorie: inputCategorie,
      };
      console.log("oggettoSezione : ", oggettoSezione);
      salvaAllData(oggettoSezione);

    } else {
      if (inputNome !== undefined && inputDescrizione !== undefined) {
        console.log("AGGIUNTA NORMALE");
        setIsModalOpen(false);
        present({
          message: "Sto salvando la sezione..."
        });
        let auxId = Date.now();
        console.log("auxId", auxId);
        setId(auxId);
        await UploadSingleImageFirebase(inputImmagine!, auxId, setLinkImmagine, folder);
      } else {
        presentToastType("ATTENZIONE! Inserire tutti i campi obbligatori", "warning", () => { });
        dimiss();
      }
    }
  }

  useEffect(() => {
    if (linkImmagine !== undefined) {
      console.log("ID : ", id);
      const oggettoSezione = {
        id: id,
        nome: inputNome,
        descrizione: inputDescrizione,
        immagineUrl: linkImmagine,
        categorie: inputCategorie,
      };
      console.log("oggettoSezione : ", oggettoSezione);
      salvaAllData(oggettoSezione);
    }
  }, [linkImmagine]);


  useEffect(() => {
    if (linkImmagineModifiche !== undefined) {
      const oggettoSezione = {
        id: idModifica,
        nome: inputNomeModifiche,
        descrizione: inputDescrizioneModifiche,
        immagineUrl: linkImmagineModifiche,
        categorie: inputCategorieModifiche,
      };
      console.log(oggettoSezione);
      handleUploadModifiche(oggettoSezione);
    }
  }, [linkImmagineModifiche]);

  const salvaAllData = async (oggettoSezione: any) => {
    console.log("salvaAllData - sezione :", oggettoSezione, " arrAutomaticAdd : ", arrAutomaticAdd);

    handleAddToAltreAttivita(arrAutomaticAdd, oggettoSezione);

    await UploadNewSezione(oggettoSezione, oggettoSezione.id).then(() => {
      UpdateAll();
      setInsertToAtt(true);
    }).catch((err) => {
      console.error("********ERRORE: ", err);
      resetAllDataInput();
      dimiss();
      presentToastType("Errore caricamento : " + err, "danger", () => { });
    });

    resetAllDataInput();
    dimiss();
    presentToastType("Sezione salvata con successo.", "success", () => { });
  }


  const handleModifica = (ind: number) => {
    console.log("handleModifica()")
    setIndiceModifica(ind)
    if (sezioni !== undefined) {
      setIdModifica(sezioni[ind].id)
      setInputImmagineModifiche(sezioni[ind].immagineUrl!)
      setInputNomeModifiche(sezioni[ind].nome)
      setInputDescrizioneModifiche(sezioni[ind].descrizione)
      setInputCategorieModifiche(sezioni[ind].categorie)
      setIsModalModificheOpen(true);
    }
  }


  const rimuoviSezione = async (sezioneData: any) => {
    present({ message: "Sto eliminando la categoria..." });
    await deleteDoc(doc(db, "sezioni", sezioneData.id.toString())).then(() => {
      console.log("Sezione ", sezioneData.id.toString(), " eliminata con successo.");
      presentToastType("Sezione " + sezioneData.nome + " eliminata con successo.", "success", () => { });
      UpdateAll();
      dimiss();
      resetAllDataInput();
    });
  }

  const handleSalvaModifiche = async () => {
    if (immagineSelezionata !== undefined) {
      setIsModalOpen(false);
      present({
        message: "Sto salvando la sezione..."
      });
      let auxId = Date.now();
      setId(auxId);
      const oggettoSezione = {
        id: auxId,
        nome: inputNome,
        descrizione: inputDescrizione,
        immagineUrl: immagineSelezionata,
        categorie: inputCategorie,
      };
      console.log("oggettoSezione : ", oggettoSezione);
      salvaAllData(oggettoSezione);
    } else {
      present({
        message: "Sto aggiornando la sezione..."
      });
      setIsModalModificheOpen(false);
      let oggettoModificato;
      if (inputImmagineModifiche === sezioni[indiceModifica!].immagineUrl) {
        oggettoModificato = {
          id: idModifica,
          nome: inputNomeModifiche,
          descrizione: inputDescrizioneModifiche,
          immagineUrl: inputImmagineModifiche,
          categorie: inputCategorieModifiche,
        }
        console.log("handleSalvaModifiche - oggettoModificato : ", oggettoModificato);
        handleUploadModifiche(oggettoModificato);
      } else {
        await UploadSingleImageFirebase(inputImmagineModifiche!, idModifica!, setLinkImmagineModifiche, folder);
      }
    }

  }

  const handleUploadModifiche = async (oggettoModificato: any) => {
    console.log("handleUploadModifiche - oggettoModificato:", oggettoModificato);

    await UploadNewSezione(oggettoModificato, oggettoModificato.id).then(() => {
      UpdateAll();
    });
    resetAllDataInput();
    dimiss();
    presentToastType("Sezione salvata", "success", () => { });

  }

  const handleSearchCategoria = (testoCercato: string) => {
    let arr = Array.from(window.document.getElementsByClassName("categoriaDaAggiungere"));
    const query = testoCercato.toLowerCase();
    requestAnimationFrame(() => {
      arr.forEach((item: any) => {
        const shouldShow = item.textContent.toLowerCase().indexOf(query) > -1;
        item.style.display = shouldShow ? 'block' : 'none';
      });
    });
  }

  const handleAggiungiCategoria = (categoriaDaAggiungere: any) => {
    presentToastType("Categoria " + categoriaDaAggiungere.nome + " aggiunta", "success", () => { });

    setInputCategorie(inputCategorie.concat(categoriaDaAggiungere));
  }

  const handleRimuoviCategoria = (index: number) => {
    setInputCategorie((inputCategorie: any) => inputCategorie.filter((_: any, i: number) => i !== index))
  }

  const handleAggiungiCategoriaModifiche = (categoriaDaAggiungere: any) => {
    presentToastType("Categoria " + categoriaDaAggiungere.nome + " aggiunta", "success", () => { });
    setInputCategorieModifiche(inputCategorieModifiche.concat(categoriaDaAggiungere));
  }
  const handleRimuoviCategoriaModifiche = (index: number) => {
    setInputCategorieModifiche((inputCategorieModifiche: any) => inputCategorieModifiche.filter((_: any, i: number) => i !== index))
  }


  const handleSearch = (text: any) => {
    let arr = Array.from(window.document.getElementsByClassName("sezioni"));
    const query = text.toLowerCase();
    requestAnimationFrame(() => {
      arr.forEach((item: any) => {
        const shouldShow = item.textContent.toLowerCase().indexOf(query) > -1;
        item.style.display = shouldShow ? 'block' : 'none';
      });
    });
  }


  const resetAllDataInput = () => {
    setId(undefined)
    setInputNome(undefined)
    setInputDescrizione(undefined)
    setInputImmagine(undefined)
    setInputCategorie([])
    setLinkImmagine(undefined)
    setIndiceModifica(undefined)
    setIdModifica(undefined)
    setInputNomeModifiche(undefined)
    setInputDescrizioneModifiche(undefined)
    setInputImmagineModifiche(undefined)
    setInputCategorieModifiche([])
    setLinkImmagineModifiche(undefined);
    setImmagineSelezionata(undefined);

    setArrAutomaticAdd([]);
    setOggettoDaAggiungere(undefined);

  }


  //RETURN -----------------------------------------------
  return (
    <div className="inventario-column-container">
      <IonToolbar>
        <IonTitle>
          Sezioni
        </IonTitle>
        <IonButtons slot='end'>
          <IonButton fill='solid' color={"primary"} onClick={() => setIsModalOpen(true)}>
            <IonIcon icon={add} />
            Aggiungi
          </IonButton>
        </IonButtons>
      </IonToolbar>
      <div className="inventario-content">
        <div className="inventario-column-items">
          <IonList>
            {sezioni?.map((item: Sezione, index: number) => {
              return (
                <IonItem key={index + "sezioni"} className='sezioni'>
                  <IonAvatar className='ion-margin-end'>
                    <IonImg src={item.immagineUrl} />
                  </IonAvatar>
                  <IonLabel>
                    <h2>{item.nome}</h2>
                    <p>{item.descrizione}</p>
                    <p>Categorie: {item.categorie.length}</p>
                    <IonToolbar>
                      <IonButtons className='ion-justify-content-around'>
                        <IonButton slot='end' color={"primary"} fill='solid' onClick={() => { handleModifica(index) }}>
                          <IonIcon icon={pencil} />
                        </IonButton>
                        <IonButton color={"danger"} slot='end' fill='solid' onClick={() => presentAlert({
                          header: 'Attenzione!',
                          message: "L'eliminazione è definitiva! La sezione non sarà rimossa dalle attività in cui è presente.",
                          buttons: [
                            {
                              text: 'Annulla',
                              role: 'cancel',
                            },
                            {
                              text: 'Conferma',
                              role: 'confirm',
                              handler: () => {
                                rimuoviSezione(item);
                              },
                            },
                          ],
                        })}>
                          <IonIcon icon={trash} />
                        </IonButton>
                      </IonButtons>
                    </IonToolbar>
                  </IonLabel>


                </IonItem>
              )
            })}

          </IonList>
        </div>
      </div>
      {/*------------------- AGGIUNGI CATEGORIE ------------------*/}
      <IonModal isOpen={isModalAddCategorieOpen} onDidDismiss={() => { setIsModalAddCategorieOpen(false) }} >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={() => setIsModalAddCategorieOpen(false)}>Chiudi</IonButton>
            </IonButtons>
            <IonTitle>Seleziona categoriee</IonTitle>
            <IonButtons slot="end">
              <IonButton color='success' fill='solid' onClick={() => setIsModalAddCategorieOpen(false)}>Conferma</IonButton>
            </IonButtons>
          </IonToolbar>
          <IonToolbar>
            <IonSearchbar value={searchTextCategorieModale} placeholder={"Cerca per nome"} onIonChange={e => { setSearchTextCategorieModale(e.detail.value!); handleSearchCategoria(e.detail.value!) }}></IonSearchbar>

          </IonToolbar>

        </IonHeader>
        <IonContent>

          <div className='ion-padding'>
            <IonLabel>
              <p>Categorie aggiunte:</p>
            </IonLabel>
            {inputCategorie?.map((categoria: Categoria, index: number) => {
              return (
                <IonChip key={"ingredienteSelezionato" + index} color={"primary"}>
                  {categoria.nome}
                  <IonIcon color='medium' icon={closeCircle} onClick={() => { handleRimuoviCategoria(index) }} />
                </IonChip>
              )
            })}
          </div>

          <IonList>
            {
              categorie?.map((item: Categoria, index: number) => {
                let verify = inputCategorie.includes(item);
                return (
                  <div key={index + "categoriaDaAggiungere"} className="categoriaDaAggiungere">
                    <IonItem>
                      <IonThumbnail slot="start">
                        <img src={item.immagineUrl} alt="Immagine" />
                      </IonThumbnail>
                      <IonLabel>
                        {item.nome}
                        <p>Prodotti  {item?.prodotti?.length}</p>
                      </IonLabel>
                      {!verify ?

                        <IonButton color={"primary"} slot="end" onClick={() => handleAggiungiCategoria(item)}>
                          <IonIcon icon={add} />
                        </IonButton>
                        :

                        <IonButton color={"success"} slot="end" onClick={() => presentToastType("Categoria già aggiunta.", "success", () => { })}>
                          <IonIcon icon={checkmark} />
                        </IonButton>
                      }

                    </IonItem>
                  </div>
                )
              })
            }
          </IonList>

        </IonContent>
      </IonModal>

      {/*------------------- MODIFICA CATEGORIE ------------------*/}
      <IonModal isOpen={isModalAddCategorieModificheOpen} onDidDismiss={() => { setIsModalAddCategorieModificheOpen(false) }} >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={() => setIsModalAddCategorieModificheOpen(false)}>Chiudi</IonButton>
            </IonButtons>
            <IonTitle>Seleziona categorie</IonTitle>
            <IonButtons slot="end">
              <IonButton color={"success"} fill='solid' onClick={() => setIsModalAddCategorieModificheOpen(false)}>Conferma</IonButton>
            </IonButtons>
          </IonToolbar>
          <IonToolbar>
            <IonSearchbar value={searchTextCategorieModale} placeholder={"Cerca per nome"} onIonChange={e => { setSearchTextCategorieModale(e.detail.value!); handleSearchCategoria(e.detail.value!) }}></IonSearchbar>

          </IonToolbar>
        </IonHeader>
        <IonContent>

          <div className='ion-padding'>
            <IonLabel>
              <p>Categorie Aggiunte:</p>
            </IonLabel>
            {inputCategorieModifiche?.map((categoria: Categoria, index: number) => {
              return (
                <IonChip key={"categoriaSelezionate" + index} color={"primary"}>
                  {categoria.nome}
                  <IonIcon color='medium' icon={closeCircle} onClick={() => { handleRimuoviCategoriaModifiche(index) }} />
                </IonChip>
              )
            })}
          </div>

          <IonList>
            {
              categorie?.map((item: Categoria, index: number) => {
                let verify = inputCategorieModifiche.includes(item);

                return (
                  <div key={index + "categoriaDaAggiungere"} className="categoriaDaAggiungere">
                    <IonItem>
                      <IonThumbnail slot="start">
                        <img src={item.immagineUrl} alt="Immagine" />
                      </IonThumbnail>
                      <IonLabel>
                        {item.nome}
                        <p>Prodotti  {item?.prodotti?.length}</p>
                      </IonLabel>
                      {
                        !verify ?
                          <IonButton color={"primary"} slot="end" onClick={() => handleAggiungiCategoriaModifiche(item)}>
                            <IonIcon icon={add} />
                          </IonButton>
                          :
                          <IonButton color={"success"} slot="end" onClick={() => presentToastType("Categoria già aggiunta.", "success", () => { })}>
                            <IonIcon icon={checkmark} />
                          </IonButton>
                      }

                    </IonItem>
                  </div>
                )
              })
            }
          </IonList>

        </IonContent>
      </IonModal>

      {/* ------------------ MODAL AGGIUNGI -------------------- */}
      <IonModal isOpen={isModalOpen} onDidDismiss={() => { setIsModalOpen(false); }} >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton color={"medium"} onClick={() => { setIsModalOpen(false); resetAllDataInput() }}>Annulla</IonButton>
            </IonButtons>
            <IonTitle>Aggiungi sezione</IonTitle>
            <IonButtons slot="end">
              <IonButton color={"success"} fill="solid" onClick={() => handleSalvaSezione()}>Salva</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>

          <IonSegment value={segmentValue}>
            <IonSegmentButton
              value="Carica"
              onClick={() => handleSelectSegment("Carica")}
            >
              <IonLabel>Carica Immagine</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton
              value="Seleziona"
              onClick={() => handleSelectSegment("Seleziona")}
            >
              <IonLabel>Seleziona Immagine</IonLabel>
            </IonSegmentButton>
          </IonSegment>

          {segmentValue === "Carica" ? (
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>
                  Seleziona immagine
                </IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                <UploadSingleImage setImage={setInputImmagine} />
              </IonCardContent>
            </IonCard>
          ) : (
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>Seleziona immagine</IonCardTitle>
                <IonCardSubtitle>
                  Se non selezioni nessuna immagine sarà utilizzato il logo di
                  MenuShare
                </IonCardSubtitle>
              </IonCardHeader>
              <IonCardContent>
                <IonButton
                  color={
                    immagineSelezionata !== undefined ? "success" : "primary"
                  }
                  expand="block"
                  onClick={() => setIsModaleImmaginiOpen(true)}
                >
                  <IonIcon icon={imagesOutline} className="ion-margin-end" />
                  Seleziona
                </IonButton>
                {immagineSelezionata !== undefined ? (
                  <IonImg src={immagineSelezionata}></IonImg>
                ) : null}
              </IonCardContent>
            </IonCard>
          )}


          <div className='ion-padding windowDubleHeight'>
            <IonList>
              <IonItem fill="outline">
                <IonLabel position="stacked">Inserisci nome</IonLabel>
                <IonInput onIonInput={(ev: any) => { setInputNome(ev.target.value) }} clearInput placeholder="Obbligatorio"></IonInput>
              </IonItem>

              <IonItem fill="outline">
                <IonLabel position="stacked">Inserisci breve descrizione</IonLabel>
                <IonInput onIonInput={(ev: any) => { setInputDescrizione(ev.target.value) }} clearInput placeholder="Obbligatorio"></IonInput>
              </IonItem>

            </IonList>


            <IonLabel >
              <p className='ion-padding-top'>Clicca per selezionare le attività a cui aggiungere automaticamente la sezione: </p>
              {
                attivita?.map((att: any, index: number) => {
                  let verify = arrAutomaticAdd.includes(att.id);
                  return (
                    <IonChip color={verify ? "success" : "primary"} key={"attivitàchip" + index} onClick={() => { handleAutomaticAdd(att.id, verify, index) }}>
                      {att.nome}
                      {verify ?
                        <IonIcon icon={checkmark} className='ion-margin-start' />
                        : null
                      }
                    </IonChip>
                  )
                })
              }
            </IonLabel>


            <IonCard className='ion-margin-top'>
              <IonCardHeader>
                <IonCardTitle>
                  Seleziona categorie
                </IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                <IonButton expand='block' onClick={() => setIsModalAddCategorieOpen(true)}>
                  <IonIcon icon={add} />
                  Aggiungi categorie
                </IonButton>

                <IonLabel>
                  <p>Categorie selezionate : {inputCategorie?.length}</p>
                </IonLabel>
                <IonList>
                  {inputCategorie?.map((categoria: Categoria, index: number) => {
                    return (
                      <IonItem key={"ProdottoSelezionato" + index}>
                        <IonThumbnail slot="start">
                          <img src={categoria.immagineUrl} alt="Immagine" />
                        </IonThumbnail>
                        <IonLabel>
                          {categoria.nome}
                          <p> Prodotti : {categoria.prodotti.length}</p>
                        </IonLabel>

                        <IonButton color={"danger"} slot="end"
                          onClick={() => handleRimuoviCategoria(index)}
                        >
                          <IonIcon icon={closeOutline} />
                        </IonButton>
                      </IonItem>
                    )
                  })}
                </IonList>
              </IonCardContent>
            </IonCard>
          </div>




        </IonContent>
      </IonModal>

      {/* ------------------ MODAL MODIFICA -------------------- */}
      <IonModal isOpen={isModalModificheOpen} onDidDismiss={() => { setIsModalModificheOpen(false); }} >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton color={"medium"} onClick={() => { setIsModalModificheOpen(false); resetAllDataInput() }}>Annulla</IonButton>
            </IonButtons>
            <IonTitle>Modifica sezione</IonTitle>
            <IonButtons slot="end">
              <IonButton color={"success"} fill="solid" onClick={() => handleSalvaModifiche()}>Salva</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>



          <IonSegment value={segmentValue}>
            <IonSegmentButton
              value="Carica"
              onClick={() => handleSelectSegment("Carica")}
            >
              <IonLabel>Carica Immagine</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton
              value="Seleziona"
              onClick={() => handleSelectSegment("Seleziona")}
            >
              <IonLabel>Seleziona Immagine</IonLabel>
            </IonSegmentButton>
          </IonSegment>

          {segmentValue === "Carica" ? (
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>
                  Seleziona immagine
                </IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                <UploadSingleImage setImage={setInputImmagineModifiche} immagine={inputImmagineModifiche} />
              </IonCardContent>
            </IonCard>
          ) : (
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>Seleziona immagine</IonCardTitle>
                <IonCardSubtitle>
                  Se non selezioni nessuna immagine sarà utilizzato il logo di
                  MenuShare
                </IonCardSubtitle>
              </IonCardHeader>
              <IonCardContent>
                <IonButton
                  color={
                    immagineSelezionata !== undefined ? "success" : "primary"
                  }
                  expand="block"
                  onClick={() => setIsModaleImmaginiOpen(true)}
                >
                  <IonIcon icon={imagesOutline} className="ion-margin-end" />
                  Seleziona
                </IonButton>
                {immagineSelezionata !== undefined ? (
                  <IonImg src={immagineSelezionata}></IonImg>
                ) : null}
              </IonCardContent>
            </IonCard>
          )}


          <div className='ion-padding windowDubleHeight'>
            <IonList>
              <IonItem fill="outline">
                <IonLabel position="stacked">Inserisci nome</IonLabel>
                <IonInput value={inputNomeModifiche} onIonInput={(ev: any) => { setInputNomeModifiche(ev.target.value) }} clearInput placeholder="Obbligatorio"></IonInput>
              </IonItem>

              <IonItem fill="outline">
                <IonLabel position="stacked">Inserisci breve descrizione</IonLabel>
                <IonInput value={inputDescrizioneModifiche} onIonInput={(ev: any) => { setInputDescrizioneModifiche(ev.target.value) }} clearInput placeholder="Obbligatorio"></IonInput>
              </IonItem>

            </IonList>

            <IonCard>
              <IonCardHeader>
                <IonCardTitle>
                  Seleziona categorie
                </IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                <IonButton expand='block' onClick={() => setIsModalAddCategorieModificheOpen(true)}>
                  <IonIcon icon={add} />
                  Aggiungi categorie
                </IonButton>

                <IonLabel>
                  <p>Categorie selezionate : {inputCategorieModifiche?.length}</p>
                </IonLabel>
                <IonList>
                  {inputCategorieModifiche?.map((categoria: Categoria, index: number) => {
                    return (
                      <IonItem key={"categoriaSelezionata" + index}>
                        <IonThumbnail slot="start">
                          <img src={categoria.immagineUrl} alt="Immagine" />
                        </IonThumbnail>
                        <IonLabel>
                          {categoria.nome}
                          <p> Prodotti : {categoria.prodotti.length}</p>
                        </IonLabel>

                        <IonButton color={"danger"} slot="end"
                          onClick={() => handleRimuoviCategoriaModifiche(index)}
                        >
                          <IonIcon icon={closeOutline} />
                        </IonButton>
                      </IonItem>
                    )
                  })}
                </IonList>
              </IonCardContent>
            </IonCard>
          </div>

        </IonContent>
      </IonModal>


      {/*---------------------------------- MODALE IMMAGINI --------------------------------------------------*/}
      <IonModal
        isOpen={isModaleImmaginiOpen}
        onDidDismiss={() => {
          setIsModaleImmaginiOpen(false);
        }}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton
                color={"medium"}
                onClick={() => {
                  setImmagineSelezionata(undefined);
                  setIsModaleImmaginiOpen(false);
                }}
              >
                Annulla
              </IonButton>
            </IonButtons>
            <IonTitle>Immagini </IonTitle>
            <IonButtons slot="end">
              <IonButton
                fill="solid"
                color={"success"}
                onClick={() => setIsModaleImmaginiOpen(false)}
              >
                Conferma
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div>
            <IonLabel>
              <p className="ion-padding">
                Filtro visualizzazione:
                <IonChip
                  color={
                    filtroVisualizzazioneImmagini === "column-portfolio-1"
                      ? "primary"
                      : undefined
                  }
                  onClick={() =>
                    setFiltroVisualizzazioneImmagini("column-portfolio-1")
                  }
                >
                  1/1
                </IonChip>
                <IonChip
                  color={
                    filtroVisualizzazioneImmagini === "column-portfolio-2"
                      ? "primary"
                      : undefined
                  }
                  onClick={() =>
                    setFiltroVisualizzazioneImmagini("column-portfolio-2")
                  }
                >
                  1/2
                </IonChip>
                <IonChip
                  color={
                    filtroVisualizzazioneImmagini === "column-portfolio-3"
                      ? "primary"
                      : undefined
                  }
                  onClick={() =>
                    setFiltroVisualizzazioneImmagini("column-portfolio-3")
                  }
                >
                  1/3
                </IonChip>
                <IonChip
                  color={
                    filtroVisualizzazioneImmagini === "column-portfolio-4"
                      ? "primary"
                      : undefined
                  }
                  onClick={() =>
                    setFiltroVisualizzazioneImmagini("column-portfolio-4")
                  }
                >
                  1/4
                </IonChip>
              </p>
            </IonLabel>
            <IonLabel>
              <p className="ion-padding-start ion-padding-end">
                Cartelle:{" "}
                {defaultFolders?.map((cartella: string, index: number) => {
                  return (
                    <IonChip
                      key={"cartelleIngredientidefaultFolders" + index}
                      color={
                        filtroCartelle === cartella ? "primary" : undefined
                      }
                      onClick={() => {
                        setFiltroCartelle(cartella);
                        handleFiltraImmagini(cartella);
                      }}
                    >
                      {cartella}
                    </IonChip>
                  );
                })}
                {cartelle?.allCartelleImmagini?.map(
                  (cartella: string, index: number) => {
                    return (
                      <IonChip
                        key={"cartelleIngredienti" + index}
                        color={
                          filtroCartelle === cartella ? "primary" : undefined
                        }
                        onClick={() => {
                          setFiltroCartelle(cartella);
                          handleFiltraImmagini(cartella);
                        }}
                      >
                        {cartella}
                      </IonChip>
                    );
                  }
                )}
              </p>
            </IonLabel>
          </div>

          <div className="row-portfolio">
            {AllImmagini?.map((immagineObj: any, index: number) => {
              return (
                <IonCard
                  color={
                    immagineSelezionata === immagineObj.urlImmagine
                      ? "primary"
                      : undefined
                  }
                  key={"immagine-" + index}
                  className={
                    "immaginiGalleria " + filtroVisualizzazioneImmagini
                  }
                  onClick={() =>
                    setImmagineSelezionata(immagineObj.urlImmagine)
                  }
                >
                  <IonCardHeader>
                    <IonCardSubtitle>{immagineObj.cartella}</IonCardSubtitle>
                  </IonCardHeader>
                  <IonImg src={immagineObj.urlImmagine} />
                </IonCard>
              );
            })}
          </div>
        </IonContent>
      </IonModal>
    </div>
  );
};

export default InventarioColumn_Sezioni;
