import { IonBadge, IonIcon, IonItem, IonLabel, IonThumbnail } from '@ionic/react';
import { personCircleOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { url_app_tavolo_ordini_init } from '../Urls';
import './TableComponent.css';

interface ContainerProps {
  tableData: any,
  index: number,
  idAttivita: string,
}

const TableComponent: React.FC<ContainerProps> = ({ tableData, index, idAttivita }) => {
  //VARIABILI -----------------------

  const [ordiniInAttesa, setOrdiniInAttesa] = useState<number>(0);
  const [ordiniInCorso, setOrdiniInCorso] = useState<number>(0);
  const [ordiniConsegnati, setOrdiniConsegnati] = useState<number>(0);
  const [richiestaConto, setRichiestaConto] = useState<number>(0);

  const [oraAttivazione, setOraAttivazione] = useState<any>();

  //CONDIZIONI ----------------------
  useEffect(() => {
    let inviato = 0;
    let prepinCorso = 0;
    let consegnato = 0;
    tableData.data().orders?.map((ordine: any) => {
      // console.log(ordine);
      if (ordine.status === "Inviato") inviato++;
      if (ordine.status === "Preparazione in corso") prepinCorso++;
      if (ordine.status === "Consegnato") consegnato++;
    })
    setOrdiniInAttesa(inviato);
    setOrdiniInCorso(prepinCorso);
    setOrdiniConsegnati(consegnato);
    let ora = new Date(tableData.data()?.riservatedDate);
    setOraAttivazione(ora.getUTCHours() + ":" + ora.getUTCMinutes() + ":" + ora.getUTCSeconds())
  }, [tableData])
  //RETURN -------------------------- 
  return (
    <IonItem color={"light"} className='tableComponent-item' lines="full" routerLink={url_app_tavolo_ordini_init + index + "/" + idAttivita} routerDirection="forward">
      <IonThumbnail slot="start" style={{width:70}}>

        {tableData.data()?.company ? <p>{tableData.data()?.company}</p> : <h1>{tableData.id}</h1>}

        <div className='tableComponent-flex tableComponent-users '>
          <IonBadge color={"secondary"}>
            <IonIcon icon={personCircleOutline} className="ion-margin-end"></IonIcon>
            {tableData.data().usersConnected ? tableData.data().usersConnected.length : "0"}
          </IonBadge>
        </div>
      </IonThumbnail>
      <IonLabel>
        <div className='tableComponent-flex ion-margin-bottom'>
          <p>Attivo da: </p>
          <IonBadge className='ion-margin-start' color={tableData.data()?.riservatedDate ? "primary" : "dark"}>{tableData.data()?.riservatedDate ? oraAttivazione : "Nessun accesso"}</IonBadge>
        </div>
        <div className='tableComponent-flex tabtableComponent-margin-top'>
          <IonBadge color={"warning"} className="ion-margin-end">
            {ordiniInAttesa}
          </IonBadge>
          <p>ordini in attesa</p>
        </div>
        <div className='tableComponent-flex tableComponent-margin-top'>
          <IonBadge color={"primary"} className="ion-margin-end">
            {ordiniInCorso}
          </IonBadge>
          <p>ordini in corso</p>
        </div>
        <div className='tableComponent-flex tableComponent-margin-top'>
          <IonBadge color={"success"} className="ion-margin-end">
            {ordiniConsegnati}
          </IonBadge>
          <p>ordini consegnati</p>
        </div>
        <div className='tableComponent-flex tableComponent-margin-top'>
          <IonBadge color={"danger"} className="ion-margin-end">
            {richiestaConto}
          </IonBadge>
          <p>richieste conto</p>
        </div>

      </IonLabel>
    </IonItem>
  );
};

export default TableComponent;
