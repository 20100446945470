import {
  IonBadge,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonToolbar,
} from "@ionic/react";
import { chevronForward, speedometerSharp } from "ionicons/icons";
import { useEffect, useState } from "react";
import { url_app_attivita_init } from "../Urls";
import "./AttivitaPerformance.css";

interface ContainerProps {
  attivitaData: any;
  indexAttivita?: number;
  onlyInfo?: boolean;
}

const AttivitaPerformance: React.FC<ContainerProps> = ({
  attivitaData,
  indexAttivita,
  onlyInfo = false,
}) => {
  //VARIABILI --------------------------------------------

  const [attivitaPerformance, setAttivitaPerformance] = useState(0);
  const [attivitaPerformanceSTR, setAttivitaPerformanceSTR] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [contatoreChisiamo, setContatoreChisiamo] = useState(0);

  //CONDIZIONI -------------------------------------------

  useEffect(() => {
    console.log("attivitaData : UPDATED",)
    let auxAttivitaprodottiConsigliati = 0;
    let auxcategorieSelezionate = 0;
    let auxsezioniSelezionate = 0;
    let auxportfolioImmagini = 0;
    let auxcomunicazioni = 0;
    let auxpersonale = 0;
    let auxchiSiamo = 0;

    auxAttivitaprodottiConsigliati = attivitaData?.prodottiConsigliati?.length;
    if (attivitaData?.categorie?.length > 0) auxcategorieSelezionate = 1;
    if (attivitaData?.sezioni?.length > 0) auxsezioniSelezionate = 1;
    if (attivitaData?.portfolio?.length > 0) auxportfolioImmagini = 1;
    if (attivitaData?.comunicazioni?.length > 0) auxcomunicazioni = 1;
    if (attivitaData?.personale?.length > 0) auxpersonale = 1;

    if (attivitaData?.about?.immaginiUrl?.length > 0) auxchiSiamo++;
    if (attivitaData?.about?.descrizione?.length > 1) auxchiSiamo++;
    if (attivitaData?.about?.telefono?.length > 0) auxchiSiamo++;
    if (attivitaData?.about?.sitoWeb?.length > 1) auxchiSiamo++;

    let perf =
      (100 / (5 + 5 + 4)) *
      (auxAttivitaprodottiConsigliati +
        auxcategorieSelezionate +
        auxsezioniSelezionate +
        auxportfolioImmagini +
        auxcomunicazioni +
        auxpersonale +
        auxchiSiamo);

    setContatoreChisiamo(auxchiSiamo)
    
    let str = perf.toString().split(".");
    let strr;
    if(str[0]?.length === 1){
      strr = "0"+str[0]
    }else{
      strr = str[0]
    }
    if(str[1]?.length > 1){
      strr = strr +"."+str[1].slice(0,2);
    }
    setAttivitaPerformanceSTR(strr);
    
    setAttivitaPerformance(perf);

  }, [attivitaData, attivitaData?.prodottiConsigliati, attivitaData?.about]);


  //RETURN -----------------------------------------------
  return (
    <>
      <div className={onlyInfo? "AttivitaPerformance-box-fWidth" : "AttivitaPerformance-box"}>
        <div className="AttivitaPerformance-box-title">
          <h2>{attivitaData?.nome}</h2>
        </div>
        <div className="AttivitaPerformance-box-content">
          <div className="AttivitaPerformance-box-content-alerts">
            <p className="AttivitaPerformance-box-content-subtitle">
              {" "}
              Performance
            </p>
            <IonButton
              color={attivitaPerformance > 80 ? "success" : "warning"}
              onClick={() => setIsModalOpen(true)}
            >
              <IonIcon icon={speedometerSharp} className="ion-margin-end" />
              {attivitaPerformanceSTR} %
            </IonButton>
          </div>
        </div>
      </div>

      {/* Modale performance */}
      <IonModal
        isOpen={isModalOpen}
        onDidDismiss={() => {
          setIsModalOpen(false);
        }}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton color={"medium"} onClick={() => setIsModalOpen(false)}>
                Chiudi
              </IonButton>
            </IonButtons>
            
            <IonButtons slot="end">
              {!onlyInfo ? (
                <IonButton
                  color={"success"}
                  routerLink={url_app_attivita_init + attivitaData?.nome + "-" + indexAttivita}
                  onClick={() => setIsModalOpen(false)}
                >
                  Pagina attività
                  <IonIcon icon={chevronForward} />
                </IonButton>
              ) : null}
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonButton
            expand="block"
            color={attivitaPerformance > 80 ? "success" : "warning"}
            className="ion-margin-bottom"
          >
            <IonIcon icon={speedometerSharp} className="ion-margin-end" />
            {attivitaPerformanceSTR} %
          </IonButton>

          <IonList>
            {/* PRODOTTI SELEZIONATI */}
            <IonItem>
              <IonBadge
                color={
                  attivitaData?.prodottiConsigliati?.length < 5
                    ? "danger"
                    : "success"
                }
                slot="start"
              >
                {attivitaData?.prodottiConsigliati?.length + "/" + 5}
              </IonBadge>
              <IonLabel className="ion-text-wrap">
                <h3>Prodotti consigliati selezionati</h3>
                <p>
                  {attivitaData?.prodottiConsigliati?.length < 5
                    ? "Puoi selezionarne ancora " +
                      (5 - attivitaData?.prodottiConsigliati?.length)
                    : "Hai selezionato tutti i prodotti"}
                </p>
              </IonLabel>
            </IonItem>

            {/* CATEGORIE */}
            <IonItem>
              <IonBadge
                color={
                  attivitaData?.categorie?.length > 0
                    ? "success"
                    : "danger"
                }
                slot="start"
              >
                 {attivitaData?.categorie?.length < 10
                  ? "0" + attivitaData?.categorie?.length
                  : attivitaData?.categorie?.length}
                
              </IonBadge>
              <IonLabel className="ion-text-wrap">
                <h3>Categorie aggiunte</h3>
                <p>
                  Aggiungi più categorie possibili
                </p>
              </IonLabel>
            </IonItem>

            {/* SEZIONI MENU */}
            <IonItem>
              <IonBadge
                color={
                  attivitaData?.sezioni?.length > 0
                    ? "success"
                    : "danger"
                }
                slot="start"
              >
                 {attivitaData?.sezioni?.length < 10
                  ? "0" + attivitaData?.sezioni?.length
                  : attivitaData?.sezioni?.length}
                
              </IonBadge>
              <IonLabel className="ion-text-wrap">
                <h3>Sezioni aggiunte</h3>
                <p>
                  Aggiungi più sezioni possibili, saranno visibili nella home
                </p>
              </IonLabel>
            </IonItem>

            {/* PORTFOLIO IMMAGINI */}
            <IonItem>
              <IonBadge
                color={
                  attivitaData?.portfolio?.length > 0
                    ? "success"
                    : "danger"
                }
                slot="start"
              >
                 {attivitaData?.portfolio?.length < 10
                  ? "0" + attivitaData?.portfolio?.length
                  : attivitaData?.portfolio?.length}
                
              </IonBadge>
              <IonLabel className="ion-text-wrap">
                <h3>Immagini nel portfolio</h3>
                <p>
                  Aggiungi immagini dell'attività a piacimento
                </p>
              </IonLabel>
            </IonItem>

            {/* COMUNICAZIONI */}
            <IonItem>
              <IonBadge
                color={
                  attivitaData?.comunicazioni?.length > 0
                    ? "success"
                    : "danger"
                }
                slot="start"
              >
                 {attivitaData?.comunicazioni?.length < 10
                  ? "0" + attivitaData?.comunicazioni?.length
                  : attivitaData?.comunicazioni?.length}
                
              </IonBadge>
              <IonLabel className="ion-text-wrap">
                <h3>Comunicazioni aggiunte</h3>
                <p>
                  Aggiungi comunicazioni, per aggiornare o informare i clienti
                </p>
              </IonLabel>
            </IonItem>

             {/* PERSONALE */}
             <IonItem>
              <IonBadge
                color={
                  attivitaData?.personale?.length > 0
                    ? "success"
                    : "danger"
                }
                slot="start"
              >
                 {attivitaData?.personale?.length < 10
                  ? "0" + attivitaData?.personale?.length
                  : attivitaData?.personale?.length}
                
              </IonBadge>
              <IonLabel className="ion-text-wrap">
                <h3>Personale aggiunto</h3>
                <p>
                  Aggiungi il personale per creare il legame cliente/personale
                </p>
              </IonLabel>
            </IonItem>

              {/* CHISIAMO */}
              <IonItem>
              <IonBadge
                color={
                  contatoreChisiamo == 4
                    ? "success"
                    : "danger"
                }
                slot="start"
              >
                 {contatoreChisiamo + "/" + 4}
                
              </IonBadge>
              <IonLabel className="ion-text-wrap">
                <h3>Informazioni attività</h3>
                <p>
                  Più informazioni ci sono più è presentabile l'attività
                </p>
              </IonLabel>
            </IonItem>



          </IonList>
        </IonContent>
      </IonModal>
    </>
  );
};

export default AttivitaPerformance;
