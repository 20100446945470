import { IonAvatar, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonChip, IonContent, IonHeader, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonList, IonModal, IonSearchbar, IonSegment, IonSegmentButton, IonTextarea, IonTitle, IonToolbar, useIonAlert, useIonLoading, useIonToast } from '@ionic/react';
import './InventarioColumn.css';
import { add, alertCircleOutline, close, closeCircle, cloudDoneOutline, copyOutline, imagesOutline, pencil, trash } from 'ionicons/icons';
import { DataDownLoadTags, defaultFolders } from '../Logic/DataDownload';
import { useEffect, useRef, useState } from 'react';
import { LocalSaveProdotti, LocalSaveTags } from '../Logic/LocalStorage';
import { DeleteDataProdotto, UploadDataProdotto, UploadDataTags } from '../Logic/DataUpload';
import { DeleteImages, UploadImagesFirebase } from '../Logic/ImageUpload';
import { Ingrediente, Prodotto, logoMenuShare } from '../data/dataSheet';
import UploadMultipleImages from './UploadMultipleImages';
import ModaleModificaIngredienti from './Modale_Ingredienti/ModaleModificaIngredienti';

interface ContainerProps {
  ProdottiDb: any;
  IngredientiDb: any;
  TagsDb: any;
  UpdateAll: any;
  allImmagini: any;
  cartelle: any;
  handleAggiornaTutteLeCategorie: (data: any) => void;
}

const InventarioColumn_Prodotti: React.FC<ContainerProps> = ({
  ProdottiDb,
  IngredientiDb,
  TagsDb,
  UpdateAll,
  allImmagini,
  cartelle,
  handleAggiornaTutteLeCategorie,
}) => {
  //VARIABILI --------------------------------------------
  const folder = "prodotti";

  const [allProdotti, setAllProdotti] = useState<any>([]);
  const [auxAllProdotti, setAuxAllProdotti] = useState<any>([]);
  const [allIngredienti, setAllIngredienti] = useState<any>([]);
  const [allTags, setAllTags] = useState<any>([]);

  const [presentAlert] = useIonAlert();
  const [searchTextModale, setSearchTextModale] = useState("");
  const [searchTextIngredienteModale, setSearchTextIngredienteModale] =
    useState("");
  const [searchTextTagModale, setSearchTextTagModale] = useState("");

  // Tags
  const [modalTagsOpen, setModalTagsOpen] = useState(false);
  const [modalTagsModificheOpen, setModalTagsModificheOpen] =
    useState(false);
  const [presentAlertTag] = useIonAlert();

  // Ingredienti
  const [modalIngredientiOpen, setModalIngredientiOpen] = useState(false);
  const [modalIngredientiModificaOpen, setModalIngredientiModificaOpen] =
    useState(false);

  // Aggiungi
  const [isModalAddOpen, setIsModalAddOpen] = useState<boolean>(false);

  const [id, setId] = useState<number>();
  const [inputNome, setInputNome] = useState("Non specificato.");
  const [inputPrezzo, setInputPrezzo] = useState("Non specificato.");
  const [inputDescrizione, setInputDescrizione] =
    useState("Non specificato.");
  const [inputIngredienti, setInputIngredienti] = useState([]);
  const [inputTags, setInputTags] = useState([]);
  const [inputImmagini, setInputImmagini] = useState([]);

  // Modifica
  const [indiceModifica, setIndiceModifica] = useState<number>();
  const [isModalModificheAddOpen, setIsModalModificheAddOpen] =
    useState<boolean>(false);

  const [idModifiche, setIdModifiche] = useState();
  const [inputNomeModifiche, setInputNomeModifiche] =
    useState("Non specificato.");
  const [inputPrezzoModifiche, setInputPrezzoModifiche] =
    useState("Non specificato.");
  const [inputDescrizioneModifiche, setInputDescrizioneModifiche] =
    useState("Non specificato.");
  const [inputIngredientiModifiche, setInputIngredientiModifiche] =
    useState([]);
  const [inputTagsModifiche, setInputTagsModifiche] = useState([]);
  const [inputImmaginiModifiche, setInputImmaginiModifiche] = useState(
    []
  );

  // Link immagini
  const [linkImmagini, setLinkImmagini] = useState([]);
  const [linkImmaginiModifiche, setLinkImmaginiModifiche] = useState([]);

  const [present, dimiss] = useIonLoading();

  const [allImagesUploaded, setAllImagesUploaded] = useState(false);
  const [allImagesModificheUploaded, setAllImagesModificheUploaded] =
    useState(false);

  const [presentToast] = useIonToast();
  const scrollRef = useRef<HTMLIonContentElement | null>(null);

  // Duplica
  const [isModaleDuplicaOpen, setIsModaleDuplicaOpen] = useState(false);

  const [segmentValue, setSegmentValue] = useState("Seleziona");
  const [immaginiSelezionate, setImmaginiSelezionate] = useState<any>([]);

  const [isModaleImmaginiOpen, setIsModaleImmaginiOpen] = useState(false);
  const [filtroCartelle, setFiltroCartelle] = useState<any>("");
  const [filtroVisualizzazioneImmagini, setFiltroVisualizzazioneImmagini] =
    useState<any>("column-portfolio-4");

  //CONDIZIONI --------------------
  const generateItems = () => {
    console.log("generateItems");
    const newItems = [];
    for (let index = auxAllProdotti.length; index < (auxAllProdotti.length + 30); index++) {
      if (allProdotti.length > index) {
        newItems.push(allProdotti[index]);
      } else {
        console.log("tutti i prodotti caricati")
      }
    }
    setAuxAllProdotti([...auxAllProdotti, ...newItems]);
  }

  const resetGenerateItems = () => {
    console.log("generateItems");
    const newItems = [];
    for (let index = 0; index < (30); index++) {
      if (allProdotti.length > index) {
        newItems.push(allProdotti[index]);
      } else {
        console.log("tutti i prodotti caricati")
      }
    }
    setAuxAllProdotti([...newItems]);
  }

  useEffect(() => {
    generateItems()
  }, [])


  const handleSelectSegment = (segmentValue: string) => {
    setSegmentValue(segmentValue);
    //reset degli input di entrambi quando si cambia
  };

  const handleAddImmagine = (urlImmagine: string) => {
    setImmaginiSelezionate(immaginiSelezionate.concat(urlImmagine));
  };

  const handleRemoveImmagine = (url: number) => {
    setImmaginiSelezionate((elemn: any) =>
      immaginiSelezionate.filter((_: any, i: any) => _ !== url)
    );
  };

  const handleFiltraImmagini = (testoCercato: string) => {
    let arr = Array.from(
      window.document.getElementsByClassName("immaginiGalleria")
    );
    const query = testoCercato.toLowerCase();
    requestAnimationFrame(() => {
      arr.forEach((item: any) => {
        const shouldShow = item.textContent.toLowerCase().indexOf(query) > -1;
        item.style.display = shouldShow ? "block" : "none";
      });
    });
  };

  const presentToastType = (
    message: string,
    type: "danger" | "success" | "warning",
    buttonEv: () => void
  ) => {
    const iconType = {
      danger: close,
      success: cloudDoneOutline,
      warning: alertCircleOutline,
    };

    presentToast({
      message: message,
      position: "top",
      duration: 3000,
      icon: iconType[type],
      color: type,
      buttons: [
        {
          text: "Chiudi",
          role: "cancel",
          handler: () => {
            buttonEv();
          },
        },
      ],
    });
  };

  const salvaNuovoProdotto = async () => {
    if (inputNome !== "Non specificato.") {
      present({
        message: "Sto salvando il prodotto...",
      });
      setIsModalAddOpen(false);
      let auxId = Date.now();
      setId(auxId);
      //Carica immagine e poi si salva tramite il useEffect
      if (immaginiSelezionate.length > 0) {
        const oggettoProdotto = {
          id: auxId,
          nome: inputNome,
          prezzo: inputPrezzo,
          descrizione: inputDescrizione,
          ingredienti: inputIngredienti,
          tags: inputTags,
          immaginiUrl: immaginiSelezionate,
        };
        salvaAllData(oggettoProdotto);
      } else {
        if (inputImmagini.length === 0) {
          const oggettoProdotto = {
            id: auxId,
            nome: inputNome,
            prezzo: inputPrezzo,
            descrizione: inputDescrizione,
            ingredienti: inputIngredienti,
            tags: inputTags,
            immaginiUrl: [logoMenuShare],
          };
          salvaAllData(oggettoProdotto);
        } else {
          await UploadImagesFirebase(
            inputImmagini!,
            auxId,
            setLinkImmagini,
            folder,
            setAllImagesUploaded
          );
        }
      }
    } else {
      presentToastType("ATTENZIONE! Nome non inserito", "warning", () => { });
    }
  };
  useEffect(() => {
    if (allImagesUploaded === true) {
      console.log("allImagesUploaded === true :", allImagesUploaded === true);
      const oggettoProdotto = {
        id: id,
        nome: inputNome,
        prezzo: inputPrezzo,
        descrizione: inputDescrizione,
        ingredienti: inputIngredienti,
        tags: inputTags,
        immaginiUrl: linkImmagini,
      };
      salvaAllData(oggettoProdotto);
      setAllImagesUploaded(false);
    }
  }, [allImagesUploaded]);

  useEffect(() => {
    if (allImagesModificheUploaded === true) {
      console.log("allImagesUploaded === true :", allImagesUploaded === true);
      const oggettoProdotto = {
        id: idModifiche,
        nome: inputNomeModifiche,
        prezzo: inputPrezzoModifiche,
        descrizione: inputDescrizioneModifiche,
        ingredienti: inputIngredientiModifiche,
        tags: inputTagsModifiche,
        immaginiUrl: linkImmaginiModifiche,
      };
      uploadModifiche(oggettoProdotto);
      setAllImagesModificheUploaded(false);
    }
  }, [allImagesModificheUploaded]);

  const salvaAllData = async (oggettoProdotto: any) => {
    await UploadDataProdotto(oggettoProdotto);
    let a = [...allProdotti];
    // console.log("salvaAllData - a : ", a)

    a.push(oggettoProdotto);
    setAllProdotti(a);
    LocalSaveProdotti(a);

    UpdateAll();
    //Reset All data input
    resetAllDataInput();
    dimiss();
    presentToastType("Prodotto salvato", "success", () => { });
  };


  const handleModifica = (item: Prodotto) => {
    let auxProdotto: any;
    let auxIndex;
    allProdotti.forEach((prodotto: Prodotto, index: number) => {
      if (prodotto.id === item.id) {
        auxProdotto = prodotto;
        auxIndex = index;
      }
    });

    setIndiceModifica(auxIndex);
    setIsModalModificheAddOpen(true);

    setIdModifiche(auxProdotto.id);
    setInputNomeModifiche(auxProdotto.nome);
    setInputPrezzoModifiche(auxProdotto.prezzo);
    setInputDescrizioneModifiche(auxProdotto.descrizione);
    setInputIngredientiModifiche(auxProdotto.ingredienti);
    setInputTagsModifiche(auxProdotto.tags);
    setInputImmaginiModifiche(auxProdotto.immaginiUrl);
  };


  const handleSalvaModifiche = async () => {
    present({
      message: "Sto aggiornando il prodotto...",
    });
    setIsModalModificheAddOpen(false);

    if (immaginiSelezionate.length > 0) {
      let oggettoModificato = {
        id: idModifiche,
        nome: inputNomeModifiche,
        prezzo: inputPrezzoModifiche,
        descrizione: inputDescrizioneModifiche,
        ingredienti: inputIngredientiModifiche,
        tags: inputTagsModifiche,
        immaginiUrl: immaginiSelezionate,
      };
      await uploadModifiche(oggettoModificato);
    } else {
      let oggettoModificato;
      if (
        inputImmaginiModifiche[0] ===
        allProdotti[indiceModifica!].immaginiUrl[0]
      ) {
        oggettoModificato = {
          id: idModifiche,
          nome: inputNomeModifiche,
          prezzo: inputPrezzoModifiche,
          descrizione: inputDescrizioneModifiche,
          ingredienti: inputIngredientiModifiche,
          tags: inputTagsModifiche,
          immaginiUrl: inputImmaginiModifiche,
        };
        await uploadModifiche(oggettoModificato);
      } else {
        UploadImagesFirebase(
          inputImmaginiModifiche,
          idModifiche!,
          setLinkImmaginiModifiche,
          folder,
          setAllImagesModificheUploaded
        );
      }
    }
  };
  const uploadModifiche = async (oggettoModificato: any) => {
    let a = [...allProdotti];
    handleAggiornaTutteLeCategorie(oggettoModificato);
    a[indiceModifica!] = oggettoModificato;
    setAllProdotti(a);
    LocalSaveProdotti(a);
    await UploadDataProdotto(oggettoModificato);
    UpdateAll();
    resetAllDataInput();
    dimiss();
    presentToastType("Prodotto salvato", "success", () => { });
  };


  const handleCancella = async (item: any) => {
    let auxProdotto: any;
    let auxIndex: any;
    allProdotti.forEach((prodotto: Prodotto, index: number) => {
      if (prodotto.id === item.id) {
        auxProdotto = prodotto;
        auxIndex = index;
      }
    });

    let a = [...allProdotti];

    let idProdotto = a[auxIndex].id;
    // console.log(idProdotto);
    a.splice(auxIndex, 1);
    setAllProdotti(a);
    LocalSaveProdotti(a);

    await DeleteDataProdotto(idProdotto);
    setSearchTextModale("");
    // await DeleteImages(
    //   idProdotto,
    //   folder,
    //   allProdotti[auxIndex].immaginiUrl.length
    // );
    UpdateAll();
  };

  const handleSearch = (testoCercato: string) => {
    let arr: any = [];
    const query = testoCercato.toLowerCase();
    if (testoCercato.length === 0) {
      resetGenerateItems()
    } else {
      requestAnimationFrame(() => {
        allProdotti.forEach((item: Prodotto) => {
          const shouldShow = item.nome.toLowerCase().indexOf(query) > -1;
          if (shouldShow) arr.push(item);
        });
        setAuxAllProdotti([...arr]);
      });
    }
  };

  const handleSearchIngredienti = (testoCercato: string) => {
    let arr = Array.from(
      window.document.getElementsByClassName("ingredienteInventario")
    );
    const query = testoCercato.toLowerCase();
    requestAnimationFrame(() => {
      arr.forEach((item: any) => {
        const shouldShow = item.textContent.toLowerCase().indexOf(query) > -1;
        item.style.display = shouldShow ? "block" : "none";
      });
    });
  };
  const handleSearchTag = (testoCercato: string) => {
    let arr = Array.from(
      window.document.getElementsByClassName("tagInventario")
    );
    const query = testoCercato.toLowerCase();
    requestAnimationFrame(() => {
      arr.forEach((item: any) => {
        const shouldShow = item.textContent.toLowerCase().indexOf(query) > -1;
        item.style.display = shouldShow ? "block" : "none";
      });
    });
  };
  const handleRemoveFromDatabaseTag = async (index: number) => {
    let auxAllTags = [...allTags];
    auxAllTags = auxAllTags.filter((_, i) => i !== index);
    await UploadDataTags(auxAllTags);
    UpdateAll();
    setAllTags(auxAllTags);
    LocalSaveTags(auxAllTags);
  };
  const handleSaveNewTag = async (tag: any) => {
    console.log("salva", tag);
    let tags = allTags;
    if (tags === undefined) {
      tags = [tag];
    } else {
      tags.push(tag);
    }
    await UploadDataTags(tags);
    UpdateAll();
    setAllTags(tags);
    LocalSaveTags(tags);
  };
  const handleAggiungiTag = (tag: any) => {
    setInputTags(inputTags.concat(tag));
  };
  const handleRimuoviTag = (index: number) => {
    setInputTags((inputTags) => inputTags.filter((_, i) => i !== index));
  };
  const handleAggiungiIngrediente = (ingrediente: any) => {
    setInputIngredienti(inputIngredienti.concat(ingrediente));
  };
  const handleRimuoviIngrediente = (index: number) => {
    setInputIngredienti((inputIngredienti) =>
      inputIngredienti.filter((_, i) => i !== index)
    );
  };
  const handleAggiungiIngredienteModifiche = (ingrediente: any) => {
    setInputIngredientiModifiche(inputIngredientiModifiche.concat(ingrediente));
  };
  const handleRimuoviIngredienteModifiche = (index: number) => {
    setInputIngredientiModifiche((inputIngredientiModifiche) =>
      inputIngredientiModifiche.filter((_, i) => i !== index)
    );
  };
  const handleAggiungiTagModifiche = (tag: any) => {
    setInputTagsModifiche(inputTagsModifiche.concat(tag));
  };
  const handleRimuoviTagModifiche = (index: number) => {
    setInputTagsModifiche((inputTagsModifiche) =>
      inputTagsModifiche.filter((_, i) => i !== index)
    );
  };
  const resetAllDataInput = () => {
    console.log("Reset All data input");
    setId(undefined);
    setInputNome("Non specificato.");
    setInputPrezzo("Non specificato.");
    setInputDescrizione("Non specificato.");
    setInputIngredienti([]);
    setInputTags([]);
    setInputImmagini([]);
    setIndiceModifica(undefined);
    setIdModifiche(undefined);
    setInputNomeModifiche("Non specificato.");
    setInputPrezzoModifiche("Non specificato.");
    setInputDescrizioneModifiche("Non specificato.");
    setInputIngredientiModifiche([]);
    setInputTagsModifiche([]);
    setInputImmaginiModifiche([]);
    setImmaginiSelezionate([]);
    setFiltroCartelle("");
  };

  const getTags = async () => {
    let a: any = await DataDownLoadTags();
    // console.log("App - getTags - a: ", (a));
    return a?.tags ? a.tags : [];
  };

  const scrollToTop = () => {
    scrollRef.current && scrollRef.current.scrollToTop(500);
  };

  useState(async () => {
    setAllProdotti(ProdottiDb);
    setAllIngredienti(IngredientiDb);
    setAllTags(await getTags());
    resetAllDataInput();
  });

  const handleDuplica = (oggettoProdotto: any) => {
    console.log("oggettoProdotto : ", oggettoProdotto);
    setIsModaleDuplicaOpen(true);
    setInputNome(oggettoProdotto.nome);
    setInputPrezzo(oggettoProdotto.prezzo);
    setInputDescrizione(oggettoProdotto.descrizione);
    setInputIngredienti(oggettoProdotto.ingredienti);
    setInputTags(oggettoProdotto.tags);
    // setInputImmagini(oggettoProdotto.immaginiUrl);
  };

  //CONDIZIONI -------------------------------------------


  //RETURN -----------------------------------------------
  return (
    <div className="inventario-column-container">
      <IonToolbar>
        <IonTitle>
          Prodotti: {allProdotti.length}
        </IonTitle>
        <IonButtons slot='end'>
          <IonButton fill='solid' color={"primary"} onClick={() => setIsModalAddOpen(true)}>
            <IonIcon icon={add} />
            Aggiungi
          </IonButton>
        </IonButtons>
      </IonToolbar>
      <IonToolbar>
        <IonSearchbar
          value={searchTextModale}
          placeholder={"Cerca per nome"}
          onIonChange={(e) => {
            setSearchTextModale(e.detail.value!);
            handleSearch(e.detail.value!);
          }}
        ></IonSearchbar>
      </IonToolbar>
      <div className="inventario-content">
        <div className="inventario-column-items">
          <IonList>
            {auxAllProdotti?.map((item: Prodotto, index: number) => {
              return (
                <div
                  key={index + "prodottoInventario"}
                  className="prodottoInventario"
                >
                  <IonItem>
                    <IonAvatar slot="start">
                      <img src={item.immaginiUrl[0]} alt="Immagine" />
                    </IonAvatar>
                    <IonLabel>
                      {item.nome}
                      <p>€ {item.prezzo}</p>
                      <IonToolbar>
                        <IonButtons className='ion-justify-content-around'>
                          <IonButton
                            color={"secondary"}
                            slot="end"
                            fill='solid'
                            onClick={() => handleDuplica(item)}
                          >
                            <IonIcon icon={copyOutline} />
                          </IonButton>

                          <IonButton
                            color={"primary"}
                            slot="end"
                            fill='solid'
                            onClick={() => handleModifica(item)}
                          >
                            <IonIcon icon={pencil} />
                          </IonButton>

                          <IonButton
                            color={"danger"}
                            slot="end"
                            fill='solid'
                            onClick={() =>
                              presentAlert({
                                header: "Attenzione!",
                                message: "L'eliminazione è definitiva!",
                                buttons: [
                                  {
                                    text: "Annulla",
                                    role: "cancel",
                                  },
                                  {
                                    text: "Conferma",
                                    role: "confirm",
                                    handler: () => {
                                      handleCancella(item);
                                    },
                                  },
                                ],
                              })
                            }
                          >
                            <IonIcon icon={trash} />
                          </IonButton>
                        </IonButtons>
                      </IonToolbar>
                    </IonLabel>

                  </IonItem>
                </div>
              );
            })}
          </IonList>
        </div>
        {
          auxAllProdotti.length === allProdotti.length ?
            <IonButton expand='block' color={"success"}>
              Tutti i prodotti caricati
            </IonButton>
            :
            <IonButton expand='block' onClick={() => generateItems()}>
              Carica altri prodotti
            </IonButton>
        }
      </div>
      {/*---------------------------------- DUPLICA PRODOTTO MODALE --------------------------------------------------*/}
      <IonModal
        isOpen={isModaleDuplicaOpen}
        onDidDismiss={() => {
          setIsModaleDuplicaOpen(false);
        }}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton
                color={"medium"}
                onClick={() => {
                  resetAllDataInput();
                  setIsModaleDuplicaOpen(false);
                }}
              >
                Annulla
              </IonButton>
            </IonButtons>
            <IonTitle>Duplica prodotto</IonTitle>
            <IonButtons slot="end">
              <IonButton
                fill="solid"
                color={"success"}
                onClick={() => {
                  setSearchTextModale(inputNome);
                  salvaNuovoProdotto();
                  setIsModaleDuplicaOpen(false);
                }}
              >
                Duplica
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonCard>
            <IonCardHeader>
              <IonCardTitle>Seleziona immagini</IonCardTitle>
            </IonCardHeader>
            <p className="ion-margin">
              ATTENZIONE! Caricare nuove immagini o nuovamente le stesse. Per
              il momento la duplicazione funziona con i dati e non con le
              immagini.
            </p>
            <IonCardContent>
              <UploadMultipleImages
                setImage={setInputImmagini}
                immagini={inputImmagini}
              />
            </IonCardContent>
          </IonCard>

          <div className="ion-padding windowDubleHeight">
            <IonList>
              <IonItem fill="outline">
                <IonLabel position="stacked">Inserisci nome</IonLabel>
                <IonInput
                  value={inputNome}
                  onIonInput={(ev: any) => {
                    setInputNome(ev.target.value);
                  }}
                  clearInput
                  placeholder="Obbligatorio"
                ></IonInput>
              </IonItem>

              <IonLabel>
                <IonItem fill="outline">
                  <IonLabel position="stacked">Prezzo</IonLabel>
                  <IonInput
                    value={inputPrezzo}
                    onIonInput={(ev: any) => {
                      setInputPrezzo(ev.target.value);
                    }}
                    clearInput
                    placeholder="Facoltativo"
                  ></IonInput>
                </IonItem>
                <p className="ion-padding">
                  <IonIcon color="warning" icon={alertCircleOutline} />
                  Se non vuoi mettere il prezzo inserisci "Su richiesta." o
                  altro.
                </p>
              </IonLabel>

              <IonItem fill="outline">
                <IonLabel position="stacked">Inserisci descrizione</IonLabel>
                <IonTextarea
                  value={inputDescrizione}
                  placeholder="Facoltativo"
                  onIonInput={(ev: any) => {
                    setInputDescrizione(ev.target.value);
                  }}
                  autoGrow={true}
                />
              </IonItem>
            </IonList>
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>Ingredienti</IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                <IonButton
                  expand="block"
                  onClick={() => setModalIngredientiOpen(true)}
                >
                  <IonIcon icon={add} />
                  Seleziona ingredienti
                </IonButton>
                <IonLabel className="ion-padding-top">
                  <p>Ingredienti selezionati : {inputIngredienti.length}</p>
                </IonLabel>
                {inputIngredienti.map(
                  (ingrediente: Ingrediente, index: number) => {
                    return (
                      <IonChip
                        key={"ingredienteSelezionato" + index}
                        color={"primary"}
                      >
                        {ingrediente.nome}
                        <IonIcon
                          color="medium"
                          icon={closeCircle}
                          onClick={() => {
                            handleRimuoviIngrediente(index);
                          }}
                        />
                      </IonChip>
                    );
                  }
                )}
              </IonCardContent>
            </IonCard>

            <IonCard>
              <IonCardHeader>
                <IonCardTitle>Tags</IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                <IonButton
                  expand="block"
                  onClick={() => setModalTagsOpen(true)}
                >
                  <IonIcon icon={add} />
                  Seleziona tags
                </IonButton>
                <IonLabel className="ion-padding-top">
                  <p>Tags selezionati : {inputTags.length}</p>
                </IonLabel>
                {inputTags.map((tag, index: number) => {
                  return (
                    <IonChip key={"tagSelezionat" + index} color={"primary"}>
                      {tag}
                      <IonIcon
                        color="medium"
                        icon={closeCircle}
                        onClick={() => {
                          handleRimuoviTag(index);
                        }}
                      />
                    </IonChip>
                  );
                })}
              </IonCardContent>
            </IonCard>
          </div>
        </IonContent>
      </IonModal>

      {/*---------------------------------- AGGIUNGI INGREDIENTI --------------------------------------------------*/}
      <ModaleModificaIngredienti
        isOpen={modalIngredientiOpen}
        setIsOpen={setModalIngredientiOpen}
        inputIngredienti={inputIngredienti}
        setInputIngredienti={setInputIngredienti}
        allIngredienti={IngredientiDb}
      />

      {/*---------------------------------- MODIFICA INGREDIENTI --------------------------------------------------*/}
      <ModaleModificaIngredienti
        isOpen={modalIngredientiModificaOpen}
        setIsOpen={setModalIngredientiModificaOpen}
        inputIngredienti={inputIngredientiModifiche}
        setInputIngredienti={setInputIngredientiModifiche}
        allIngredienti={IngredientiDb}
      />

      {/*---------------------------------- AGGIUNGI PRODOTTO MODALE --------------------------------------------------*/}
      <IonModal
        isOpen={isModalAddOpen}
        onDidDismiss={() => {
          setIsModalAddOpen(false);
        }}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton
                color={"medium"}
                onClick={() => setIsModalAddOpen(false)}
              >
                Annulla
              </IonButton>
            </IonButtons>
            <IonTitle>Nuovo Prodotto</IonTitle>
            <IonButtons slot="end">
              <IonButton
                fill="solid"
                color={"success"}
                onClick={() => {
                  salvaNuovoProdotto();
                }}
              >
                Salva
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonSegment value={segmentValue}>
            <IonSegmentButton
              value="Carica"
              onClick={() => handleSelectSegment("Carica")}
            >
              <IonLabel>Carica Immagine</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton
              value="Seleziona"
              onClick={() => handleSelectSegment("Seleziona")}
            >
              <IonLabel>Seleziona Immagine</IonLabel>
            </IonSegmentButton>
          </IonSegment>

          {segmentValue === "Carica" ? (
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>Seleziona immagini</IonCardTitle>
                <IonCardSubtitle>
                  Se non selezioni nessuna immagine sarà utilizzato il logo di
                  MenuShare
                </IonCardSubtitle>
              </IonCardHeader>
              <IonCardContent>
                <UploadMultipleImages setImage={setInputImmagini} />
              </IonCardContent>
            </IonCard>
          ) : (
            <>
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>Seleziona immagini</IonCardTitle>
                  <IonCardSubtitle>
                    Se non selezioni nessuna immagine sarà utilizzato il logo
                    di MenuShare
                  </IonCardSubtitle>
                </IonCardHeader>
                <IonCardContent>
                  <IonButton
                    color={
                      immaginiSelezionate.legth > 0 ? "success" : "primary"
                    }
                    expand="block"
                    onClick={() => setIsModaleImmaginiOpen(true)}
                  >
                    <IonIcon
                      icon={imagesOutline}
                      className="ion-margin-end"
                    />
                    Seleziona
                  </IonButton>
                  {immaginiSelezionate.legth > 0
                    ? immaginiSelezionate.map(
                      (img: string, index: number) => {
                        return (
                          <IonImg
                            key={"indexselezionate" + index}
                            src={img}
                          ></IonImg>
                        );
                      }
                    )
                    : null}
                </IonCardContent>
              </IonCard>
              <IonLabel>
                <p className="ion-padding">Immagini selezionate</p>
                {immaginiSelezionate?.map((img: string, index: number) => {
                  return <IonImg key={"imgSelezionate" + index} src={img} />;
                })}
              </IonLabel>
            </>
          )}

          <div className="ion-padding windowDubleHeight">
            <IonList>
              <IonItem fill="outline">
                <IonLabel position="stacked">Inserisci nome</IonLabel>
                <IonInput
                  onIonInput={(ev: any) => {
                    setInputNome(ev.target.value);
                  }}
                  clearInput
                  placeholder="Obbligatorio"
                ></IonInput>
              </IonItem>

              <IonLabel>
                <IonItem fill="outline">
                  <IonLabel position="stacked">Prezzo</IonLabel>
                  <IonInput
                    onIonInput={(ev: any) => {
                      setInputPrezzo(ev.target.value);
                    }}
                    clearInput
                    placeholder="Facoltativo"
                  ></IonInput>
                </IonItem>
                <p className="ion-padding">
                  <IonIcon color="warning" icon={alertCircleOutline} />
                  Se non vuoi mettere il prezzo inserisci "Su richiesta." o
                  altro.
                </p>
              </IonLabel>

              <IonItem fill="outline">
                <IonLabel position="stacked">Inserisci descrizione</IonLabel>
                <IonTextarea
                  placeholder="Facoltativo"
                  onIonInput={(ev: any) => {
                    setInputDescrizione(ev.target.value);
                  }}
                  autoGrow={true}
                />
              </IonItem>
            </IonList>
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>Ingredienti</IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                <IonButton
                  expand="block"
                  onClick={() => setModalIngredientiOpen(true)}
                >
                  <IonIcon icon={add} />
                  Seleziona ingredienti
                </IonButton>
                <IonLabel className="ion-padding-top">
                  <p>Ingredienti selezionati : {inputIngredienti.length}</p>
                </IonLabel>
                {inputIngredienti.map(
                  (ingrediente: Ingrediente, index: number) => {
                    return (
                      <IonChip
                        key={"ingredienteSelezionato" + index}
                        color={"primary"}
                      >
                        {ingrediente.nome}
                        <IonIcon
                          color="medium"
                          icon={closeCircle}
                          onClick={() => {
                            handleRimuoviIngrediente(index);
                          }}
                        />
                      </IonChip>
                    );
                  }
                )}
              </IonCardContent>
            </IonCard>

            <IonCard>
              <IonCardHeader>
                <IonCardTitle>Tags</IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                <IonButton
                  expand="block"
                  onClick={() => setModalTagsOpen(true)}
                >
                  <IonIcon icon={add} />
                  Seleziona tags
                </IonButton>
                <IonLabel className="ion-padding-top">
                  <p>Tags selezionati : {inputTags.length}</p>
                </IonLabel>
                {inputTags.map((tag, index: number) => {
                  return (
                    <IonChip key={"tagSelezionat" + index} color={"primary"}>
                      {tag}
                      <IonIcon
                        color="medium"
                        icon={closeCircle}
                        onClick={() => {
                          handleRimuoviTag(index);
                        }}
                      />
                    </IonChip>
                  );
                })}
              </IonCardContent>
            </IonCard>
          </div>
        </IonContent>
      </IonModal>

      {/*---------------------------------- MODIFICA PRODOTTO MODALE --------------------------------------------------*/}
      <IonModal
        isOpen={isModalModificheAddOpen}
        onDidDismiss={() => {
          setIsModalModificheAddOpen(false);
        }}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton
                color={"medium"}
                onClick={() => {
                  setIsModalModificheAddOpen(false);
                  resetAllDataInput();
                }}
              >
                Annulla
              </IonButton>
            </IonButtons>
            <IonTitle>Modifica</IonTitle>
            <IonButtons slot="end">
              <IonButton
                fill="solid"
                color={"success"}
                onClick={() => {
                  handleSalvaModifiche();
                }}
              >
                Salva
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonSegment value={segmentValue}>
            <IonSegmentButton
              value="Carica"
              onClick={() => handleSelectSegment("Carica")}
            >
              <IonLabel>Carica Immagine</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton
              value="Seleziona"
              onClick={() => handleSelectSegment("Seleziona")}
            >
              <IonLabel>Seleziona Immagine</IonLabel>
            </IonSegmentButton>
          </IonSegment>

          {segmentValue === "Carica" ? (
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>Seleziona immagini</IonCardTitle>
              </IonCardHeader>
              <p className="ion-margin">
                ATTENZIONE! La preview delle immagini è disponibile solo
                durante la creazione di un nuovo prodotto. ATTENZIONE! Le
                immagini selezionate sovrascrivono quelle preesistenti.
              </p>
              <IonCardContent>
                <UploadMultipleImages
                  setImage={setInputImmaginiModifiche}
                  immagini={inputImmaginiModifiche}
                />
              </IonCardContent>
            </IonCard>
          ) : (
            <>
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>Seleziona immagini</IonCardTitle>
                  <IonCardSubtitle>
                    Se non selezioni nessuna immagine sarà utilizzato il logo
                    di MenuShare
                  </IonCardSubtitle>
                </IonCardHeader>
                <IonCardContent>
                  <IonButton
                    color={
                      immaginiSelezionate.legth > 0 ? "success" : "primary"
                    }
                    expand="block"
                    onClick={() => setIsModaleImmaginiOpen(true)}
                  >
                    <IonIcon
                      icon={imagesOutline}
                      className="ion-margin-end"
                    />
                    Seleziona
                  </IonButton>
                  {immaginiSelezionate.legth > 0
                    ? immaginiSelezionate.map(
                      (img: string, index: number) => {
                        return (
                          <IonImg
                            key={"indexselezionate" + index}
                            src={img}
                          ></IonImg>
                        );
                      }
                    )
                    : null}
                </IonCardContent>
              </IonCard>
              <IonLabel>
                <p className="ion-padding">Immagini selezionate</p>
                {immaginiSelezionate?.map((img: string, index: number) => {
                  return <IonImg key={"imgSelezionate" + index} src={img} />;
                })}
              </IonLabel>
            </>
          )}

          <div className="ion-padding windowDubleHeight">
            <IonList>
              <IonItem fill="outline">
                <IonLabel position="stacked">Inserisci nome</IonLabel>
                <IonInput
                  value={inputNomeModifiche}
                  onIonInput={(ev: any) => {
                    setInputNomeModifiche(ev.target.value);
                  }}
                  clearInput
                  placeholder="Obbligatorio"
                ></IonInput>
              </IonItem>

              <IonLabel>
                <IonItem fill="outline">
                  <IonLabel position="floating">Prezzo</IonLabel>
                  <IonInput
                    value={inputPrezzoModifiche}
                    onIonInput={(ev: any) => {
                      setInputPrezzoModifiche(ev.target.value);
                    }}
                    clearInput
                    placeholder="Facoltativo"
                  ></IonInput>
                </IonItem>
                <p className="ion-padding">
                  <IonIcon color="warning" icon={alertCircleOutline} />
                  Se non vuoi mettere il prezzo inserisci "Su richiesta." o
                  altro.
                </p>
              </IonLabel>
              <IonItem fill="outline">
                <IonLabel position="stacked">Inserisci descrizione</IonLabel>
                <IonTextarea
                  placeholder="Facoltativo"
                  value={inputDescrizioneModifiche}
                  onIonInput={(ev: any) => {
                    setInputDescrizioneModifiche(ev.target.value);
                  }}
                  autoGrow={true}
                />
              </IonItem>
            </IonList>
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>Ingredienti</IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                <IonButton
                  expand="block"
                  onClick={() => setModalIngredientiModificaOpen(true)}
                >
                  <IonIcon icon={add} />
                  Seleziona ingredienti
                </IonButton>
                <IonLabel className="ion-padding-top">
                  <p>
                    Ingredienti selezionati :{" "}
                    {inputIngredientiModifiche.length}
                  </p>
                </IonLabel>
                {inputIngredientiModifiche.map(
                  (ingrediente: Ingrediente, index: number) => {
                    return (
                      <IonChip
                        key={"ingredienteSelezionato" + index}
                        color={"primary"}
                      >
                        {ingrediente.nome}
                        <IonIcon
                          color="medium"
                          icon={closeCircle}
                          onClick={() => {
                            handleRimuoviIngredienteModifiche(index);
                          }}
                        />
                      </IonChip>
                    );
                  }
                )}
              </IonCardContent>
            </IonCard>

            <IonCard>
              <IonCardHeader>
                <IonCardTitle>Tags</IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                <IonButton
                  expand="block"
                  onClick={() => setModalTagsModificheOpen(true)}
                >
                  <IonIcon icon={add} />
                  Seleziona tags
                </IonButton>
                <IonLabel className="ion-padding-top">
                  <p>Tags selezionati : {inputTagsModifiche.length}</p>
                </IonLabel>
                {inputTagsModifiche.map((tag, index: number) => {
                  return (
                    <IonChip key={"tagSelezionat" + index} color={"primary"}>
                      {tag}
                      <IonIcon
                        color="medium"
                        icon={closeCircle}
                        onClick={() => {
                          handleRimuoviTagModifiche(index);
                        }}
                      />
                    </IonChip>
                  );
                })}
              </IonCardContent>
            </IonCard>
          </div>
        </IonContent>
      </IonModal>

      {/*---------------------------------- AGGIUNGI TAG --------------------------------------------------*/}
      <IonModal
        isOpen={modalTagsOpen}
        onDidDismiss={() => {
          setModalTagsOpen(false);
        }}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={() => setModalTagsOpen(false)}>
                Chiudi
              </IonButton>
            </IonButtons>
            <IonTitle>Tags</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setModalTagsOpen(false)}>
                Salva
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonSearchbar
            value={searchTextTagModale}
            placeholder={"Cerca per nome"}
            onIonChange={(e) => {
              setSearchTextTagModale(e.detail.value!);
              handleSearchTag(e.detail.value!);
            }}
          ></IonSearchbar>

          <div className="ion-padding">
            <IonLabel>
              <p className="ion-padding">
                <IonIcon color="warning" icon={alertCircleOutline} />I tag
                servono per creare delle categorie esterne a quelle già
                esistenti. Esempio: "Senza glutine", "Per 3 persone" ecc.
              </p>
            </IonLabel>
            <IonLabel>
              <p className="ion-padding-bottom">
                Tag aggiunti: {inputTags.length}
              </p>
            </IonLabel>
            {inputTags.map((tag: string, index: number) => {
              return (
                <IonChip key={"tagSelezionato" + index} color={"primary"}>
                  {tag}
                  <IonIcon
                    color="medium"
                    icon={closeCircle}
                    onClick={() => {
                      handleRimuoviTag(index);
                    }}
                  />
                </IonChip>
              );
            })}
          </div>

          <div className="ion-padding">
            <IonButton
              expand="block"
              onClick={() =>
                presentAlertTag({
                  header: "Crea nuovo tag",
                  message: "Sarà aggiunto automaticamente al database",
                  inputs: [
                    {
                      placeholder: "Name tag",
                      name: "tag",
                      type: "text",
                    },
                  ],
                  buttons: [
                    {
                      text: "Cancel",
                      role: "cancel",
                    },
                    {
                      text: "Salva",
                      role: "confirm",
                      handler: (alertData) => {
                        handleSaveNewTag(alertData.tag);
                      },
                    },
                  ],
                })
              }
            >
              <IonIcon icon={add} />
              Crea nuovo tag
            </IonButton>
          </div>

          <div>
            <IonLabel>
              <p className="ion-padding">Tutti i tag: {allTags?.length}</p>
            </IonLabel>
            <IonList>
              {allTags?.map((tag: string, index: number) => {
                return (
                  <div
                    key={index + "tagInventario"}
                    className="tagInventario"
                  >
                    <IonItem>
                      <IonLabel>{tag}</IonLabel>
                      <IonButton
                        color={"primary"}
                        slot="end"
                        onClick={() => handleAggiungiTag(tag)}
                      >
                        <IonIcon icon={add} />
                      </IonButton>
                      <IonButton
                        color={"danger"}
                        slot="end"
                        onClick={() =>
                          presentAlert({
                            header: "Attenzione!",
                            message:
                              "L'eliminazione è definitiva! E rimarrà nei prodotti che lo contengono. Per un'eliminazione completa rimuoverlo anche dagli altri prodotti.",
                            buttons: [
                              {
                                text: "Annulla",
                                role: "cancel",
                              },
                              {
                                text: "Conferma",
                                role: "confirm",
                                handler: () => {
                                  handleRemoveFromDatabaseTag(index);
                                },
                              },
                            ],
                          })
                        }
                      >
                        <IonIcon icon={trash} />
                      </IonButton>
                    </IonItem>
                  </div>
                );
              })}
            </IonList>
          </div>
        </IonContent>
      </IonModal>

      {/*---------------------------------- MODIFICHE TAG --------------------------------------------------*/}
      <IonModal
        isOpen={modalTagsModificheOpen}
        onDidDismiss={() => {
          setModalTagsModificheOpen(false);
        }}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={() => setModalTagsModificheOpen(false)}>
                Chiudi
              </IonButton>
            </IonButtons>
            <IonTitle>Tags</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setModalTagsModificheOpen(false)}>
                Salva
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonSearchbar
            value={searchTextTagModale}
            placeholder={"Cerca per nome"}
            onIonChange={(e) => {
              setSearchTextTagModale(e.detail.value!);
              handleSearchTag(e.detail.value!);
            }}
          ></IonSearchbar>

          <div className="ion-padding">
            <IonLabel>
              <p className="ion-padding">
                <IonIcon color="warning" icon={alertCircleOutline} />I tag
                servono per creare delle categorie esterne a quelle già
                esistenti. Esempio: "Senza glutine", "Per 3 persone" ecc.
              </p>
            </IonLabel>
            <IonLabel>
              <p>Tag aggiunti: {inputTagsModifiche.length}</p>
            </IonLabel>
            {inputTagsModifiche.map((tag: string, index: number) => {
              return (
                <IonChip key={"tagSelezionato" + index} color={"primary"}>
                  {tag}
                  <IonIcon
                    color="medium"
                    icon={closeCircle}
                    onClick={() => {
                      handleRimuoviTagModifiche(index);
                    }}
                  />
                </IonChip>
              );
            })}
          </div>
          <div className="ion-padding">
            <IonButton
              expand="block"
              onClick={() =>
                presentAlertTag({
                  header: "Crea nuovo tag",
                  message: "Sarà aggiunto automaticamente al database",
                  inputs: [
                    {
                      placeholder: "Name tag",
                      name: "tag",
                      type: "text",
                    },
                  ],
                  buttons: [
                    {
                      text: "Cancel",
                      role: "cancel",
                    },
                    {
                      text: "Salva",
                      role: "confirm",
                      handler: (alertData) => {
                        handleSaveNewTag(alertData.tag);
                      },
                    },
                  ],
                })
              }
            >
              <IonIcon icon={add} />
              Crea nuovo tag
            </IonButton>
          </div>

          <div>
            <IonLabel>
              <p className="ion-padding">Tutti i tag: {allTags?.length}</p>
            </IonLabel>
          </div>
          <IonList>
            {allTags?.map((tag: string, index: number) => {
              return (
                <div key={index + "tagInventario"} className="tagInventario">
                  <IonItem>
                    <IonLabel>{tag}</IonLabel>
                    <IonButton
                      color={"primary"}
                      slot="end"
                      onClick={() => handleAggiungiTagModifiche(tag)}
                    >
                      <IonIcon icon={add} />
                    </IonButton>
                    <IonButton
                      color={"danger"}
                      slot="end"
                      onClick={() =>
                        presentAlert({
                          header: "Attenzione!",
                          message:
                            "L'eliminazione è definitiva! E rimarrà nei prodotti che lo contengono. Per un'eliminazione completa rimuoverlo anche dagli altri prodotti.",
                          buttons: [
                            {
                              text: "Annulla",
                              role: "cancel",
                            },
                            {
                              text: "Conferma",
                              role: "confirm",
                              handler: () => {
                                handleRemoveFromDatabaseTag(index);
                              },
                            },
                          ],
                        })
                      }
                    >
                      <IonIcon icon={trash} />
                    </IonButton>
                  </IonItem>
                </div>
              );
            })}
          </IonList>
        </IonContent>
      </IonModal>

      {/*---------------------------------- MODALE IMMAGINI --------------------------------------------------*/}
      <IonModal
        isOpen={isModaleImmaginiOpen}
        onDidDismiss={() => {
          setIsModaleImmaginiOpen(false);
        }}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton
                color={"medium"}
                onClick={() => {
                  setImmaginiSelezionate([]);
                  setIsModaleImmaginiOpen(false);
                }}
              >
                Annulla
              </IonButton>
            </IonButtons>
            <IonTitle>Immagini </IonTitle>
            <IonButtons slot="end">
              <IonButton
                fill="solid"
                color={"success"}
                onClick={() => setIsModaleImmaginiOpen(false)}
              >
                Conferma
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div>
            <IonLabel>
              <p className="ion-padding">
                Filtro visualizzazione:
                <IonChip
                  color={
                    filtroVisualizzazioneImmagini === "column-portfolio-1"
                      ? "primary"
                      : undefined
                  }
                  onClick={() =>
                    setFiltroVisualizzazioneImmagini("column-portfolio-1")
                  }
                >
                  1/1
                </IonChip>
                <IonChip
                  color={
                    filtroVisualizzazioneImmagini === "column-portfolio-2"
                      ? "primary"
                      : undefined
                  }
                  onClick={() =>
                    setFiltroVisualizzazioneImmagini("column-portfolio-2")
                  }
                >
                  1/2
                </IonChip>
                <IonChip
                  color={
                    filtroVisualizzazioneImmagini === "column-portfolio-3"
                      ? "primary"
                      : undefined
                  }
                  onClick={() =>
                    setFiltroVisualizzazioneImmagini("column-portfolio-3")
                  }
                >
                  1/3
                </IonChip>
                <IonChip
                  color={
                    filtroVisualizzazioneImmagini === "column-portfolio-4"
                      ? "primary"
                      : undefined
                  }
                  onClick={() =>
                    setFiltroVisualizzazioneImmagini("column-portfolio-4")
                  }
                >
                  1/4
                </IonChip>
              </p>
            </IonLabel>
            <IonLabel>
              <p className="ion-padding-start ion-padding-end">
                Cartelle:{" "}
                {defaultFolders?.map((cartella: string, index: number) => {
                  return (
                    <IonChip
                      key={"cartelleIngredientidefaultFolders" + index}
                      color={
                        filtroCartelle === cartella ? "primary" : undefined
                      }
                      onClick={() => {
                        setFiltroCartelle(cartella);
                        handleFiltraImmagini(cartella);
                      }}
                    >
                      {cartella}
                    </IonChip>
                  );
                })}
                {cartelle?.allCartelleImmagini?.map(
                  (cartella: string, index: number) => {
                    return (
                      <IonChip
                        key={"cartelleIngredienti" + index}
                        color={
                          filtroCartelle === cartella ? "primary" : undefined
                        }
                        onClick={() => {
                          setFiltroCartelle(cartella);
                          handleFiltraImmagini(cartella);
                        }}
                      >
                        {cartella}
                      </IonChip>
                    );
                  }
                )}
              </p>
            </IonLabel>
          </div>

          <div className="row-portfolio">
            {allImmagini?.map((immagineObj: any, index: number) => {
              let x = immaginiSelezionate?.find(
                (elem: any) => elem === immagineObj.urlImmagine
              );
              return (
                <IonCard
                  color={x !== undefined ? "primary" : undefined}
                  key={"immagine-" + index}
                  className={
                    "immaginiGalleria " + filtroVisualizzazioneImmagini
                  }
                  onClick={() => {
                    x === undefined
                      ? handleAddImmagine(immagineObj.urlImmagine)
                      : handleRemoveImmagine(immagineObj.urlImmagine);
                  }}
                >
                  <IonCardHeader>
                    <IonCardSubtitle>{immagineObj.cartella}</IonCardSubtitle>
                  </IonCardHeader>
                  <IonImg src={immagineObj.urlImmagine} />
                </IonCard>
              );
            })}
          </div>
        </IonContent>
      </IonModal>


    </div>
  );
};

export default InventarioColumn_Prodotti;
