import { IonAvatar, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonHeader, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonList, IonModal, IonPage, IonReorder, IonReorderGroup, IonTitle, IonToolbar, ItemReorderEventDetail, useIonAlert, useIonLoading, useIonToast } from '@ionic/react';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { addCircle, alertCircleOutline, arrowBack, close, cloudDoneOutline, pencil, trash } from 'ionicons/icons';
import React from 'react';
import { useParams } from 'react-router';
import UploadSingleImage from '../components/UploadSingleImage';
import { Persona } from '../data/dataSheet';
import { auth, db } from '../fb';
import { UploadDataPersonale } from '../Logic/DataUpload';
import { UploadSingleImageFirebase } from '../Logic/ImageUpload';
import { LocalSaveAttivita } from '../Logic/LocalStorage';
import '../pages/CSS/PersonaleScreen.css';
import { url_app_attivita_init } from '../Urls';
import ModalInputImmagineSingola from '../components/Modal_Input_Immagini/ModalInputImmagineSingola';

type PersonaleScreenProps = {
  AllData: any,
  setAllData: any,
  cartelle: any,
  allImmagini: any
}


const PersonaleScreen: React.FC<PersonaleScreenProps> = ({ AllData, setAllData, cartelle, allImmagini }) => {

  //VARIABILI --------------------------------------------
  const folder = "/personale";
  const { indiceAttivita } = useParams<{ indiceAttivita: string }>();
  const index = indiceAttivita.split('-')[1];

  const [dataAttivita, setDataAttivita] = React.useState<any>();
  const [personale, setPersonale] = React.useState<any>([]);

  const [present, dimiss] = useIonLoading();
  const [presentAlert] = useIonAlert();

  //Aggiungi
  const [isModaleAggiungiOpen, setIsModaleAggiungiOpen] = React.useState(false);
  const [id, setId] = React.useState<number>();
  const [inputNome, setInputNome] = React.useState<string>("");
  const [inputMansione, setInputMansione] = React.useState("");
  const [inputTitoloDiStudio, setInputTitoloDiStudio] = React.useState("");
  const [inputDescrizione, setInputDescrizione] = React.useState("");
  const [inputImmagine, setInputImmagine] = React.useState();
  const [inputInstagramLink, setInputInstagramLink] = React.useState("");
  const [inputFacebookLink, setInputFacebookLink] = React.useState("");
  const [inputLinkedinLink, setInputLinkedinLink] = React.useState("");
  const [inputSitoWebLink, setInputSitoWebLink] = React.useState("");

  const [inputEmail, setInputEmail] = React.useState<any>()
  const [inputPassword, setInputPassword] = React.useState<any>()

  const [linkImmagine, setLinkImmagine] = React.useState();

  //Modifica
  const [isModaleModificaOpen, setIsModaleModificaOpen] = React.useState(false);
  const [indiceModifica, setIndiceModifica] = React.useState<number>()
  const [idModifica, setIdModifica] = React.useState<number>();
  const [inputNomeModifica, setInputNomeModifica] = React.useState<string>("");
  const [inputMansioneModifica, setInputMansioneModifica] = React.useState("");
  const [inputTitoloDiStudioModifica, setInputTitoloDiStudioModifica] = React.useState("");
  const [inputDescrizioneModifica, setInputDescrizioneModifica] = React.useState("");
  const [inputImmagineModifica, setInputImmagineModifica] = React.useState();
  const [inputInstagramLinkModifica, setInputInstagramLinkModifica] = React.useState("");
  const [inputFacebookLinkModifica, setInputFacebookLinkModifica] = React.useState("");
  const [inputLinkedinLinkModifica, setInputLinkedinLinkModifica] = React.useState("");
  const [inputSitoWebLinkModifica, setInputSitoWebLinkModifica] = React.useState("");

  const [linkImmagineModifica, setLinkImmagineModifica] = React.useState();


  const [presentToast] = useIonToast();

  const [immagineSelezionata, setImmagineSelezionata] = React.useState(undefined);


  //CONDIZIONI -------------------------------------------

  const presentToastType = (message: string, type: "danger" | "success" | "warning", buttonEv: () => void) => {

    const iconType = {
      "danger": close,
      "success": cloudDoneOutline,
      "warning": alertCircleOutline,
    }

    presentToast({
      message: message,
      position: "top",
      duration: 3000,
      icon: iconType[type],
      color: type,
      buttons: [
        {
          text: 'Chiudi',
          role: 'cancel',
          handler: () => { buttonEv() }
        }
      ]
    })
  }



  React.useState(() => {
    const data = AllData;
    setDataAttivita(data[parseInt(index)]);
    setPersonale(data[parseInt(index)].personale);
  });

  const handleOrdinamento = (ev: CustomEvent<ItemReorderEventDetail>) => {
    console.log("PersonaleScreen - handleOrdinamento()");
    setPersonale(ev.detail.complete(personale));
    salvaOrdinamento(ev.detail.complete(personale));
    ev.detail.complete();
  }

  const salvaOrdinamento = async (personale: any) => {
    console.log("PersonaleScreen - salvaOrdinamento()");
    let a = [...AllData];
    a[parseInt(index)].personale = personale;
    await UploadDataPersonale(a[parseInt(index)], a[parseInt(index)].id);
    LocalSaveAttivita(a);
    setAllData(a);
  }

  const rimuoviPersonale = async (indice: number) => {
    console.log("PersonaleScreen - rimuoviCategoria()");
    let a = [...AllData];
    let b = [...a[parseInt(index)].personale!];
    b.splice(indice, 1);
    setPersonale(b);
    a[parseInt(index)].personale = b;
    await UploadDataPersonale(a[parseInt(index)], a[parseInt(index)].id);
    LocalSaveAttivita(a);
    setAllData(a);
  }

  const handleModifica = (indice: number) => {
    console.log("PersonaleScreen - handleModifica() : indice : ", indice, " DATA: ", personale[indice]);
    setIndiceModifica(indice);
    if (personale !== undefined) {
      setIdModifica(personale[indice].id)
      setInputNomeModifica(personale[indice].nome)
      setInputMansioneModifica(personale[indice].mansione)
      setInputTitoloDiStudioModifica(personale[indice].titoloDiStudio)
      setInputDescrizioneModifica(personale[indice].descrizione)
      setInputImmagineModifica(personale[indice].immagineUrl)
      setInputInstagramLinkModifica(personale[indice].instagram)
      setInputFacebookLinkModifica(personale[indice].facebook)
      setInputLinkedinLinkModifica(personale[indice].linkedin)
      setInputSitoWebLinkModifica(personale[indice].sitoWeb);

      setIsModaleModificaOpen(true);
    }
  }

  const handleSalvaModifica = async () => {
    if (immagineSelezionata !== undefined) {
      present({
        message: "Sto aggiornando il personale..."
      });
      setIsModaleModificaOpen(false);
      let oggettoPersona = {
        id: idModifica,
        nome: inputNomeModifica,
        mansione: inputMansioneModifica,
        titoloDiStudio: inputTitoloDiStudioModifica,
        descrizione: inputDescrizioneModifica,
        immagineUrl: immagineSelezionata,
        instagram: inputInstagramLinkModifica,
        facebook: inputFacebookLinkModifica,
        linkedin: inputLinkedinLinkModifica,
        sitoWeb: inputSitoWebLinkModifica,
      };
      handleUploadModifiche(oggettoPersona);
    } else {
      present({
        message: "Sto aggiornando il personale..."
      });
      setIsModaleModificaOpen(false);
      let oggettoPersona;
      if (inputImmagineModifica === personale[indiceModifica!].immagineUrl) {
        oggettoPersona = {
          id: idModifica,
          nome: inputNomeModifica,
          mansione: inputMansioneModifica,
          titoloDiStudio: inputTitoloDiStudioModifica,
          descrizione: inputDescrizioneModifica,
          immagineUrl: inputImmagineModifica,
          instagram: inputInstagramLinkModifica,
          facebook: inputFacebookLinkModifica,
          linkedin: inputLinkedinLinkModifica,
          sitoWeb: inputSitoWebLinkModifica,
        };
        handleUploadModifiche(oggettoPersona);
      } else {
        await UploadSingleImageFirebase(inputImmagineModifica!, idModifica!, setLinkImmagineModifica, folder);
      }
    }

  }

  const handleUploadModifiche = async (oggettoPersona: any) => {
    let a = [...AllData];
    a[parseInt(index)].personale[indiceModifica!] = oggettoPersona;
    setAllData(a);

    await UploadDataPersonale(a[parseInt(index)], a[parseInt(index)].id);
    LocalSaveAttivita(a);
    resetAllDataInput();
    dimiss();
    presentToastType("Personale salvato", "success", () => { });
  }

  const handleAggiungiPersonale = async () => {
    if (immagineSelezionata !== undefined && inputNome !== "" && inputMansione !== "" && inputDescrizione !== "" && inputPassword.length > 6) {
      const oggettoPersona = {
        id: id,
        nome: inputNome,
        mansione: inputMansione,
        titoloDiStudio: inputTitoloDiStudio,
        descrizione: inputDescrizione,
        immagineUrl: immagineSelezionata,
        instagram: inputInstagramLink,
        facebook: inputFacebookLink,
        linkedin: inputLinkedinLink,
        sitoWeb: inputSitoWebLink,
      };
      console.log("oggettoPersona salva nuova persona : ", oggettoPersona);
      registraUtente(oggettoPersona);
      salvaAllData(oggettoPersona);
    } else {
      if (inputNome !== "" && inputMansione !== "" && inputDescrizione !== "" && inputPassword.length > 6) {
        setIsModaleAggiungiOpen(false);
        present({
          message: "Sto salvando il personale..."
        });

        let auxId = Date.now();
        setId(auxId);

        await UploadSingleImageFirebase(inputImmagine!, auxId, setLinkImmagine, folder);

      } else {
        presentToastType("ATTENZIONE! Inserire tutti i campi obbligatori", "warning", () => { });
      }
    }


  }

  const registraUtente = async (oggettoPersona: any) => {
    createUserWithEmailAndPassword(auth, inputEmail, inputPassword)
      .then((userCredential) => {
        console.log("UTENTE :", userCredential.user.uid);
        setDoc(doc(db, "personale", userCredential.user.uid),
          {
            oggettoPersona,
            token: "",
            attivita: dataAttivita?.id,
          });
      })
  }

  React.useEffect(() => {
    if (linkImmagine !== undefined) {
      const oggettoPersona = {
        id: id,
        nome: inputNome,
        mansione: inputMansione,
        titoloDiStudio: inputTitoloDiStudio,
        descrizione: inputDescrizione,
        immagineUrl: linkImmagine,
        instagram: inputInstagramLink,
        facebook: inputFacebookLink,
        linkedin: inputLinkedinLink,
        sitoWeb: inputSitoWebLink,
      };
      console.log("oggettoPersona salva nuova persona : ", oggettoPersona);
      registraUtente(oggettoPersona);
      salvaAllData(oggettoPersona);
    }
  }, [linkImmagine]);

  React.useEffect(() => {
    if (linkImmagineModifica !== undefined) {
      const oggettoPersona = {
        id: idModifica,
        nome: inputNomeModifica,
        mansione: inputMansioneModifica,
        titoloDiStudio: inputTitoloDiStudioModifica,
        descrizione: inputDescrizioneModifica,
        immagineUrl: linkImmagineModifica,
        instagram: inputInstagramLinkModifica,
        facebook: inputFacebookLinkModifica,
        linkedin: inputLinkedinLinkModifica,
        sitoWeb: inputSitoWebLinkModifica,
      };
      console.log("oggettoPersona  modifica persona: ", oggettoPersona);
      handleUploadModifiche(oggettoPersona);
    }
  }, [linkImmagineModifica]);

  const salvaAllData = async (oggettoPersona: any) => {
    console.log("salvaAllData - oggettoPersona :", oggettoPersona);
    let a = [...AllData];
    a[parseInt(index)].personale?.push(oggettoPersona);
    console.log(a);
    setAllData(a);
    await UploadDataPersonale(a[parseInt(index)], a[parseInt(index)].id);
    LocalSaveAttivita(a);
    resetAllDataInput();
    dimiss();
    presentToastType("Personale salvato", "success", () => { });

  }

  const resetAllDataInput = () => {
    setId(undefined)
    setInputNome("")
    setInputMansione("")
    setInputTitoloDiStudio("")
    setInputDescrizione("")
    setInputImmagine(undefined)
    setInputInstagramLink("")
    setInputFacebookLink("")
    setInputLinkedinLink("")
    setInputSitoWebLink("");
    setIndiceModifica(undefined);
    setIdModifica(undefined)
    setInputNomeModifica("")
    setInputMansioneModifica("")
    setInputTitoloDiStudioModifica("")
    setInputDescrizioneModifica("")
    setInputImmagineModifica(undefined)
    setInputInstagramLinkModifica("")
    setInputFacebookLinkModifica("")
    setInputLinkedinLinkModifica("")
    setInputSitoWebLinkModifica("")
    setLinkImmagine(undefined)
    setLinkImmagineModifica(undefined)
    setInputEmail("")
    setInputPassword("")
  }

  const resetImageInput = () => {
    setLinkImmagine(undefined);
    setImmagineSelezionata(undefined);
  }
  //RETURN -----------------------------------------------
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonButton routerLink={url_app_attivita_init + indiceAttivita} routerDirection="back">
              <IonIcon icon={arrowBack} />
              {dataAttivita?.nome}
            </IonButton>
          </IonButtons>
          <IonTitle>Personale</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Personale</IonTitle>
          </IonToolbar>
        </IonHeader>

        <div className='ion-padding'>
          <IonButton expand='block' fill='solid' onClick={() => setIsModaleAggiungiOpen(true)}>
            <IonIcon icon={addCircle} className="ion-margin-end" />
            Aggiungi personale
          </IonButton>
        </div>

        <div>
          <IonList>
            <IonReorderGroup disabled={false} onIonItemReorder={handleOrdinamento}>
              {personale?.map((item: Persona, index: number) => {
                return (
                  <IonItem key={index + "persona"}>
                    <IonButton color={"danger"} slot='start' fill='solid' onClick={() => presentAlert({
                      header: 'Attenzione!',
                      message: "L'eliminazione è definitiva! La persona non sarà rimossa.",
                      buttons: [
                        {
                          text: 'Annulla',
                          role: 'cancel',
                        },
                        {
                          text: 'Conferma',
                          role: 'confirm',
                          handler: () => {
                            rimuoviPersonale(index);
                          },
                        },
                      ],
                    })}>
                      <IonIcon icon={trash} />
                    </IonButton>
                    <IonButton slot='start' fill='solid' onClick={() => { handleModifica(index) }}>
                      <IonIcon icon={pencil} />
                    </IonButton>
                    <IonAvatar className='ion-margin-end'>
                      <IonImg src={item.immagineUrl} />
                    </IonAvatar>
                    <IonLabel>
                      <p>
                        <b>
                          {item?.mansione}
                        </b>
                      </p>
                      <h2>
                        {item.nome}
                      </h2>
                      <p>
                        {item?.descrizione}
                      </p>
                    </IonLabel>
                    <IonReorder slot="end"></IonReorder>
                  </IonItem>
                )
              })
              }
            </IonReorderGroup>
          </IonList>
        </div>


        {/* --------------- MODALE AGGIUGNI ------------------- */}
        <IonModal isOpen={isModaleAggiungiOpen} onDidDismiss={() => { setIsModaleAggiungiOpen(false); }}>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton onClick={() => setIsModaleAggiungiOpen(false)}>Chiudi</IonButton>
              </IonButtons>
              <IonTitle>Aggiungi persona</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => handleAggiungiPersonale()}>Salva</IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className='windowDubleHeight'>

            <IonCard>
              <IonCardHeader>
                <IonCardTitle>Credenziali Accesso</IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                <IonLabel>
                  <p className="ion-padding-bottom">
                    Queste credenziali servono per creare un utente e permetteranno al personale di accedere
                    all'app, con la possibilità di visualizzare i contenuti
                    dell'attività e di gestire gli ordini. <br />
                    Attenzione!
                    Consiglio di creare le credeziali su un file esterno e poi incollarle qui, da poter poi condividerle al personale.
                    NON SONO MODIFICABILI SUCCESSIVAMENTE.
                  </p>
                </IonLabel>
                <IonItem>
                  <IonLabel>Email</IonLabel>
                  <IonInput
                    type="email"
                    placeholder="nome@attività.menushare"
                    value={inputEmail}
                    onIonChange={(ev) => { setInputEmail(ev.target.value!) }}
                  ></IonInput>
                </IonItem>
                <IonItem>
                  <IonLabel>Password</IonLabel>
                  <IonInput
                    type="text"
                    placeholder=""
                    value={inputPassword}
                    onIonChange={(ev) => { setInputPassword(ev.target.value!) }}
                  ></IonInput>
                </IonItem>
                <IonLabel><p>
                  Attenzione! La password deve essere di almeno 6 caratteri.
                </p></IonLabel>
              </IonCardContent>
            </IonCard>


            <ModalInputImmagineSingola
              resetImageInput={resetImageInput}
              setInputImmagine={setInputImmagine}
              immagineSelezionata={immagineSelezionata}
              setImmagineSelezionata={setImmagineSelezionata}
              cartelle={cartelle}
              allImmagini={allImmagini}
            />


            <div className='ion-padding windowDubleHeight'>

              <IonItem fill="outline">
                <IonLabel position="stacked">Inserisci nome</IonLabel>
                <IonInput onIonInput={(ev: any) => { setInputNome(ev.target.value) }} clearInput placeholder="Obbligatorio"></IonInput>
              </IonItem>

              <IonItem fill="outline">
                <IonLabel position="stacked">Inserisci mansione</IonLabel>
                <IonInput onIonInput={(ev: any) => { setInputMansione(ev.target.value) }} clearInput placeholder="Obbligatorio"></IonInput>
              </IonItem>

              <IonItem fill="outline">
                <IonLabel position="stacked">Inserisci descrizione</IonLabel>
                <IonInput onIonInput={(ev: any) => { setInputDescrizione(ev.target.value) }} clearInput placeholder="Obbligatorio"></IonInput>
              </IonItem>

              <IonItem fill="outline">
                <IonLabel position="stacked">Inserisci titolo di studio o certificati</IonLabel>
                <IonInput onIonInput={(ev: any) => { setInputTitoloDiStudio(ev.target.value) }} clearInput placeholder="Facoltativo"></IonInput>
              </IonItem>

              <IonItem fill="outline">
                <IonLabel position="stacked">Inserisci link Instagram</IonLabel>
                <IonInput onIonInput={(ev: any) => { setInputInstagramLink(ev.target.value) }} clearInput placeholder="Facoltativo"></IonInput>
              </IonItem>

              <IonItem fill="outline">
                <IonLabel position="stacked">Inserisci link Facebook</IonLabel>
                <IonInput onIonInput={(ev: any) => { setInputFacebookLink(ev.target.value) }} clearInput placeholder="Facoltativo"></IonInput>
              </IonItem>

              <IonItem fill="outline">
                <IonLabel position="stacked">Inserisci link Linkedin</IonLabel>
                <IonInput onIonInput={(ev: any) => { setInputLinkedinLink(ev.target.value) }} clearInput placeholder="Facoltativo"></IonInput>
              </IonItem>

              <IonItem fill="outline">
                <IonLabel position="stacked">Inserisci link Sito web</IonLabel>
                <IonInput onIonInput={(ev: any) => { setInputSitoWebLink(ev.target.value) }} clearInput placeholder="Facoltativo"></IonInput>
              </IonItem>

            </div>
          </IonContent>
        </IonModal>

        {/* --------------- MODALE MODIFICA ------------------- */}
        <IonModal isOpen={isModaleModificaOpen} onDidDismiss={() => { setIsModaleModificaOpen(false) }} >
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton onClick={() => setIsModaleModificaOpen(false)}>Chiudi</IonButton>
              </IonButtons>
              <IonTitle>Modifica persona</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => handleSalvaModifica()}>Salva</IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>


            <ModalInputImmagineSingola
              resetImageInput={resetImageInput}
              setInputImmagine={setInputImmagineModifica}
              inputImmagine={inputImmagineModifica}
              immagineSelezionata={immagineSelezionata}
              setImmagineSelezionata={setImmagineSelezionata}
              cartelle={cartelle}
              allImmagini={allImmagini}
            />

            <div className='ion-padding windowDubleHeight'>
              <IonList>
                <IonItem fill="outline">
                  <IonLabel position="stacked">Inserisci nome</IonLabel>
                  <IonInput value={inputNomeModifica} onIonInput={(ev: any) => { setInputNomeModifica(ev.target.value) }} clearInput placeholder="Obbligatorio"></IonInput>
                </IonItem>

                <IonItem fill="outline">
                  <IonLabel position="stacked">Inserisci mansione</IonLabel>
                  <IonInput value={inputMansioneModifica} onIonInput={(ev: any) => { setInputMansioneModifica(ev.target.value) }} clearInput placeholder="Obbligatorio"></IonInput>
                </IonItem>

                <IonItem fill="outline">
                  <IonLabel position="stacked">Inserisci descrizione</IonLabel>
                  <IonInput value={inputDescrizioneModifica} onIonInput={(ev: any) => { setInputDescrizioneModifica(ev.target.value) }} clearInput placeholder="Obbligatorio"></IonInput>
                </IonItem>

                <IonItem fill="outline">
                  <IonLabel position="stacked">Inserisci titolo di studio o certificati</IonLabel>
                  <IonInput value={inputTitoloDiStudioModifica} onIonInput={(ev: any) => { setInputTitoloDiStudioModifica(ev.target.value) }} clearInput placeholder="Facoltativo"></IonInput>
                </IonItem>

                <IonItem fill="outline">
                  <IonLabel position="stacked">Inserisci link Instagram</IonLabel>
                  <IonInput value={inputInstagramLinkModifica} onIonInput={(ev: any) => { setInputInstagramLinkModifica(ev.target.value) }} clearInput placeholder="Facoltativo"></IonInput>
                </IonItem>

                <IonItem fill="outline">
                  <IonLabel position="stacked">Inserisci link Facebook</IonLabel>
                  <IonInput value={inputFacebookLinkModifica} onIonInput={(ev: any) => { setInputFacebookLinkModifica(ev.target.value) }} clearInput placeholder="Facoltativo"></IonInput>
                </IonItem>

                <IonItem fill="outline">
                  <IonLabel position="stacked">Inserisci link Linkedin</IonLabel>
                  <IonInput value={inputLinkedinLinkModifica} onIonInput={(ev: any) => { setInputLinkedinLinkModifica(ev.target.value) }} clearInput placeholder="Facoltativo"></IonInput>
                </IonItem>

                <IonItem fill="outline">
                  <IonLabel position="stacked">Inserisci link Sito web</IonLabel>
                  <IonInput value={inputSitoWebLinkModifica} onIonInput={(ev: any) => { setInputSitoWebLinkModifica(ev.target.value) }} clearInput placeholder="Facoltativo"></IonInput>
                </IonItem>

              </IonList>
            </div>

          </IonContent>
        </IonModal>


      </IonContent>
    </IonPage>
  );
};

export default PersonaleScreen;
