import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonReorder,
  IonReorderGroup,
  IonTitle,
  IonToolbar,
  ItemReorderEventDetail,
  useIonAlert,
  useIonLoading,
  useIonToast,
} from "@ionic/react";
import {
  addCircle,
  alertCircleOutline,
  arrowBack,
  close,
  cloudDoneOutline,
  pencil,
  trash,
} from "ionicons/icons";
import React, { useState } from "react";
import { useParams } from "react-router";
import { Categoria } from "../data/dataSheet";
import { UploadDataCategorie, UploadDataSezioni } from "../Logic/DataUpload";
import { LocalSaveAttivita } from "../Logic/LocalStorage";
import "../pages/CSS/CategorieScreen.css";
import { url_app_attivita_init } from "../Urls";
import ModaleModificaProdotti from "../components/Modale_Prodotti/ModaleModificaProdotti";
import ModaleImportaDaInventario from "../components/Modale_ImportaDaInventario_CATEGORIE/ModaleImportaDaInventario";
import ModalModificaCategoriaAttività from "../components/Modale_Categorie/ModalModificaCategoriaAttività";

type CategorieScreenProps = {
  AllData: any;
  setAllData: any;
  prodotti: any;
  categorieGenerali: any;
  cartelle: any,
  allImmagini: any,
};

const CategorieScreen: React.FC<CategorieScreenProps> = ({
  AllData,
  setAllData,
  prodotti,
  categorieGenerali,
  cartelle,
  allImmagini,

}) => {
  //VARIABILI --------------------------------------------
  const { indiceAttivita } = useParams<{ indiceAttivita: string }>();
  const index = indiceAttivita.split("-")[1];
  const [dataAttivita, setDataAttivita] = React.useState<any>();
  const [isModalAddOpen, setIsModalAddOpen] = React.useState(false);
  const [isModalAddModelloOpen, setIsModalAddModelloOpen] =
    React.useState(false);
  const [categorie, setCategorie] = React.useState<any>([]);

  const [allProdotti, setAllProdotti] = React.useState<any>([]);

  const [presentAlert] = useIonAlert();

  //Aggiungi --------


  //Modifica --------
  const [isModalModificheOpen, setIsModalModificheOpen] = React.useState(false);
  const [isModalModificheProdottiOpen, setIsModalModificheProdottiOpen] =
    React.useState(false);

  const [indiceModifica, setIndiceModifica] = React.useState<number>();
  const [idModifica, setIdModifica] = React.useState<number>();
  const [inputNomeModifiche, setInputNomeModifiche] = React.useState();
  const [inputDescrizioneModifiche, setInputDescrizioneModifiche] =
    React.useState();
  const [inputImmagineModifiche, setInputImmagineModifiche] = React.useState();
  const [inputProdottiModifiche, setInputProdottiModifiche] =
    React.useState<any>([]);

  const [linkImmagineModifiche, setLinkImmagineModifiche] = React.useState();

  const [present, dimiss] = useIonLoading();
  const [presentToast] = useIonToast();


  const [allSezioni, setAllSezioni] = useState<any>();

  const [oggettoDaModificare, setOggettoDaModificare] = useState<any>();

  //CONDIZIONI -------------------------------------------

  React.useState(() => {
    const data = AllData;
    setDataAttivita(data[parseInt(index)]);
    setCategorie(data[parseInt(index)].categorie);
    setAllSezioni(data[parseInt(index)].sezioni);
    setAllProdotti(prodotti);
  });

  const handleOrdinamento = (ev: CustomEvent<ItemReorderEventDetail>) => {
    console.log("handleOrdinamento()");
    setCategorie(ev.detail.complete(categorie));
    salvaOrdinamento(ev.detail.complete(categorie));
    ev.detail.complete();
  };

  const salvaOrdinamento = async (categoria: any) => {
    console.log("salvaOrdinamento()");
    let a = [...AllData];
    a[parseInt(index)].categorie = categoria;
    await UploadDataCategorie(a[parseInt(index)], a[parseInt(index)].id);
    LocalSaveAttivita(a);
    setAllData(a);
  };

  const rimuoviCategoria = async (ind: number) => {
    console.log("rimuoviCategoria()");
    let a = [...AllData];
    let b = [...a[parseInt(index)].categorie!];
    b.splice(ind, 1);
    setCategorie(b);
    a[parseInt(index)].categorie = b;
    await UploadDataCategorie(a[parseInt(index)], a[parseInt(index)].id);
    LocalSaveAttivita(a);
    setAllData(a);
  };



  React.useEffect(() => {
    if (linkImmagineModifiche !== undefined) {
      const oggettoCategoria = {
        id: idModifica,
        nome: inputNomeModifiche,
        descrizione: inputDescrizioneModifiche,
        immagineUrl: linkImmagineModifiche,
        prodotti: inputProdottiModifiche,
      };
      console.log(oggettoCategoria);
      handleUploadModifiche(oggettoCategoria);
    }
  }, [linkImmagineModifiche]);

  const handleUploadModifiche = async (oggettoModificato: any) => {
    console.log(
      "handleUploadModifiche - oggettoModificato:",
      oggettoModificato
    );
    let a = [...AllData];
    a[parseInt(index)].categorie[indiceModifica!] = oggettoModificato;
    setAllData(a);

    await UploadDataCategorie(a[parseInt(index)], a[parseInt(index)].id).then(() => {
      handleAggiornaAltreSezioni(oggettoModificato);
    });

    LocalSaveAttivita(a);
    resetAllDataInput();
    dimiss();
    presentToastType("Categoria salvata", "success", () => { });
  };

  const salvaAllData = async (oggettoCategoria: any) => {
    console.log("salvaAllData - oggettoCategoria :", oggettoCategoria);
    let a = [...AllData];
    a[parseInt(index)].categorie?.push(oggettoCategoria);
    setAllData(a);

    await UploadDataCategorie(a[parseInt(index)], a[parseInt(index)].id).then(() => {
      LocalSaveAttivita(a);
      resetAllDataInput();
      dimiss();
      presentToastType("Categoria salvata", "success", () => { });
    }).catch((err) => {
      resetAllDataInput();
      dimiss();
      console.error(err);
      presentToastType("ERRORE: " + err, "danger", () => { });
    });
    dimiss();
  };


  const resetAllDataInput = () => {
    setIdModifica(undefined);
    setInputNomeModifiche(undefined);
    setInputDescrizioneModifiche(undefined);
    setInputImmagineModifiche(undefined);
    setInputProdottiModifiche([]);
  };



  // const handleAggiorna = async () => {
  //   let a = [...categorie];

  //   present({
  //     message: "Sto aggiornando le categorie con i prodotti aggiornati..."
  //   })

  //   a?.map((categoria: Categoria, indexCategoria: number) => {
  //     categoria.prodotti?.map((prodotto: Prodotto, indexProdotto: number) => {

  //       let prodottoAggiornato = allProdotti.find((prods: Prodotto) => prods.id === prodotto.id);

  //       console.log("trovato in all prodotti : ", prodottoAggiornato);

  //       if (prodottoAggiornato === undefined) {
  //         a[indexCategoria].prodotti.splice(indexProdotto, 1);
  //       } else {
  //         a[indexCategoria].prodotti[indexProdotto] = prodottoAggiornato;
  //       }
  //     })
  //   })

  //   let aD = [...AllData];
  //   aD[parseInt(index)].categorie = a

  //   await UploadDataCategorie(aD[parseInt(index)], aD[parseInt(index)].id);
  //   setCategorie(a);
  //   dimiss();
  // }

  const presentToastType = (
    message: string,
    type: "danger" | "success" | "warning",
    buttonEv: () => void
  ) => {
    const iconType = {
      danger: close,
      success: cloudDoneOutline,
      warning: alertCircleOutline,
    };

    presentToast({
      message: message,
      position: "top",
      duration: 3000,
      icon: iconType[type],
      color: type,
      buttons: [
        {
          text: "Chiudi",
          role: "cancel",
          handler: () => {
            buttonEv();
          },
        },
      ],
    });
  };

  const presentUploading = () => {
    present({
      message: "Sto salvando la categoria..."
    })
  }

  const handleAggiungiModelloCategoria = (data: any) => {
    present({
      message: "Sto aggiungendo il modello della categoria...",
    });
    salvaAllData(data);
  };


  const handleAggiornaAltreSezioni = (categoriaModifiacata: any) => {
    console.log("handleAggiornaAltreSezioni : Funzione ancora da implementare: ", allSezioni);
    let a = [...AllData];
    let allSezioniAttivita = [...allSezioni];
    // Per ogni sezione in allSezioni: individuare la categoriaModificata:
    allSezioniAttivita.map((sezione: any, indexSezione: any) => {
      sezione.categorie.map((categoriaSezione: any, indexCategoria: any) => {
        if (categoriaSezione.id === categoriaModifiacata.id) {
          allSezioniAttivita[indexSezione].categorie[indexCategoria] = categoriaModifiacata;
        }
      })
    })
    console.log("allSezioniAttivita AGGIORNATE : ", allSezioniAttivita);
    a[parseInt(index)].sezioni = allSezioniAttivita;
    UploadDataSezioni(a[parseInt(index)], a[parseInt(index)].id);
  }

  //RETURN -----------------------------------------------
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton
              routerLink={url_app_attivita_init + indiceAttivita}
              routerDirection="back"
            >
              <IonIcon icon={arrowBack} />
              {dataAttivita?.nome}
            </IonButton>
          </IonButtons>
          <IonTitle>Categorie</IonTitle>
          {/* <IonButtons slot='end'>
            <IonButton size='large' color={"success"} onClick={() => { handleAggiorna() }}>Aggiorna</IonButton>
          </IonButtons> */}
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Categorie</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div>
          <div className="ion-padding">

            {/* <IonButton
              expand="block"
              fill="solid"
              onClick={() => setIsModalAddOpen(true)}
            >
              <IonIcon icon={addCircle} className="ion-margin-end" />
              Crea categoria
            </IonButton> */}

            <IonButton
              className="ion-margin-top"
              expand="block"
              fill="solid"
              onClick={() => setIsModalAddModelloOpen(true)}
            >
              <IonIcon icon={addCircle} className="ion-margin-end" />
              Importa da inventario
            </IonButton>
          </div>

          <div>
            <IonList>
              <IonReorderGroup
                disabled={false}
                onIonItemReorder={handleOrdinamento}
              >
                {categorie?.map((item: Categoria, index: number) => {
                  return (
                    <IonItem key={index + "categorie"}>
                      <IonButton
                        color={"danger"}
                        slot="start"
                        fill="solid"
                        onClick={() =>
                          presentAlert({
                            header: "Attenzione!",
                            message:
                              "L'eliminazione è definitiva! La categoria non sarà rimossa dalle sezioni in cui è presente.",
                            buttons: [
                              {
                                text: "Annulla",
                                role: "cancel",
                              },
                              {
                                text: "Conferma",
                                role: "confirm",
                                handler: () => {
                                  rimuoviCategoria(index);
                                },
                              },
                            ],
                          })
                        }
                      >
                        <IonIcon icon={trash} />
                      </IonButton>
                      <IonButton
                        slot="start"
                        fill="solid"
                        onClick={() => {
                          setIsModalModificheOpen(true)
                          setOggettoDaModificare(item);
                          setIndiceModifica(index);
                        }}
                      >
                        <IonIcon icon={pencil} />
                      </IonButton>

                      <IonAvatar className="ion-margin-end">
                        <IonImg src={item.immagineUrl}></IonImg>
                      </IonAvatar>
                      <IonLabel>
                        <h2>
                          {item.nome}
                        </h2>
                        <p>{item?.descrizione}</p>
                        <p>
                          Prodotti: {item?.prodotti?.length}
                        </p>
                      </IonLabel>
                      <IonReorder slot="end"></IonReorder>
                    </IonItem>
                  );
                })}
              </IonReorderGroup>
            </IonList>
          </div>
        </div>

        {/*------------------- MODALE PRODOTTI ------------------*/}
        <ModaleModificaProdotti
          isOpen={isModalModificheProdottiOpen}
          setIsOpen={setIsModalModificheProdottiOpen}
          inputProdotti={inputProdottiModifiche}
          setInputProdotti={setInputProdottiModifiche}
          allProdotti={allProdotti} />


        {/* ------------------ MODALE IMPORTA MODELLO DA INVENTARIO  -------------------- */}
        <ModaleImportaDaInventario
          isOpen={isModalAddModelloOpen}
          setIsOpen={setIsModalAddModelloOpen}
          elements={categorieGenerali}
          handleAddElement={handleAggiungiModelloCategoria}
        />


        {/* ------------------ MODAL MODIFICA -------------------- */}

        <ModalModificaCategoriaAttività
          isOpen={isModalModificheOpen}
          setIsOpen={setIsModalModificheOpen}
          cartelle={cartelle}
          allImmagini={allImmagini}
          allProdotti={allProdotti}
          AllSezioni={allSezioni}
          handleAggiornaAltreSezioni={handleAggiornaAltreSezioni}
          present={presentUploading}
          oggettoDaModificare={oggettoDaModificare}
          indModifica={indiceModifica}
          allCategorie={categorie}
          handleUploadModifiche={handleUploadModifiche}
        />

      </IonContent>
    </IonPage>
  );
};

export default CategorieScreen;
