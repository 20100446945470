import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonChip,
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonActionSheet,
  useIonAlert,
  useIonToast,
} from "@ionic/react";
import { useParams } from "react-router";
import "./OrdersTableScreen.css";
import { useEffect, useState } from "react";
import { QRCode } from "react-qrcode-logo";
import OrderComponent from "../components/OrderComponent";
import {
  ChiudiContoParziale,
  ChiudiContoTotale,
  LiberaTavolo,
  UpdateOrder,
} from "../Logic/DataUpload";
import { useAuth } from "../auth";
import { url_app_tavoli_init } from "../Urls";
import {
  arrowBack,
  checkmark,
  checkmarkDone,
  idCardOutline,
  languageOutline,
  personCircle,
  qrCode,
  settingsOutline,
} from "ionicons/icons";
import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../fb";
import html2canvas from "html2canvas";

interface PageProps {
  AllRealTables: any;
  AllData: any;
}

const OrdersTableScreen: React.FC<PageProps> = ({ AllRealTables, AllData }) => {
  //VARIABILI ------------------------------
  const baseURL = "https://menudemomenushare.web.app/t-"
  const { userId } = useAuth();

  const { indexTable, idAttivita } = useParams<{
    indexTable: string;
    idAttivita: string;
  }>();

  const [tableData, setTableData] = useState<any>();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalTotaleOpen, setIsModalTotaleOpen] = useState(false);
  const [dataCheck, setDataCheck] = useState<any>();

  const [presentActionSheet] = useIonActionSheet();
  const [presentAlertChiudiTavolo] = useIonAlert();
  const [presentConferma] = useIonToast();

  const [dataAttivita, setDataAttivita] = useState<any>();

  const [modaleQrCode, setModaleQrCode] = useState(false);

  const [uniqueUsers, setUniqueUsers] = useState<any>();
  const [userInfo, setUserInfo] = useState<any>();
  const [modaleUser, setModaleUser] = useState(false);
  const [filtro, setFiltro] = useState<any>("Visualizza tutti");

  const [personalCheck, setPersonalCheck] = useState(0);
  const [totalCheck, setTotalCheck] = useState(0);

  //CONDIZIONI -----------------------------
  useEffect(() => {
    setTableData(AllRealTables.data[indexTable]);
    AllData.map((attivita: any, ind: number) => {
      if (attivita?.realTables?.id === AllRealTables.id)
        setDataAttivita(attivita);
    });

    //DEFINE USER ------
    let resArr: any[] = [];
    AllRealTables.data[indexTable]
      ?.data()
      ?.usersConnected?.filter(function (item: any) {
        var i = resArr.findIndex((x) => x.userUID == item.userUID);
        if (i <= -1) {
          resArr.push(item);
        }
        return null;
      });
    setUniqueUsers(resArr);
  }, [AllRealTables]);

  const handleInProgressOrder = (index: number) => {
    let x = [...tableData?.data()?.orders];
    x[index].personaleOperanteUID = userId;
    x[index].status = "Preparazione in corso";
    console.log(x);
    UpdateOrder(AllRealTables?.id, tableData?.id, x);
  };
  const handleCompleteOrder = (index: number) => {
    let x = [...tableData?.data()?.orders];
    x[index].personaleOperanteUID = userId;
    x[index].status = "Consegnato";
    console.log(x);
    UpdateOrder(AllRealTables?.id, tableData?.id, x);
  };

  const handleOpzioni = () => {
    presentActionSheet({
      header: "Opzioni tavolo",
      subHeader:
        "Non è possbilie cambiare il nome. In caso crearne uno nuovo ed elimare questo.",
      buttons: [
        {
          text: "Elimina tavolo",
          role: "Elimina tavolo",
          handler: async () => {
            eliminaTavolo(idAttivita);
          },
        },
        {
          text: "Chiudi",
          role: "cancel",
        },
      ],
    });
  };

  const eliminaTavolo = (idatt: any) => {
    presentAlertChiudiTavolo({
      header: "Attenzione!",
      message:
        "Stai per eliminare un tavolo definitivamente.",
      buttons: [
        {
          text: "Annulla",
          role: "cancel",
          handler: () => { },
        },
        {
          text: "Elimina",
          role: "confirm",
          handler: () => {
            deleteDoc(doc(db, "RealTables-" + idatt, tableData?.id)).then(() => {
              console.log("ELIMINAZIONE TAVOLO : ", idatt);
              presentConferma({
                message: "Tavolo eliminato",
                color: "success",
                duration: 500,
                position: "top",
              });
            });
          },
        },
      ],
    });

  };

  const handleChiudiTavolo = () => {
    presentAlertChiudiTavolo({
      header: "Chiusura sessione",
      message:
        "Attenzione! PRIMA verificare che tutti i conti siano stati pagati.",
      buttons: [
        {
          text: "Annulla",
          role: "cancel",
          handler: () => { },
        },
        {
          text: "Chiudi sessione",
          role: "confirm",
          handler: () => {
            verificaPreChiusura();
          },
        },
      ],
    });
  };

  const verificaPreChiusura = async () => {
    //tableData?.data()?
    await LiberaTavolo(
      AllRealTables?.id,
      tableData?.id,
      idAttivita,
      tableData?.data()
    );
  };

  const handleOpenCheckInfo = (info: any, index: number) => {
    let totalCheck = 0;

    info.order.map((ord: any, index: number) => {
      totalCheck += parseFloat(ord.prezzo);
    })

    let obj = {
      i: index,
      data: info,
      check: totalCheck
    };

    setDataCheck(obj);
    console.log(obj);
    setIsModalOpen(true);
  };

  const handleChiudiConto = async () => {
    setIsModalOpen(false);
    let x = [...tableData?.data()?.requestedChecks];
    x[dataCheck?.i].status = "Pagato";
    x[dataCheck?.i].personaleOperanteUID = userId;
    console.log(x);

    await ChiudiContoParziale(AllRealTables?.id, tableData?.id, x).then(() => {
      presentConferma({
        message: "Conto parziale chiuso con successo",
        color: "success",
        duration: 1000,
        position: "top",
      });
    });
  };

  const handleTotalePagato = async () => {
    setIsModalTotaleOpen(false);
    let x = [...tableData?.data()?.totalCheck];
    x[0].status = "Pagato";
    x[0].personaleOperanteUID = userId;
    x[0].dataChiusuraConto = new Date();
    console.log(x);
    await ChiudiContoTotale(AllRealTables?.id, tableData?.id, x).then(() => {
      presentConferma({
        message: "Conto totale chiuso con successo",
        color: "success",
        position: "top",
        duration: 2500,
        buttons: [
          {
            text: "Chiudi sessione",
            handler: () => {
              handleChiudiTavolo();
            }
          }
        ]
      });
    });
  };

  // const handleDownload = () => {
  // 	html2canvas(document.querySelector('#react-qrcode-logo') as any)
  // 		.then(function (canvas) {
  // 			const link = document.createElement('a');
  // 			link.download = 'Tavolo'+tableData?.id+'.png';
  // 			link.href = canvas.toDataURL();
  // 			link.click();
  // 		});
  // }

  const handelFiltraOrdini = (filtro: string) => {
    setFiltro(filtro);
    let arr = Array.from(
      window.document.getElementsByClassName("orderComponent")
    );
    const query = filtro.toLowerCase();
    requestAnimationFrame(() => {
      arr.forEach((item: any) => {
        const shouldShow = item.textContent.toLowerCase().indexOf(query) > -1;
        item.style.display = shouldShow ? "block" : "none";
      });
    });
    if (filtro === "Visualizza tutti") {
      requestAnimationFrame(() => {
        arr.forEach((item: any) => {
          item.style.display = "block";
        });
      });
    }
  };

  //RETURN ---------------------------------
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton
              routerLink={url_app_tavoli_init + idAttivita}
              routerDirection="back"
            >
              <IonIcon icon={arrowBack} />
              Tutti i tavoli
            </IonButton>
          </IonButtons>
          <IonTitle>Ordini tavolo {tableData?.id}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => setModaleQrCode(true)}>
              <IonIcon icon={qrCode} />
              Qr Code
            </IonButton>
            <IonButton onClick={() => handleOpzioni()}>
              <IonIcon icon={settingsOutline} />
              Opzioni
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Ordini tavolo {tableData?.id}</IonTitle>
          </IonToolbar>
        </IonHeader>
        {/* CONTENT */}

        {/* ----- UTENTI COLLEGATI ----- */}
        <div className="ion-padding">
          <IonLabel>
            <p>Utenti partecipanti: </p>
          </IonLabel>
          {uniqueUsers?.map((utente: any, index: number) => {
            return (
              <IonChip
                key={"user" + indexTable + idAttivita + index}
                onClick={() => {
                  setUserInfo(utente);
                  setModaleUser(true);
                }}
              >
                <IonAvatar>
                  <img
                    alt="User"
                    src="https://ionicframework.com/docs/img/demos/avatar.svg"
                  />
                </IonAvatar>
                <IonLabel>Utente {index + 1}</IonLabel>
              </IonChip>
            );
          })}
        </div>

        {/* ----- SINGOLI CONTI RICHIESTI ----- */}
        <div className="ion-padding">
          <IonLabel>
            <p>Conto richiesto:</p>
          </IonLabel>
          {tableData
            ?.data()
            ?.requestedChecks?.map((req: any, index: number) => {
              if (req.status === "Inviato") {
                return (
                  <IonButton
                    key={"reqCheck" + index}
                    color={"warning"}
                    expand="block"
                    onClick={() => handleOpenCheckInfo(req, index)}
                  >
                    Richiesta conto parziale
                  </IonButton>
                );
              }

            })}

          {tableData?.data()?.totalCheck?.map((req: any, index: number) => {
            if (req.status === "Inviato")
              return (
                <IonButton
                  key={"reqCheck" + index}
                  color={"danger"}
                  expand="block"
                  onClick={() => setIsModalTotaleOpen(true)}
                >
                  Richiesta conto totale € {req.totalCheck}
                </IonButton>
              );
          })}
        </div>

        {/* ----- ORDINI EFFETTUATI  ----- */}
        <div>
          <div className="ion-padding">
            <IonLabel>
              <p>
                Filtra visualizzazione ordini ricevuti:
                <IonChip color={"primary"} outline>
                  {filtro}
                  <IonIcon
                    icon={checkmark}
                    className="ion-margin-start"
                  ></IonIcon>
                </IonChip>
              </p>
            </IonLabel>
            <IonChip onClick={() => handelFiltraOrdini("Visualizza tutti")}>
              Visualizza tutti
            </IonChip>

            <IonChip
              color={"warning"}
              onClick={() => handelFiltraOrdini("Inviato")}
            >
              Inviato
            </IonChip>

            <IonChip
              color={"primary"}
              onClick={() => handelFiltraOrdini("Preparazione in corso")}
            >
              Preparazione in corso
            </IonChip>

            <IonChip
              color={"success"}
              onClick={() => handelFiltraOrdini("Consegnato")}
            >
              Consegnato
            </IonChip>
          </div>

          {tableData?.data()?.orders?.map((ordine: any, index: number) => {
            return (
              <div key={"AllRealTables[indexTable]" + index}>
                <OrderComponent
                  data={ordine}
                  handleInProgressOrder={() => handleInProgressOrder(index)}
                  handleCompleteOrder={() => handleCompleteOrder(index)}
                />
              </div>
            );
          })}
        </div>


        <div className="ion-padding">
          {
            tableData?.data()?.isFree == false ?
              <IonButton
                color={"success"}
                onClick={() => handleChiudiTavolo()}
                expand="block"
              >
                Chiudi sessione
              </IonButton>
              :
              <IonLabel>
                <p className="ion-text-center">
                  Non c'è nessun cliente al tavolo
                </p>
              </IonLabel>
          }

        </div>

        {/* -------- MODALE USER INFO ----------- */}
        <IonModal
          isOpen={modaleUser}
          onDidDismiss={() => {
            setModaleUser(false);
          }}
        >
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton
                  color={"medium"}
                  onClick={() => setModaleUser(false)}
                >
                  Chiudi
                </IonButton>
              </IonButtons>
              <IonTitle>Info utente</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <IonList>
              <IonItem>
                <IonIcon icon={personCircle} />
                <IonLabel className="ion-text-wrap ion-margin-start">
                  <h2>Identificativo:</h2>
                  <p>{userInfo?.userUID}</p>
                </IonLabel>
              </IonItem>
              <IonItem>
                <IonIcon icon={languageOutline} />
                <IonLabel className="ion-text-wrap ion-margin-start">
                  <h2>Lingua:</h2>
                  <p>{userInfo?.user.lingua}</p>
                </IonLabel>
              </IonItem>
              <IonItem>
                <IonIcon icon={idCardOutline} />
                <IonLabel className="ion-text-wrap ion-margin-start">
                  <h2>Informazioni dispositivo:</h2>
                  <p>{userInfo?.user.userAgent}</p>
                </IonLabel>
              </IonItem>
            </IonList>
            <IonLabel>
              <h2 className="ion-padding-top">Ordini:</h2>
            </IonLabel>
            <IonList>
              {tableData?.data()?.orders?.map((ordine: any, index: number) => {
                if (ordine.userUID === userInfo?.userUID)
                  return ordine.order.map((ord: any, inde: number) => {
                    return (
                      <IonItem key={"ordine.userUID" + ordine.userUID + index}>
                        <IonLabel>
                          <h2>{ord.product.nome}</h2>
                        </IonLabel>
                      </IonItem>
                    );
                  });
              })}
            </IonList>
          </IonContent>
        </IonModal>

        {/* -------- MODALE QR CODE ----------- */}
        <IonModal
          isOpen={modaleQrCode}
          onDidDismiss={() => {
            setModaleQrCode(false);
          }}
        >
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton
                  color={"medium"}
                  onClick={() => setModaleQrCode(false)}
                >
                  Chiudi
                </IonButton>
              </IonButtons>
              <IonTitle>{tableData?.id}</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            {/* <IonButton expand='block' onClick={()=>handleDownload()}>
              <IonIcon icon={downloadOutline} />
              Scarica
            </IonButton>
             */}
            <div id="qrCode">
              <QRCode
                value={baseURL + tableData?.id}
                ecLevel={"Q"}
                size={300}
                removeQrCodeBehindLogo={true}
                logoPadding={10}
              />
              ,
            </div>
          </IonContent>
        </IonModal>

        {/* -------- MODALE ----------- */}
        <IonModal
          isOpen={isModalOpen}
          onDidDismiss={() => {
            setIsModalOpen(false);
          }}
        >
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton
                  color={"medium"}
                  onClick={() => setIsModalOpen(false)}
                >
                  Annulla
                </IonButton>
              </IonButtons>
              <IonTitle>Conto</IonTitle>
              <IonButtons slot="end">
                <IonButton
                  fill="solid"
                  color={"success"}
                  onClick={() => handleChiudiConto()}
                >
                  Conto pagato
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <div className="ion-padding-bottom">
              <IonButton expand="block">
                Totale da pagare : € {dataCheck?.check}
              </IonButton>
            </div>
            <IonList>
              {dataCheck?.data?.order?.map((ordine: any, index: number) => {
                return (
                  <div key={"Check" + index}>
                    <IonItem>
                      <IonAvatar>
                        <IonImg src={ordine.immaginiUrl[0]}></IonImg>
                      </IonAvatar>
                      <IonLabel className="ion-padding-start">
                        <h2>{ordine.nome}</h2>
                        <p>€ {ordine.prezzo}</p>
                      </IonLabel>
                    </IonItem>
                  </div>
                );
              })}
            </IonList>
          </IonContent>
        </IonModal>

        {/* -------- MODALE ----------- */}
        <IonModal
          isOpen={isModalTotaleOpen}
          onDidDismiss={() => {
            setIsModalTotaleOpen(false);
          }}
        >
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton
                  color={"medium"}
                  onClick={() => setIsModalTotaleOpen(false)}
                >
                  Annulla
                </IonButton>
              </IonButtons>
              <IonTitle>Conto totale da pagare</IonTitle>
              <IonButtons slot="end">
                <IonButton
                  fill="solid"
                  color={"success"}
                  onClick={() => handleTotalePagato()}
                >
                  Conto pagato
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <div className="ion-padding-bottom">
              {tableData?.data()?.totalCheck ? (
                <IonButton expand="block">
                  Totale da pagare : €{" "}
                  {tableData?.data()?.totalCheck[0]?.totalCheck}
                </IonButton>
              ) : null}
            </div>
          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default OrdersTableScreen;
