import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonTitle,
  IonToolbar,
  useIonLoading,
  useIonToast
} from '@ionic/react';
import './ModaleCategoria.css';
import {
  useEffect,
  useState
} from 'react';
import {
  alertCircleOutline,
  checkmark,
  close,
  cloudDoneOutline,
} from 'ionicons/icons';
import { UploadNuovaCategoria } from '../../Logic/DataUpload';
import { UploadSingleImageFirebase } from '../../Logic/ImageUpload';
import ModaleSelezionaProdotti from '../Modale_Prodotti/ModaleSelezionaProdotti';
import ModalInputImmagini from '../Modal_Input_Immagini/ModalInputImmagineSingola';
import CardSelezionaProdotti from '../CardSelezionaProdotti';
import CardAddAutomaticToSezione from '../CardAddAutomaticToSezione';

interface ContainerProps {
  isOpen: boolean,
  setIsOpen: any,
  cartelle: any,
  allImmagini: any,
  allProdotti: any,


  AllSezioni: any,
  handleAddToAltreSezioni: any,
  UpdateAll: any,

}

const ModaleCreaCategoriaInventario: React.FC<ContainerProps> = ({
  isOpen,
  setIsOpen,
  cartelle,
  allImmagini,
  allProdotti,
  AllSezioni,
  handleAddToAltreSezioni,
  UpdateAll,
}) => {
  //VARIABILI --------------------------------------------
  const folder = "/categorie";

  const [isModalAddProdottiOpen, setIsModalAddProdottiOpen] = useState(false);


  const [id, setId] = useState<any>();
  const [inputNome, setInputNome] = useState();
  const [inputDescrizione, setInputDescrizione] = useState();
  const [inputImmagine, setInputImmagine] = useState();
  const [inputProdotti, setInputProdotti] = useState<any>([]);

  const [linkImmagine, setLinkImmagine] = useState();

  const [immagineSelezionata, setImmagineSelezionata] =
    useState<any>(undefined);

  const [arrAutomaticAdd, setArrAutomaticAdd] = useState<any>([]);

  const [presentToast] = useIonToast();
  const [present, dimiss] = useIonLoading();
  //CONDIZIONI -------------------------------------------


  const handleSalvaCategoria = async () => {
    if (immagineSelezionata !== undefined && inputNome !== undefined && inputDescrizione !== undefined) {
      present({
        message: "Sto salvando la categoria...",
      });
      let auxId = Date.now();
      setId(auxId);

      const oggettoCategoria = {
        id: auxId,
        nome: inputNome,
        descrizione: inputDescrizione,
        immagineUrl: immagineSelezionata,
        prodotti: inputProdotti,
      };
      console.log(inputProdotti);
      UploadNuovaCategoria(oggettoCategoria, auxId).then(() => {
        if (AllSezioni !== undefined) {
          handleAddToAltreSezioni(arrAutomaticAdd, oggettoCategoria);
        }
        setIsOpen(false);
        UpdateAll();
        dimiss();
        resetAllInputs();
      });
    } else {
      if (inputNome !== undefined && inputDescrizione !== undefined) {

        present({
          message: "Sto salvando la categoria...",
        });
        let auxId = Date.now();
        setId(auxId);

        await UploadSingleImageFirebase(
          inputImmagine!,
          auxId,
          setLinkImmagine,
          folder
        );
      } else {
        presentToastType(
          "ATTENZIONE! Inserire tutti i campi obbligatori",
          "warning",
          () => { }
        );
      }
    }
  };


  useEffect(() => {
    if (linkImmagine !== undefined) {
      const oggettoCategoria = {
        id: id,
        nome: inputNome,
        descrizione: inputDescrizione,
        immagineUrl: linkImmagine,
        prodotti: inputProdotti,
      };
      console.log(inputProdotti);
      UploadNuovaCategoria(oggettoCategoria, id).then(() => {
        handleAddToAltreSezioni(arrAutomaticAdd, oggettoCategoria);
        setIsOpen(false);
        UpdateAll();
        dimiss();
        resetAllInputs();
      });
    }
  }, [linkImmagine]);


  const handleRimuoviProdotto = (index: number) => {
    setInputProdotti((inputProdotti: any) =>
      inputProdotti.filter((_: any, i: number) => i !== index)
    );
  };

  const resetAllInputs = () => {
    setId(undefined);
    setInputNome(undefined);
    setInputDescrizione(undefined);
    setInputImmagine(undefined);
    setInputProdotti([]);
    setLinkImmagine(undefined);
    setImmagineSelezionata(undefined);
  };

  const resetImageInput = () => {
    setLinkImmagine(undefined);
    setImmagineSelezionata(undefined);
  }

  const presentToastType = (
    message: string,
    type: "danger" | "success" | "warning",
    buttonEv: () => void,
    durationS?: number
  ) => {
    const iconType = {
      danger: close,
      success: cloudDoneOutline,
      warning: alertCircleOutline,
    };
    return presentToast({
      message: message,
      position: "top",
      duration: durationS ? durationS : 3000,
      icon: iconType[type],
      color: type,
      buttons: [
        {
          text: "Chiudi",
          role: "cancel",
          handler: () => {
            buttonEv();
          },
        },
      ],
    });
  };


  //RETURN -----------------------------------------------
  return (
    <>
      <IonModal
        isOpen={isOpen}
        onDidDismiss={() => {
          setIsOpen(false);
        }}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton
                color={"medium"}
                onClick={() => {
                  setIsOpen(false);
                  resetAllInputs()
                }}
              >
                Annulla
              </IonButton>
            </IonButtons>
            <IonTitle>Crea categoria</IonTitle>
            <IonButtons slot="end">
              <IonButton
                color={"success"}
                fill="solid"
                onClick={() => handleSalvaCategoria()}
              >
                Salva
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>

          <ModalInputImmagini
            resetImageInput={resetImageInput}
            setInputImmagine={setInputImmagine}
            immagineSelezionata={immagineSelezionata}
            setImmagineSelezionata={setImmagineSelezionata}
            cartelle={cartelle}
            allImmagini={allImmagini}
          />

          <div className="ion-padding windowDubleHeight">
            <IonList>
              <IonItem fill="outline">
                <IonLabel position="stacked">Inserisci nome</IonLabel>
                <IonInput
                  onIonInput={(ev: any) => {
                    setInputNome(ev.target.value);
                  }}
                  clearInput
                  placeholder="Obbligatorio"
                ></IonInput>
              </IonItem>

              <IonItem fill="outline">
                <IonLabel position="stacked">
                  Inserisci breve descrizione
                </IonLabel>
                <IonInput
                  onIonInput={(ev: any) => {
                    setInputDescrizione(ev.target.value);
                  }}
                  clearInput
                  placeholder="Obbligatorio"
                ></IonInput>
              </IonItem>
            </IonList>


            <CardSelezionaProdotti
              setModalOpen={setIsModalAddProdottiOpen}
              inputItems={inputProdotti}
              setInputItems={setInputProdotti}
              handleRemoveItem={handleRimuoviProdotto}
            />


            <CardAddAutomaticToSezione
              AllSezioni={AllSezioni}
              arrAutomaticAdd={arrAutomaticAdd}
              setArrAutomaticAdd={setArrAutomaticAdd} />

          </div>
        </IonContent>
      </IonModal>

      {/* MODALE SELEZIONA PRODOTTI */}
      <ModaleSelezionaProdotti
        isOpen={isModalAddProdottiOpen}
        setIsOpen={setIsModalAddProdottiOpen}
        inputProdotti={inputProdotti}
        setInputProdotti={setInputProdotti}
        allProdotti={allProdotti}
      />

    </>

  );
};

export default ModaleCreaCategoriaInventario;
