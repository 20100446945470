import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonChip,
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonRow,
  IonSearchbar,
  IonSegment,
  IonSegmentButton,
  IonThumbnail,
  IonTitle,
  IonToolbar,
  useIonAlert,
  useIonLoading,
  useIonToast,
} from "@ionic/react";
import {
  addCircle,
  alertCircleOutline,
  arrowBack,
  chevronUp,
  close,
  cloudDoneOutline,
  imagesOutline,
  pencil,
  trash,
} from "ionicons/icons";
import React, { useState } from "react";
import { Ingrediente, logoMenuShare } from "../data/dataSheet";
import { LocalSaveIngredienti } from "../Logic/LocalStorage";
import {
  DeleteSingleImage,
  UploadSingleImageFirebase,
} from "../Logic/ImageUpload";

import "../pages/CSS/IngredientiScreen.css";
import UploadSingleImage from "../components/UploadSingleImage";
import {
  DeleteDataIngrediente,
  UploadDataIngredienti,
} from "../Logic/DataUpload";
import { DataDownLoadIngredienti, defaultFolders } from "../Logic/DataDownload";
import { url_app_inventario } from "../Urls";

type PageProps = {
  UpdateAll: any;
  IngredientiDb: any;
  handleAggiornaTuttiIProdotti: (data: any) => void;
  allImmagini: any;
  cartelle: any;
};

const IngredientiScreen: React.FC<PageProps> = ({
  UpdateAll,
  IngredientiDb,
  handleAggiornaTuttiIProdotti,
  allImmagini,
  cartelle,
}) => {
  //VARIABILI ---------------------
  const folder = "ingredienti";
  const [allIngredienti, setAllIngredienti] = React.useState(IngredientiDb);

  const [presentAlert] = useIonAlert();
  const [searchTextModale, setSearchTextModale] = React.useState("");
  //aggiungi
  const [isModalAddOpen, setIsModalAddOpen] = React.useState<boolean>(false);

  const [id, setId] = React.useState<number>();
  const [inputNome, setInputNome] = React.useState("Non specificato.");
  const [inputMarca, setInputMarca] = React.useState("Non specificato.");
  const [inputDescrizione, setInputDescrizione] =
    React.useState("Non specificato.");
  const [immagine, setImmagine] = React.useState();

  //modifica
  const [indiceModifica, setIndiceModifica] = React.useState<number>();
  const [isModalModificheAddOpen, setIsModalModificheAddOpen] =
    React.useState<boolean>(false);

  const [idModifiche, setIdModifiche] = React.useState();
  const [inputNomeModifiche, setInputNomeModifiche] = React.useState();
  const [inputMarcaModifiche, setInputMarcaModifiche] = React.useState();
  const [inputDescrizioneModifiche, setInputDescrizioneModifiche] =
    React.useState();
  const [immagineModifiche, setImmagineModifiche] = React.useState();

  const [linkImmagine, setLinkImmagine] = React.useState(undefined);
  const [linkImmagineModifica, setLinkImmagineModifica] =
    React.useState(undefined);

  const [present, dimiss] = useIonLoading();
  const [presentToast] = useIonToast();

  const scrollRef = React.useRef<HTMLIonContentElement | null>(null);

  const [segmentValue, setSegmentValue] = useState("Seleziona");
  const [immagineSelezionata, setImmagineSelezionata] = useState();

  const [isModaleImmaginiOpen, setIsModaleImmaginiOpen] = useState(false);
  const [filtroCartelle, setFiltroCartelle] = useState<any>();
  const [filtroVisualizzazioneImmagini, setFiltroVisualizzazioneImmagini] =
    useState<any>("column-portfolio-4");

  //CONDIZIONI --------------------

  const handleSelectSegment = (segmentValue: string) => {
    setSegmentValue(segmentValue);
    //reset degli input di entrambi quando si cambia
    setImmagine(undefined);
    setImmagineModifiche(undefined);
    setImmagineSelezionata(undefined);
  };

  const handleFiltraImmagini = (testoCercato: string) => {
    let arr = Array.from(
      window.document.getElementsByClassName("immaginiGalleria")
    );
    const query = testoCercato.toLowerCase();
    requestAnimationFrame(() => {
      arr.forEach((item: any) => {
        const shouldShow = item.textContent.toLowerCase().indexOf(query) > -1;
        item.style.display = shouldShow ? "block" : "none";
      });
    });
  };

  const presentToastType = (
    message: string,
    type: "danger" | "success" | "warning",
    buttonEv: () => void
  ) => {
    const iconType = {
      danger: close,
      success: cloudDoneOutline,
      warning: alertCircleOutline,
    };

    presentToast({
      message: message,
      position: "top",
      duration: 3000,
      icon: iconType[type],
      color: type,
      buttons: [
        {
          text: "Chiudi",
          role: "cancel",
          handler: () => {
            buttonEv();
          },
        },
      ],
    });
  };

  const salvaNuovoIngrediente = async () => {
    if (inputNome !== "Non specificato.") {
      present({
        message: "Sto salvando l'ingrediente...",
      });
      setIsModalAddOpen(false);
      let auxId = Date.now();
      setId(auxId);
      //Carica immagine e poi si salva tramite il useEffect

      if (immagineSelezionata !== undefined) {
        const oggettoIngrediente = {
          id: auxId,
          nome: inputNome,
          marca: inputMarca,
          descrizione: inputDescrizione,
          immagineUrl: immagineSelezionata,
        };
        salvaAllData(oggettoIngrediente);
      } else {
        if (immagine === undefined) {
          const oggettoIngrediente = {
            id: auxId,
            nome: inputNome,
            marca: inputMarca,
            descrizione: inputDescrizione,
            immagineUrl: logoMenuShare,
          };
          salvaAllData(oggettoIngrediente);
        } else {
          await UploadSingleImageFirebase(
            immagine!,
            auxId,
            setLinkImmagine,
            folder
          );
        }
      }
    } else {
      presentToastType("ATTENZIONE! Nome non inserito", "warning", () => { });
    }
  };

  React.useEffect(() => {
    if (linkImmagine !== undefined) {
      const oggettoIngrediente = {
        id: id,
        nome: inputNome,
        marca: inputMarca,
        descrizione: inputDescrizione,
        immagineUrl: linkImmagine,
      };
      salvaAllData(oggettoIngrediente);
    }
  }, [linkImmagine]);

  const salvaAllData = async (oggettoIngrediente: any) => {
    let a = [...allIngredienti];
    a.push(oggettoIngrediente);
    console.log("salvaAllData - a : ", a);
    setAllIngredienti(a);
    LocalSaveIngredienti(a);

    await UploadDataIngredienti(oggettoIngrediente);
    //Reset All data input
    UpdateAll();
    resetAllDataInput();
    dimiss();
    presentToastType("Ingrediente salvato", "success", () => { });
  };

  const handleModifica = (index: number) => {
    // console.log("modifica", index, "Ingrediente : ", allIngredienti[index]);
    setIndiceModifica(index);
    setIsModalModificheAddOpen(true);
    setIdModifiche(allIngredienti[index].id);
    setInputNomeModifiche(allIngredienti[index].nome);
    setInputMarcaModifiche(allIngredienti[index].marca);
    setInputDescrizioneModifiche(allIngredienti[index].descrizione);
    setImmagineModifiche(allIngredienti[index].immagineUrl);
  };

  const handleSalvaModifiche = async () => {
    present({
      message: "Sto aggiornando l'ingrediente...",
    });
    setIsModalModificheAddOpen(false);

    if (immagineSelezionata !== undefined) {
      let oggettoModificato = {
        id: idModifiche,
        nome: inputNomeModifiche,
        marca: inputMarcaModifiche,
        descrizione: inputDescrizioneModifiche,
        immagineUrl: immagineSelezionata,
      };
      handleSalvaAllModifiche(oggettoModificato);
    } else {
      let oggettoModificato;
      if (immagineModifiche === allIngredienti[indiceModifica!].immagineUrl) {
        console.log("immagineModifiche è la stessa immagine");
        oggettoModificato = {
          id: idModifiche,
          nome: inputNomeModifiche,
          marca: inputMarcaModifiche,
          descrizione: inputDescrizioneModifiche,
          immagineUrl: immagineModifiche,
        };
        handleSalvaAllModifiche(oggettoModificato);
      } else {
        console.log("UPLOAD IMMAGINE");
        await UploadSingleImageFirebase(
          immagineModifiche!,
          idModifiche!,
          setLinkImmagineModifica,
          folder
        );
      }
    }
  };

  React.useEffect(() => {
    if (linkImmagineModifica !== undefined) {
      console.log("linkImmagineModifica : ", linkImmagineModifica);
      const oggettoModificato = {
        id: idModifiche,
        nome: inputNomeModifiche,
        marca: inputMarcaModifiche,
        descrizione: inputDescrizioneModifiche,
        immagineUrl: linkImmagineModifica,
      };
      handleSalvaAllModifiche(oggettoModificato);
    }
  }, [linkImmagineModifica]);

  const handleSalvaAllModifiche = async (oggettoModificato: any) => {
    let a = [...allIngredienti];
    a[indiceModifica!] = oggettoModificato;
    setAllIngredienti(a);
    LocalSaveIngredienti(a);
    await UploadDataIngredienti(oggettoModificato);
    dimiss();
    presentToastType("Ingrediente aggiornato.", "success", () => { });
    UpdateAll();
    resetAllDataInput();

    console.log("AGGIORNAMENTO TUTTI I PRODOTTI ...");
    handleAggiornaTuttiIProdotti(oggettoModificato);
  };

  const handleCancella = async (index: number) => {
    // console.log("cancella", index);

    let a = [...allIngredienti];

    let idIngrediente = a[index].id;
    console.log(idIngrediente);
    a.splice(index, 1);
    setAllIngredienti(a);
    LocalSaveIngredienti(a);
    await DeleteDataIngrediente(idIngrediente);
    // await DeleteSingleImage(idIngrediente, folder);
    UpdateAll();
  };

  const handleSearch = (testoCercato: string) => {
    let arr = Array.from(
      window.document.getElementsByClassName("ingredienteInventario")
    );
    const query = testoCercato.toLowerCase();
    requestAnimationFrame(() => {
      arr.forEach((item: any) => {
        const shouldShow = item.textContent.toLowerCase().indexOf(query) > -1;
        item.style.display = shouldShow ? "block" : "none";
      });
    });
  };

  const resetAllDataInput = () => {
    console.log("Reset All data input");
    setId(undefined);
    setInputNome("Non specificato.");
    setInputMarca("Non specificato.");
    setInputDescrizione("Non specificato.");
    setImmagine(undefined);
    setIndiceModifica(undefined);
    setIdModifiche(undefined);
    setInputNomeModifiche(undefined);
    setInputMarcaModifiche(undefined);
    setInputDescrizioneModifiche(undefined);
    setImmagineModifiche(undefined);
    setLinkImmagineModifica(undefined);
    setLinkImmagine(undefined);

    setImmagineSelezionata(undefined);
  };

  const getIngredienti = async () => {
    let a = await DataDownLoadIngredienti();
    // console.log("App - getIngredienti - a: ", (a));
    let b = [...a];
    return b;
  };

  const scrollToTop = () => {
    scrollRef.current && scrollRef.current.scrollToTop(500);
  };

  React.useState(async () => {
    // console.log(LocalGetIngredienti());
    setAllIngredienti(await getIngredienti());
    resetAllDataInput();
  });
  //RETURN ------------------------
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton routerLink={url_app_inventario} routerDirection="back">
              <IonIcon icon={arrowBack} />
              Inventario
            </IonButton>
          </IonButtons>
          <IonTitle>Ingredienti</IonTitle>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar
            value={searchTextModale}
            placeholder={"Cerca per nome o marca"}
            onIonChange={(e) => {
              setSearchTextModale(e.detail.value!);
              handleSearch(e.detail.value!);
            }}
          ></IonSearchbar>
          <div className="ion-padding">
            <IonButton
              expand="block"
              fill="solid"
              onClick={() => setIsModalAddOpen(true)}
            >
              <IonIcon icon={addCircle} className="ion-margin-end" />
              Aggiungi ingrediente
            </IonButton>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen ref={scrollRef}>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Ingredienti</IonTitle>
          </IonToolbar>
        </IonHeader>

        {/* Contenuto */}

        <IonFab slot="fixed" vertical="bottom" horizontal="start">
          <IonFabButton onClick={() => scrollToTop()}>
            <IonIcon icon={chevronUp}></IonIcon>
          </IonFabButton>
        </IonFab>

        <div>
          <IonList>
            {allIngredienti?.map((item: Ingrediente, index: number) => {
              let clas = "ingredienteInventario";
              if (item.marca === "Non specificato.") clas += " noMarca";
              if (
                item.immagineUrl ===
                "https://firebasestorage.googleapis.com/v0/b/menushare-a4100.appspot.com/o/logos%2FmenuShareLogo.jpg?alt=media&token=6f02aee1-26d7-4eca-9714-c3ba4515abfd"
              )
                clas += " noImmagine";

              return (
                <div key={index + "ingredienteInventario"} className={clas}>
                  <IonItem>
                    <IonThumbnail slot="start">
                      <img src={item.immagineUrl} alt="Immagine" />
                    </IonThumbnail>
                    <IonLabel>
                      {item.nome}
                      <p>Marca : {item.marca}</p>
                    </IonLabel>
                    <IonButton
                      color={"primary"}
                      slot="end"
                      onClick={() => handleModifica(index)}
                    >
                      <IonIcon icon={pencil} />
                    </IonButton>
                    {/* <IonButton color={"medium"} slot="end" onClick={() => handleArchivia(index)}>
                          <IonIcon icon={archive} ></IonIcon>
                        </IonButton> */}
                    <IonButton
                      color={"danger"}
                      slot="end"
                      onClick={() =>
                        presentAlert({
                          header: "Attenzione!",
                          message:
                            "L'eliminazione è definitiva! E rimarrà nei prodotti che lo contengono. Per un'eliminazione completa rimuoverlo anche dai prodotti.",
                          buttons: [
                            {
                              text: "Annulla",
                              role: "cancel",
                            },
                            {
                              text: "Conferma",
                              role: "confirm",
                              handler: () => {
                                handleCancella(index);
                              },
                            },
                          ],
                        })
                      }
                    >
                      <IonIcon icon={trash} />
                    </IonButton>
                  </IonItem>
                </div>
              );
            })}
          </IonList>
        </div>

        {/*---------------------------------- AGGIUNGI MODALI --------------------------------------------------*/}
        <IonModal
          isOpen={isModalAddOpen}
          onDidDismiss={() => {
            setIsModalAddOpen(false);
          }}
        >
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton
                  color={"medium"}
                  onClick={() => {
                    setImmagineSelezionata(undefined);
                    setIsModalAddOpen(false);
                  }}
                >
                  Annulla
                </IonButton>
              </IonButtons>
              <IonTitle>Nuovo Ingrediente</IonTitle>
              <IonButtons slot="end">
                <IonButton
                  fill="solid"
                  color={"success"}
                  onClick={() => {
                    salvaNuovoIngrediente();
                  }}
                >
                  Salva
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>

          <IonContent>
            <IonSegment value={segmentValue}>
              <IonSegmentButton
                value="Carica"
                onClick={() => handleSelectSegment("Carica")}
              >
                <IonLabel>Carica Immagine</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton
                value="Seleziona"
                onClick={() => handleSelectSegment("Seleziona")}
              >
                <IonLabel>Seleziona Immagine</IonLabel>
              </IonSegmentButton>
            </IonSegment>

            {segmentValue === "Carica" ? (
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>Carica immagine</IonCardTitle>
                  <IonCardSubtitle>
                    Se non carichi nessuna immagine sarà utilizzato il logo di
                    MenuShare
                  </IonCardSubtitle>
                </IonCardHeader>
                <IonCardContent>
                  <UploadSingleImage setImage={setImmagine} />
                </IonCardContent>
              </IonCard>
            ) : (
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>Seleziona immagine</IonCardTitle>
                  <IonCardSubtitle>
                    Se non selezioni nessuna immagine sarà utilizzato il logo di
                    MenuShare
                  </IonCardSubtitle>
                </IonCardHeader>
                <IonCardContent>
                  <IonButton
                    color={
                      immagineSelezionata !== undefined ? "success" : "primary"
                    }
                    expand="block"
                    onClick={() => setIsModaleImmaginiOpen(true)}
                  >
                    <IonIcon icon={imagesOutline} className="ion-margin-end" />
                    Seleziona
                  </IonButton>
                  {immagineSelezionata !== undefined ? (
                    <IonImg src={immagineSelezionata}></IonImg>
                  ) : null}
                </IonCardContent>
              </IonCard>
            )}

            <div className="ion-padding windowDubleHeight">
              <IonList>
                <IonItem fill="outline">
                  <IonLabel position="stacked">
                    Inserisci nome ingrediente
                  </IonLabel>
                  <IonInput
                    onIonInput={(ev: any) => {
                      setInputNome(ev.target.value);
                    }}
                    clearInput
                    placeholder="Obbligatorio"
                  ></IonInput>
                </IonItem>

                <IonItem fill="outline">
                  <IonLabel position="stacked">Inserisci nome marca</IonLabel>
                  <IonInput
                    onIonInput={(ev: any) => {
                      setInputMarca(ev.target.value);
                    }}
                    clearInput
                    placeholder="Facoltativo"
                  ></IonInput>
                </IonItem>

                <IonItem fill="outline">
                  <IonLabel position="stacked">Inserisci descrizione</IonLabel>
                  <IonInput
                    onIonInput={(ev: any) => {
                      setInputDescrizione(ev.target.value);
                    }}
                    clearInput
                    placeholder="Facoltativo"
                  ></IonInput>
                </IonItem>
              </IonList>
            </div>
          </IonContent>
        </IonModal>

        {/*---------------------------------- MODIFICA MODALI --------------------------------------------------*/}
        <IonModal
          isOpen={isModalModificheAddOpen}
          onDidDismiss={() => {
            setIsModalModificheAddOpen(false);
          }}
        >
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton
                  color={"medium"}
                  onClick={() => {
                    setImmagineSelezionata(undefined);
                    setIsModalModificheAddOpen(false);
                  }}
                >
                  Annulla
                </IonButton>
              </IonButtons>
              <IonTitle>Modifica</IonTitle>
              <IonButtons slot="end">
                <IonButton
                  fill="solid"
                  color={"success"}
                  onClick={() => handleSalvaModifiche()}
                >
                  Salva
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonSegment value={segmentValue}>
              <IonSegmentButton
                value="Carica"
                onClick={() => handleSelectSegment("Carica")}
              >
                <IonLabel>Carica Immagine</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton
                value="Seleziona"
                onClick={() => handleSelectSegment("Seleziona")}
              >
                <IonLabel>Seleziona Immagine</IonLabel>
              </IonSegmentButton>
            </IonSegment>

            {segmentValue === "Carica" ? (
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>Carica immagine</IonCardTitle>
                  <IonCardSubtitle>
                    Se non carichi nessuna immagine sarà utilizzato il logo di
                    MenuShare
                  </IonCardSubtitle>
                </IonCardHeader>
                <IonCardContent>
                  <UploadSingleImage
                    setImage={setImmagineModifiche}
                    immagine={immagineModifiche}
                  />
                </IonCardContent>
              </IonCard>
            ) : (
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>Seleziona immagine</IonCardTitle>
                  <IonCardSubtitle>
                    Se non selezioni nessuna immagine sarà utilizzato il logo di
                    MenuShare
                  </IonCardSubtitle>
                </IonCardHeader>
                <IonCardContent>
                  <IonButton
                    color={
                      immagineSelezionata !== undefined ? "success" : "primary"
                    }
                    expand="block"
                    onClick={() => setIsModaleImmaginiOpen(true)}
                  >
                    <IonIcon icon={imagesOutline} className="ion-margin-end" />
                    Seleziona
                  </IonButton>
                  {immagineSelezionata !== undefined ? (
                    <IonImg src={immagineSelezionata}></IonImg>
                  ) : null}
                </IonCardContent>
              </IonCard>
            )}

            <div className="ion-padding windowDubleHeight">
              <IonList>
                <IonItem fill="outline">
                  <IonLabel position="stacked">Modifica nome</IonLabel>
                  <IonInput
                    value={inputNomeModifiche}
                    onIonInput={(ev: any) => {
                      setInputNomeModifiche(ev.target.value);
                    }}
                    clearInput
                    placeholder="Obbligatorio"
                  ></IonInput>
                </IonItem>

                <IonItem fill="outline">
                  <IonLabel position="stacked">Modifica marca</IonLabel>
                  <IonInput
                    value={inputMarcaModifiche}
                    onIonInput={(ev: any) => {
                      setInputMarcaModifiche(ev.target.value);
                    }}
                    clearInput
                    placeholder="Facoltativo"
                  ></IonInput>
                </IonItem>

                <IonItem fill="outline">
                  <IonLabel position="stacked">Modifica descrizione</IonLabel>
                  <IonInput
                    value={inputDescrizioneModifiche}
                    onIonInput={(ev: any) => {
                      setInputDescrizioneModifiche(ev.target.value);
                    }}
                    clearInput
                    placeholder="Facoltativo"
                  ></IonInput>
                </IonItem>
              </IonList>
            </div>
          </IonContent>
        </IonModal>

        {/*---------------------------------- MODALE IMMAGINI --------------------------------------------------*/}
        <IonModal
          isOpen={isModaleImmaginiOpen}
          onDidDismiss={() => {
            setIsModaleImmaginiOpen(false);
          }}
        >
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton
                  color={"medium"}
                  onClick={() => {
                    setImmagineSelezionata(undefined);
                    setIsModaleImmaginiOpen(false);
                  }}
                >
                  Annulla
                </IonButton>
              </IonButtons>
              <IonTitle>Immagini </IonTitle>
              <IonButtons slot="end">
                <IonButton
                  fill="solid"
                  color={"success"}
                  onClick={() => setIsModaleImmaginiOpen(false)}
                >
                  Conferma
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <div>
              <IonLabel>
                <p className="ion-padding">
                  Filtro visualizzazione:
                  <IonChip
                    color={
                      filtroVisualizzazioneImmagini === "column-portfolio-1"
                        ? "primary"
                        : undefined
                    }
                    onClick={() =>
                      setFiltroVisualizzazioneImmagini("column-portfolio-1")
                    }
                  >
                    1/1
                  </IonChip>
                  <IonChip
                    color={
                      filtroVisualizzazioneImmagini === "column-portfolio-2"
                        ? "primary"
                        : undefined
                    }
                    onClick={() =>
                      setFiltroVisualizzazioneImmagini("column-portfolio-2")
                    }
                  >
                    1/2
                  </IonChip>
                  <IonChip
                    color={
                      filtroVisualizzazioneImmagini === "column-portfolio-3"
                        ? "primary"
                        : undefined
                    }
                    onClick={() =>
                      setFiltroVisualizzazioneImmagini("column-portfolio-3")
                    }
                  >
                    1/3
                  </IonChip>
                  <IonChip
                    color={
                      filtroVisualizzazioneImmagini === "column-portfolio-4"
                        ? "primary"
                        : undefined
                    }
                    onClick={() =>
                      setFiltroVisualizzazioneImmagini("column-portfolio-4")
                    }
                  >
                    1/4
                  </IonChip>
                </p>
              </IonLabel>
              <IonLabel>
                <p className="ion-padding-start ion-padding-end">
                  Cartelle:{" "}
                  {defaultFolders?.map((cartella: string, index: number) => {
                    return (
                      <IonChip
                        key={"cartelleIngredientidefaultFolders" + index}
                        color={
                          filtroCartelle === cartella ? "primary" : undefined
                        }
                        onClick={() => {
                          setFiltroCartelle(cartella);
                          handleFiltraImmagini(cartella);
                        }}
                      >
                        {cartella}
                      </IonChip>
                    );
                  })}
                  {cartelle?.allCartelleImmagini?.map(
                    (cartella: string, index: number) => {
                      return (
                        <IonChip
                          key={"cartelleIngredienti" + index}
                          color={
                            filtroCartelle === cartella ? "primary" : undefined
                          }
                          onClick={() => {
                            setFiltroCartelle(cartella);
                            handleFiltraImmagini(cartella);
                          }}
                        >
                          {cartella}
                        </IonChip>
                      );
                    }
                  )}
                </p>
              </IonLabel>
            </div>

            <div className="row-portfolio">
              {allImmagini?.map((immagineObj: any, index: number) => {
                return (
                  <IonCard
                    color={
                      immagineSelezionata === immagineObj.urlImmagine
                        ? "primary"
                        : undefined
                    }
                    key={"immagine-" + index}
                    className={
                      "immaginiGalleria " + filtroVisualizzazioneImmagini
                    }
                    onClick={() =>
                      setImmagineSelezionata(immagineObj.urlImmagine)
                    }
                  >
                    <IonCardHeader>
                      <IonCardSubtitle>{immagineObj.cartella}</IonCardSubtitle>
                    </IonCardHeader>
                    <IonImg src={immagineObj.urlImmagine} />
                  </IonCard>
                );
              })}
            </div>
          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default IngredientiScreen;
