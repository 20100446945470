import { IonAvatar, IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonIcon, IonImg, IonItem, IonLabel, IonList, IonReorder, IonReorderGroup, ItemReorderEventDetail } from '@ionic/react';
import './CardSelezionaProdotti.css';
import { add, closeOutline } from 'ionicons/icons';
import { Prodotto } from '../data/dataSheet';

interface ContainerProps {
  setModalOpen: any,
  inputItems: any,
  setInputItems: any,
  handleRemoveItem: any
}

const CardSelezionaProdotti: React.FC<ContainerProps> = ({
  setModalOpen,
  inputItems,
  setInputItems,
  handleRemoveItem,
}) => {
  //VARIABILI --------------------------------------------

  //CONDIZIONI -------------------------------------------
  function handleReorder(event: CustomEvent<ItemReorderEventDetail>) {
    setInputItems(event.detail.complete(inputItems))
    event.detail.complete()
  }

  //RETURN -----------------------------------------------
  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>Seleziona prodotti</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <IonButton
          expand="block"
          onClick={() => setModalOpen(true)}
        >
          <IonIcon icon={add} />
          Aggiungi prodotti
        </IonButton>

        <IonLabel>
          <p>
            Prodotti selezionati : {inputItems?.length}
          </p>
        </IonLabel>
        <IonList>
          <IonReorderGroup disabled={false} onIonItemReorder={handleReorder}>
            {inputItems?.map(
              (prodotto: Prodotto, index: number) => {
                return (
                  <IonItem key={"ProdottoSelezionato" + index}>
                    <IonAvatar slot="start">
                      <IonImg
                        src={prodotto.immaginiUrl[0]}
                        alt="Immagine"
                      />
                    </IonAvatar>
                    <IonLabel>
                      {prodotto.nome}
                      <p> €: {prodotto.prezzo}</p>
                    </IonLabel>

                    <IonButton
                      color={"danger"}
                      slot="end"
                      onClick={() =>
                        handleRemoveItem(index)
                      }
                    >
                      <IonIcon icon={closeOutline} />
                    </IonButton>
                    <IonReorder slot="end"></IonReorder>
                  </IonItem>
                );
              }
            )}
          </IonReorderGroup>
        </IonList>
      </IonCardContent>
    </IonCard>

  );
};

export default CardSelezionaProdotti;
