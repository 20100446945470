import { IonAvatar, IonButton, IonButtons, IonContent, IonHeader, IonImg, IonItem, IonLabel, IonList, IonModal, IonTitle, IonToolbar } from '@ionic/react';
import './ModaleImportaDaInventario.css';

interface ContainerProps {
  isOpen: boolean,
  setIsOpen: any,

  elements: any,

  handleAddElement: any,
}

const ModaleImportaDaInventario: React.FC<ContainerProps> = ({
  isOpen,
  setIsOpen,

  elements,
  handleAddElement,
}) => {
  //VARIABILI --------------------------------------------

  //CONDIZIONI -------------------------------------------


  //RETURN -----------------------------------------------
  return (
    <IonModal
      isOpen={isOpen}
      onDidDismiss={() => {
        setIsOpen(false);
      }}
    >
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton
              color={"medium"}
              onClick={() => setIsOpen(false)}
            >
              Annulla
            </IonButton>
          </IonButtons>
          <IonTitle>Importa da inventario</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonLabel>
          <p className="ion-padding">
            Una volta aggiunto il modello potrete modificarlo per adattarlo
            all'attività.
          </p>
        </IonLabel>

        <IonList>
          {elements?.map((cat: any, index: number) => {
            return (
              <IonItem key={"categorieComuni" + index}>
                <IonAvatar>
                  <IonImg src={cat.immagineUrl} />
                </IonAvatar>
                <IonLabel className="ion-padding-start">
                  <h2>{cat.nome}</h2>
                  <p>Prodotti : {cat.prodotti.length}</p>
                </IonLabel>
                <IonButton
                  onClick={() => handleAddElement(cat)}
                >
                  Seleziona
                </IonButton>
              </IonItem>
            );
          })}
        </IonList>
      </IonContent>
    </IonModal>
  );
};

export default ModaleImportaDaInventario;
