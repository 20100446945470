import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonMenuButton,
  IonModal,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonLoading,
  useIonToast,
} from "@ionic/react";
import {
  addCircle,
  alertCircleOutline,
  close,
  cloudDoneOutline,
} from "ionicons/icons";
import React from "react";
import UploadSingleImage from "../components/UploadSingleImage";
import { MenuData } from "../data/dataSheet";
import { UploadDataAttivita } from "../Logic/DataUpload";
import { UploadSingleImageFirebase } from "../Logic/ImageUpload";
import { LocalSaveAttivita } from "../Logic/LocalStorage";
import "../pages/CSS/Home.css";
import { url_app_attivita_init } from "../Urls";

type HomeScreenProps = {
  AllData: any;
  setAllData: any;
};

const TutteLeAttivitaScreen: React.FC<HomeScreenProps> = ({
  AllData,
  setAllData,
}) => {
  //VARIABILI --------------------------------------------
  const folder = "/logos";
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const [inputNome, setInputNome] = React.useState("");
  const [immagine, setImmagine] = React.useState();
  const [linkImmagine, setLinkImmagine] = React.useState();
  const [realTables, setRealTables] = React.useState<boolean>(false);


  const [presentToast] = useIonToast();
  const [present, dimiss] = useIonLoading();

  //CONDIZIONI --------------------

  const presentToastType = (
    message: string,
    type: "danger" | "success" | "warning",
    buttonEv: () => void
  ) => {
    const iconType = {
      danger: close,
      success: cloudDoneOutline,
      warning: alertCircleOutline,
    };

    presentToast({
      message: message,
      position: "top",
      duration: 3000,
      icon: iconType[type],
      color: type,
      buttons: [
        {
          text: "Chiudi",
          role: "cancel",
          handler: () => {
            buttonEv();
          },
        },
      ],
    });
  };

  const handleAggiungiAttivita = async () => {
    if (inputNome !== "" && immagine !== undefined) {
      present({
        message: "Sto aggiungendo l'attività...",
      });
      console.log("Aggiungi attività");
      setIsModalOpen(false);
      await UploadSingleImageFirebase(
        immagine!,
        Date.now(),
        setLinkImmagine,
        folder
      );
    } else {
      presentToastType(
        "ATTENZIONE! Nome o immagine non inseriti",
        "warning",
        () => {}
      );
    }
  };

  React.useEffect(() => {
    if (linkImmagine !== undefined) {
      salvaAllData();
    }
  }, [linkImmagine]);

  const salvaAllData = async () => {
    const idData = Date.now();
    let objAttivita = {
      id: idData,
      nome: inputNome,
      logo: linkImmagine,
      dataCreazione: new Date().toISOString(),
      comunicazioni: [],
      sezioni: [],
      categorie: [],
      personale: [],
      prodottiConsigliati: [],
      realTables : {
        attivata : realTables,
        id : "RealTables-"+idData,
      },
      about: null,
      orari: [
        {
          giorno: "Lunedì",
          orario: [
            {
              inizio: "08:00",
              fine: "13:00",
              chiuso: false,
            },
            {
              inizio: "14:00",
              fine: "23:00",
              chiuso: false,
            },
          ],
        },
        {
          giorno: "Martedì",
          orario: [
            {
              inizio: "08:00",
              fine: "13:00",
              chiuso: false,
            },
            {
              inizio: "14:00",
              fine: "23:00",
              chiuso: false,
            },
          ],
        },
        {
          giorno: "Mercoledì",
          orario: [
            {
              inizio: "08:00",
              fine: "13:00",
              chiuso: false,
            },
            {
              inizio: "14:00",
              fine: "23:00",
              chiuso: false,
            },
          ],
        },
        {
          giorno: "Giovedì",
          orario: [
            {
              inizio: "08:00",
              fine: "13:00",
              chiuso: false,
            },
            {
              inizio: "14:00",
              fine: "23:00",
              chiuso: false,
            },
          ],
        },
        {
          giorno: "Venerdì",
          orario: [
            {
              inizio: "08:00",
              fine: "13:00",
              chiuso: false,
            },
            {
              inizio: "14:00",
              fine: "23:00",
              chiuso: false,
            },
          ],
        },
        {
          giorno: "Sabato",
          orario: [
            {
              inizio: "08:00",
              fine: "13:00",
              chiuso: false,
            },
            {
              inizio: "14:00",
              fine: "23:00",
              chiuso: false,
            },
          ],
        },
        {
          giorno: "Domenica",
          orario: [
            {
              inizio: "08:00",
              fine: "13:00",
              chiuso: false,
            },
            {
              inizio: "14:00",
              fine: "23:00",
              chiuso: false,
            },
          ],
        },
      ],
      portfolio:[],
      cartelleGallery:[],
    };
    let auxAttivita = [...AllData];
    if (auxAttivita.length === 0) {
      console.log("objAttivita : ", objAttivita);
      let auxArr = [objAttivita];
      LocalSaveAttivita(auxArr);
      setAllData(auxArr);
    } else {
      console.log("objAttivita : ", objAttivita);
      auxAttivita.push(objAttivita);
      setAllData(auxAttivita);
      LocalSaveAttivita(auxAttivita);
    }
    await UploadDataAttivita(objAttivita);
    setLinkImmagine(undefined);
    dimiss();
    presentToastType(
      "Attività creata con successo. Benvenuto!",
      "success",
      () => {}
    );
  };

 
  //RETURN -----------------------------------------------
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Tutte le attività</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Tutte le attività</IonTitle>
          </IonToolbar>
        </IonHeader>
        {/* Contenuto */}
        <div>
          <IonLabel>
            <p className="ion-padding">
              Qui potrai visualizzare tutti i locali, bar o ristorazioni
              registrate ed editabili. Questa sezione è solo per il gestionale. La visualizzazione del menù se non già disponibile sarà configurata successivamente, e si avrà a disposizione il link del proprio sito-menù digitale
            </p>
          </IonLabel>

          {AllData?.map((data: MenuData, index: number) => {
            return (
              <IonCard
                routerLink={url_app_attivita_init + data.nome + "-" + index}
                key={index + "DatiAttivita" + data.nome}
              >
                <IonCardHeader className="title-row">
                  <IonCardSubtitle className="ion-margin-bottom">
                    Attività
                  </IonCardSubtitle>
                  <IonCardTitle className="display-flex">
                    {data.nome}
                  </IonCardTitle>
                  <IonCardSubtitle className="ion-margin-top">
                    Creato il: {data.dataCreazione?.split("T")[0]}
                  </IonCardSubtitle>
                </IonCardHeader>
              </IonCard>
            );
          })}
          <IonCard class="ion-text-center">
            <IonButton fill="clear" onClick={() => setIsModalOpen(true)}>
              <IonIcon icon={addCircle} className="ion-margin-end" />
              Aggiungi attività
            </IonButton>
          </IonCard>
        </div>

        {/* ----------------------- MODAL AGGIUGNI ATTIVITA -------------------------- */}
        <IonModal
          isOpen={isModalOpen}
          onDidDismiss={() => {
            setIsModalOpen(false);
          }}
        >
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton
                  color={"medium"}
                  onClick={() => setIsModalOpen(false)}
                >
                  Annulla
                </IonButton>
              </IonButtons>
              <IonTitle>Nuova attività</IonTitle>
              <IonButtons slot="end">
                <IonButton
                  fill="solid"
                  color={"success"}
                  onClick={() => {
                    handleAggiungiAttivita();
                  }}
                >
                  Salva
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <div className="windowDubleHeight">
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>Seleziona logo</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <UploadSingleImage setImage={setImmagine} />
                </IonCardContent>
              </IonCard>

              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>Nome attività</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <IonItem fill="outline">
                    <IonLabel position="floating">Inserisci nome</IonLabel>
                    <IonInput
                      onIonInput={(ev: any) => {
                        setInputNome(ev.target.value);
                      }}
                      clearInput
                      placeholder="Inserisci nome"
                    ></IonInput>
                  </IonItem>
                </IonCardContent>
              </IonCard>

              <IonCard>
                <IonCardHeader>
                  <IonCardTitle></IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <IonList>
                  <IonItem>
                    <IonLabel>Notifiche ordini in arrivo</IonLabel>
                    <IonCheckbox onIonChange={() => setRealTables(!realTables)}/>
                  </IonItem>
                  </IonList>
                  <IonLabel>
                    <p>Questa funzionalità non è automatica, per completare il funzionamento devi possedere il pacchetto specifico
                      per poter usufruire di questa funzionalità. Se la possiedi attiva questa spunta ed avvisa l'amministratore, si occuperà di configurare la procedura in autonomia.
                    </p>
                  </IonLabel>
                </IonCardContent>
              </IonCard>
            </div>
          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default TutteLeAttivitaScreen;
