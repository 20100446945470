import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonChip, IonContent, IonHeader, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonList, IonModal, IonPage, IonSegment, IonSegmentButton, IonSlide, IonSlides, IonTextarea, IonTitle, IonToolbar, useIonAlert, useIonLoading, useIonToast } from '@ionic/react';
import { add, alertCircleOutline, arrowBack, close, cloudDoneOutline, globeOutline, imagesOutline, logoFacebook, logoInstagram, logoLinkedin, pencil, trash } from 'ionicons/icons';
import React from 'react';
import { useParams } from 'react-router';
import UploadMultipleImages from '../components/UploadMultipleImages';
import { AboutUs, ContattoTelefonico } from '../data/dataSheet';
import { UploadDataAbout } from '../Logic/DataUpload';
import { UploadImagesFirebase } from '../Logic/ImageUpload';
import { LocalSaveAttivita } from '../Logic/LocalStorage';
import '../pages/CSS/ChisiamoScreen.css';
import { url_app_attivita_init } from '../Urls';
import ModalInputImmagineSingola from '../components/Modal_Input_Immagini/ModalInputImmagineSingola';
import { defaultFolders } from '../Logic/DataDownload';

type ChisiamoScreenProps = {
  AllData: any,
  setAllData: any,
  cartelle: any,
  allImmagini: any
}


const ChisiamoScreen: React.FC<ChisiamoScreenProps> = ({ AllData, setAllData, cartelle, allImmagini }) => {

  //VARIABILI --------------------------------------------
  const folder = "/about"
  const { indiceAttivita } = useParams<{ indiceAttivita: string }>();
  const index = indiceAttivita.split('-')[1];

  const [dataAttivita, setDataAttivita] = React.useState<any>();
  const [chisiamo, setChisiamo] = React.useState<AboutUs>();

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const [inputImmagini, setInputImmagini] = React.useState<any>();
  const [inputDescrizione, setInputDescrizione] = React.useState("");
  const [inputInstagram, setInputInstagram] = React.useState("");
  const [inputFacebook, setInputFacebook] = React.useState("");
  const [inputLinkedin, setInputLinkedin] = React.useState("");
  const [inputSitoWeb, setInputSitoWeb] = React.useState("");
  const [inputTelefono, setInputTelefono] = React.useState<any>();

  const [linkImmagini, setLinkImmagini] = React.useState([])
  const [allImagesUploaded, setAllImagesUploaded] = React.useState(false);


  const [segmentValue, setSegmentValue] = React.useState("Seleziona");
  const [immaginiSelezionate, setImmaginiSelezionate] = React.useState<any>([]);
  const [isModaleImmaginiOpen, setIsModaleImmaginiOpen] = React.useState(false);
  const [filtroCartelle, setFiltroCartelle] = React.useState<any>("");
  const [filtroVisualizzazioneImmagini, setFiltroVisualizzazioneImmagini] =
    React.useState<any>("column-portfolio-4");



  const [presentAlert] = useIonAlert();
  const [presentAlertTag] = useIonAlert();
  const [present, dimiss] = useIonLoading();

  const slideOpts = {
    initialSlide: 0,
    speed: 400,
    spaceBetween: 5
  };

  const [presentToast] = useIonToast();

  //CONDIZIONI -------------------------------------------

  const presentToastType = (message: string, type: "danger" | "success" | "warning", buttonEv: () => void) => {

    const iconType = {
      "danger": close,
      "success": cloudDoneOutline,
      "warning": alertCircleOutline,
    }

    presentToast({
      message: message,
      position: "top",
      duration: 3000,
      icon: iconType[type],
      color: type,
      buttons: [
        {
          text: 'Chiudi',
          role: 'cancel',
          handler: () => { buttonEv() }
        }
      ]
    })
  }


  React.useState(() => {
    const data = AllData;
    setDataAttivita(data[parseInt(index)]);
    setChisiamo(data[parseInt(index)].about);
    console.log(data[parseInt(index)].about);
  });

  const handleSalva = async () => {
    console.log(immaginiSelezionate);
    if (immaginiSelezionate.length !== 0) {
      let oggettoAbout = {
        immaginiUrl: immaginiSelezionate,
        descrizione: inputDescrizione ? inputDescrizione : "",
        instagram: inputInstagram ? inputInstagram : "",
        facebook: inputFacebook ? inputFacebook : "",
        linkedin: inputLinkedin ? inputLinkedin : "",
        sitoWeb: inputSitoWeb ? inputSitoWeb : "",
        telefono: inputTelefono,
      };
      let a = AllData;
      a[parseInt(index)].about = oggettoAbout;
      console.log("handleAggiungiContattoTelefonico () - a: ", a);
      setChisiamo(a[parseInt(index)].about);
      uploadData(a[parseInt(index)]);
      LocalSaveAttivita(a);
      setAllData(a);
      setAllImagesUploaded(false);
    } else {
      if (inputDescrizione !== "") {
        present({
          message: "Sto salvando le informazioni..."
        });
        setIsModalOpen(false);

        await UploadImagesFirebase(inputImmagini!, Date.now(), setLinkImmagini, folder, setAllImagesUploaded);

      } else {
        presentToastType("ATTENZIONE! Inserire tutti i campi obbligatori", "warning", () => { });
      }
    }

  }

  React.useEffect(() => {
    if (allImagesUploaded === true) {
      // console.log("allImagesUploaded === true :", allImagesUploaded === true);
      let oggettoAbout;
      if (inputImmagini === chisiamo?.immaginiUrl) {
        oggettoAbout = {
          immaginiUrl: inputImmagini,
          descrizione: inputDescrizione ? inputDescrizione : "",
          instagram: inputInstagram ? inputInstagram : "",
          facebook: inputFacebook ? inputFacebook : "",
          linkedin: inputLinkedin ? inputLinkedin : "",
          sitoWeb: inputSitoWeb ? inputSitoWeb : "",
          telefono: inputTelefono,
        };

      } else {
        oggettoAbout = {
          immaginiUrl: linkImmagini,
          descrizione: inputDescrizione ? inputDescrizione : "",
          instagram: inputInstagram ? inputInstagram : "",
          facebook: inputFacebook ? inputFacebook : "",
          linkedin: inputLinkedin ? inputLinkedin : "",
          sitoWeb: inputSitoWeb ? inputSitoWeb : "",
          telefono: inputTelefono,
        };
      }

      let a = AllData;
      a[parseInt(index)].about = oggettoAbout;
      console.log("handleAggiungiContattoTelefonico () - a: ", a);
      setChisiamo(a[parseInt(index)].about);
      uploadData(a[parseInt(index)]);
      LocalSaveAttivita(a);
      setAllData(a);
      setAllImagesUploaded(false);
    }
  }, [allImagesUploaded]);

  const handleModifica = () => {
    setInputImmagini(chisiamo?.immaginiUrl)
    setInputDescrizione(chisiamo?.descrizione!)
    setInputInstagram(chisiamo?.instagram!)
    setInputFacebook(chisiamo?.facebook!)
    setInputLinkedin(chisiamo?.linkedin!)
    setInputSitoWeb(chisiamo?.sitoWeb!)
    setInputTelefono(chisiamo?.telefono)
    setIsModalOpen(true);
  }

  const uploadData = async (oggettoAbout: AboutUs) => {
    await UploadDataAbout(oggettoAbout, dataAttivita.id).then(() => {
      dimiss();
      presentToastType("Dati salvati", "success", () => { });
      setIsModalOpen(false);
    });
  }

  const rimuoviNumeroTelefono = (index: number) => {
    setInputTelefono((inputTelefono: any[]) => inputTelefono.filter((_: any, i: number) => i !== index))
  }

  const handleAggiungiContattoTelefonico = (nome: string, numeroTelefono: number) => {
    console.log("handleAggiungiContattoTelefonico - nome:", nome, " - numeroTelefono:", numeroTelefono);
    let obj = {
      nome: nome,
      numero: numeroTelefono
    }
    console.log("handleAggiungiContattoTelefonico - obj : ", obj, "inputTelefono : ", inputTelefono);
    if (inputTelefono === undefined) {
      setInputTelefono([obj]);
    } else {
      setInputTelefono(inputTelefono.concat(obj));
    }
  }


  const resetImageInput = () => {
    setLinkImmagini([]);
    setImmaginiSelezionate([]);
  }

  const handleSelectSegment = (segmentValue: string) => {
    setSegmentValue(segmentValue);
    //reset degli input di entrambi quando si cambia
  };

  const handleAddImmagine = (urlImmagine: string) => {
    setImmaginiSelezionate(immaginiSelezionate.concat(urlImmagine));
  };

  const handleRemoveImmagine = (url: number) => {
    setImmaginiSelezionate((elemn: any) =>
      immaginiSelezionate.filter((_: any, i: any) => _ !== url)
    );
  };

  const handleFiltraImmagini = (testoCercato: string) => {
    let arr = Array.from(
      window.document.getElementsByClassName("immaginiGalleria")
    );
    const query = testoCercato.toLowerCase();
    requestAnimationFrame(() => {
      arr.forEach((item: any) => {
        const shouldShow = item.textContent.toLowerCase().indexOf(query) > -1;
        item.style.display = shouldShow ? "block" : "none";
      });
    });
  };


  //RETURN -----------------------------------------------
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonButton routerLink={url_app_attivita_init + indiceAttivita} routerDirection="back">
              <IonIcon icon={arrowBack} />
              {dataAttivita?.nome}
            </IonButton>
          </IonButtons>
          <IonTitle>Chi siamo</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen >
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Chi siamo</IonTitle>
          </IonToolbar>
        </IonHeader>


        <div className='ion-padding'>
          <IonButton fill='solid' expand='block' onClick={() => handleModifica()}>
            <IonIcon icon={pencil} className="ion-margin-end" />
            Modifica
          </IonButton>
        </div>

        <IonCard>
          <IonCardContent>
            Immagini:
            <IonSlides pager options={slideOpts}>
              {
                chisiamo?.immaginiUrl?.map((immagineUrl: string, index: number) => {
                  return (
                    <IonSlide key={index + "immagine-about"}>
                      <img src={immagineUrl} alt="immagine-about" width={"250px"} />
                    </IonSlide>
                  )
                })
              }
            </IonSlides>
          </IonCardContent>
        </IonCard>

        <IonCard>
          <IonCardContent>
            Descrizione:
            <br />
            {
              chisiamo?.descrizione
            }

          </IonCardContent>
        </IonCard>

        <IonCard>
          <IonCardContent>
            Social:
            <br />
            {
              chisiamo?.instagram ?
                <IonButton expand='block' onClick={() => window.open(chisiamo?.instagram, "_blank")}>
                  <IonIcon icon={logoInstagram} />
                </IonButton>
                : null
            }
            {
              chisiamo?.facebook ?
                <IonButton expand='block' onClick={() => window.open(chisiamo?.facebook, "_blank")}>
                  <IonIcon icon={logoFacebook} />
                </IonButton>
                : null
            }
            {
              chisiamo?.linkedin ?
                <IonButton expand='block' onClick={() => window.open(chisiamo?.linkedin, "_blank")}>
                  <IonIcon icon={logoLinkedin} />
                </IonButton>
                : null
            }
            {
              chisiamo?.sitoWeb ?
                <IonButton expand='block' onClick={() => window.open(chisiamo?.sitoWeb, "_blank")}>
                  <IonIcon icon={globeOutline} />
                </IonButton>
                : null
            }
          </IonCardContent>
        </IonCard>

        <IonCard>
          <IonCardContent>
            Contatti telefonici:
            <br />

            {chisiamo?.telefono?.map((utente: ContattoTelefonico, index: number) => {
              return (
                <IonItem key={index + "comunicazioni"}>
                  <IonLabel>
                    <p>{utente.nome}</p>
                    <p>{utente.numero}</p>
                  </IonLabel>
                </IonItem>
              )
            })
            }
          </IonCardContent>
        </IonCard>


        {/* -------------- MODIFICA ABOUT US ----------------- */}
        <IonModal isOpen={isModalOpen} onDidDismiss={() => setIsModalOpen(false)}>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot='start'>
                <IonButton color={"medium"} onClick={() => { setIsModalOpen(false); resetImageInput() }}>
                  Annulla
                </IonButton>
              </IonButtons>
              <IonTitle>
                About us
              </IonTitle>
              <IonButtons slot='end'>
                <IonButton fill='solid' color={"success"} onClick={() => {
                  handleSalva()
                }}>
                  Salva
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>

          <IonContent fullscreen>

            <IonSegment value={segmentValue}>
              <IonSegmentButton
                value="Carica"
                onClick={() => handleSelectSegment("Carica")}
              >
                <IonLabel>Carica Immagine</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton
                value="Seleziona"
                onClick={() => handleSelectSegment("Seleziona")}
              >
                <IonLabel>Seleziona Immagine</IonLabel>
              </IonSegmentButton>
            </IonSegment>

            {segmentValue === "Carica" ? (
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>Seleziona immagini</IonCardTitle>
                  <IonCardSubtitle>
                    Se non selezioni nessuna immagine sarà utilizzato il logo di
                    MenuShare
                  </IonCardSubtitle>
                </IonCardHeader>
                <IonCardContent>
                  <UploadMultipleImages setImage={setInputImmagini} />
                </IonCardContent>
              </IonCard>
            ) : (
              <>
                <IonCard>
                  <IonCardHeader>
                    <IonCardTitle>Seleziona immagini</IonCardTitle>
                    <IonCardSubtitle>
                      Se non selezioni nessuna immagine sarà utilizzato il logo
                      di MenuShare
                    </IonCardSubtitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonButton
                      color={
                        immaginiSelezionate.legth > 0 ? "success" : "primary"
                      }
                      expand="block"
                      onClick={() => setIsModaleImmaginiOpen(true)}
                    >
                      <IonIcon
                        icon={imagesOutline}
                        className="ion-margin-end"
                      />
                      Seleziona
                    </IonButton>
                    {immaginiSelezionate.legth > 0
                      ? immaginiSelezionate.map(
                        (img: string, index: number) => {
                          return (
                            <IonImg
                              key={"indexselezionate" + index}
                              src={img}
                            ></IonImg>
                          );
                        }
                      )
                      : null}
                  </IonCardContent>
                </IonCard>
                <IonLabel>
                  <p className="ion-padding">Immagini selezionate</p>
                  {immaginiSelezionate?.map((img: string, index: number) => {
                    return <IonImg key={"imgSelezionate" + index} src={img} />;
                  })}
                </IonLabel>
              </>
            )}


            <div className='ion-padding windowDubleHeight'>
              <IonList>

                <IonItem>
                  <IonLabel position="stacked">Inserisci descrizione</IonLabel>
                  <IonTextarea
                    placeholder="Obbligatorio"
                    value={inputDescrizione}
                    onIonInput={(ev: any) => { setInputDescrizione(ev.target.value) }}
                    autoGrow={true}
                  />
                </IonItem>

                <IonItem className='ion-margin-bottom ion-margin-top' fill="outline">
                  <IonLabel>Link instrgram</IonLabel>
                  <IonInput value={inputInstagram} onIonInput={(ev: any) => { setInputInstagram(ev.target.value) }} clearInput placeholder="Facoltativo"></IonInput>
                </IonItem>

                <IonItem className='ion-margin-bottom ion-margin-top' fill="outline">
                  <IonLabel>Link facebook</IonLabel>
                  <IonInput value={inputFacebook} onIonInput={(ev: any) => { setInputFacebook(ev.target.value) }} clearInput placeholder="Facoltativo"></IonInput>
                </IonItem>

                <IonItem className='ion-margin-bottom ion-margin-top' fill="outline">
                  <IonLabel>Link linkedin</IonLabel>
                  <IonInput value={inputLinkedin} onIonInput={(ev: any) => { setInputLinkedin(ev.target.value) }} clearInput placeholder="Facoltativo"></IonInput>
                </IonItem>

                <IonItem className='ion-margin-bottom ion-margin-top' fill="outline">
                  <IonLabel>Link sito Web</IonLabel>
                  <IonInput value={inputSitoWeb} onIonInput={(ev: any) => { setInputSitoWeb(ev.target.value) }} clearInput placeholder="Facoltativo"></IonInput>
                </IonItem>
              </IonList>
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>
                    Inserisci contatti telefonici
                  </IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <IonButton expand='block' onClick={() =>
                    presentAlertTag({
                      header: 'Crea nuovo tag',
                      message: "Sarà aggiunto automaticamente al database",
                      inputs: [
                        {
                          placeholder: 'Name',
                          name: "nome",
                          type: 'text'
                        },
                        {
                          placeholder: 'Numero telefono',
                          name: "numeroTelefono",
                          type: 'number'
                        },
                      ],
                      buttons: [
                        {
                          text: 'Cancel',
                          role: 'cancel',
                        },
                        {
                          text: 'Salva',
                          role: 'confirm',
                          handler: (alertData: any) => {
                            handleAggiungiContattoTelefonico(alertData.nome, alertData.numeroTelefono);
                          },
                        },
                      ],

                    })
                  }>
                    <IonIcon icon={add} />
                    Aggiungi contatto
                  </IonButton>

                  {inputTelefono?.map((utente: ContattoTelefonico, index: number) => {
                    return (
                      <IonItem key={index + "comunicazioni"}>
                        <IonButton color={"danger"} slot='start' fill='solid' onClick={() => presentAlert({
                          header: 'Attenzione!',
                          message: "L'eliminazione è definitiva!",
                          buttons: [
                            {
                              text: 'Annulla',
                              role: 'cancel',
                            },
                            {
                              text: 'Conferma',
                              role: 'confirm',
                              handler: () => {
                                rimuoviNumeroTelefono(index);
                              },
                            },
                          ],
                        })}>
                          <IonIcon icon={trash} />
                        </IonButton>
                        <IonLabel>
                          <p>{utente.nome}</p>
                          <p>{utente.numero}</p>
                        </IonLabel>
                      </IonItem>
                    )
                  })
                  }
                </IonCardContent>
              </IonCard>
            </div>

          </IonContent>
        </IonModal>

        {/*---------------------------------- MODALE IMMAGINI --------------------------------------------------*/}
        <IonModal
          isOpen={isModaleImmaginiOpen}
          onDidDismiss={() => {
            setIsModaleImmaginiOpen(false);
          }}
        >
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton
                  color={"medium"}
                  onClick={() => {
                    setImmaginiSelezionate([]);
                    setIsModaleImmaginiOpen(false);
                  }}
                >
                  Annulla
                </IonButton>
              </IonButtons>
              <IonTitle>Immagini </IonTitle>
              <IonButtons slot="end">
                <IonButton
                  fill="solid"
                  color={"success"}
                  onClick={() => setIsModaleImmaginiOpen(false)}
                >
                  Conferma
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <div>
              <IonLabel>
                <p className="ion-padding">
                  Filtro visualizzazione:
                  <IonChip
                    color={
                      filtroVisualizzazioneImmagini === "column-portfolio-1"
                        ? "primary"
                        : undefined
                    }
                    onClick={() =>
                      setFiltroVisualizzazioneImmagini("column-portfolio-1")
                    }
                  >
                    1/1
                  </IonChip>
                  <IonChip
                    color={
                      filtroVisualizzazioneImmagini === "column-portfolio-2"
                        ? "primary"
                        : undefined
                    }
                    onClick={() =>
                      setFiltroVisualizzazioneImmagini("column-portfolio-2")
                    }
                  >
                    1/2
                  </IonChip>
                  <IonChip
                    color={
                      filtroVisualizzazioneImmagini === "column-portfolio-3"
                        ? "primary"
                        : undefined
                    }
                    onClick={() =>
                      setFiltroVisualizzazioneImmagini("column-portfolio-3")
                    }
                  >
                    1/3
                  </IonChip>
                  <IonChip
                    color={
                      filtroVisualizzazioneImmagini === "column-portfolio-4"
                        ? "primary"
                        : undefined
                    }
                    onClick={() =>
                      setFiltroVisualizzazioneImmagini("column-portfolio-4")
                    }
                  >
                    1/4
                  </IonChip>
                </p>
              </IonLabel>
              <IonLabel>
                <p className="ion-padding-start ion-padding-end">
                  Cartelle:{" "}
                  {defaultFolders?.map((cartella: string, index: number) => {
                    return (
                      <IonChip
                        key={"cartelleIngredientidefaultFolders" + index}
                        color={
                          filtroCartelle === cartella ? "primary" : undefined
                        }
                        onClick={() => {
                          setFiltroCartelle(cartella);
                          handleFiltraImmagini(cartella);
                        }}
                      >
                        {cartella}
                      </IonChip>
                    );
                  })}
                  {cartelle?.allCartelleImmagini?.map(
                    (cartella: string, index: number) => {
                      return (
                        <IonChip
                          key={"cartelleIngredienti" + index}
                          color={
                            filtroCartelle === cartella ? "primary" : undefined
                          }
                          onClick={() => {
                            setFiltroCartelle(cartella);
                            handleFiltraImmagini(cartella);
                          }}
                        >
                          {cartella}
                        </IonChip>
                      );
                    }
                  )}
                </p>
              </IonLabel>
            </div>

            <div className="row-portfolio">
              {allImmagini?.map((immagineObj: any, index: number) => {
                let x = immaginiSelezionate?.find(
                  (elem: any) => elem === immagineObj.urlImmagine
                );
                return (
                  <IonCard
                    color={x !== undefined ? "primary" : undefined}
                    key={"immagine-" + index}
                    className={
                      "immaginiGalleria " + filtroVisualizzazioneImmagini
                    }
                    onClick={() => {
                      x === undefined
                        ? handleAddImmagine(immagineObj.urlImmagine)
                        : handleRemoveImmagine(immagineObj.urlImmagine);
                    }}
                  >
                    <IonCardHeader>
                      <IonCardSubtitle>{immagineObj.cartella}</IonCardSubtitle>
                    </IonCardHeader>
                    <IonImg src={immagineObj.urlImmagine} />
                  </IonCard>
                );
              })}
            </div>
          </IonContent>
        </IonModal>

      </IonContent>
    </IonPage>
  );
};

export default ChisiamoScreen;
