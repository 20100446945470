import { IonAvatar, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonHeader, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonList, IonModal, IonPage, IonReorder, IonReorderGroup, IonTextarea, IonTitle, IonToolbar, ItemReorderEventDetail, useIonAlert, useIonLoading, useIonToast } from '@ionic/react';
import { addCircle, alertCircleOutline, arrowBack, close, cloudDoneOutline, pencil, trash } from 'ionicons/icons';
import React from 'react';
import { useParams } from 'react-router';
import UploadSingleImage from '../components/UploadSingleImage';
import { Comunicazioni, logoMenuShare } from '../data/dataSheet';
import { UploadDataComunicazione } from '../Logic/DataUpload';
import { UploadSingleImageFirebase } from '../Logic/ImageUpload';
import { LocalSaveAttivita } from '../Logic/LocalStorage';
import '../pages/CSS/ComunicazioniScreen.css';
import { url_app_attivita_init } from '../Urls';
import ModalInputImmagineSingola from '../components/Modal_Input_Immagini/ModalInputImmagineSingola';

type ScreenProps = {
  AllData: any,
  setAllData: any,
  cartelle: any,
  allImmagini: any
}


const ComunicazioniScreen: React.FC<ScreenProps> = ({ AllData, setAllData, cartelle, allImmagini }) => {

  //VARIABILI --------------------------------------------
  const { indiceAttivita } = useParams<{ indiceAttivita: string }>();
  const index = indiceAttivita.split('-')[1];

  const [dataAttivita, setDataAttivita] = React.useState<any>([]);

  const [comunicazioni, setComunicazioni] = React.useState<any>([]);
  const [isModalAddOpen, setIsModalAddOpen] = React.useState<boolean>(false);

  const [presentAlert] = useIonAlert();

  const [id, setId] = React.useState<number>()
  const [immagine, setImmagine] = React.useState();
  const [inputNome, setInputNome] = React.useState("");
  const [inputSottotitolo, setInputSottotitolo] = React.useState("");
  const [inputDescrizione, setInputDescrizione] = React.useState("");
  const [inputParagrafi, setInputParagrafi] = React.useState("");


  const [indiceModifica, setIndiceModifica] = React.useState<number>();
  const [immagineModifiche, setImmagineModifiche] = React.useState();
  const [inputNomeModifiche, setInputNomeModifiche] = React.useState("");
  const [inputSottotitoloModifiche, setInputSottotitoloModifiche] = React.useState("");
  const [inputDescrizioneModifiche, setInputDescrizioneModifiche] = React.useState("");
  const [inputParagrafiModifiche, setInputParagrafiModifiche] = React.useState("");


  const [isModalModificheAddOpen, setIsModalModificheAddOpen] = React.useState<boolean>(false);

  const [linkImmagine, setLinkImmagine] = React.useState(undefined);
  const [linkImmagineModifica, setLinkImmagineModifica] = React.useState(undefined);

  const [immagineSelezionata, setImmagineSelezionata] =
    React.useState<any>(undefined);

  const [present, dimiss] = useIonLoading();
  const [presentToast] = useIonToast();

  //CONDIZIONI -------------------------------------------
  const presentToastType = (message: string, type: "danger" | "success" | "warning", buttonEv: () => void) => {

    const iconType = {
      "danger": close,
      "success": cloudDoneOutline,
      "warning": alertCircleOutline,
    }

    presentToast({
      message: message,
      position: "top",
      duration: 3000,
      icon: iconType[type],
      color: type,
      buttons: [
        {
          text: 'Chiudi',
          role: 'cancel',
          handler: () => { buttonEv() }
        }
      ]
    })
  }

  React.useState(() => {
    setDataAttivita(AllData[parseInt(index)])
    setComunicazioni(AllData[parseInt(index)].comunicazioni)
  })

  const handleOrdinamento = (ev: CustomEvent<ItemReorderEventDetail>) => {
    console.log("handleOrdinamento()")
    setComunicazioni(ev.detail.complete(comunicazioni));
    salvaOrdinamento(ev.detail.complete(comunicazioni));
    ev.detail.complete();
  }

  const salvaNuovaComunicazione = async () => {
    if (immagineSelezionata !== undefined && inputNome !== "" && inputSottotitolo !== "" && inputDescrizione !== "" && inputParagrafi !== "") {
      console.log("salvaNuovaComunicazione()")
      present({
        message: "Sto salvando la comunicazione..."
      });
      setIsModalAddOpen(false);
      let auxId = Date.now();
      setId(auxId);
      const oggettoComunicazione = {
        id: auxId,
        nome: inputNome,
        sottotitolo: inputSottotitolo,
        descrizione: inputDescrizione,
        paragrafi: inputParagrafi,
        immagineUrl: immagineSelezionata,
        dataPubblicazione: new Date().toDateString()
      };
      // setComunicazioni(comunicazioni => [...comunicazioni!, oggettoComunicazione]);
      salvaAllData(oggettoComunicazione);
      resetAllDataInput();
    } else {
      if (inputNome !== "" && inputSottotitolo !== "" && inputDescrizione !== "" && inputParagrafi !== "") {
        console.log("salvaNuovaComunicazione()")
        present({
          message: "Sto salvando la comunicazione..."
        });
        setIsModalAddOpen(false);
        let auxId = Date.now();
        setId(auxId);
        if (immagine === undefined) {
          const oggettoComunicazione = {
            id: auxId,
            nome: inputNome,
            sottotitolo: inputSottotitolo,
            descrizione: inputDescrizione,
            paragrafi: inputParagrafi,
            immagineUrl: logoMenuShare,
            dataPubblicazione: new Date().toDateString()
          };
          // setComunicazioni(comunicazioni => [...comunicazioni!, oggettoComunicazione]);
          salvaAllData(oggettoComunicazione);
          resetAllDataInput();
        } else {
          await UploadSingleImageFirebase(immagine!, auxId, setLinkImmagine, "/comunicazioni");
        }
      } else {
        presentToastType("ATTENZIONE! Inserire tutti i campi obbligatori", "warning", () => { });
      }
    }

  }

  React.useEffect(() => {
    console.log("React.useEffect()")
    if (linkImmagine !== undefined) {
      const oggettoComunicazione = {
        id: id,
        nome: inputNome,
        sottotitolo: inputSottotitolo,
        descrizione: inputDescrizione,
        paragrafi: inputParagrafi,
        immagineUrl: linkImmagine,
        dataPubblicazione: new Date().toDateString()
      };
      // setComunicazioni(comunicazioni => [...comunicazioni!, oggettoComunicazione]);
      salvaAllData(oggettoComunicazione);
      resetAllDataInput();
    }
  }, [linkImmagine])

  const handleModifica = (ind: number) => {
    console.log("handleModifica()")
    setIndiceModifica(ind)
    if (comunicazioni !== undefined) {
      setImmagineModifiche(comunicazioni[ind].immagineUrl!)
      setInputNomeModifiche(comunicazioni[ind].nome)
      setInputSottotitoloModifiche(comunicazioni[ind].sottotitolo)
      setInputDescrizioneModifiche(comunicazioni[ind].descrizione)
      setInputParagrafiModifiche(comunicazioni[ind].paragrafi)
      setIsModalModificheAddOpen(true);
    }
  }

  const handleSalvaModifiche = async () => {
    if (immagineSelezionata !== undefined) {
      console.log("handleSalvaModifiche()")
      present({
        message: "Sto salvando la comunicazione..."
      });
      setIsModalModificheAddOpen(false);
      let oggettoModificato;
      oggettoModificato = {
        nome: inputNomeModifiche,
        sottotitolo: inputSottotitoloModifiche,
        descrizione: inputDescrizioneModifiche,
        paragrafi: inputParagrafiModifiche,
        immagineUrl: immagineSelezionata,
        dataPubblicazione: new Date().toDateString(),
      }
      let a = [...AllData];
      let b = [...a[parseInt(index)].comunicazioni!];
      b[indiceModifica!] = oggettoModificato;
      setComunicazioni(b);
      a[parseInt(index)].comunicazioni = b;
      await UploadDataComunicazione(a[parseInt(index)], a[parseInt(index)].id);
      LocalSaveAttivita(a);
      setAllData(a);
      resetAllDataInput()
      dimiss();
      presentToastType("Comunicazione salvata", "success", () => { });

    } else {
      console.log("handleSalvaModifiche()")
      present({
        message: "Sto salvando la comunicazione..."
      });
      setIsModalModificheAddOpen(false);
      let oggettoModificato;
      if (immagineModifiche === comunicazioni[indiceModifica!].immagineUrl) {
        oggettoModificato = {
          nome: inputNomeModifiche,
          sottotitolo: inputSottotitoloModifiche,
          descrizione: inputDescrizioneModifiche,
          paragrafi: inputParagrafiModifiche,
          immagineUrl: immagineModifiche,
          dataPubblicazione: new Date().toDateString(),
        }
        let a = [...AllData];
        let b = [...a[parseInt(index)].comunicazioni!];
        b[indiceModifica!] = oggettoModificato;
        setComunicazioni(b);
        a[parseInt(index)].comunicazioni = b;
        await UploadDataComunicazione(a[parseInt(index)], a[parseInt(index)].id);
        LocalSaveAttivita(a);
        setAllData(a);
        resetAllDataInput()
        dimiss();
        presentToastType("Comunicazione salvata", "success", () => { });
      } else {
        await UploadSingleImageFirebase(immagineModifiche!, indiceModifica!, setLinkImmagineModifica, "/comunicazioni");
      }
    }


  }

  React.useEffect(() => {
    console.log("React.useEffect()")
    if (linkImmagineModifica !== undefined) {
      const oggettoComunicazione = {
        nome: inputNomeModifiche,
        sottotitolo: inputSottotitoloModifiche,
        descrizione: inputDescrizioneModifiche,
        paragrafi: inputParagrafiModifiche,
        immagineUrl: linkImmagineModifica,
        dataPubblicazione: new Date().toDateString(),
      };
      handleUploadComunicazioneModificata(oggettoComunicazione)
    }
  }, [linkImmagineModifica]);

  const handleUploadComunicazioneModificata = async (oggettoModificato: any) => {
    let a = [...AllData];
    let b = [...a[parseInt(index)].comunicazioni!];
    b[indiceModifica!] = oggettoModificato;
    setComunicazioni(b);
    a[parseInt(index)].comunicazioni = b;
    await UploadDataComunicazione(a[parseInt(index)], a[parseInt(index)].id);
    LocalSaveAttivita(a);
    setAllData(a);
    resetAllDataInput()
    dimiss();
    presentToastType("Comunicazione salvata", "success", () => { });

  }

  const salvaOrdinamento = async (comunicazione: any) => {
    console.log("salvaOrdinamento()")
    let a = [...AllData];
    a[parseInt(index)].comunicazioni = comunicazione;
    await UploadDataComunicazione(a[parseInt(index)], a[parseInt(index)].id);
    LocalSaveAttivita(a);
    setAllData(a);
  }

  const salvaAllData = async (oggettoComunicazione: any) => {
    console.log("salvaAllData()")
    let a = [...AllData];
    a[parseInt(index)].comunicazioni?.push(oggettoComunicazione);
    setAllData(a);
    await UploadDataComunicazione(a[parseInt(index)], a[parseInt(index)].id);
    LocalSaveAttivita(a);
    resetAllDataInput()
    dimiss();
    presentToastType("Comunicazione salvata", "success", () => { });
  }

  const resetAllDataInput = () => {
    console.log("resetAllDataInput()")
    setId(undefined)
    setImmagine(undefined)
    setInputNome("")
    setInputSottotitolo("")
    setInputDescrizione("")
    setInputParagrafi("")
    setImmagineModifiche(undefined)
    setInputNomeModifiche("")
    setInputSottotitoloModifiche("")
    setInputDescrizioneModifiche("")
    setInputParagrafiModifiche("")
    setIndiceModifica(undefined)
    setLinkImmagine(undefined);
    setImmagineSelezionata(undefined);
  }

  const resetImageInput = () => {
    setLinkImmagine(undefined);
    setImmagineSelezionata(undefined);
  }

  const rimuoviElemento = async (ind: number) => {
    console.log("rimuoviElemento()")
    let a = [...AllData];
    let b = [...a[parseInt(index)].comunicazioni!];
    b.splice(ind, 1);
    setComunicazioni(b);
    a[parseInt(index)].comunicazioni = b;
    await UploadDataComunicazione(a[parseInt(index)], a[parseInt(index)].id);
    LocalSaveAttivita(a);
    setAllData(a);
  }

  //RETURN -----------------------------------------------
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonButton routerLink={url_app_attivita_init + indiceAttivita} routerDirection="back">
              <IonIcon icon={arrowBack} />
              {dataAttivita?.nome}
            </IonButton>
          </IonButtons>
          <IonTitle>Comunicazioni</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Comunicazioni</IonTitle>
          </IonToolbar>
        </IonHeader>

        {/* Contenuto */}
        <div>
          <IonLabel>
            <p className='ion-padding'>ATTENZIONE! Se la comunicazione appena creata non è visibile tornare indietro e rientrare nella pagina comunicazioni</p>
          </IonLabel>

          <div className='ion-padding'>
            <IonButton expand='block' fill='solid' onClick={() => setIsModalAddOpen(true)}>
              <IonIcon icon={addCircle} className="ion-margin-end" />
              Aggiungi comunicazione
            </IonButton>
          </div>

          <IonList>
            <IonReorderGroup disabled={false} onIonItemReorder={handleOrdinamento}>
              {comunicazioni?.map((item: Comunicazioni, index: number) => {
                return (
                  <IonItem key={index + "comunicazioni"}>
                    <IonButton color={"danger"} slot='start' fill='solid' onClick={() => presentAlert({
                      header: 'Attenzione!',
                      message: "L'eliminazione è definitiva!",
                      buttons: [
                        {
                          text: 'Annulla',
                          role: 'cancel',
                        },
                        {
                          text: 'Conferma',
                          role: 'confirm',
                          handler: () => {
                            rimuoviElemento(index);
                          },
                        },
                      ],
                    })}>
                      <IonIcon icon={trash} />
                    </IonButton>
                    <IonButton slot='start' fill='solid' onClick={() => handleModifica(index)}>
                      <IonIcon icon={pencil} />
                    </IonButton>
                    <IonAvatar className='ion-margin-end'>
                      <IonImg src={item.immagineUrl}></IonImg>
                    </IonAvatar>
                    <IonLabel>
                      <p>
                        <b>
                          {item?.sottotitolo}
                        </b>
                      </p>
                      <h2>
                        {item.nome}
                      </h2>
                      <p>
                        {item?.descrizione}
                      </p>

                    </IonLabel>
                    <IonReorder slot="end"></IonReorder>
                  </IonItem>
                )
              })
              }
            </IonReorderGroup>
          </IonList>
        </div>

        {/* ---------------------- Modali AGGIUNGI ---------------------- */}
        <IonModal isOpen={isModalAddOpen} onDidDismiss={() => { setIsModalAddOpen(false); }} >
          <IonHeader>
            <IonToolbar>
              <IonButtons slot='start'>
                <IonButton color={"medium"} onClick={() => { setIsModalAddOpen(false); resetImageInput() }}>
                  Annulla
                </IonButton>
              </IonButtons>
              <IonTitle>
                Nuova comunicazione
              </IonTitle>
              <IonButtons slot='end'>
                <IonButton fill='solid' color={"success"} onClick={() => salvaNuovaComunicazione()}>
                  Salva
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>

            <ModalInputImmagineSingola
              resetImageInput={resetImageInput}
              setInputImmagine={setImmagine}
              immagineSelezionata={immagineSelezionata}
              setImmagineSelezionata={setImmagineSelezionata}
              cartelle={cartelle}
              allImmagini={allImmagini}
            />

            <div className='ion-padding windowDubleHeight'>
              <IonList>
                <IonLabel>
                  <IonItem fill="outline">
                    <IonLabel position="stacked">Inserisci tipologia</IonLabel>
                    <IonInput onIonInput={(ev: any) => { setInputSottotitolo(ev.target.value) }} clearInput placeholder="Obbligatorio"></IonInput>
                  </IonItem>
                  <p className='ion-padding'>
                    <IonIcon color='warning' icon={alertCircleOutline} />
                    Tipologia es: "Novità", "Promozione", "Comunicazione", "Sconto", "Evento"
                  </p>
                </IonLabel>

                <IonItem fill="outline">
                  <IonLabel position="stacked">Inserisci titolo</IonLabel>
                  <IonInput onIonInput={(ev: any) => { setInputNome(ev.target.value) }} clearInput placeholder="Obbligatorio"></IonInput>
                </IonItem>

                <IonItem fill="outline">
                  <IonLabel position="stacked">Inserisci breve descrizione</IonLabel>
                  <IonInput onIonInput={(ev: any) => { setInputDescrizione(ev.target.value) }} clearInput placeholder="Obbligatorio"></IonInput>
                </IonItem>

                <IonItem fill="outline">
                  <IonLabel position="stacked">Inserisci paragrafo</IonLabel>
                  <IonTextarea autoGrow onIonInput={(ev: any) => { setInputParagrafi(ev.target.value) }} placeholder="Obbligatorio"></IonTextarea>
                </IonItem>

              </IonList>
            </div>

          </IonContent>
        </IonModal>



        {/* ---------------------- Modali MODIFICA ---------------------- */}
        <IonModal isOpen={isModalModificheAddOpen} onDidDismiss={() => { setIsModalModificheAddOpen(false); }} >
          <IonHeader>
            <IonToolbar>
              <IonButtons slot='start'>
                <IonButton color={"medium"} onClick={() => { setIsModalModificheAddOpen(false); resetImageInput() }}>
                  Annulla
                </IonButton>
              </IonButtons>
              <IonTitle>
                Modifica
              </IonTitle>
              <IonButtons slot='end'>
                <IonButton fill='solid' color={"success"} onClick={() => handleSalvaModifiche()}>
                  Salva
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>



            <ModalInputImmagineSingola
              resetImageInput={resetImageInput}
              setInputImmagine={setImmagineModifiche}
              inputImmagine={immagineModifiche}
              immagineSelezionata={immagineSelezionata}
              setImmagineSelezionata={setImmagineSelezionata}
              cartelle={cartelle}
              allImmagini={allImmagini}
            />


            <div className='ion-padding windowDubleHeight'>
              <IonList>

                <IonLabel>
                  <IonItem fill="outline">
                    <IonLabel position="stacked">Inserisci tipologia</IonLabel>
                    <IonInput value={inputSottotitoloModifiche} onIonInput={(ev: any) => { setInputSottotitoloModifiche(ev.target.value) }} clearInput placeholder="Obbligatorio"></IonInput>
                  </IonItem>
                  <p className='ion-padding'>
                    <IonIcon color='warning' icon={alertCircleOutline} />
                    Tipologia es: "Novità", "Promozione", "Comunicazione", "Sconto", "Evento"
                  </p>
                </IonLabel>

                <IonItem fill="outline">
                  <IonLabel position="stacked">Inserisci titolo</IonLabel>
                  <IonInput value={inputNomeModifiche} onIonInput={(ev: any) => { setInputNomeModifiche(ev.target.value) }} clearInput placeholder="Obbligatorio"></IonInput>
                </IonItem>

                <IonItem fill="outline">
                  <IonLabel position="stacked">Inserisci breve descrizione</IonLabel>
                  <IonInput value={inputDescrizioneModifiche} onIonInput={(ev: any) => { setInputDescrizioneModifiche(ev.target.value) }} clearInput placeholder="Obbligatorio"></IonInput>
                </IonItem>

                <IonItem fill="outline">
                  <IonLabel position="stacked">Inserisci paragrafo</IonLabel>
                  <IonTextarea value={inputParagrafiModifiche} autoGrow onIonInput={(ev: any) => { setInputParagrafiModifiche(ev.target.value) }} placeholder="Obbligatorio"></IonTextarea>
                </IonItem>

              </IonList>
            </div>


          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default ComunicazioniScreen;
