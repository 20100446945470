import { IonAvatar, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonChip, IonContent, IonHeader, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonList, IonModal, IonPage, IonReorder, IonReorderGroup, IonSearchbar, IonThumbnail, IonTitle, IonToolbar, ItemReorderEventDetail, useIonAlert, useIonLoading, useIonToast } from '@ionic/react';
import { add, addCircle, alertCircleOutline, arrowBack, close, closeCircle, closeOutline, cloudDoneOutline, pencil, trash } from 'ionicons/icons';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import UploadSingleImage from '../components/UploadSingleImage';
import { Categoria, Sezione } from '../data/dataSheet';
import { UploadDataSezioni } from '../Logic/DataUpload';
import { UploadSingleImageFirebase } from '../Logic/ImageUpload';
import { LocalSaveAttivita } from '../Logic/LocalStorage';
import '../pages/CSS/SezioniScreen.css';
import { url_app_attivita_init } from '../Urls';
import ModalModificaSezioneAttivita from '../components/Modale_sezioni/ModalModificaSezioneAttivita';
import ModaleImportaDaInventarioSezione from '../components/Modale_sezioni/ModaleImportaDaInventarioSezione';

type SezioniScreenProps = {
  AllData: any,
  setAllData: any,
  cartelle: any,
  allImmagini: any,
  sezioniInventario: any
}


const SezioniScreen: React.FC<SezioniScreenProps> = ({ AllData, setAllData, cartelle, allImmagini, sezioniInventario }) => {

  //VARIABILI --------------------------------------------
  const folder = "/sezioni";
  const { indiceAttivita } = useParams<{ indiceAttivita: string }>();
  const index = indiceAttivita.split('-')[1];
  const [dataAttivita, setDataAttivita] = useState<any>();
  const [sezioni, setSezioni] = useState<any>([]);
  const [categorie, setCategorie] = useState<any>([]);

  const [presentAlert] = useIonAlert();
  const [present, dimiss] = useIonLoading();

  const [oggettoDaModificare, setOggettoDaModificare] = useState<any>()

  const [isModalImportaOpen, setIsModalImportaOpen] = useState(false)

  //Modificia
  const [indiceModifica, setIndiceModifica] = useState<number>()

  //Modali
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalModificheOpen, setIsModalModificheOpen] = useState(false);



  const [presentToast] = useIonToast();

  //CONDIZIONI -------------------------------------------

  const presentToastType = (message: string, type: "danger" | "success" | "warning", buttonEv: () => void) => {

    const iconType = {
      "danger": close,
      "success": cloudDoneOutline,
      "warning": alertCircleOutline,
    }

    presentToast({
      message: message,
      position: "top",
      duration: 3000,
      icon: iconType[type],
      color: type,
      buttons: [
        {
          text: 'Chiudi',
          role: 'cancel',
          handler: () => { buttonEv() }
        }
      ]
    })
  }


  React.useState(() => {
    const data = AllData;
    setDataAttivita(data[parseInt(index)]);
    setSezioni(data[parseInt(index)].sezioni);
    setCategorie(data[parseInt(index)].categorie);

  });


  const handleOrdinamento = (ev: CustomEvent<ItemReorderEventDetail>) => {
    console.log("handleOrdinamento()")
    setSezioni(ev.detail.complete(sezioni));
    salvaOrdinamento(ev.detail.complete(sezioni));
    ev.detail.complete();
  }

  const salvaOrdinamento = async (sezioni: any) => {
    console.log("salvaOrdinamento()")
    let a = [...AllData];
    a[parseInt(index)].sezioni = sezioni;
    await UploadDataSezioni(a[parseInt(index)], a[parseInt(index)].id);
    LocalSaveAttivita(a);
    setAllData(a);
  }

  const rimuoviSezione = async (ind: number) => {
    console.log("rimuoviCategoria()")
    let a = [...AllData];
    let b = [...a[parseInt(index)].sezioni!];
    b.splice(ind, 1);
    setSezioni(b);
    a[parseInt(index)].sezioni = b;
    await UploadDataSezioni(a[parseInt(index)], a[parseInt(index)].id);
    LocalSaveAttivita(a);
    setAllData(a);
  }



  const handleUploadModifiche = async (oggettoModificato: any) => {
    console.log("handleUploadModifiche - oggettoModificato:", oggettoModificato);
    let a = [...AllData];
    a[parseInt(index)].sezioni[indiceModifica!] = oggettoModificato;
    setAllData(a);

    await UploadDataSezioni(a[parseInt(index)], a[parseInt(index)].id);
    LocalSaveAttivita(a);
    dimiss();
    presentToastType("Sezione salvata", "success", () => { });

  }

  const handleAggiungiSezioneDaInventario = async (oggettoSezioneImportato: any) => {
    console.log("handleUploadModifiche - oggettoModificato:", oggettoSezioneImportato);
    let a = [...AllData];
    a[parseInt(index)].sezioni.push(oggettoSezioneImportato);
    setAllData(a);

    //Aggiungi le categorie della sezione in "CATEGORIE - ATTIVITA";

    oggettoSezioneImportato?.categorie.map((categoriaImportata: any, indexImportata: any) => {
      let find = a[parseInt(index)].categorie.find((catEsistente: any) => catEsistente.id === categoriaImportata.id);
      if (find !== undefined) {
        console.log("categoriaImportata è presente nelle categorie esistenti: NON IMPORTARE ", categoriaImportata);
      } else {
        console.log("categoriaImportata Non è presente nelle categorie esistenti: IMPORTA ", categoriaImportata);
        a[parseInt(index)].categorie.push(categoriaImportata);
      }
    })


    await UploadDataSezioni(a[parseInt(index)], a[parseInt(index)].id);
    LocalSaveAttivita(a);
    dimiss();
    presentToastType("Sezione importata,", "success", () => { });

  }

  //RETURN -----------------------------------------------
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonButton routerLink={url_app_attivita_init + indiceAttivita} routerDirection="back">
              <IonIcon icon={arrowBack} />
              {dataAttivita?.nome}
            </IonButton>
          </IonButtons>
          <IonTitle>Sezioni</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Sezioni</IonTitle>
          </IonToolbar>
        </IonHeader>

        <div className='ion-padding'>
          <IonButton expand='block' fill='solid' onClick={() => setIsModalImportaOpen(true)}>
            <IonIcon icon={addCircle} className="ion-margin-end" />
            Importa da inventario
          </IonButton>
        </div>

        <div>
          <IonList>
            <IonReorderGroup disabled={false} onIonItemReorder={handleOrdinamento}>
              {sezioni?.map((item: Sezione, index: number) => {
                return (
                  <IonItem key={index + "sezioni"}>
                    <IonButton color={"danger"} slot='start' fill='solid' onClick={() => presentAlert({
                      header: 'Attenzione!',
                      message: "L'eliminazione è definitiva! La categoria non sarà rimossa dalle sezioni in cui è presente.",
                      buttons: [
                        {
                          text: 'Annulla',
                          role: 'cancel',
                        },
                        {
                          text: 'Conferma',
                          role: 'confirm',
                          handler: () => {
                            rimuoviSezione(index);
                          },
                        },
                      ],
                    })}>
                      <IonIcon icon={trash} />
                    </IonButton>
                    <IonButton slot='start' fill='solid' onClick={() => {
                      setIsModalModificheOpen(true);
                      setOggettoDaModificare(item);
                      setIndiceModifica(index)
                    }}>
                      <IonIcon icon={pencil} />
                    </IonButton>
                    <IonAvatar className='ion-margin-end'>
                      <IonImg src={item.immagineUrl} />
                    </IonAvatar>
                    <IonLabel>
                      <h2>
                        {item.nome}
                      </h2>
                      <p>{item?.descrizione}</p>
                      <p>Categorie: {item?.categorie?.length}</p>
                    </IonLabel>
                    <IonReorder slot="end"></IonReorder>
                  </IonItem>
                )
              })}
            </IonReorderGroup>
          </IonList>
        </div>



        {/* ------------------ MODAL IMPORTA -------------------- */}
        <ModaleImportaDaInventarioSezione
          isOpen={isModalImportaOpen}
          setIsOpen={setIsModalImportaOpen}
          elements={sezioniInventario}
          handleAddElement={handleAggiungiSezioneDaInventario}
        />

        {/* ------------------ MODAL MODIFICA -------------------- */}
        <ModalModificaSezioneAttivita
          oggettoDaModificare={oggettoDaModificare}
          isModalModificheOpen={isModalModificheOpen}
          setIsModalModificheOpen={setIsModalModificheOpen}
          allSezioni={sezioni}
          handleUploadModifiche={handleUploadModifiche}
          cartelle={cartelle}
          allImmagini={allImmagini}
          categorieAttivita={categorie}
          indModifica={indiceModifica} />

      </IonContent>
    </IonPage>
  );
};

export default SezioniScreen;
