import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
} from "@ionic/react";

import "./css/Menu.css";

import { useLocation } from "react-router-dom";
import {
  albumsOutline,
  businessOutline,
  chatbubblesOutline,
  folderOpenOutline,
  folderOutline,
  gridOutline,
  imagesOutline,
  listOutline,
  statsChartOutline,
} from "ionicons/icons";
import { MenuData } from "../data/dataSheet";
import {
  url_app_allAttivita,
  url_app_allImmagini,
  url_app_attivita_init,
  url_app_dashboard,
  url_app_inventario,
  url_app_inventario_categorie,
  url_app_inventario_ingredienti,
  url_app_inventario_prodotti,
  url_app_inventario_sezioni,
  url_app_tavoli_init,
} from "../Urls";

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const pages: AppPage[] = [
  {
    title: "Dashboard",
    url: url_app_dashboard,
    iosIcon: statsChartOutline,
    mdIcon: statsChartOutline,
  },
  {
    title: "Tutte le attività",
    url: url_app_allAttivita,
    iosIcon: businessOutline,
    mdIcon: businessOutline,
  },
  {
    title: "Inventario",
    url: url_app_inventario,
    iosIcon: folderOpenOutline,
    mdIcon: folderOpenOutline,
  },
];

const shortcuts: AppPage[] = [
  {
    title: "Tutte le Immagini",
    url: url_app_allImmagini,
    iosIcon: imagesOutline,
    mdIcon: imagesOutline,
  },
  {
    title: "Ingredienti",
    url: url_app_inventario_ingredienti,
    iosIcon: listOutline,
    mdIcon: listOutline,
  },
  {
    title: "Prodotti",
    url: url_app_inventario_prodotti,
    iosIcon: gridOutline,
    mdIcon: gridOutline,
  },
  {
    title: "Categorie",
    url: url_app_inventario_categorie,
    iosIcon: folderOutline,
    mdIcon: folderOutline,
  },
  {
    title: "Sezioni",
    url: url_app_inventario_sezioni,
    iosIcon: albumsOutline,
    mdIcon: albumsOutline,
  },
];

interface PageProps {
  allActivity: any;
  realTables: any;
}

const Menu: React.FC<PageProps> = ({ allActivity, realTables }) => {
  //VARIABLES -------------------------
  const location = useLocation();

  // console.log("realTables Menu: ", realTables);
  //CONDITIONS ------------------------

  //RETURN ----------------------------
  return (
    <IonMenu contentId="main" type="overlay" className="menuComponent">
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader>Menu</IonListHeader>

          {/* <IonMenuToggle autoHide={false}>
            <IonItem lines="none" routerLink={url_app_tutorial} routerDirection="forward">
              <IonIcon slot="start" ios={helpCircle} md={helpCircle} />
              <IonLabel>{'Come funziona'}</IonLabel>
            </IonItem>
          </IonMenuToggle> */}

          <IonList>
            <IonListHeader>Funzionalità</IonListHeader>
            {pages.map((appPage, index) => {
              return (
                <IonMenuToggle key={index} autoHide={false}>
                  <IonItem
                    lines="inset"
                    className={
                      location.pathname === appPage.url ? "selected" : ""
                    }
                    routerLink={appPage.url}
                    routerDirection="root"
                  >
                    <IonIcon
                      slot="start"
                      ios={appPage.iosIcon}
                      md={appPage.mdIcon}
                    />
                    <IonLabel className="ion-text-wrap">
                      {appPage.title}
                    </IonLabel>
                  </IonItem>
                </IonMenuToggle>
              );
            })}
          </IonList>

          <hr />

          <IonList>
            <IonListHeader>
              <IonIcon icon={businessOutline} className="ion-margin-end" />
              Tutte le attività
            </IonListHeader>
            <IonNote>La lista delle attività che stai gestendo:</IonNote>
            {allActivity.map((data: MenuData, index: number) => {
              return (
                <IonMenuToggle key={index} autoHide={false}>
                  <IonItem
                    lines="inset"
                    routerLink={url_app_attivita_init + data.nome + "-" + index}
                    detail
                  >
                    <IonLabel className="ion-text-wrap">{data.nome}</IonLabel>
                  </IonItem>
                </IonMenuToggle>
              );
            })}
          </IonList>
          <hr />

          <IonList id="labels-list ion-no-padding">
            <h2 className="ion-text-uppercase ion-padding-horizontal">
              <b>shortcut</b>
            </h2>
            <IonListHeader>
              <IonIcon icon={folderOpenOutline} className="ion-margin-end" />
              Inventario
            </IonListHeader>
            <IonNote>Aggiungi, modifica o elimina:</IonNote>
            {shortcuts.map((appPage, index) => {
              return (
                <IonMenuToggle key={index} autoHide={false}>
                  <IonItem
                    lines="inset"
                    className={
                      location.pathname === appPage.url ? "selected" : ""
                    }
                    routerLink={appPage.url}
                    routerDirection="root"
                  >
                    <IonIcon
                      slot="start"
                      ios={appPage.iosIcon}
                      md={appPage.mdIcon}
                    />
                    <IonLabel className="ion-text-wrap">
                      {appPage.title}
                    </IonLabel>
                  </IonItem>
                </IonMenuToggle>
              );
            })}
          </IonList>

          <hr />

          <IonList>
            <IonListHeader>Gestione tavoli</IonListHeader>
            {realTables?.map((table: any, index: number) => {
              return (
                <IonMenuToggle key={index + "MenuTable"} autoHide={false}>
                  <IonItem
                    lines="inset"
                    routerLink={url_app_tavoli_init + table.idAttivita}
                    routerDirection="root"
                  >
                    <IonIcon
                      slot="start"
                      ios={chatbubblesOutline}
                      md={chatbubblesOutline}
                    />
                    <IonLabel className="ion-text-wrap">{table.nome}</IonLabel>
                  </IonItem>
                </IonMenuToggle>
              );
            })}
          </IonList>
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
