import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonIcon, IonImg, IonLabel, IonSegment, IonSegmentButton } from '@ionic/react';
import './ModalInputImmagineSingola.css';
import UploadSingleImage from '../UploadSingleImage';
import { useState } from 'react';
import ModaleImmagini from '../Modale_Immagini/ModaleImmagini';
import { imagesOutline } from 'ionicons/icons';

interface ContainerProps {
  resetImageInput: any,
  inputImmagine?: any,
  setInputImmagine: any,
  immagineSelezionata: any,
  setImmagineSelezionata: any,
  cartelle: any,
  allImmagini: any,
}

const ModalInputImmagineSingola: React.FC<ContainerProps> = ({
  resetImageInput,
  inputImmagine,
  setInputImmagine,
  immagineSelezionata,
  setImmagineSelezionata,
  cartelle,
  allImmagini
}) => {
  //VARIABILI --------------------------------------------
  const [segmentValue, setSegmentValue] = useState("Carica");
  const [isModaleImmaginiOpen, setIsModaleImmaginiOpen] = useState(false);
  //CONDIZIONI -------------------------------------------
  const handleChangeInputImage = (val: string) => {
    setSegmentValue(val);
    resetImageInput();
  }

  //RETURN -----------------------------------------------
  return (
    <>
      <IonSegment value={segmentValue}>
        <IonSegmentButton
          value="Carica"
          onClick={() => handleChangeInputImage("Carica")}
        >
          <IonLabel>Carica Immagine</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton
          value="Seleziona"
          onClick={() => handleChangeInputImage("Seleziona")}
        >
          <IonLabel>Seleziona Immagine</IonLabel>
        </IonSegmentButton>
      </IonSegment>

      {segmentValue === "Carica" ? (
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Carica immagine</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <UploadSingleImage immagine={inputImmagine} setImage={setInputImmagine} />
          </IonCardContent>
        </IonCard>
      ) : (
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Seleziona immagine</IonCardTitle>
            <IonCardSubtitle>
              Se non selezioni nessuna immagine sarà utilizzato il logo di
              MenuShare
            </IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
            <IonButton
              color={
                immagineSelezionata !== undefined ? "success" : "primary"
              }
              expand="block"
              onClick={() => setIsModaleImmaginiOpen(true)}
            >
              <IonIcon icon={imagesOutline} className="ion-margin-end" />
              Seleziona
            </IonButton>
            {immagineSelezionata !== undefined ? (
              <IonImg src={immagineSelezionata}></IonImg>
            ) : null}
          </IonCardContent>
        </IonCard>
      )}

      {/* MODALE IMMAGINI */}
      <ModaleImmagini
        isModalOpen={isModaleImmaginiOpen}
        setIsModalOpen={setIsModaleImmaginiOpen}
        immagineSelezionata={immagineSelezionata}
        setImmagineSelezionata={setImmagineSelezionata}
        cartelle={cartelle}
        allImmagini={allImmagini}
      />
    </>
  );
};

export default ModalInputImmagineSingola;
