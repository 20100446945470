import { IonApp, IonLoading, IonRouterOutlet, setupIonicReact } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router';
import React from 'react'
import { Redirect, Route, Switch } from 'react-router';
import App from './App';
import { AuthContext, useAuthInit } from './auth';
import LoginScreen from './pages/LoginScreen';
import { url_app, url_loginScreen } from './Urls';


setupIonicReact({
  swipeBackEnabled: false,
});


const AccessApp: React.FC = () => {

  //VARIABILI -------------
  const { loading, auth } = useAuthInit();
  //CONDIZIONI --------------
  if (loading) {
    return <IonLoading isOpen={true} />;
  }
  //RETURN ----------------
  return (
    <IonApp>
      <AuthContext.Provider value={auth!}>
        <IonReactRouter>
          <Switch>
            <IonRouterOutlet>
              <Route exact path={url_loginScreen}>
                <LoginScreen />
              </Route>

              <Route exact path={url_app}>
                <App />
              </Route>

              <Redirect exact path="/" to={url_loginScreen} />

            </IonRouterOutlet>
          </Switch>
        </IonReactRouter>

      </AuthContext.Provider>
    </IonApp>
  )
}

export default AccessApp