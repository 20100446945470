import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonModal, IonPage, IonTitle, IonToolbar, useIonLoading, useIonToast } from '@ionic/react';
import { alertCircleOutline, arrowBack, close, cloudDoneOutline, pencil } from 'ionicons/icons';
import React from 'react';
import { useParams } from 'react-router';
import { Orario } from '../data/dataSheet';
import { UploadDataOrari } from '../Logic/DataUpload';
import { LocalSaveAttivita } from '../Logic/LocalStorage';
import '../pages/CSS/OrariScreen.css';
import { url_app_attivita_init } from '../Urls';

type OrariScreenProps = {
  AllData: any,
  setAllData: any,
}

const OrariScreen: React.FC<OrariScreenProps> = ({ AllData, setAllData }) => {

  //VARIABILI --------------------------------------------
  const { indiceAttivita } = useParams<{ indiceAttivita: string }>();
  const index = indiceAttivita.split('-')[1];
  // const data = AllData?.attivita[parseInt(index)];

  const [dataAttivita, setDataAttivita] = React.useState<any>();
  const [orari, setOrari] = React.useState<any>();

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  //aggiungi -----
  const [giornata, setGiornata] = React.useState<Orario>();
  const [indiceModifica, setIndiceModifica] = React.useState<any>();

  const [mattinaInizio, setMattinaInizio] = React.useState("");
  const [mattinaFine, setMattinaFine] = React.useState("");
  const [isMattinaChiuso, setIsMattinaChiuso] = React.useState<boolean>(false);
  const [pomeriggioInizio, setPomeriggioInizio] = React.useState("");
  const [pomeriggioFine, setPomeriggioFine] = React.useState("");
  const [isPomeriggioChiuso, setIsPomeriggioChiuso] = React.useState<boolean>(false);

  const [presentToast] = useIonToast();
  const [present, dimiss] = useIonLoading();


  //CONDIZIONI -------------------------------------------
  const presentToastType = (message: string, type: "danger" | "success" | "warning", buttonEv: () => void) => {

    const iconType = {
      "danger": close,
      "success": cloudDoneOutline,
      "warning": alertCircleOutline,
    }

    presentToast({
      message: message,
      position: "top",
      duration: type === "danger" ? undefined : 3000,
      icon: iconType[type],
      color: type,
      buttons: [
        {
          text: 'Chiudi',
          role: 'cancel',
          handler: () => { buttonEv() }
        }
      ]
    })
  }

  React.useState(() => {
    const data = AllData;
    setDataAttivita(data[parseInt(index)]);
    setOrari(data[parseInt(index)].orari);
  });

  const handleModifica = (ind: number) => {
    console.log("indice modifica : ", ind);
    setGiornata(orari[ind]);
    setIndiceModifica(ind);
    setMattinaInizio(orari[ind].orario[0].inizio)
    setMattinaFine(orari[ind].orario[0].fine)
    setIsMattinaChiuso(orari[ind].orario[0].chiuso)
    setPomeriggioInizio(orari[ind].orario[1].inizio)
    setPomeriggioFine(orari[ind].orario[1].fine)
    setIsPomeriggioChiuso(orari[ind].orario[1].chiuso)
    setIsModalOpen(true);
  }

  const handleSalvaOrari = async () => {
    present({
      message: "Sto aggiornando gli orari..."
    });
    let a = [...AllData];
    let nuovoOrario: Orario = {
      giorno: giornata?.giorno!,
      orario: [
        {
          inizio: mattinaInizio,
          fine: mattinaFine,
          chiuso: isMattinaChiuso,
        },
        {
          inizio: pomeriggioInizio,
          fine: pomeriggioFine,
          chiuso: isPomeriggioChiuso,
        },
      ]
    }
    console.log("nuovoOrario : ", nuovoOrario);

    a[parseInt(index)].orari[indiceModifica] = nuovoOrario;
    setAllData(a);
    console.log("UploadDataOrari(a[parseInt(index)], a[parseInt(index)].id) : ", a[parseInt(index)], a[parseInt(index)].id);
    try {
      await UploadDataOrari(a[parseInt(index)], a[parseInt(index)].id);

      LocalSaveAttivita(a);
      resetAllDataInput();
      dimiss();
      presentToastType("Orari aggiornati", "success", () => { });
    } catch (error) {
      console.error(error);
      presentToastType("Errore di connessione, riprovare", "danger", () => { dimiss() });
    }

  }

  const resetAllDataInput = () => {
    setIndiceModifica(undefined);
  }

  //RETURN -----------------------------------------------
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonButton routerLink={url_app_attivita_init + indiceAttivita} routerDirection="back">
              <IonIcon icon={arrowBack} />
              {dataAttivita?.nome}
            </IonButton>
          </IonButtons>
          <IonTitle>Orari</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Orari</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div>
          {orari?.map((ora: Orario, index: number) => {
            return (
              <IonCard key={"orario" + index}>
                <IonCardHeader>
                  <IonCardSubtitle>
                    {ora?.giorno}
                  </IonCardSubtitle>
                </IonCardHeader>
                <IonCardContent>
                  {giornata?.chiuso ?
                    <p>Chiuso</p>
                    :
                    <>
                      {
                        ora.orario[0].chiuso ?
                          <p>Mattina: Chiuso</p>
                          :
                          <>
                            <p>
                              Mattina: {ora.orario[0].inizio} - {ora.orario[0].fine}
                            </p>
                          </>
                      }
                      {
                        ora.orario[1].chiuso ?
                          <p>Pomeriggio: Chiuso</p>
                          :
                          <p>
                            Pomeriggio: {ora.orario[1].inizio} - {ora.orario[1].fine}
                          </p>
                      }

                    </>
                  }
                </IonCardContent>

                <div className='ion-padding-start'>
                  <IonCardSubtitle>
                    <IonButton size='small' onClick={() => handleModifica(index)}>
                      <IonIcon icon={pencil} />
                    </IonButton>
                  </IonCardSubtitle>
                </div>
              </IonCard>
            )
          })}
        </div>


        {/* ----------------- MODAL AGGIUNGI ------------------ */}

        <IonModal isOpen={isModalOpen} onDidDismiss={() => setIsModalOpen(false)}>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton onClick={() => setIsModalOpen(false)}>Chiudi</IonButton>
              </IonButtons>
              <IonTitle>Modifica giornata</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => { handleSalvaOrari(); setIsModalOpen(false) }}>Salva</IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonCard>
              <IonCardHeader>
                {giornata?.giorno}
              </IonCardHeader>
              <IonCardContent>
                <IonCardContent>
                  {
                    isMattinaChiuso ?
                      <p>Mattina: Chiuso</p>
                      :
                      <p>
                        Mattina: {mattinaInizio} - {mattinaFine}
                      </p>
                  }
                  {
                    isPomeriggioChiuso ?
                      <p>Pomeriggio: Chiuso</p>
                      :
                      <p>
                        Pomeriggio: {pomeriggioInizio} - {pomeriggioFine}
                      </p>
                  }
                </IonCardContent>

              </IonCardContent>
            </IonCard>

            <div className='ion-padding windowDubleHeight'>
              <IonList>
                <IonLabel>
                  <div className='ion-padding'>
                    <p>Mattina</p>
                    <IonButton color={"warning"} style={{ position: "absolute", right: 10, top: 5 }} onClick={() => setIsMattinaChiuso(!isMattinaChiuso)} size="small">
                      {!isMattinaChiuso ? "Chiudi" : "Apri"}
                    </IonButton>
                  </div>
                </IonLabel>
                <IonItem fill="outline">
                  <IonLabel position="stacked">Orario apertura</IonLabel>
                  <IonInput value={mattinaInizio} onIonInput={(ev: any) => { setMattinaInizio(ev.target.value) }} clearInput placeholder="es: 08:00"></IonInput>
                </IonItem>

                <IonItem fill="outline">
                  <IonLabel position="stacked">Orario chiusura</IonLabel>
                  <IonInput value={mattinaFine} onIonInput={(ev: any) => { setMattinaFine(ev.target.value) }} clearInput placeholder="es: 12:50"></IonInput>
                </IonItem>
              </IonList>
              <br /><br />
              <IonList>
                <IonLabel>
                  <div className='ion-padding'>
                    <p>Pomeriggio</p>
                    <IonButton color={"warning"} style={{ position: "absolute", right: 10, top: 5 }} onClick={() => setIsPomeriggioChiuso(!isPomeriggioChiuso)} size="small">
                      {!isPomeriggioChiuso ? "Chiudi" : "Apri"}
                    </IonButton>
                  </div>
                </IonLabel>
                <IonItem fill="outline">
                  <IonLabel position="stacked">Orario apertura</IonLabel>
                  <IonInput value={pomeriggioInizio} onIonInput={(ev: any) => { setPomeriggioInizio(ev.target.value) }} clearInput placeholder="es: 13:50"></IonInput>
                </IonItem>

                <IonItem fill="outline">
                  <IonLabel position="stacked">Orario chiusura</IonLabel>
                  <IonInput value={pomeriggioFine} onIonInput={(ev: any) => { setPomeriggioFine(ev.target.value) }} clearInput placeholder="es: 19:50"></IonInput>
                </IonItem>
              </IonList>
            </div>

          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default OrariScreen;
