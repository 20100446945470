import { IonAvatar, IonBadge, IonButton, IonCard, IonCardContent, IonCardHeader, IonChip, IonImg, IonItem, IonLabel, IonList } from '@ionic/react';
import { useEffect, useState } from 'react';
import './OrderComponent.css';

interface ContainerProps {
  data: any;
  handleInProgressOrder: () => void;
  handleCompleteOrder: () => void;
}

const OrderComponent: React.FC<ContainerProps> = ({ data, handleInProgressOrder, handleCompleteOrder }) => {
  //VARIABILI -----------------
  const [colorStatus, setColorStatus] = useState<"warning" | "primary" | "success">();
  //CONDIZIONI ----------------
  useEffect(() => {
    switch (data?.status) {
      case "Inviato":
        setColorStatus("warning");
        break;
      case "Preparazione in corso":
        setColorStatus("primary");
        break;
      case "Consegnato":
        setColorStatus("success");
        break;

      default:
        break;
    }
  }, [data])
  //RETURN --------------------
  return (
    <IonCard className='orderComponent'>
      <IonCardHeader>
        <div className='orderComponent-align-center'>
          Stato ordine:
          <IonBadge color={colorStatus} className='ion-margin-start'>{data?.status}</IonBadge>
        </div>
        <div className='orderComponent-align-center'>
          Ordinato alle:
          <IonBadge color={colorStatus} className='ion-margin-start'>{data?.when}</IonBadge>
        </div>
      </IonCardHeader>
      <IonCardContent>
        <p>Ordine: </p>
        <IonList>
          {data?.order.map((dataOrder: any, index: number) => {
            return (
              <IonItem lines='full' key={"ordine" + data?.when + index}>
                <IonAvatar>
                  <IonImg src={dataOrder.product?.immaginiUrl[0]}></IonImg>
                </IonAvatar>
                <IonLabel className='ion-margin-start'>
                  <h2> <b>{dataOrder.product?.nome}</b> </h2>
                  {dataOrder.withOut?.length > 0 ?
                    <div>
                      <IonLabel>
                        <p>Senza:</p>
                        {dataOrder.withOut?.map((ingrediente: any, index: number) => {
                          return (
                            <IonChip key={"SenzaIngrediente" + index} color={"danger"}>
                              {ingrediente.nome}
                            </IonChip>
                          )
                        })}
                      </IonLabel>
                    </div>
                    : null
                  }

                </IonLabel>
              </IonItem>
            )
          })}
        </IonList>

      </IonCardContent>
      <div className='ion-padding'>
        {colorStatus === "primary" ?
          <IonButton expand='block' color={"success"} onClick={() => { handleCompleteOrder() }}>
            Completa ordine
          </IonButton>
          :
          colorStatus !== "success" ?
            <IonButton expand='block' color={"primary"} onClick={() => handleInProgressOrder()}>
              Preprara ordine
            </IonButton> :
            <IonButton expand='block' fill='clear' color={"success"}>
              Ordine consegnato
            </IonButton>
        }

      </div>


    </IonCard>
  );
};

export default OrderComponent;
