import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardHeader,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonPage,
  IonRow,
  IonSearchbar,
  IonTitle,
  IonToolbar,
  useIonAlert,
  useIonLoading,
  useIonToast,
} from "@ionic/react";
import {
  add,
  addOutline,
  alertCircleOutline,
  arrowBack,
  close,
  cloudDoneOutline,
  trashOutline,
} from "ionicons/icons";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import UploadMultipleImagesPortfolio from "../components/UploadMultipleImagesPortfolio";
import { UploadDataCartelle, UploadDataPortfolio } from "../Logic/DataUpload";
import { UploadImagesFirebase } from "../Logic/ImageUpload";
import { LocalSaveAttivita } from "../Logic/LocalStorage";
import { url_app_attivita_init } from "../Urls";
import "./CSS/PortfolioScreen.css";

interface PageProps {
  AllData: any;
  setAllData: any;
}

const PortfolioScreen: React.FC<PageProps> = ({ AllData, setAllData }) => {
  //VARIABILI ------------------------------

  const { indiceAttivita } = useParams<{ indiceAttivita: string }>();
  const [dataAttivita, setDataAttivita] = useState<any>();
  const [allImmagini, setAllImmagini] = useState<any>([]);
  const index = indiceAttivita.split("-")[1];
  const folder = "/portfolio-" + index;
  const [isModalAddOpen, setIsModalAddOpen] = useState<boolean>(false);
  const [presentToast] = useIonToast();

  const [cartelle, setCartelle] = useState<any>([]);

  const [filtroImmagini, setFiltroImmagini] = useState<string>();

  const [titolo, setTitolo] = useState<string | undefined>();
  const [inputImmagini, setInputImmagini] = useState<any>();
  const [inputCartella, setInputCartella] = useState<any>();
  const [id, setId] = useState<number>();
  const [allImagesUploaded, setAllImagesUploaded] = useState<boolean>(false);
  const [linkImmagini, setLinkImmagini] = useState<any[]>([]);

  const [modaleImmagine, setModaleImmagine] = useState(false);
  const [visualizzaImmagine, setVisualizzaImmagine] = useState<any>();

  const [present, dimiss] = useIonLoading();
  const [presentAlert] = useIonAlert();
  const [searchTextModale, setSearchTextModale] = useState("");
  const [presentAlertNomeCartella] = useIonAlert();

  //CONDIZIONI -----------------------------
  useEffect(() => {
    const data = AllData;
    console.log("AttivitaScreen : AllData : ", AllData);
    setDataAttivita(data[parseInt(index)]);
    setAllImmagini(data[parseInt(index)]?.portfolio);
    setCartelle(data[parseInt(index)]?.cartelleGallery);
  }, [AllData]);

  const handleAggiungiImmagini = async () => {
    if (titolo !== undefined && inputImmagini.length > 0 && inputCartella !== undefined) {
      setIsModalAddOpen(false);
      present({
        message: "Sto caricando le immagini...",
      });
      let auxId = Date.now();
      setId(auxId);
      await UploadImagesFirebase(
        inputImmagini!,
        titolo + auxId + new Date(),
        setLinkImmagini,
        folder,
        setAllImagesUploaded
      );
    }else{
      presentToastType("Attenzione selezionare e completare tutti i campi.", "warning", () => {});
    }
  };

  useEffect(() => {
    if (allImagesUploaded === true) {
      let auxArr: any = [];
      linkImmagini.map((link: string, index: number) => {
        const objImg = {
          titolo: titolo,
          immagineUrl: link,
          cartella: inputCartella,
        };
        auxArr.push(objImg);
      });
      console.log("ARRAY IMMAGINI : ", auxArr);
      UpdateData(auxArr);
    }
  }, [allImagesUploaded]);

  const UpdateData = async (arrImmagini: any) => {
    let a = [...AllData];
    let b = a[parseInt(index)].portfolio.concat(arrImmagini);
    a[parseInt(index)].portfolio = b;
    console.log("PORTFOLIO : ", a[parseInt(index)].portfolio);
    await UploadDataPortfolio(a[parseInt(index)], a[parseInt(index)].id).then(
      () => {
        setAllData(a);
        LocalSaveAttivita(a);
        resetAllDataInput();
        presentToastType("Portfolio aggiornato", "success", () => {});
        dimiss();
      }
    );
  };

  const resetAllDataInput = () => {
    setTitolo(undefined);
    setInputImmagini(null);
    setId(undefined);
    setInputCartella(undefined);
    setAllImagesUploaded(false);
    setLinkImmagini([]);
  };

  const handleEliminaImmagine = (ind: number) => {
    presentAlert({
      header: "Attenzione!",
      message: "L'eliminazione è definitiva!",
      buttons: [
        {
          text: "Annulla",
          role: "cancel",
        },
        {
          text: "Conferma",
          role: "confirm",
          handler: () => {
            updateEliminazione(ind);
          },
        },
      ],
    });
  };

  const updateEliminazione = async (ind: number) => {
    let a = [...AllData];
    setAllImmagini((allImmagini: any) =>
      allImmagini.filter((_: any, i: any) => i !== ind)
    );

    a[parseInt(index)].portfolio = allImmagini.filter(
      (_: any, i: any) => i !== ind
    );

    await UploadDataPortfolio(a[parseInt(index)], a[parseInt(index)].id).then(
      () => {
        setAllData(a);
        LocalSaveAttivita(a);
        resetAllDataInput();
        presentToastType("Portfolio aggiornato", "success", () => {});
        dimiss();
      }
    );
  };

  const handleSearch = (testoCercato: string) => {
    setFiltroImmagini(testoCercato);
    let arr = Array.from(
      window.document.getElementsByClassName("immaginePortfolio")
    );
    const query = testoCercato.toLowerCase();
    requestAnimationFrame(() => {
      arr.forEach((item: any) => {
        const shouldShow = item.textContent.toLowerCase().indexOf(query) > -1;
        item.style.display = shouldShow ? "block" : "none";
      });
    });
  };

  const handleAggiungiNuovaCartella = () => {
    presentAlertNomeCartella({
      header: "Creazione nuova cartella",
      inputs: [
        {
          type: "text",
          placeholder: "Nome",
          name: "nomeCartella",
        },
      ],
      buttons: [
        {
          text: "Annulla",
          role: "cancel",
          handler: () => {},
        },
        {
          text: "Crea",
          role: "confirm",
          handler: (alertData) => {
            let a = [...AllData];

            let x = cartelle.concat(alertData.nomeCartella);
            a[parseInt(index)].cartelleGallery = x;
            setCartelle(x);
            setAllData(a);
            LocalSaveAttivita(a);
            resetAllDataInput();

            UploadDataCartelle(a[parseInt(index)], a[parseInt(index)].id);
          },
        },
      ],
    });
  };

  const handleFiltraVisualizzazione = (nome: any) => {
    let arr = Array.from(
      window.document.getElementsByClassName("immaginePortfolio")
    );
    const query = nome.toLowerCase();
    requestAnimationFrame(() => {
      arr.forEach((item: any) => {
        const shouldShow = item.textContent.toLowerCase().indexOf(query) > -1;
        item.style.display = shouldShow ? "block" : "none";
      });
    });
  };

  const handleVisualizzaTutte = () => {
    setFiltroImmagini("");
    let arr = Array.from(
      window.document.getElementsByClassName("immaginePortfolio")
    );
    requestAnimationFrame(() => {
      arr.forEach((item: any) => {
        item.style.display = "block";
      });
    });
  };


  const presentToastType = (
    message: string,
    type: "danger" | "success" | "warning",
    buttonEv: () => void
  ) => {
    const iconType = {
      danger: close,
      success: cloudDoneOutline,
      warning: alertCircleOutline,
    };

    presentToast({
      message: message,
      position: "top",
      duration: type === "danger" ? undefined : 3000,
      icon: iconType[type],
      color: type,
      buttons: [
        {
          text: "Chiudi",
          role: "cancel",
          handler: () => {
            buttonEv();
          },
        },
      ],
    });
  };
  //RETURN ---------------------------------
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton
              routerLink={url_app_attivita_init + indiceAttivita}
              routerDirection="back"
            >
              <IonIcon icon={arrowBack} />
              {dataAttivita?.nome}
            </IonButton>
          </IonButtons>
          <IonTitle>Portfolio Immagini</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Portfolio Immagini</IonTitle>
          </IonToolbar>
        </IonHeader>

        {/* CONTENUTO */}

        <IonSearchbar
          value={searchTextModale}
          placeholder={"Cerca titolo immagine"}
          onIonChange={(e) => {
            setSearchTextModale(e.detail.value!);
            handleSearch(e.detail.value!);
          }}
        ></IonSearchbar>

        <div className="ion-padding">
          <IonButton expand="block" onClick={() => setIsModalAddOpen(true)}>
            <IonIcon icon={addOutline} />
            Aggiungi immagini
          </IonButton>
        </div>

        <div>
          <div className="ion-padding">
            <IonLabel>
              <p>Cartelle presenti:</p>
            </IonLabel>
            <IonChip
              onClick={() => {
                handleVisualizzaTutte();
              }}
            >
              Visualizza tutte
            </IonChip>
            {cartelle?.map((nome: string, index: number) => {
              return (
                <IonChip
                  key={"filtroImmagini" + index}
                  onClick={() => {
                    handleFiltraVisualizzazione(nome);
                    setFiltroImmagini(nome);
                  }}
                  color={filtroImmagini == nome ? "success" : "primary"}
                >
                  {nome}
                </IonChip>
              );
            })}
          </div>
          <IonLabel>
            <p className="ion-padding ion-align-items-center">Immagini presenti:</p>
          </IonLabel>

          <div className="row-portfolio">
            {allImmagini?.map((immagineObj: any, index: number) => {
              return (
                <IonCard
                  key={"immagine-" + index}
                  className="column-portfolio immaginePortfolio"
                >
                  <IonCardHeader>
                    <IonGrid>
                      <IonRow className="ion-align-items-center">
                        <IonCol>
                          <IonLabel>
                            <p>Cartella:</p>
                            <h2>{immagineObj.cartella}</h2>
                          </IonLabel>
                        </IonCol>
                        <IonCol>
                          <IonLabel>
                            <p>Titolo:</p>
                            <h2>{immagineObj.titolo}</h2>
                          </IonLabel>
                        </IonCol>
                        <IonCol className="ion-text-end">
                          <IonButton
                            size="small"
                            color={"danger"}
                            onClick={() => handleEliminaImmagine(index)}
                          >
                            <IonIcon icon={trashOutline} />
                          </IonButton>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCardHeader>
                  <IonImg
                   onClick={()=>{ setVisualizzaImmagine(immagineObj);setModaleImmagine(true);}}
                   src={immagineObj.immagineUrl} />
                </IonCard>
              );
            })}
          </div>
        </div>

        {/* MODALE IMMAGINI */}
        <IonModal
          isOpen={modaleImmagine}
          onDidDismiss={() => setModaleImmagine(false)}
        >
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton
                  color={"medium"}
                  onClick={() => setModaleImmagine(false)}
                >
                  Chiudi
                </IonButton>
              </IonButtons>
              <IonTitle>{visualizzaImmagine?.titolo}</IonTitle>
            </IonToolbar>
          </IonHeader>

          <IonContent fullscreen>
            <IonImg src={visualizzaImmagine?.immagineUrl} />
          </IonContent>
        </IonModal>


        {/* MODALI */}
        <IonModal
          isOpen={isModalAddOpen}
          onDidDismiss={() => setIsModalAddOpen(false)}
        >
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton
                  color={"medium"}
                  onClick={() => setIsModalAddOpen(false)}
                >
                  Annulla
                </IonButton>
              </IonButtons>
              <IonTitle>Seleziona immagini</IonTitle>
              <IonButtons slot="end">
                <IonButton
                  fill="solid"
                  color={"success"}
                  onClick={() => {
                    handleAggiungiImmagini();
                  }}
                >
                  Aggiungi
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>

          <IonContent fullscreen>
            <IonLabel>
              <p className="ion-padding">
                Attenzione: è consigliato avere tutte le immagini (per titolo)
                nella stessa cartella. La navigazione e selezione di immagini
                tra cartelle diverse non è attiva.
              </p>
            </IonLabel>

            <div className="ion-padding">
              <IonLabel>
                <p>Seleziona cartella:</p>
                {cartelle?.length === 0 ? (
                  <p>Nessuna cartella disponibile</p>
                ) : null}
              </IonLabel>

              {cartelle?.map((nome: string, index: number) => {
                return (
                  <IonChip
                    key={"Cartella" + index}
                    onClick={() => setInputCartella(nome)}
                    color={inputCartella == nome ? "success" : "primary"}
                  >
                    {nome}
                  </IonChip>
                );
              })}

              <IonButton
                expand="block"
                onClick={() => {
                  handleAggiungiNuovaCartella();
                }}
              >
                <IonIcon icon={add} />
                Crea nuova cartella
              </IonButton>
            </div>

            <div className="ion-padding">
              <IonItem fill="outline">
                <IonLabel position="stacked">Inserisci titolo</IonLabel>
                <IonInput
                  onIonInput={(ev: any) => {
                    setTitolo(ev.target.value);
                  }}
                  clearInput
                  placeholder="Obbligatorio"
                ></IonInput>
              </IonItem>
            </div>
            <div className="ion-padding">
              <UploadMultipleImagesPortfolio setImage={setInputImmagini} />
            </div>
          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default PortfolioScreen;
